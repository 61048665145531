import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, P, ul } from "../../brand/typography";
import { SideBySide } from "../RuleBookParts";
import {
  FastTravelRulesFrench,
  FastTravelRulesEnglish,
  FastTravelRulesItalian,
  FastTravelRulesSpanish,
  FastTravelRulesGerman,
} from "./FastTravelRules";
import { ChaosGolfIcon } from "./ChaosGolfIcon";
import { FromMatchPlay } from "./FromMatchPlay";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";

const FrenchModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF</H1>
      <P>
        Partez dans n'importe quelle direction et marquez les trous dans
        n'importe quel ordre. Nous avons supprimé la contrainte de jouer d'un
        trou à l'autre dans l'ordre. Le score le plus élevé l'emporte.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>3 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous avez un groupe de personnes qui aiment moins les règles du golf et
        qui préfèrent un jeu plus chaotique. Cette version de 18 Holes réduit
        l'influence de la chance dans le jeu.
      </P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Le score de chaque joueur est la somme des jetons de 2 et 5 points qu'il
        a collectés. Le score le plus élevé gagne.
      </P>

      <H3>Variations des règles</H3>
      <FromMatchPlay />

      <H4>Configuration</H4>
      <P>
        Pour obtenir les meilleurs résultats, concevez un parcours très serré
        avec tous les hexagones multi-green inclus. Ignorer l'exigence de la
        tuile du fairway (par exemple, la tuile +1) donne de bons résultats.
      </P>

      <SideBySide RightComponent={ChaosGolfIcon} size={200}>
        <P>
          Placez un jeton de 2 et de 5 points sur chaque trou. Assurez-vous que
          le numéro du trou soit le même pour les deux. L'ordre des trous n'a
          pas d'importance. Pour de meilleurs résultats, placez le jeton à 5
          points côté orange vers le haut et le jeton à 2 points du côté du
          numéro. Cela permet de faire ressortir un peu plus les greens.
        </P>
      </SideBySide>

      <H4>Comment jouer</H4>
      <P>
        Les joueurs peuvent frapper dans n'importe quelle direction et essayer
        de revendiquer un trou dans n'importe quel ordre. 
      </P>
      <P>
        Lorsqu'il atterrit sur un tee box, le joueur peut jouer un autre tour.
        Cela permet d'enchaîner et de se déplacer plus rapidement sur le
        parcours. Tous les tours supplémentaires sont pris dans l'ordre
        d'initiative après que tous les joueurs aient terminé leur tour initial.
      </P>

      <H4>Marquage des points</H4>
      <P>
        Le joueur qui atterrit le premier sur le green prend le grand jeton de
        score (5 points) et la deuxième personne reçoit le petit jeton (2
        points). Il n'y a pas d’égalité et l'ordre d'initiative est utilisé pour
        déterminer qui prend le jeton de 5 points.
      </P>
      <P>
        La personne qui a pris le grand jeton de score ne peut pas prendre le
        petit jeton pour le même trou. Utilisez les numéros des trous sur les
        jetons de score pour vérifier.
      </P>
      <P>
        Quand un joueur a pris le dernier jeton de 5 points, le jeu se termine à
        la fin de ce tour. Les jetons à 2 points restants sont ignorés.
      </P>
      <P>Le joueur qui a le plus de points gagne.</P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <ul className={ul}>
        <li>Jetons à 5 points</li>
        <li>Jetons à 2 points</li>
      </ul>

      <FastTravelRulesFrench />
    </>
  );
};

const EnglishModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF</H1>
      <P>
        Tee off in any direction and score the holes in any order. Golf where we
        removed the constraints of playing from hole to hole in order. Highest
        score wins.
      </P>

      <H3>Plays</H3>
      <P>3 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You have a group of people who like something a bit less like golf and a
        bit more chaotic. This version of 18 Holes reduces the influence of luck
        in the game.
      </P>

      <H3>HOW TO WIN</H3>
      <P>
        Each player's score is the sum of their collected 2 and 5 point tokens.
        Highest score wins.
      </P>

      <H3>Rule Variations</H3>
      <FromMatchPlay />

      <H4>Setup</H4>
      <P>
        For best results, design a tightly packed course with all the
        multi-green hexes included. Ignoring the fairway tile requirement (e.g.
        +1 tile) yields good results.
      </P>

      <SideBySide RightComponent={ChaosGolfIcon} size={200}>
        <P>
          Place a 2 and a 5 point token on each hole. Make sure the hole number
          is the same for both. The hole sequencing does not matter. For best
          results, have the 5-point token orange side up and the 2-point hole
          number-side up. Doing so helps make the greens stand out a bit more.
        </P>
      </SideBySide>

      <H4>Game Play</H4>
      <P>
        Players may hit in any direction and try to claim any hole in any order.
      </P>
      <P>
        When landing on a tee box, the player may take another turn. This chains
        and allows for faster travel across the course. Any extra turns are
        taken in initiative order after all players have finished their initial
        round.
      </P>

      <H4>Scoring</H4>
      <P>
        The player who first lands on the green takes the large scoring token (5
        points) and the second person receives the smaller token (2 points).
        There is no tie-break and initiative order is used to determine who
        takes the 5 point token.
      </P>
      <P>
        The person who took the large scoring token cannot take the small token
        for the same hole. Use the hole numbers on the score tokens to check.
      </P>
      <P>
        Once someone has claimed the last 5-point token, the game ends at the
        end of that round. Any remaining 2-point tokens are ignored.
      </P>
      <P>The player with the most points wins.</P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>5-point tokens</li>
        <li>2-point tokens</li>
      </ul>

      <FastTravelRulesEnglish />
    </>
  );
};

const SpanishModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF</H1>
      <P>
        Saca en cualquier dirección y marca los hoyos en cualquier orden. El
        golf en el que eliminamos las limitaciones de jugar de hoyo en hoyo en
        orden. La puntuación más alta gana.
      </P>

      <H3>Jugadas</H3>
      <P>3 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Tienes un grupo de gente a la que le gusta algo menos como el golf y un
        poco más caótico. Esta versión de 18 Holes reduce la influencia de la
        suerte en el juego.
      </P>

      <H3>CÓMO GANAR</H3>
      <P>
        El puntaje de cada jugador es la suma de sus fichas de 2 y 5 puntos
        conseguidas. La puntuación más alta gana.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromMatchPlay />

      <H4>Setup</H4>
      <P>
        For best results, design a tightly packed course with all the
        multi-green hexes included. Ignoring the fairway tile requirement (e.g.
        +1 tile) yields good results.
      </P>

      <SideBySide RightComponent={ChaosGolfIcon} size={200}>
        <P>
          Place a 2 and a 5 point token on each hole. Make sure the hole number
          is the same for both. The hole sequencing does not matter. For best
          results, have the 5-point token orange side up and the 2-point hole
          number-side up. Doing so helps make the greens stand out a bit more.
        </P>
      </SideBySide>

      <H4>Game Play</H4>
      <P>
        Players may hit in any direction and try to claim any hole in any order.
      </P>
      <P>
        When landing on a tee box, the player may take another turn. This chains
        and allows for faster travel across the course. Any extra turns are
        taken in initiative order after all players have finished their initial
        round.
      </P>

      <H4>Scoring</H4>
      <P>
        The player who first lands on the green takes the large scoring token (5
        points) and the second person receives the smaller token (2 points).
        There is no tie-break and initiative order is used to determine who
        takes the 5 point token.
      </P>
      <P>
        The person who took the large scoring token cannot take the small token
        for the same hole. Use the hole numbers on the score tokens to check.
      </P>
      <P>
        Once someone has claimed the last 5-point token, the game ends at the
        end of that round. Any remaining 2-point tokens are ignored.
      </P>
      <P>The player with the most points wins.</P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>5-point tokens</li>
        <li>2-point tokens</li>
      </ul>

      <FastTravelRulesSpanish />
    </>
  );
};

const GermanModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF</H1>
      <P>
        Schlage in jede Richtung ab und schlage die Löcher in beliebiger
        Reihenfolge an. Golfe, wo wir die Zwänge des Spiels von Loch zu Loch in
        beliebiger Reihenfolge aufgehoben haben. Die höchste Punktzahl gewinnt.
      </P>

      <H3>Spieler</H3>
      <P>3 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        … du eine Gruppe von Menschen hast, die klassisches Golf etwas weniger
        mögen und lieber etwas Chaotischeres möchten. Diese Version von 18 Holes
        reduziert den Einfluss des Glücks im Spiel.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Die Punktzahl jedes Spielers ergibt sich aus der Summe seiner
        gesammelten 2- und 5-Punkte-Token. Die höchste Punktzahl gewinnt.
      </P>

      <H3>Regelvariationen</H3>
      <FromMatchPlay />

      <H4>Aufbau</H4>
      <P>
        Um beste Ergebnisse zu erzielen, entwirf einen dicht gedrängten
        Golfplatz, der alle Green-Felder enthält. Das Ignorieren der
        Fairway-Platten-Anforderung (z.B. +1 Platten) führt zu guten
        Ergebnissen.
      </P>

      <SideBySide RightComponent={ChaosGolfIcon} size={200}>
        <P>
          Platziere einen 2- und einen 5-Punkte-Token auf jedem Loch. Achte
          darauf, dass die Lochnummer für beide gleich ist. Die Reihenfolge der
          Löcher spielt keine Rolle. Die besten Ergebnisse erzielst du, wenn der
          5-Punkte-Token mit der orangen Seite nach oben und die
          2-Punkte-Lochnummer mit der Seite nach oben platziert wird. Dadurch
          kommen die Greens etwas besser zur Geltung.
        </P>
      </SideBySide>

      <H4>Gameplay</H4>
      <P>
        Die Spieler dürfen in jede Richtung schlagen und versuchen, jedes Loch
        in beliebiger Reihenfolge zu beanspruchen.
      </P>
      <P>
        Beim Landen auf einem Abschlagplatz darf der Spieler einen weiteren Zug
        ausführen. Dies verkettet und ermöglicht eine schnellere Bewegung über
        den Golfplatz. Alle zusätzlichen Züge werden in der Reihenfolge der
        Initiative ausgeführt, nachdem alle Spieler ihre erste Runde beendet
        haben.
      </P>

      <H4>Punkten</H4>
      <P>
        Der Spieler, der als Erster auf dem Green landet, erhält den großen
        Punkte-Token (5 Punkte) und die zweite Person erhält den kleineren Token
        (2 Punkte). Es gibt keinen Tie-Break und die Reihenfolge der Initiative
        bestimmt, wer den 5-Punkte-Token erhält.
      </P>
      <P>
        Die Person, die den großen Punkte-Token genommen hat, kann nicht den
        kleinen Token für dasselbe Loch nehmen. Verwende zur Kontrolle die
        Lochnummern auf den Punkte-Token.
      </P>
      <P>
        Sobald jemand den letzten 5-Punkte-Token beansprucht hat, endet das
        Spiel am Ende dieser Runde. Alle verbleibenden 2-Punkte-Token werden
        ignoriert.
      </P>
      <P>Der Spieler mit den meisten Punkten gewinnt.</P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <ul className={ul}>
        <li>5-Punkt-Token</li>
        <li>2-Punkt-Token</li>
      </ul>

      <FastTravelRulesGerman />
    </>
  );
};

const ItalianModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF</H1>
      <P>
        Parti in qualsiasi direzione e metti a segno le buche in qualsiasi
        ordine. Un tipo di golf in cui abbiamo rimosso le limitazioni che
        costringono a giocare da una buca all’altra in ordine. Vincerà chi avrà
        ottenuto il punteggio più alto.
      </P>

      <H3>GIOCATORI</H3>
      <P>3 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Hai un gruppo di persone a cui piace qualcosa che si distacchi un po’
        dal golf e che sia un po’ meno ordinato. Questa versione di 18 Holes
        riduce l’influenza della fortuna nel gioco.
      </P>

      <H3>COME SI VINCE</H3>
      <P>
        Il punteggio di ogni giocatore è la somma dei segnalini da 2 e da 5
        raccolti. Vincerà chi avrà ottenuto il punteggio più alto.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromMatchPlay />

      <H4>PREPARAZIONE</H4>
      <P>
        Per i migliori risultati, progetta un percorso denso che include tutti
        gli esagoni multi-green. Il requisito di ignorare la casella fairway
        (es. +1 casella) porta buoni risultati.
      </P>

      <SideBySide RightComponent={ChaosGolfIcon} size={200}>
        <P>
          Posiziona un segnalino da 2 e da 5 punti su ogni buca. Assicurati che
          il numero della buca sia lo stesso per entrambi. La sequenza delle
          buche non è importante. Per i migliori risultati, metti il segnalino
          da 5 punti con il lato arancio rivolto verso l’alto e la buca da 2
          punti con il lato rivolto verso l’alto. Così facendo, i green si
          noteranno un po’ di più.
        </P>
      </SideBySide>

      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>
        I giocatori possono colpire in qualsiasi direzione e cercare di
        reclamare qualsiasi buca in qualsiasi ordine.
      </P>
      <P>
        Quando si atterra su un tee box, il giocatore può fare un altro turno.
        Ciò crea una catena e consente di viaggiare più velocemente lungo il
        percorso. Qualsiasi turno extra viene fatto in ordine di iniziativa dopo
        che tutti i giocatori avranno concluso il round iniziale.
      </P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        Il giocatore che atterra per primo sul green prende il segnalino
        punteggio grande (5 punti) mentre il secondo riceve il segnalino più
        piccolo (2 punti). Non c’è tie-break e viene usato l’ordine di
        iniziativa per determinare chi prende il segnalino da 5 punti.
      </P>
      <P>
        Il giocatore che prende il segnalino punteggio grande non può prendere
        il segnalino piccolo per la stessa buca. Usa i numeri delle buche sui
        segnalini punteggio per controllare.
      </P>
      <P>
        La partita termina alla fine del round in cui qualcuno avrà reclamato
        l’ultimo segnalino da 5 punti. Ogni segnalino da 2 punti rimanente verrà
        ignorato.
      </P>
      <P>
        A vincere sarà il giocatore che avrà totalizzato il maggior numero di
        punti.
      </P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <ul className={ul}>
        <li>Segnalini 5 punti</li>
        <li>Segnalini 2 punti</li>
      </ul>

      <FastTravelRulesItalian />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeChaosGolf />}
          {language === "fr" && <FrenchModeChaosGolf />}
          {language === "it" && <ItalianModeChaosGolf />}
          {language === "es" && <SpanishModeChaosGolf />}
          {language === "de" && <GermanModeChaosGolf />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
