import React from "react";
import { link, P } from "../../brand/typography";
import { ModesChaosGolf } from "../../navigation/locations";
import { Link } from "react-router-dom";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FromChaosGolfFrench = () => (
  <>
    <P>
      Utilisez les règles du{" "}
      <Link className={link} to={ModesChaosGolf}>
        Chaos Golf
      </Link>
      , sauf en cas de conflit avec les règles ci-dessous.
    </P>
  </>
);

const FromChaosGolfEnglish = () => (
  <>
    <P>
      Use the rules for&nbsp;
      <Link className={link} to={ModesChaosGolf}>
        Chaos Golf
      </Link>
      &nbsp;except when in conflict with the rules below.
    </P>
  </>
);

const FromChaosGolfItalian = () => (
  <>
    <P>
      Usa le regole per&nbsp;
      <Link className={link} to={ModesChaosGolf}>
        Chaos Golf
      </Link>
      &nbsp;eccetto quando vanno in conflitto con le regole sotto.
    </P>
  </>
);

const FromChaosGolfSpanish = () => (
  <>
    <P>
      Utiliza las reglas del&nbsp;
      <Link className={link} to={ModesChaosGolf}>
        Chaos Golf
      </Link>
      , excepto cuando entren en conflicto con las reglas que se indican a
      continuación.
    </P>
  </>
);

const FromChaosGolfGerman = () => (
  <>
    <P>
      Verwende die Regeln für das&nbsp;
      <Link className={link} to={ModesChaosGolf}>
        Chaos Golf
      </Link>
      , es sei denn, sie stehen im Widerspruch zu den nachstehenden Regeln.
    </P>
  </>
);

const FromChaosGolfBase = ({ language }) => (
  <>
    {language === "fr" && <FromChaosGolfFrench />}
    {language === "en" && <FromChaosGolfEnglish />}
    {language === "it" && <FromChaosGolfItalian />}
    {language === "es" && <FromChaosGolfSpanish />}
    {language === "de" && <FromChaosGolfGerman />}
  </>
);

export const FromChaosGolf = withLanguage(FromChaosGolfBase);
