import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../auth/react-auth0-wrapper";
import { ButtonButton } from "../common/Button";
import { Designer } from "../navigation/locations";
import { createNewCourse } from "../services/private";

export const NewCourse = () => {
  let history = useHistory();
  const { token, onTokenExpired } = useAuth0();

  const createCourse = async () => {
    const courseId = await createNewCourse(token, onTokenExpired);

    history.push(Designer(courseId));
  };

  return (
    <ButtonButton onClick={createCourse}>
      <div className="flex-col h-full flex justify-start items-start flex-col justify-center items-center">
        <p className="font-bold text-2xl text-center">New Course</p>
      </div>
    </ButtonButton>
  );
};
