import React from "react";
import { Link } from "react-router-dom";
import courseTile from "./images/course-tile.png";
import adjustmentTile from "./images/adjustment-tiles.png";
import clubCard from "./images/fr/sample-club.png";
import clubCardWithPlayerMarker from "./images/fr/sample-club-with-player-count.png";
import soloCard from "./images/sample-solo.png";
import ruleBook from "./images/rule-book-cover.png";
import courseBook from "./images/course-book-cover.png";
import golferCard from "./images/fr/sample-golfer.png";
import shotCard from "./images/sample-shot.png";
import token1 from "./images/1-point.png";
import token2 from "./images/2-points.png";
import token5 from "./images/5-points.png";
import meeple from "./images/meeple.png";
import flags from "./images/flag.png";
import trees from "./images/tree.png";
import holeInOne from "./images/final-hole-in-one-token.png";
import soloMarker from "./images/final-solo-marker.png";
import courseTileWithHighlight from "./images/course-tile-with-highlight.png";
import teeToGreen from "./images/from-tee-to-green.png";
import greenHex from "./images/green-hex.png";
import bunkerHex from "./images/bunker-hex.png";
import treeHex from "./images/tree-hex.png";
import teeboxHex from "./images/teebox-hex.png";
import clubhouse from "./images/clubhouse.png";
import hybrid8 from "./images/fr/8-hybrid.png";
import iron9 from "./images/fr/9-iron.png";
import iron6 from "./images/fr/6-iron.png";
import iron4 from "./images/fr/4-iron.png";
import iron3 from "./images/fr/3-iron.png";
import wood5 from "./images/fr/5-wood.png";
import lobWedge from "./images/fr/lw.png";
import driver from "./images/fr/driver.png";
import deckC from "./images/deck-c.png";
import deckCStraight from "./images/deck-c-straight.png";
import deckCRight from "./images/deck-c-right.png";
import deck3Left from "./images/deck-3-left.png";
import deck3Super from "./images/fr/deck-3-super.png";
import deck4Left from "./images/deck-4-left.png";
import deck4Straight from "./images/deck-4-straight.png";
import deck5right from "./images/deck-5-right.png";
import wildCard from "./images/deck-4-wild.png";
import shuffle from "./images/fr/shuffle.png";
import superHit from "./images/fr/super-hit.png";
import shortHit from "./images/fr/short-hit.png";
import longestDriveCard from "./images/fr/longest-drive.png";
import roughHex from "./images/rough-hex.png";
import clareExample from "./images/clare-example.png";
import bunkerOutcomes from "./images/bunker-outcomes.png";
import exitBunkerExample from "./images/exit-bunker.png";
import exitBunkerShotCard from "./images/exit-bunker-shot-card.png";
import roughExample1 from "./images/rough-example-1.png";
import roughExample2 from "./images/rough-example-2.png";
import chippingExample from "./images/chipping-example.png";
import golfer1a from "./images/fr/golfer-1-a.png";
import golfer1b from "./images/fr/golfer-1-b.png";
import golfer2a from "./images/fr/golfer-2-a.png";
import golfer2b from "./images/fr/golfer-2-b.png";
import golfer3a from "./images/fr/golfer-3-a.png";
import golfer3b from "./images/fr/golfer-3-b.png";
import golfer4a from "./images/fr/golfer-4-a.png";
import golfer4b from "./images/fr/golfer-4-b.png";
import golfer5a from "./images/fr/golfer-5-a.png";
import golfer5b from "./images/fr/golfer-5-b.png";
import golfer6a from "./images/fr/golfer-6-a.png";
import golfer6b from "./images/fr/golfer-6-b.png";
import golfer7a from "./images/fr/golfer-7-a.png";
import golfer7b from "./images/fr/golfer-7-b.png";
import golfer8a from "./images/fr/golfer-8-a.png";
import golfer8b from "./images/fr/golfer-8-b.png";
import {
  Modes,
  ModesChaosGolf,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  PublicRules,
} from "../navigation/locations";
import {
  Section,
  BoxPart,
  SideBySide,
  SideBySideSection,
  FirstGame,
  GreenPanel,
  Example,
  AlwaysSideBySide,
  HybridCardsFrench,
  StandardPortraitCardSize,
  StandardLandscapeCardSize,
  SmallPortraitCardSize,
  SmallHex,
  CourseTileSize,
  WaterHazards,
} from "./RuleBookParts";
import { A, H1, H2, note, P, H3, H4, H5, link, ul } from "../brand/typography";

export const RuleBookFrench = () => {
  return (
    <div className="flex flex-col justify-start items-start p-8 md:w-5/5 print:block">
      <H1>Règles</H1>
      <div className="self-center print:hidden">
        <A href={PublicRules} target="_blank">
          Download as PDF
        </A>
      </div>
      <div className="p-4">
        <H2>TABLE DES MATIÈRES</H2>
        <div className="flex flex-col">
          <A href="#box-contents">CONTENU DE LA BOÎTE</A>
          <A href="#establishing-shot">ÉTABLISSEMENT DU TEE-SHOT</A>
          <A href="#overview">PRÉSENTATION</A>
          <A href="#from-tee-box-to-green">DU TEE BOX AU GREEN</A>
          <A href="#setup">MISE EN PLACE</A>
          <A href="#playing-a-round">JOUER UN TOUR</A>
          <A href="#rough">JOUER À PARTIR DU ROUGH</A>
          <A href="#chipping">CHIPPING</A>
          <A href="#super-short-wild">CARTES SUPER, COURT, MÉLANGE ET JOKER</A>
          <A href="#hazards">OBSTACLES ET AUTRES TUILES</A>
          <A href="#golfers">APTITUDES DES GOLFEURS</A>
          <A href="#game-modes">DIFFÉRENTES FAÇONS DE JOUER</A>
        </div>
      </div>
      <Section id="box-contents" title="CONTENU DE LA BOÎTE">
        <div className="flex flex-col md:flex-row justify-between items-center md:flex-wrap print:w-full print:flex-row print:flex-wrap">
          <BoxPart src={courseTile} text="49 tuiles de parcours" size={200} />
          <BoxPart src={adjustmentTile} text="21 tuiles d'ajustement" size={66} />
          <BoxPart src={meeple} size={150} text="5 golfeurs" />
          <BoxPart src={golferCard} size={150} text="8 cartes de golfeur" />
          <BoxPart src={clubCard} size={200} text="34 cartes de club" />
          <BoxPart src={shotCard} size={150} text="74 cartes de coups" />
          <BoxPart src={soloCard} size={200} text="24 cartes de Chaos Golf en solo" />
          <BoxPart src={token1} size={66} text="18 jetons à 1 point" />
          <BoxPart src={token2} size={66} text="18 jetons à 2 points" />
          <BoxPart src={token5} size={100} text="18 jetons à 5 points" />
          <BoxPart src={holeInOne} size={66} text="18 jetons « trou en un »" />
          <BoxPart src={soloMarker} size={66} text="Marqueur de tour de jeu" />
          <BoxPart src={flags} size={66} text="18 drapeaux et stands" />
          <BoxPart src={trees} size={66} text="14 arbres" />
          <BoxPart src={ruleBook} size={66} text="Ce manuel" />
          <BoxPart src={courseBook} size={66} text="Livret du parcours" />
        </div>
        <em className={note}>
          Certains modes de jeu nécessitent un stylo et une feuille pour marquer les points (non
          inclus).
        </em>
      </Section>
      <Section id="establishing-shot" title="ÉTABLISSEMENT DU TEE-SHOT">
        <P>
          <em>
            Une réunion du conseil d'administration se tient au siège social du géant international
            du sport, Golf Corp. Cette réunion déterminera l'avenir du golf.
          </em>
        </P>
        <P>
          Chers membres du conseil d'administration, les choses semblent désastreuses. La
          fréquentation est en baisse, les progrès de la technologie des clubs ont rendu notre jeu
          trop facile. Avec une concurrence accrue qui attire leur attention, les gens ne peuvent
          plus passer quatre heures à jouer au golf. La faible fréquentation des greens exerce une
          pression sur nos parcours qui ont du mal à se maintenir à flots. Des groupes de promoteurs
          immobiliers ont commencé à traquer les propriétaires pour convertir nos précieux fairways
          en domaines sans âme.
        </P>
        <P>
          Mais tout n'est pas perdu. Une équipe composée de nos plus brillants esprits a conçu la
          prochaine itération de notre grand jeu. L'équipe a apporté quelques modifications aux
          règles qui nous permettront de sortir du rough et de nous envoler vers le drapeau du
          green.
        </P>
        <P>
          Les propriétaires de parcours de golf ont besoin de plus de clients, et nous n'avons pas
          réussi à améliorer le nombre d'heures pleines dans la journée. Nous avons changé notre
          approche et introduit le jeu simultané. Plus de personnes jouant au golf en même temps
          signifie plus d’argent. Notre technologie brevetée HOLOBALL™ permettra à tous les membres
          d'un groupe de jouer au golf en toute sécurité et en même temps.
        </P>
        <P>
          C'est une technologie fantastique. Je pensais que la flottille de drones qui suivent et
          arrêtent les balles de golf allait gagner. Mais ceci... C'est beaucoup plus intelligent de
          faire de la balle de golf un drone. Des balles de golf qui ne se perdent jamais. Des
          balles de golf qui peuvent s'arrêter avant de toucher quelqu'un et reprendre leur envol
          après. Des balles de golf qui suivent votre score.{" "}
        </P>
        <P>
          J'admets néanmoins qu'il y a eu quelques blessures. On pourrait dire que la technologie
          est encore un peu rudimentaire. Mais je pense que nous pouvons commencer à l'appliquer sur
          certains des parcours qui ont du mal à rester sous le par.{" "}
        </P>
        <P>
          Nous avions de bonnes relations avec les fabricants de clubs. Cependant, ces derniers
          temps, chaque nouvelle avancée technologique dans le domaine du golf réduit le défi, qu'il
          s'agisse des super-drivers ou des sand wedges magnétiques récemment mis sur le marché.
        </P>
        <P>
          Afin de riposter contre les fabricants de clubs qui profitent de la disparition de la
          grandeur de notre jeu, nous avons apporté quelques changements qui ne les cibleront pas
          directement, mais qui réduiront leur influence.{" "}
        </P>
        <P>
          Le golf n'a jamais été qu’une question d'exécution de coups, la sélection des coups à une
          grande importance elle aussi. Nous allons réduire la limite des clubs de 14 à 5, ce qui
          obligera les joueurs à réfléchir davantage aux clubs qu'ils prennent et au moment où ils
          les utilisent. Le golf est aussi une question de planification et d'élaboration de votre
          approche, pour cela nous ne permettrons pas aux joueurs de réutiliser le même club tant
          qu'ils n'auront pas utilisé tous leurs autres clubs.{" "}
        </P>
        <P>Chers membres du conseil d'administration. Bienvenue dans la nouvelle ère du golf !</P>
        <P>
          <em>Un tonnerre d’applaudissements éclate dans la salle du conseil d'administration.</em>
        </P>
      </Section>
      <Section id="overview" title="Présentation">
        <P>
          Dix ans se sont écoulés depuis cette réunion du conseil d'administration. Le golf n'a
          jamais été aussi populaire. Avec l'invention de la technologie HOLOBALL™, de nouvelles
          façons de jouer au golf ont été créées, et ce sport est en plein essor. Vous êtes un
          golfeur en cet âge d'or du golf. Vous pouvez piochez vos clubs, puis vous affronter sur le
          parcours grâce à une sélection minutieuse des coups et à une navigation intelligente en
          fonction des obstacles.{" "}
        </P>
        <P>
          Le jeu se déroule sur les 18 trous d'un parcours de golf, chaque trou nécessitant
          l'utilisation d'un ou plusieurs clubs. Après avoir joué un club, les joueurs ne peuvent
          plus utiliser ce club avant d'avoir joué tous les autres clubs. Les golfeurs marquent des
          points en arrivant les premiers sur les greens. Le gagnant est le golfeur qui a marqué le
          plus de points.
        </P>
        <P>
          Ce livre contient des règles pour de nombreux modes différents, notamment le solo, le jeu
          à deux, le Golf Racing et le Chaos Golf. Chaque mode de jeu vous permet d'adapter
          l'expérience à votre groupe de joueurs. La première partie de ce livre couvre l'ensemble
          de règles standard appelé Match Play.
        </P>
        <SideBySide title="Les TUILES de parcours" right={courseTileWithHighlight} size={200}>
          <P>
            Utilisez ces tuiles pour construire le parcours sur lequel vous jouez. Il y a 49 tuiles
            double face que vous pouvez utiliser pour créer un nouveau parcours à chaque fois que
            vous jouez.{" "}
          </P>
          <P>
            Les tuiles possèdent un des trois niveaux de difficulté indiqués par des étoiles dans le
            coin de chaque tuile. Chaque tuile comporte un numéro et une flèche qui pointe dans la
            direction principale.{" "}
          </P>
          <P>
            Consultez le livret pour connaître les plans des parcours et les instructions pour les
            construire.
          </P>
        </SideBySide>
        <SideBySide title="Tuiles d'ajustement" right={adjustmentTile} size={132} height="132">
          <P>
            Ces mini-hexagones peuvent être placés sur les tuiles du parcours pour modifier la
            difficulté du parcours. Pour un jeu plus détendu, couvrez les obstacles avec des tuiles
            à l'envers. Si vous cherchez un défi, utilisez le côté des obstacles pour ajuster ceux
            qui existent déjà ou en ajouter de nouveaux.
          </P>
        </SideBySide>
        <SideBySide title="Cartes de golfeur" right={golferCard} size={200}>
          <P>
            Les joueurs choisissent des cartes de golfeur qui leur offrent une capacité spéciale
            unique. Les capacités de la face A réduisent le défi tandis que la face B contient
            souvent certaines restrictions. À vous de choisir soit la face A, soit la face B lorsque
            vous jouez.
          </P>
        </SideBySide>
        <SideBySide title="Cartes de club" right={clubCard} size={250}>
          <P>
            Chaque club a une capacité unique et vous dit dans quel paquet vous devez piocher. Vous
            ne pouvez emporter que cinq clubs sur le parcours. Une fois que vous en avez utilisé un,
            vous ne pouvez pas le réutiliser tant que vous n'avez pas utilisé tous vos autres clubs.
          </P>
        </SideBySide>
        <SideBySide title="Cartes de coups" right={shotCard} size={200}>
          <P>
            Ces cartes sont organisées en cinq paquets et représentent les différentes distances et
            directions pour vos coups. Chacune contient des cartes qui envoient la balle à gauche, à
            droite et, dans certains cas, dans la direction de votre choix.
          </P>
          <P>
            Un plan de vol sur chaque carte vous aide à tracer le mouvement de votre balle.
            L'hexagone orange marque l'endroit où votre balle atterrit. Lorsque vous frappez la
            balle depuis le rough, elle parcourt un hexagone de moins. Voir{" "}
            <A href="#rough">« JOUER DEPUIS LE ROUGH »</A>.
          </P>
          <P>
            Vous utilisez également des cartes de coups pour sortir des bunkers. L'icône de sortie
            du bunker se trouve dans le coin inférieur droit. Voir{" "}
            <A href="#4d">« SORTIR DES BUNKERS  »</A>.
          </P>
        </SideBySide>
        <SideBySide
          title="Marqueurs de green / Jetons de score"
          right={courseTileWithHighlight}
          size={200}
        >
          <P>
            Les jetons circulaires ont un double objectif. La face avant comporte des greens
            numérotés de 1 à 18. Comme les tuiles du parcours n'ont pas de numéros sur les greens,
            le fait de placer les jetons face numérotée vers le haut permet de suivre l'ordre des
            trous.
          </P>
          <P>
            Dans la plupart des modes de jeu en 18 Holes, vous utilisez l'envers des jetons pour
            marquer les points. Ces faces indiquent des valeurs de 5, 2 et 1. Voir{" "}
            <A href="#game-modes">« DIFFÉRENTES FAÇONS DE JOUER »</A>.
          </P>
          <P>
            Il existe des marqueurs de 1pt, 2pt et 5pt supplémentaires. Ceux-ci n'ont pas de
            chiffres de green. Veuillez les mettre en lieu sûr au cas où vous en auriez besoin.
          </P>
        </SideBySide>
        <SideBySide title="Jetons « Trou en un »" right={holeInOne} size={200}>
          <P>
            Ils sont collectés lorsque vous réussissez un trou en un coup. En cas d'égalité, le
            joueur qui a le plus de marqueurs de trou en un est le gagnant.
          </P>
        </SideBySide>
        <SideBySide title="Cartes de Chaos Golf en solo" right={soloCard} size={250}>
          <P>
            Utilisez ce jeu de cartes pour organiser le déroulement du Chaos Golf en solo et pour
            ajuster la difficulté. Ces cartes ont deux symboles différents. Des cercles de couleur
            unique qui représentent des tours où seul le joueur marque et des cercles à deux tons où
            vous et vos adversaires automatisés marquez simultanément. Voir
            <A href={ModesChaosGolfSolo}>« CHAOS GOLF EN SOLO »</A>.
          </P>
        </SideBySide>
        <SideBySide title="Marqueur de tour de jeu" right={soloMarker} size={200}>
          <P>
            Utilisez-le pour savoir à quel tour vous êtes lors d'une partie de chaos golf en solo.
          </P>
        </SideBySide>
        <SideBySide title="Drapeaux" right={flags} size={200}>
          <P>
            Ces drapeaux sont placés sur les greens pour indiquer où vous visez. Il y en a un pour
            chaque trou numéroté de 1 à 18 et trois drapeaux supplémentaires (non numérotés). Rangez
            les drapeaux supplémentaires en lieu sûr.
          </P>
        </SideBySide>
        <SideBySide title="Arbres" right={trees} size={200}>
          <P>
            Ces arbres sont des décorations que vous pouvez mettre sur votre parcours. Placez-les
            au-dessus des obstacles pour les rendre plus visibles. Il n'y a que 14 arbres, vous
            devrez donc les déplacer le long du parcours pendant que vous jouez.
          </P>
        </SideBySide>
        <SideBySide title="GOLFEURS" right={meeple} size={200}>
          <P>
            Chaque joueur a un golfeur qui le représente sur le parcours. Il n'y a pas de différence
            entre eux, alors choisissez la couleur que vous préférez.
          </P>
        </SideBySide>
      </Section>
      <SideBySideSection
        id="from-tee-box-to-green"
        title="Du Tee box au green"
        right={teeToGreen}
        size="450"
        height="1024"
      >
        <P>
          Un jeu de 18 Holes se joue sur un parcours composé d'un ou plusieurs trous. La plupart des
          parcours ont 18 trous, bien qu'il soit courant d'avoir des parcours de 9 trous plus courts
          et plus rapides. Vous marquez un point pour chaque trou que vous gagnez. Vous gagnez un
          trou en atteignant le green avant vos adversaires. En cas d'égalité, plusieurs joueurs
          marquent des points.
        </P>
        <P>
          Le green est le petit hexagone sur lequel se trouve un petit drapeau noir. Nous avons un
          trou unique composé de trois tuiles de parcours. La tuile du haut contient le green. Le
          tee box se trouve sur la tuile du bas. Le fairway s'étend du tee box au green.
        </P>
        <P>
          Vous devez commencer avec votre golfeur sur le tee box et la tâche consiste à atteindre le
          green. Vous jouez avec des clubs qui feront bouger votre golfeur. Vous n'êtes pas obligé
          de suivre le fairway jusqu'au green. Cela peut cependant être une bonne idée, car vos
          coups iront plus loin si vous jouez depuis le fairway.
        </P>
        <P>
          Il n'y a que quelques tuiles sur lesquelles vous ne pouvez pas atterrir. Il s'agit des
          obstacles d’arbres et du club-house. Une clôture blanche entoure l'obstacle d’arbres.{" "}
          <A href="#hazards">Vous trouverez le clubhouse à la page 15</A>. Vous pouvez atterrir sur
          les fairways et les greens d'autres trous et vous pouvez atterrir sur des tuiles qui
          contiennent d'autres golfeurs. Tout cela grâce à HOLOBALL™.
        </P>
        <P>
          Si vous pouvez atterrir sur la plupart des tuiles du jeu, toutes ne sont pas recommandées.
          Certaines contiennent de l'eau qui vous fera perdre un tour et d'autres contiennent des
          bunkers dont vous devrez sortir avant de continuer. Le vert foncé qui se trouve sur une
          tuile sur deux est le rough et cela réduira la distance de vos coups. Voir
          <A href="#hazards">« OBSTACLES ET AUTRES TUILES »</A>.
        </P>
        <P>
          Il existe de nombreuses façons de personnaliser votre expérience des 18 Holes. Les
          différents modes de jeu vous offrent des expériences et des défis différents. Vous pouvez
          ajuster les parcours pour les rendre plus faciles ou plus difficiles. Les huit golfeurs
          qui entrent dans le jeu vous permettent de rendre le jeu plus facile ou plus difficile en
          fonction des joueurs. En essayant les différentes options, nous sommes convaincus que vous
          trouverez une expérience de jeu que vous apprécierez.
        </P>
      </SideBySideSection>
      <Section id="setup" title="Mise en place">
        <H3>CONSTRUIRE LE PARCOURS</H3>
        <P>
          Vous pouvez mettre en place le parcours en utilisant l'un des dessins du livret ou en
          concevoir un vous-même. La dernière page du livret de parcours contient des instructions
          sur la manière de créer des parcours.
        </P>
        <SideBySide right={greenHex} size={132}>
          <P>
            Une fois que vous avez construit votre parcours, placez un drapeau sur chaque trou.
            Placez le 1er drapeau sur le green (à droite) pour le premier trou et continuez jusqu'au
            18e green. Si vous jouez avec des arbres, placez-les sur les 14 premiers obstacles
            d’arbres du parcours. Les arbres sont les hexagones entourés de petites clôtures
            blanches.
          </P>
        </SideBySide>
        <P>Il est temps pour tout le monde de choisir un golfeur et de piocher ses clubs.</P>
        <FirstGame>
          <A
            href="https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5"
            target="_blank"
          >
            Essayez Starting course à la page 5 du livret de parcours.
          </A>
        </FirstGame>
        <H3>CHOISIR UN GOLFEUR</H3>
        <P>
          Chaque joueur choisit une carte de golfeur à jouer. Vous pouvez laisser les joueurs
          choisir la carte et la face qu'ils veulent ou distribuer au hasard.
        </P>
        <P>
          Chaque carte de golfeur a une face A et une face B. Les golfeurs de la face A ont tendance
          à rendre le jeu plus facile, et les golfeurs de la face B ont tendance à rendre le jeu
          plus difficile.
        </P>
        <P>
          La sélection des golfeurs a lieu avant la sélection des clubs, car certains golfeurs
          influencent la phase de sélection des clubs.
        </P>
        <FirstGame>
          Sélectionnez les cartes de golfeur jusqu'à ce que vous soyez prêt à relever le défi.
        </FirstGame>
        <H3 style={{ breakBefore: "page" }}>SÉLECTION DES CLUBS</H3>
        <P>Les joueurs recevront 5 clubs chacun. </P>
        <SideBySide right={clubCardWithPlayerMarker} size={250}>
          <P>
            Mélangez les cartes des clubs. Si vous jouez en solo ou à deux, retirez les clubs qui
            affichent 3+ ou 5+ dans le coin inférieur droit. Dans une partie à 3 ou 4 joueurs,
            retirez les cartes 5+.
          </P>
        </SideBySide>
        <P>
          Distribuez le même nombre de cartes à chaque joueur. Les cartes restantes ne sont pas
          utilisées et sont replacées dans la boîte à l'abri des regards.
        </P>
        <P>
          Chaque joueur choisit un club parmi les cartes distribuées et le place devant lui, face
          cachée. Il passe ensuite les cartes restantes à la personne qui se trouve à sa gauche. De
          nouveau, chaque joueur choisit une carte parmi les quatre restantes et la place devant
          lui, face cachée, et passe le reste à la personne de gauche. Continuez jusqu'à ce que tous
          les joueurs aient cinq clubs. Ce sera leur main. Chaque joueur aura également besoin
          d'espace devant lui pour défausser les clubs utilisés.
        </P>
        <P>
          Si un joueur a The Shark, Iron Jane ou Slugger, consultez la section
          <A href="#golfers">« COMPÉTENCES DES GOLFEURS »</A> pour connaître les règles qui
          modifient la sélection des clubs.
        </P>
        <P>
          <em>
            La sélection des clubs est votre première chance d'obtenir un avantage. Les
            caractéristiques du parcours déterminent l'efficacité de certains clubs. Examinez le
            parcours pour voir quels clubs seront les plus utiles.
          </em>
        </P>
        <FirstGame>
          Sur la face de certaines cartes de club se trouve un A, B, C, D ou E. Au lieu de
          sélectionner les clubs, donnez les A au joueur 1, les B au joueur 2, etc.
        </FirstGame>
        <H3>COMMENCER À JOUER</H3>
        <P>
          Vous êtes maintenant prêt pour commencer à jouer. Placez tous les golfeurs sur le tee box
          du premier trou. Tous les joueurs doivent avoir leurs clubs en main, cachés des autres
          joueurs.
        </P>
        <P>
          Prenez les 5 paquets de coup, ceux-ci ont un C, 2, 3, 4 ou 5 au dos. Mélangez et
          placez-les à la portée de tous les joueurs.
        </P>
      </Section>
      <Section id="playing-a-round" title="Jouer un tour">
        <GreenPanel>
          <H3>Jouer un tour</H3>
          <P>Chaque tour de 18 holes est divisé selon les étapes suivantes.</P>
          <H4 href="#selecting-clubs">SÉLECTION DES CLUBS</H4>
          <ul className="pl-4">
            <li>
              <A href="#1">
                1.
                <span className="pl-3">Réinitialisez votre main si elle est vide</span>
              </A>
            </li>
            <li>
              <A href="#2">
                2.
                <span className="pl-3">Sélectionnez et révélez les clubs</span>
              </A>
            </li>
            <li>
              <A href="#3">
                3.<span className="pl-3">Déterminez l'ordre de jeu</span>
              </A>
            </li>
          </ul>
          <H4 href="#on-your-turn">À VOTRE TOUR</H4>
          <ul className="pl-4">
            <li>
              <A href="#4">
                4.<span className="pl-3">Réalisez une action</span>
              </A>
            </li>
            <ul className="pl-4">
              <li>
                <A href="#4a">
                  A.<span className="pl-3">Déplacez-vous d'un hexagone</span>
                </A>
              </li>
              <li>
                <A href="#4b">
                  B.<span className="pl-3">Jouez une carte de club</span>
                </A>
              </li>
              <li>
                <A href="#4c">
                  C.<span className="pl-3">Tentez un gros coup</span>
                </A>
              </li>
              <li>
                <A href="#4d">
                  D.<span className="pl-3">Sortez du bunker</span>
                </A>
              </li>
              <li>
                <A href="#4e">
                  E.<span className="pl-3">Réinitialisez votre main</span>
                </A>
              </li>
            </ul>
            <li>
              <A href="#5">
                5.<span className="pl-3">Défaussez le club.</span>
              </A>
            </li>
          </ul>
          <H4 href="#reaching-the-green">ATTEINDRE LE GREEN</H4>
          <ul className="pl-4">
            <li>
              <A href="#6">
                6.<span className="pl-3">Drapeaux de score</span>
              </A>
            </li>
            <li>
              <A href="#7">
                7.<span className="pl-3">Jetons de score « trou en un »</span>
              </A>
            </li>
            <li>
              <A href="#8">
                8.
                <span className="pl-3">Déplacer tous les joueurs vers le prochain trou</span>
              </A>
            </li>
            <li>
              <A href="#9">
                9.
                <span className="pl-3">Supprimer tous les effets des obstacles </span>
              </A>
            </li>
          </ul>
        </GreenPanel>
        <H3 id="selecting-clubs" href="#selecting-clubs">
          SÉLECTION DES CLUBS
        </H3>
        <H4 id="1" href="#1">
          1. RÉINITALISEZ VOTRE MAIN
        </H4>
        <P>
          Si vous n'avez pas de club dans votre main, reprenez tous vos clubs défaussés dans votre
          main.
        </P>
        <P>
          Si vous avez en main un club que vous ne pouvez pas utiliser, vous pouvez également
          réinitialiser votre main. Par exemple, vous avez le driver et vous n'êtes pas sur un tee
          box.
        </P>
        <P>
          Si vous jouez la face A de Dorian, vous ne pouvez réinitialisez vos clubs qu'au départ du
          trou.
        </P>
        <H4 id="2" href="#2">
          2. SÉLECTIONNER UN CLUB
        </H4>
        <P>Chaque joueur sélectionne un club de sa main et le place face cachée devant lui.</P>
        <P>
          Lorsque tous les joueurs ont sélectionné leur club, révélez simultanément tous les clubs.
          Une fois cette opération terminée, les joueurs ne peuvent plus changer d'avis.
        </P>
        <P>
          Le joueur avec Bunjil doit utiliser son pouvoir avant de placer un club face cachée. Si
          Bunjil est sur sa face B et expose une carte coup, les joueurs qui ont placé un club face
          cachée peuvent changer d'avis.
        </P>
        <P>
          Le joueur avec Slick Rikesh sur sa face A peut utiliser son pouvoir après la révélation et
          désigner le club d'un autre joueur à utiliser.
        </P>
        <H4 id="3" href="#3">
          3. DÉTERMINER L'ORDRE DE JEU
        </H4>
        <P>
          Utilisez l'ordre d'initiative de chaque club pour déterminer l'ordre de jeu. Le club ayant
          le moins d'initiative joue en premier. Vous pouvez trouver l'initiative dans le coin
          supérieur gauche de la carte du club.
        </P>
        <P>
          Si Slick Rikesh utilise le club d'un autre joueur, Slick Rikesh joue immédiatement après
          l'autre joueur.
        </P>
        <P>
          Si Swift est sur la face A, elle remporte les éventuelles égalités d'initiative. Si Swift
          est sur sa face B, elle perd toute égalité d'initiative.
        </P>
        <P>
          Si Swift est à égalité avec Slick Rikesh qui emprunte un club, elle gagne sur sa face A et
          perd sur sa face B.
        </P>
        <P>
          <em>
            Si l'on regarde les clubs ci-dessous, le 8 Hybride a une initiative de 5, et ce joueur
            joue son tour en premier. Le joueur suivant est celui qui a le fer 6 (12 d’initiative).
            Le dernier joueur à jouer son tour est la personne qui a le Bois 5 (20 d’initiative).
          </em>
        </P>
        <div className="flex flex-col md:flex-row justify-evenly items-center pt-4">
          <div>
            <img src={hybrid8} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron6} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={wood5} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H3 id="on-your-turn" href="#on-your-turn" style={{ breakBefore: "page" }}>
          À VOTRE TOUR
        </H3>
        <P>Chaque joueur joue son tour en suivant l'ordre de jeu.</P>
        <H4 id="4" href="#4">
          4. RÉALISER UNE ACTION
        </H4>
        <P>
          Lorsque c'est votre tour, effectuez <strong>l'une</strong> des actions suivantes :
        </P>
        <ul className={ul}>
          <li>A. Déplacez-vous d'un hexagone</li>
          <li>B. Jouez une carte de club</li>
          <li>C. Tentez un gros coup</li>
          <li>D. Sortez du bunker</li>
          <li>E. Réinitialisez votre main</li>
        </ul>
        <P>Une fois que vous avez terminé votre tour, vous devez défausser votre club.</P>
        <H4 id="4a" href="#4a">
          4.a) SE DÉPLACER D’UN HEXAGONE
        </H4>
        <P>
          Vous avez la possibilité de vous déplacer d'un hexagone, sauf si vous êtes dans un bunker.
          Placez votre golfeur dans l'un des hexagones adjacents. Vous ne pouvez pas vous déplacer
          sur un hexagone comportant un arbre ou hors du plateau. Voir{" "}
          <A href="#hazards">« OBSTACLES ET AUTRES TUILES »</A>.
        </P>
        <H4 id="4b" href="#4b">
          4.b) JOUER UNE CARTE DE CLUB
        </H4>
        <P>Selon le club, vous pourrez faire l'une des choses suivantes : </P>
        <ul className={ul}>
          <li>Piocher 1 carte du paquet de coup indiqué sur la carte</li>
          <li>Piocher une carte de l'un des deux paquets de coup indiqués</li>
          <li>Piocher 2 cartes du paquet de coup indiqué sur la carte</li>
          <li>Déplacer votre golfeur du nombre d'hexagones indiqués en ligne droite</li>
        </ul>
        <P>
          L'inscription sur chaque club est l'option dont vous disposez. Jouer une carte de club
          comporte trois étapes : piocher, décider, bouger.
        </P>
        <P>
          Pour la plupart des clubs, vous piochez vos cartes de coups, décidez de la direction dans
          laquelle vous voulez frapper et déplacez ensuite votre golfeur dans cet ordre. Lorsque
          vous tirez une carte à partir du paquet « chip », vous devez décider de la direction avant
          de piocher une carte. Voir <A href="#chipping">« CHIPPING »</A>. Lorsque vous jouez un
          bois, vous ne tirez aucune carte car votre club se déplace toujours sur une distance
          déterminée en ligne droite.
        </P>
        <H5>Piocher une carte coup</H5>
        <P>
          La plupart des clubs vous permettent de piocher une carte coup d'un seul paquet de cartes.
          Certains clubs vous permettent de choisir parmi plusieurs paquets de cartes, mais vous ne
          pouvez toujours piocher qu'une seule carte.
        </P>
        <P>
          Choisissez le paquet de cartes et piochez la carte du dessus. Si la carte piochée a des
          effets spéciaux (super, court ou mélange), suivez alors les instructions écrites sur la
          carte. Placez chaque carte supplémentaire que vous piochez sur la carte précédente. Une
          fois que vous avez fini de piocher, utilisez la carte du coup pour déterminer où déplacer
          votre golfeur.
        </P>
        <P>
          Pour plus d'informations sur les cartes spéciales, voir{" "}
          <A href="#super-short-wild">« LES CARTES SUPER, COURT, MÉLANGE ET JOKER »</A>.
        </P>
        <P>
          Si le paquet est vide lorsque vous souhaitez piocher, mélangez la pile de défausse et
          retournez la pour former un nouveau jeu. Ne mélangez pas les cartes que vous avez piochées
          ce tour-ci dans le nouveau jeu.
        </P>
        <P>
          Si vous jouez Lucky vous pouvez utiliser votre capacité et piocher une nouvelle carte à ce
          stade.
        </P>
        <H5>Piocher plusieurs cartes de coups</H5>
        <P>
          Lorsque vous piochez deux cartes de coups, vous devez choisir celle que vous voulez
          utiliser.
        </P>
        <P>
          Placez chaque carte coup côte à côte. Si l'une des cartes vous demande d'en piocher
          d'autres (super, court, mélange), vous pouvez choisir de suivre les instructions de cette
          carte et de placer les nouvelles cartes sur la carte qui a demandé la pioche. Lorsque vous
          avez fini de piocher, vous devez en choisir une parmi les cartes du haut visibles. Vous
          utilisez la carte choisie pour déplacer votre golfeur.
        </P>
        <Example>
          <P>
            Dans l'exemple ci-dessous, Gustav a fini par piocher 3 cartes. Il doit donc choisir
            l'une des deux cartes visibles du dessus.
          </P>
          <div className="flex flex-row justify-evenly items-start w-full">
            <div style={{ paddingTop: 50 }}>
              <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
            </div>
            <div
              style={{
                maxHeight: 275,
                paddingTop: 50,
                overflow: "hidden",
                width: "60%",
              }}
            >
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <img src={deck3Super} style={{ maxHeight: 200, height: 200 }} />
              </div>
              <div style={{ position: "relative", top: -235, left: 35 }}>
                <img src={deck4Straight} style={{ maxHeight: 200, height: 200 }} />
              </div>
            </div>
          </div>
        </Example>
        <H5>Déplacez votre golfeur</H5>
        <P>
          Le plan de vol indiqué sur votre carte coup indique le trajet de la balle. L'icône du
          golfeur sur la carte coup indique l'endroit où se trouve votre golfeur au début du coup.
          Déplacez votre golfeur le long des hexagones numérotés jusqu'à ce que vous atteigniez
          l'hexagone d'arrivée orange. Vous n'êtes pas obligé de frapper la balle vers le green ou
          le long du fairway.
        </P>
        <P>
          Si votre plan de vol traverse un hexagone avec des arbres clôturés, vous devez vous
          arrêter avant. Votre balle a touché les arbres et s'est arrêtée.
        </P>
        <P>
          Si vous avez atterri dans un obstacle (bunker, lac), suivez les règles de la page{" "}
          <A href="#hazards">« OBSTACLES ET AUTRES TUILES »</A>.
        </P>
        <AlwaysSideBySide right={roughHex} size={132}>
          <P>
            Si vous avez commencé votre tour dans l'hexagone du rough (à droite), voir :{" "}
            <A href="#rough">« JOUER À PARTIR DU ROUGH »</A>.
          </P>
        </AlwaysSideBySide>
        <H5 style={{ breakBefore: "page" }}>Changer d'avis</H5>
        <P>
          Si vous n'avez pas encore retiré vos doigts de votre golfeur, vous pouvez modifier la
          direction dans laquelle vous avez frappé la balle.
        </P>
        <Example>
          <P>
            Clare se retrouve dans une position où elle ne peut pas atteindre le green en un seul
            coup. Elle joue un fer 2 et tire une seule carte du paquet de coup n°4.
          </P>

          <P>
            La carte coup piochée tend vers la gauche. Sa meilleure option est de tirer en dehors du
            parcours et d'utiliser l’effet du coup pour atterrir au-delà de l'obstacle d'eau
            adjacent au green, dans une excellente position pour son prochain tour.
          </P>
          <div style={{ maxHeight: 350, overflow: "hidden" }}>
            <img src={clareExample} style={{ maxHeight: 350 }} />
            <img
              src={shotCard}
              style={{
                maxHeight: 150,
                height: 150,
                position: "relative",
                top: -350,
                left: 250,
              }}
            />
          </div>
        </Example>
        <H4 id="4c" href="#4c" style={{ breakBefore: "page" }}>
          4.c) TENTER UN GROS COUP
        </H4>
        <P>
          Parfois, il faut prendre un peu de distance. Vous pouvez frapper votre club plus loin que
          les options affichées sur la carte du club. Pour ce faire, à votre tour, annoncez au
          groupe que vous effectuez un GROS COUP. Vous piochez alors une carte de plus que
          d'habitude, et toutes les cartes doivent être du paquet supérieur d'un point au paquet le
          plus élevé indiqué sur votre carte de club.
        </P>
        <P>
          Après avoir pioché vos cartes, donnez-les au joueur qui se trouve à votre gauche. Il
          défausse ensuite une des cartes piochées. Vous choisissez alors une carte à jouer parmi
          les cartes restantes.
        </P>
        <P>
          Toutes les cartes super, court ou mélange sont jouées comme d'habitude. Une carte
          « super » signifie que votre gros coup sera encore plus important que prévu et une carte
          « court » vous décevra probablement en ce moment crucial.
        </P>
        <P>
          Tous les clubs ne vous permettent pas de tenter un gros coup, par exemple les clubs qui
          demandent déjà de piocher dans le paquet de cartes n°5. Dans le coin supérieur de chaque
          club se trouve un symbole qui indique si vous pouvez l'utiliser pour un gros coup. Dans
          l'exemple ci-dessous, le driver a une icône « Pas de gros coup » tandis que le fer 9
          indique que vous pouvez faire un gros coup.
        </P>
        <div className="flex flex-r0ow justify-evenly items-center">
          <div>
            <img src={driver} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron9} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>

        <GreenPanel>
          <H3>Gros coups</H3>
          <SideBySide right={iron4} size={200}>
            <P>
              Si votre club vous permet de piocher dans les paquets de coup n°3 et n°4, alors vous
              pouvez tirer deux cartes du paquet de coup n°5. Vous ne pouvez pas tirer deux cartes
              du paquet de coup n°4.
            </P>
          </SideBySide>
          <SideBySide right={iron3} size={200}>
            <P>
              Si votre club vous permet de piocher deux cartes, alors vous devez en piocher trois.
              Dans ce cas, l'autre joueur défausse une carte et vous choisissez parmi les deux
              restantes.
            </P>
          </SideBySide>
        </GreenPanel>
        <H4 id="4d" href="#4d" style={{ breakBefore: "page" }}>
          4.D) SORTIR D’UN BUNKER
        </H4>
        <P>
          Lorsque vous atterrissez dans un bunker, vous devez en sortir avant de continuer à jouer.
          Avant de piocher une carte coup, vous devez décider de la direction dans laquelle vous
          souhaitez vous déplacer. Cette règle est la même que celle du « chipping » (voir{" "}
          <A href="#chipping">« CHIPPING »</A>). L'hexagone dans lequel vous avez choisi de vous
          déplacer est votre hexagone ciblé.
        </P>
        <P>
          Piochez une seule carte coup à partir d'un des paquets de cartes indiqués sur la carte. Au
          lieu de regarder la trajectoire de vol de la carte coup, regardez l'icône de résultat du
          bunker dans le coin inférieur droit.
        </P>
        <P>
          Il y a quatre résultats indiqués ci-dessous. De gauche à droite, voici les résultats :
        </P>
        <ul className="list-disc list-inside">
          <li>restez dans le bunker</li>
          <li>déplacez-vous vers la gauche de l'hexagone ciblé</li>
          <li>déplacez-vous vers l'hexagone ciblé</li>
          <li>déplacez-vous vers la droite de l'hexagone ciblé</li>
        </ul>
        <div className="p-4 flex justify-center items-center">
          <img src={bunkerOutcomes} width={300} />
        </div>
        <P>
          Si vous sortez du bunker, déplacez votre golfeur vers l'hexagone déterminé par l'icône du
          bunker.
        </P>
        <P>
          Lorsque le club que vous avez choisi de jouer est le sand wedge, vous considérez le bunker
          comme le rough. Vous n'avez pas besoin d'effectuer l'action de sortie du bunker. Si votre
          sand wedge est dans votre main ou dans votre pile de défausse, il ne vous protège pas des
          bunkers.
        </P>
        <Example>
          <P>
            Sébastien se retrouve dans un bunker (A). Il déclare qu'il tire vers le green (flèche).
          </P>
          <P>
            Il pioche une carte coup, et le résultat du bunker montre que la balle va vers la
            droite.
          </P>
          <P>
            Il suit l'icône du bunker et se déplace vers la droite (B) de la direction qu'il a
            déclarée.
          </P>
          <div style={{ height: 325, overflow: "hidden" }} className="p-4">
            <img
              src={exitBunkerShotCard}
              width={150}
              style={{ position: "relative", top: 100, left: 0 }}
            />
            <img
              src={exitBunkerExample}
              width={300}
              style={{ position: "relative", top: -225, left: 0 }}
            />
            <div
              style={{
                position: "relative",
                top: -249,
                left: 98,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </Example>
        <H4 id="4e" href="#4e" style={{ breakBefore: "page" }}>
          4.e) RÉINITIALISER SA MAIN
        </H4>
        <P>
          Vous pouvez choisir de ne pas frapper la balle et de réinitialiser votre main. Reprenez
          tous les clubs que vous avez défaussés dans votre main. Si vous jouez la face A de Dorian,
          vous ne pouvez pas réinitialiser votre main à ce stade.
        </P>
        <H4 id="5" href="#5">
          5. Défausser une carte
        </H4>
        <P>
          Votre tour s'arrête ici. Placez le club que vous avez utilisé sur votre pile de défausse.
          Si vous jouez une carte qui n'expire jamais, remettez-la dans votre main, par exemple
          Slugger's Driver ou le club de Dorian sur la face A. Si vous jouez la face B de Slick
          Rikesh ou la face B de Dorian, ignorez cette étape.
        </P>
        <H3 id="reaching-the-green" href="#reaching-the-green">
          ATTEINDRE LE GREEN
        </H3>
        <H4 id="6" href="#6">
          6. DRAPEAUX
        </H4>
        <P>
          Lorsqu'un joueur atteint le green, il prend le drapeau. Si plusieurs joueurs atteignent le
          green au cours du même tour, un joueur prend le drapeau et les autres un jeton de 1 point.
        </P>
        <H4 id="7" href="#7">
          7. JETONS de score « trou en un »
        </H4>
        <P>
          Si vous avez fait un trou en un coup, prenez un jeton de trou en un. Un trou en un est
          marqué lorsque vous frappez depuis le tee box et atterrissez sur le green lors du premier
          coup pour ce trou.
        </P>
        <H4 id="8" href="#8">
          8. PASSER AU TROU SUIVANT
        </H4>
        <P>
          Une fois que les joueurs ont reçu leurs jetons de green, déplacez tous les joueurs vers le
          tee box du trou suivant.
        </P>
        <H4 id="9" href="#9">
          9. SUPPRIMER LES EFFETS DES OBSTACLES
        </H4>
        <P>
          Tout joueur qui était sur le point de perdre un tour à cause d'un obstacle d'eau se voit
          retirer ce statut.
        </P>
        <H3>Gagner la partie</H3>
        <P>
          A la fin du parcours, chaque joueur compte le nombre de drapeaux et de jetons à 1 point
          qu'il possède. Chaque drapeau et chaque jeton vaut 1 point. Le joueur qui a le plus de
          points gagne.
        </P>
        <P>
          En cas d'égalité, comptez les jetons « trou en un ». Le joueur qui a le plus de jetons
          « trou en un » remporte la partie. Si les joueurs sont toujours à égalité, ils se
          partagent la victoire.
        </P>
      </Section>
      <Section id="rough" title="JOUER À PARTIR DU ROUGH">
        <P>
          Lorsque vous commencez votre tour sur une tuile de rough, votre coup parcourt une distance
          réduite.
        </P>
        <SideBySide right={wood5} size={250}>
          <P>
            Pour les clubs qui parcourent une distance fixe (bois), c’est représenté par un deuxième
            hexagone sur votre club avec un symbole de rough. Ci-dessous : le Bois n°5 envoie la
            balle à 2 hexagones lorsqu'il est joué depuis le rough et 3 depuis un fairway.
          </P>
        </SideBySide>
        <SideBySide right={deck5right} size={200}>
          <P>
            Pour les clubs qui piochent dans un paquet de cartes de coups, il y a un symbole de
            rough sur votre carte coup (voir à droite). Utilisez-le à la place de l'hexagone orange
            pour déterminer la destination finale pour votre golfeur.
          </P>
        </SideBySide>
        <P>
          Le rough est considéré comme toute autre tuile qui ne comporte pas de fairway. Un obstacle
          d'eau est considéré comme rough (une fois que vous avez passé un tour). Un obstacle d'eau
          sur un fairway (<A href="#hazards">« OBSTACLES ET AUTRES TUILES »</A>) est considéré comme
          un fairway. La majeure partie d'un terrain de golf est composé de rough. Il est
          généralement conseillé de ne pas s'y aventurer.
        </P>
        <SideBySide RightComponent={HybridCardsFrench} size={200}>
          <P>
            <em>
              Tous les clubs hybrides (Hybride 3, Hybride 5 et Hybride 8) ne sont pas pénalisés
              lorsqu'ils frappent depuis le rough.
            </em>
          </P>
        </SideBySide>
        <SideBySide right={deckCStraight} size={200}>
          <P>
            <em>
              Toutes les cartes piochées du paquet chip ne peuvent pas être pénalisées lorsqu'elles
              tirent depuis le rough. Ces cartes ne comportent pas de symbole de rough (elles
              parcourent toujours 2 cases).
            </em>
          </P>
        </SideBySide>
        <Example>
          <P>Mackenzie (disque blanc) se retrouve dans le rough.</P>
          <img src={roughExample1} width={300} />
          <P>
            Elle joue un fer 2, ce qui lui permet de tirer à partir du jeu de cartes de coups n°4.
          </P>
          <div className="flex justify-center items-center w-full">
            <img src={deck4Left} width={StandardPortraitCardSize} />
          </div>
          <P>
            La carte coup va vers la gauche. Lorsqu'elle trace son mouvement, elle utilise le
            symbole du rough sur la carte coup pour calculer la trajectoire de la balle.
          </P>
          <P>
            En raison de la réduction de la distance, elle se déplace de deux hexagones en ligne
            droite, puis d'un hexagone vers la gauche pour atterrir là où le marqueur de rough de la
            carte coup indique. Dans cet exemple, elle est retombée sur le fairway, une bonne
            position pour son prochain coup.
          </P>
          <img src={roughExample2} width={300} />
        </Example>
      </Section>
      <Section id="chipping" title="Chipping">
        <SideBySide right={deckC} size={StandardPortraitCardSize}>
          <P>
            Le paquet « chip » est différent des autres paquets de coup. Ces cartes de coups vous
            donnent la possibilité de passer par-dessus les arbres, ne subissent pas de pénalité de
            rough et sont assorties d'une contrainte. Vous devez déclarer la direction dans laquelle
            vous allez utiliser la carte chip avant de piocher une ou plusieurs cartes.
          </P>
        </SideBySide>
        <H4>Déclarer une direction</H4>
        <P>
          Cette règle signifie qu'il faut choisir l'une des six directions autour de l'hexagone et
          en informer les autres joueurs. Vous pouvez soit la décrire tel que : « je tire vers
          l'eau » ou la pointer du doigt. Vous ne pouvez pas changer de direction après avoir pioché
          une carte coup.
        </P>
        <H4>Piocher des cartes de coups</H4>
        <P>
          Une fois que vous avez déclaré votre direction, vous piochez vos cartes comme d'habitude.
        </P>
        <H4>Déplacez votre golfeur</H4>
        <P>
          La principale modification de la phase de déplacement de votre golfeur est que vous avez
          déjà déclaré la direction dans laquelle vous frappez la balle. Déplacez votre golfeur
          comme d'habitude, à une exception près.
        </P>
        <P>
          Si l'hexagone adjacent à votre golfeur est un arbre, vous sautez par-dessus et atterrissez
          dans l'hexagone de destination orange. Vous ne pouvez pas atterrir sur des arbres, si
          l’hexagone prédéfini est un arbre, alors vous atterrissez sur l'hexagone suivant, comme si
          vous l’aviez sauté. S'il s'agit également d'un arbre, votre golfeur ne bouge pas.
        </P>
        <SideBySide right={lobWedge} size={StandardLandscapeCardSize}>
          <P>
            Le Lob Wedge est un club unique car vous n'avez pas besoin de déclarer la direction dans
            laquelle vous allez frapper la balle avant de le jouer.
          </P>
        </SideBySide>
        <Example>
          <P>
            Brock se retrouve dans une position délicate avec des arbres qui bloquent sa progression
            sur le fairway. Il joue son fidèle fer 9 et pointe la tuile où il a l’intention
            d’atterrir (flèche). C'est un jeu sûr, car la plupart des cartes du paquet chip le
            placeront sur le fairway.
          </P>
          <P>
            Il tire ensuite une carte du paquet de chip pour voir ce que le destin lui a réservé.
            Cela paye et il reçoit une carte coup qui va vers la droite, le remettant sur le fairway
            et en excellente position pour son prochain coup.
          </P>
          <div style={{ height: 350, overflow: "hidden" }}>
            <img src={chippingExample} width={350} />
            <img
              src={deckCRight}
              width={SmallPortraitCardSize}
              style={{ position: "relative", left: 0, top: -150 }}
            />
          </div>
        </Example>
      </Section>
      <Section id="super-short-wild" title="CARTES SUPER, COURT, MÉLANGE ET JOKER">
        <H3>Super et court</H3>
        <P>
          Si vous jouez une carte « super » ou « court », vous la posez sur la table, puis vous
          tirez une carte coup de remplacement et la placez sur la carte super ou court. Tirez une
          carte de remplacement dans le jeu de cartes indiqué sur la carte super ou la carte court.
        </P>
        <P>
          Ces cartes s'enchaînent, il est possible de revenir au paquet de cartes de départ ou de
          passer du paquet de cartes n°2 au paquet de cartes n°5.
        </P>
        <P>
          Un super coup du paquet chip qui demande à piocher dans un autre paquet de coup que le
          paquet chip perd sa capacité à passer par-dessus les arbres.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={superHit} width={StandardPortraitCardSize} />
          <img src={shortHit} width={StandardPortraitCardSize} />
        </div>
        <H3>Mélange</H3>
        <P>
          Lorsque vous tirez une carte « mélange », remettez toutes les cartes défaussées de ce
          paquet de cartes en jeu et mélangez-les. Placez cette carte sur la table et piochez une
          carte de remplacement que vous placerez sur le dessus. Piochez toujours la carte de
          remplacement dans le même paquet de cartes.
        </P>
        <P>
          Les cartes que vous avez pioché jusqu'à présent ce tour-ci, par exemple les cartes super
          ou d'autres cartes de clubs qui vous permettent de piocher 2 cartes, ne sont pas remises
          dans le paquet.
        </P>
        <P>
          Si vous piochez une carte mélange et qu'il n'y a pas de pile de défausse, vous n'avez pas
          besoin de mélanger la pioche. Vous devez quand même piocher une carte de remplacement.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={shuffle} width={StandardPortraitCardSize} />
        </div>
        <H3 style={{ breakBefore: "page" }}>Jokers</H3>
        <P>
          Lorsque vous piochez une carte avec plusieurs résultats, vous pouvez choisir celui que
          vous voulez utiliser. Si l'une des options est hors-jeu, vous devez alors prendre la seule
          option qui reste en jeu.
        </P>
        <P>
          Voir <A href="#hazards">« OBSTACLES ET AUTRES TUILES »</A> pour plus d'informations sur
          les options hors-jeu.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={wildCard} width={StandardPortraitCardSize} />
        </div>

        <P>
          Lorsque vous piochez et jouez cette carte, gardez-la. Cette carte vous rappelle que vous
          avez frappé le plus long coup du jeu, et si vous ne gagnez pas aujourd'hui, au moins vous
          rentrerez chez vous avec quelque chose. Pas la carte, vous comprenez… elle reste dans la
          boîte, mais la sensation de satisfaction d'avoir fait le plus long coup.
        </P>
        <P>
          Cette carte coup parcourt 6 hexagones en ligne droite. Elle s'arrête aux obstacles
          d’arbres comme les autres cartes de coups.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={longestDriveCard} width={StandardPortraitCardSize} />
        </div>
      </Section>
      <Section id="hazards" title="OBSTACLES ET AUTRES TUILES">
        <SideBySide title="Bunker" right={bunkerHex} size={SmallHex}>
          <P>
            Les contraintes des bunkers s’appliquent à tous les tours où vous commencez dans cet
            hexagone. Lorsque vous êtes dans le bunker, vous devez en sortir. Voir{" "}
            <A href="#4d">« 4.D) SORTIR D’UN BUNKER »</A>.
          </P>
          <P>Vous pouvez utiliser le Sand Wedge pour traiter les bunkers comme un rough.</P>
        </SideBySide>
        <SideBySide title="Le green" right={greenHex} size={SmallHex}>
          <P>
            Votre destination. Selon le mode de jeu, les joueurs qui atteignent le green en premier
            remportent le tour. Lorsque vous jouez depuis le green d'un autre trou considérez la
            tuile comme un fairway.
          </P>
        </SideBySide>
        <SideBySide title="Le rough" right={roughHex} size={SmallHex}>
          <P>
            Les coups de cet hexagone parcourent un hexagone de moins que prévu. Après avoir pioché
            votre carte coup, cherchez l'icône du rough sur celle-ci. Ce symbole indique l'endroit
            où votre balle atterrit. Voir
            <A href="#rough">« JOUER À PARTIR DU ROUGH »</A>.
          </P>
        </SideBySide>
        <div style={{ breakBefore: "page" }}>
          <SideBySide title="Hors Limite &amp; CLUBHOUSE" right={clubhouse} size={SmallHex}>
            <P>
              Chaque fois qu’une balle quitte le terrain, elle est hors-jeu. La balle est également
              hors-jeu lorsqu'elle sort du terrain partiellement lors de sa course vers une tuile.
              Lorsque vous êtes hors-jeu, votre golfeur reste à l'endroit où il a tiré le coup. Il
              reprend le club dans sa main ; son tour est terminé.
            </P>
            <P>
              L'atterrissage sur le club-house est également considéré comme hors-jeu, sauf si, bien
              sûr, vous jouez le 19ème.
            </P>
            <P>
              <em>
                Remarque : les angles extérieurs des hexagones du parcours font partie du parcours,
                vous pouvez atterrir dessus.
              </em>
            </P>
          </SideBySide>
        </div>
        <div
          className="flex p-8 justify-center items-center"
          style={{ height: 300, overflow: "hidden" }}
        >
          <div className="pt-16">
            <img src={courseTile} width={CourseTileSize} />
            <div
              style={{
                position: "relative",
                top: -240,
                left: 35,
                borderRadius: 75,
                width: 75,
                height: 75,
                flex: 0,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H5>Variation</H5>
        <P>
          Nous avons conçu la règle du hors-jeu parce qu’il était difficile pour la plupart des
          joueurs de savoir où ils étaient en dehors du parcours sans une grille. Mais certains
          joueurs le peuvent. Si les joueurs sont tous d'accord pour utiliser cette variante, vous
          pouvez alors permettre aux joueurs de frapper en dehors des limites du parcours. Les
          atterrissages en dehors du parcours sont toujours hors-jeu.
        </P>
        <SideBySide title="Tee box" right={teeboxHex} size={SmallHex}>
          <P>
            Le tee box est le point de départ de chaque trou. Ces tuiles indiquent le par et le
            nombre de grandes tuiles du parcours à ajouter avant le green. Pour plus d'informations
            sur la construction des parcours, allez voir la dernière page du livret de parcours.
          </P>
        </SideBySide>
        <SideBySide title="Arbres" right={treeHex} size={SmallHex}>
          <P>
            Les arbres arrêtent le vol de votre balle. Déplacez votre balle d'un hexagone vers
            l'arrière le long de sa trajectoire de vol. Si l'hexagone précédent est un obstacle,
            suivez les règles de cet obstacle.
          </P>
          <P>
            <em>Remarque : seuls les arbres entourés d'une clôture arrêtent la balle.</em>
          </P>
        </SideBySide>
        <SideBySide
          title="L'eau (ET les fairways avec des lacs)"
          RightComponent={WaterHazards}
          size={SmallHex}
        >
          <P>
            Les obstacles d'eau s'appliquent lorsque la balle termine son vol sur cet hexagone. Vous
            passez votre prochain tour. Allongez votre golfeur. Au prochain tour, levez votre
            golfeur et sautez le tour. Vous pourrez jouer au tour suivant.
          </P>
          <P>
            Vous jouez votre prochain coup à partir de la tuile d'eau. Jouez la tuile d'eau comme si
            elle était un rough, sauf s'il s'agit d'un fairway avec un lac. Dans ce cas, on
            considère qu'il s'agit d'un fairway.
          </P>
          <P>The Shark ignore ce danger et joue la tuile sous-jacente.</P>
        </SideBySide>
      </Section>
      <Section id="golfers" title="Aptitudes des golfeurs">
        <P>
          Il y a huit golfeurs différents sur 18 Holes, et chacun d'eux apporte une expérience
          différente. Chaque golfeur a une face A et une face B. La face A facilite le jeu et la
          face B le rend plus difficile. Il y a un A ou un B dans le coin inférieur droit.
        </P>
        <H3>BUNJIL</H3>
        <SideBySide right={golfer2a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Immédiatement avant que Bunjil ne choisisse son club pour jouer, il peut regarder une
            fois la carte supérieure d'un paquet de cartes de coups. Tournez Bunjil sur le côté (à
            l’horizontale) pour indiquer que son pouvoir a été utilisé. Le joueur ne doit pas
            montrer la carte aux autres joueurs. Retournez la carte face cachée sur le dessus du
            paquet de carte coup.
          </P>
          <P>Lorsque le trou est terminé, retournez Bunjil verticalement.</P>
          <P>
            Bunjil ne peut que regarder les paquets de cartes dans lesquels il pourrait légitimement
            piocher.
          </P>
        </SideBySide>
        <SideBySide right={golfer2b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Comme la face A, de plus Bunjil peut décider soit de garder la carte coup et de
            l'utiliser lors de son prochain coup, soit de la remettre sur le dessus du paquet de
            cartes de coups, face vers le haut, pour que tous les joueurs puissent la voir.
          </P>
          <P>
            Si Bunjil souhaite garder la carte, le joueur doit alors jouer avec un club qui peut
            accéder à ce paquet de cartes de coups.
          </P>
        </SideBySide>
        <H3>Dorian</H3>
        <SideBySide right={golfer8a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Après le tirage des cartes, avant le premier trou, le joueur avec Dorian dit au reste du
            groupe lequel de ses clubs n'expire jamais. Chaque fois que vous utilisez ce club,
            remettez-le dans votre main au lieu de le jeter.
          </P>
          <P>
            Dorian ne peut pas réinitialiser sa main pendant un trou mais peut le faire à la fin du
            trou si sa main ne contient que le club qui n'expire jamais (et qu’il respecte les
            règles de réinitialisation de la main).
          </P>
        </SideBySide>
        <SideBySide right={golfer8b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Dorian ne jette jamais ses clubs après usage. Dorian ne peut prendre que trois clubs
            lors de la sélection.
          </P>
        </SideBySide>
        <H3>Iron Jane</H3>
        <SideBySide right={golfer3a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Avant la sélection des clubs, le joueur avec Iron Jane sélectionne ses clubs parmi les
            clubs disponibles. Il mélange les cartes restantes et les distribue aux autres joueurs.
          </P>
        </SideBySide>
        <SideBySide right={golfer3b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Même pouvoir que la face A mais Iron Jane ne peut porter que quatre clubs. Iron Jane ne
            peut pas prendre deux clubs de la même couleur.
          </P>
        </SideBySide>
        <H3>Lucky</H3>
        <SideBySide right={golfer6a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Pendant le jeu, une fois par main, Lucky peut re-piocher une carte coup. Pour ce faire,
            le joueur avec Lucky le dit au groupe et tourne ensuite Lucky sur le côté (à
            l’horizontale) pour indiquer que son pouvoir a été utilisé. Le joueur pioche alors une
            nouvelle carte coup du même jeu que le précédent. Lucky doit jouer la nouvelle carte.
          </P>
          <P>Vous ne pouvez pas utiliser ce pouvoir quand Lucky est à l’horizontale.</P>
          <P>Lorsque vous réinitialisez votre main, remettez Lucky à la verticale.</P>
        </SideBySide>
        <SideBySide right={golfer6b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Lors de l'utilisation de la capacité de la face A de Lucky, le joueur écarte un de ses
            autres clubs. Si le joueur n'a pas d'autres clubs, il ne peut pas utiliser le pouvoir de
            Lucky.
          </P>
          <P>Vous ne pouvez utiliser le pouvoir de Lucky qu'une seule fois par trou.</P>
        </SideBySide>
        <H3>Slick Rikesh</H3>
        <SideBySide right={golfer4a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Une fois que tous les joueurs ont révélé leurs cartes de club, Slick Rikesh peut
            utiliser le club choisi par un autre joueur. L'initiative du club de Slick Rikesh est la
            même que celle du club du propriétaire d'origine et il joue immédiatement après. Slick
            Rikesh ne peut utiliser son pouvoir qu'une seule fois par trou.
          </P>
          <P>
            Faites pivoter Slick Rikesh sur le côté (à l’horizontale) pour indiquer que son pouvoir
            est utilisé. Remettez en position verticale après chaque trou.
          </P>
        </SideBySide>
        <SideBySide right={golfer4b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Le joueur avec Slick Rikesh ne choisit qu'une seule carte pendant la sélection et peut
            choisir ce club à n'importe quel tour de pioche.
          </P>
          <P>
            Pendant le jeu, Slick Rikesh joue son club comme d'habitude (pour déterminer
            l'initiative) et peut ensuite choisir d’utiliser n'importe quel club défaussé d'un autre
            joueur. S'il n'y a pas de clubs défaussés, Slick Rikesh doit jouer son club.
          </P>
        </SideBySide>
        <H3>Slugger</H3>
        <SideBySide right={golfer5a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Slugger doit porter le driver. Retirez cette carte du paquet avant la phase de sélection
            des clubs et donnez-la au joueur qui a Slugger. Le joueur sélectionnera quatre clubs
            pendant la phase de sélection, en sautant le dernier tour.
          </P>
          <P>
            Pendant le jeu, vous ne vous défaussez jamais du driver. Remettez-le dans votre main
            après avoir joué.
          </P>
        </SideBySide>
        <SideBySide right={golfer5b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Comme la face A mais Slugger doit utiliser un bois ou un driver sur tous les départs.
            Oui, y compris les par-3.
          </P>
        </SideBySide>
        <H3>SWIFT</H3>
        <SideBySide right={golfer7a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Lors de l'étape « Déterminer l'ordre de jeu », soustrayez un à l'initiative du club joué
            par Swift. Si l'initiative de Swift est identique à celle d'un autre joueur, Swift passe
            en premier.
          </P>
        </SideBySide>
        <SideBySide right={golfer7b} size={StandardPortraitCardSize}>
          <H4>Face B</H4>
          <P>
            Lors de l'étape « Déterminer l'ordre de jeu », soustrayez un à l'initiative du club joué
            par Swift. Si l'initiative de Swift est identique à celle d'un autre joueur, Swift passe
            après ce joueur.
          </P>
        </SideBySide>
        <H3>The Shark</H3>
        <SideBySide right={golfer1a} size={StandardPortraitCardSize}>
          <H4>Face A</H4>
          <P>
            Les obstacles d'eau ne concernent pas The Shark, y compris sur les fairways. Traitez-les
            comme la tuile sous-jacente.
          </P>
        </SideBySide>
        <H4>Face B</H4>
        <SideBySide right={golfer1b} size={StandardPortraitCardSize}>
          <P>Comme la face A mais The Shark ne peut pas porter de wedges.</P>
          <P>Les wedges incluent le Pitching Wedge, le Sand Wedge et le Lob Wedge.</P>
          <P>
            Pendant la phase de sélection des clubs, un joueur qui a The Shark doit passer son tour
            si les seuls clubs disponibles sont des wedges. The Shark peut ensuite choisir parmi les
            cartes restantes après la sélection.
          </P>
        </SideBySide>
      </Section>
      <Section id="game-modes" title="DIFFÉRENTES FAÇONS DE JOUER">
        <P>
          Les 18 Holes, comme le golf, ont plusieurs modes de jeu différents. La principale méthode
          décrite dans ce livre permet à tous les joueurs de s'affronter pour chaque trou. Le golf
          est un jeu difficile où il peut être difficile de rattraper son retard. Si vous aimez ce
          genre de jeu ou si vous souhaitez vivre une expérience de golf plus réaliste, vous pouvez
          vous intéresser au golf racing, au Stroke Play ou Stableford.
        </P>
        <P>
          Si votre groupe préfère les jeux où tout le monde se bat pour chaque point, essayez Match
          Play et Skins.
        </P>
        <P>
          Certaines variantes s'éloignent un peu plus du fairway. Elles s'adressent aux personnes
          qui souhaitent une expérience de golf moins traditionnelle. Les variantes Chaos Golf et
          Kelly Golf correspondent à cette description.
        </P>
        <P>
          Si vous voulez vivre une expérience en solo, essayez le Chaos Golf, le Stableford ou le
          Stroke Play.
        </P>
        <P>
          Si vous voulez jouer à deux, essayez Chaos Teams : notre expérience dédiée à deux joueurs.
          Vous pouvez aussi jouer en Match Play, Skins, Stroke Play, Stableford ou Golf Racing.
        </P>
        <P>
          Chaque mode de jeu est accompagné d'une section qui décrit le mode de jeu, le nombre de
          joueurs qu'il supporte et le moment où vous pourriez être intéressé par ce jeu. À droite,
          nous avons la section pour le Match Play, la règle décrite jusqu'à présent.
        </P>
        <P className={link}>
          <Link to={Modes}>Index des variations :</Link>
        </P>
        <ul className="list-disc list-inside">
          <li className={link}>
            <Link to={ModesChaosGolf}>Chaos Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosTeams}>Chaos Teams</Link>
          </li>
          <li className={link}>
            <Link to={ModesGolfRacing}>Golf Racing</Link>
          </li>
          <li className={link}>
            <Link to={ModesKellyGolf}>Kelly Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesMatchPlay}>Match Play</Link>
          </li>
          <li className={link}>
            <Link to={ModesSkins}>Skins</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
          </li>
          <li className={link}>
            <Link to={ModesStableford}>Stableford</Link>
          </li>
          <li className={link}>
            <Link to={ModesStrokePlay}>Stroke Play</Link>
          </li>
        </ul>
      </Section>
    </div>
  );
};
