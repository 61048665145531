import meta from "./meta.json";

/**
 * @param {any[]} withErrors
 * @param {number} teeboxCount
 * @param {number} greenCount
 * @param {any[]} holeNumbers
 * @param {any[]} positions
 * @returns {boolean}
 */
export const canPublishCourse = (withErrors, teeboxCount, greenCount, holeNumbers, positions) => {
  const errors = validateCourseForPublish(
    withErrors,
    teeboxCount,
    greenCount,
    holeNumbers,
    positions
  );

  return errors.length === 0;
};

/**
 * @param {any[]} withErrors
 * @param {number} teeboxCount
 * @param {number} greenCount
 * @param {any[]} holeNumbers
 * @param {any[]} positions
 * @returns {string[]}
 */
export const validateCourseForPublish = (
  withErrors,
  teeboxCount,
  greenCount,
  holeNumbers,
  positions
) => {
  if (withErrors.length > 0) {
    return withErrors;
  }
  if (teeboxCount === 0) {
    return ["no tees"];
  }
  if (teeboxCount !== greenCount) {
    return ["Tee count does not equal green count."];
  }

  const numbers = holeNumbers
    .map(({ holeNumber }) => holeNumber)
    .reduce((set, n) => (set.includes(n) ? set : set.concat(n)), []);

  const incompleteHoles = numbers.filter((number) => {
    const forHole = holeNumbers
      .filter(({ holeNumber }) => holeNumber === number)
      .map(({ id }) => positions.find(({ ref }) => ref.id === id))
      .map(({ brush }) => meta[brush]);

    const teeBoxCountForHole = forHole.reduce((t, { par }) => t + par.length, 0);
    const greenCountForHole = forHole.reduce((t, { greens }) => t + greens, 0);

    return teeBoxCountForHole !== greenCountForHole;
  });

  if (incompleteHoles.length > 0) {
    return incompleteHoles.map((number) => `Hole ${number} is incomplete.`);
  }

  return [];
};
