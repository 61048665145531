import React, { useEffect, useRef, useState } from "react";
import { LoadingIndicator } from "./Loading";

export const Button = ({ children, href, ...props }) => {
  return (
    <a
      href={href}
      className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-0"
      {...props}
    >
      {children}
    </a>
  );
};

export const ButtonAlt = ({ children, href, size, ...props }) => {
  const enabledStyle =
    "text-white border-brand-green hover:border-transparent bg-brand-green hover:opacity-75 cursor-pointer";

  return (
    <a
      href={href}
      className={`inline-block text-${size} px-4 py-2 leading-none border rounded mt-0 ${enabledStyle} flex-row flex justify-center items-center`}
      {...props}
    >
      {children}
    </a>
  );
};

export const ButtonButton = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-0 cursor-pointer"
    >
      {children}
    </button>
  );
};

export const ButtonButtonAlt = ({
  children,
  onClick,
  enabled,
  busyText = "",
  danger = false,
  dangerText = "Are you sure?",
  style = "",
}) => {
  let id = useRef(null);
  const [busy, setBusy] = useState(false);

  const borderStyle = danger ? "border-red-900" : "border-brand-green";
  const backgroundStyle = danger ? "bg-red-100 hover:bg-red-500" : "bg-white hover:bg-brand-green";
  const enabledStyle = `text-black ${borderStyle} ${backgroundStyle} hover:border-transparent hover:text-white cursor-pointer`;
  const notEnabledStyle = "text-gray-500 cursor-not-allowed";
  const activeStyle = enabled ? enabledStyle : notEnabledStyle;

  async function onClickHandler(e) {
    if (danger) {
      const result = window.confirm(dangerText);

      if (!result) {
        return;
      }
    }
    setBusy(true);
    await onClick(e);

    id.current = setTimeout(() => {
      if (!id.current) {
        return;
      }

      setBusy(false);
    }, 100);
  }

  useEffect(() => {
    return () => {
      clearTimeout(id.current);
      id.current = 0;
    };
  }, []);

  return (
    <button
      onClick={onClickHandler}
      disabled={!enabled}
      className={`inline-block text-sm px-4 py-2 h-8 leading-none border rounded mt-0 ${activeStyle} flex-row flex justify-center items-center flex-0 ${style}`}
    >
      {busy ? (
        <>
          <LoadingIndicator width={6} height={6} />
          {busyText}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const ScoringButton = ({ children, onClick, enabled }) => {
  let id = useRef(null);
  const [busy, setBusy] = useState(false);

  const enabledStyle = `text-black border-brand-green bg-white hover:bg-brand-green hover:border-transparent hover:text-white cursor-pointer`;
  const notEnabledStyle = "text-gray-500 cursor-not-allowed";
  const activeStyle = enabled ? enabledStyle : notEnabledStyle;

  async function onClickHandler(e) {
    if (busy) {
      return;
    }

    setBusy(true);
    await onClick(e);

    id.current = setTimeout(() => {
      if (!id.current) {
        return;
      }

      setBusy(false);
    }, 100);
  }

  useEffect(() => {
    return () => {
      clearTimeout(id.current);
      id.current = 0;
    };
  }, []);

  return (
    <button
      onClick={onClickHandler}
      disabled={!enabled}
      className={`text-sm w-8 h-8 leading-none border rounded p-0 m-0 ${activeStyle} flex-row flex justify-center items-center flex-0`}
      style={{
        minWidth: "2rem",
        minHeight: "2rem",
        maxWidth: "2rem",
        maxHeight: "2rem",
      }}
    >
      {busy ? <LoadingIndicator /> : children}
    </button>
  );
};

export const CourseDesignerButton = ({ children, onClick, enabled, busyText = "" }) => {
  const [busy, setBusy] = useState(false);

  const enabledStyle =
    "text-black border-brand-green hover:border-transparent hover:text-white hover:bg-brand-green cursor-pointer";
  const notEnabledStyle = "text-gray-500 cursor-not-allowed border-gray-500";
  const activeStyle = enabled ? enabledStyle : notEnabledStyle;

  async function onClickHandler(e) {
    setBusy(true);
    await onClick(e);

    setTimeout(() => {
      setBusy(false);
    }, 500);
  }

  return (
    <button
      onClick={onClickHandler}
      disabled={!enabled}
      className={`inline-block text-sm px-4 py-2 h-8 leading-none border rounded mt-0 ${activeStyle} flex-row flex justify-center items-center`}
    >
      {busy ? (
        <>
          <LoadingIndicator />
          {busyText}
        </>
      ) : (
        children
      )}
    </button>
  );
};
