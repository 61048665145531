import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";

const LandingPage = () => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-8 w-3/5">
          <h1 className="font-bold text-xl">Privacy Policy</h1>
          <p className="pt-2">
            Your privacy is important to us. It is our policy to respect your
            privacy regarding any information we may collect from you across
            this website.
          </p>

          <h2 className="font-bold text-lg pt-4">Information we collect</h2>
          <p className="pt-2">
            This website is statically hosted and does not record information
            about the requesting browser or user.
          </p>

          <p className="pt-2">
            If you sign up for our mailing list then we will ask for your email
            address in order to send you newsletters. You can unsubscribe from
            our newsletter at any time.
          </p>

          <p className="pt-2">
            If you buy a copy of 18 Holes then we will ask for your name and
            postal address so we can ship the game to you.
          </p>

          <p className="pt-2">
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </p>

          <h2 className="font-bold text-lg pt-4">
            Disclosure of information to third parties
          </h2>
          <p className="pt-2">
            Our analytics are provided by Google Analytics and Facebook.
          </p>

          <h2 className="font-bold text-lg pt-4">About this policy</h2>
          <p className="pt-2">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </p>

          <p className="pt-2">
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>

          <p className="pt-2">
            Your continued use of our website will be regarded as acceptance of
            our practices around privacy and personal information. If you have
            any questions about how we handle user data and personal
            information, feel free to contact us.
          </p>

          <p className="pt-2">
            <em>This policy is effective as of 3 February 2020.</em>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
