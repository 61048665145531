import React from "react";

const PathForHoleFrame = ({ path, colour }) => <path d={path} stroke={colour} fill="none" strokeWidth="3" />;

const HoleFrame = {
  NONE: "M 25 -43 L -25 -43 L -50 0 L -25 43 L 25 43 L 50 0 Z",
  TL: "M -50 0 L -25 43 L 25 43 L 50 0 L 25 -43 L -25 -43",
  TM: "M -25 -43.3 L -49.74 -0.15 L -24.74 43.15 L 25 43.3 L 49.74 0.15 L 24.74 -43.15",
  TR: "M 25 -43.3 L -24.74 -43.15 L -49.74 0.15 L -25 43.3 L 24.74 43.15 L 49.74 -0.15",
  BR: "M 50 0 L 25 -43 L -25 -43 L -50 0 L -25 43 L 25 43",
  BM: "M 25 43.3 L 49.74 0.15 L 24.74 -43.15 L -25 -43.3 L -49.74 -0.15 L -24.74 43.15",
  BL: "M -25 43.3 L 24.74 43.15 L 49.74 -0.15 L 25 -43.3 L -24.74 -43.15 L -49.74 0.15",
  "BL-BR": "M 50 0 L 25 -43 L -25 -43 L -50 0 M -25 43 L 25 43",
  "BL-TL": "M -25 43.3 L 24.74 43.15 L 49.74 -0.15 L 25 -43.3 L -24.74 -43.15",
  "BL-TM": "M -25 43.3 L 24.74 43.15 L 49.74 -0.15 L 25 -43.3 M -24.74 -43.15 L -49.74 0.15",
  "BL-TR": "M 25 -43.3 L -24.74 -43.15 L -49.74 0.15 M -25 43.3 L 24.74 43.15 L 49.74 -0.15",
  "BM-BL": "M 25.37 42.65 L 50.11 -0.5 L 25.1 -43.82 L -24.62 -43.96 L -49.36 -0.81",
  "BM-TL": "M 25 43.3 L 49.74 0.15 L 24.74 -43.15 L -25 -43.3 M -49.74 -0.15 L -24.74 43.15",
  "BM-TM": "M -25 -43.3 L -49.74 -0.15 L -24.74 43.15 M 25 43.3 L 49.74 0.15 L 24.74 -43.15",
  "BM-TR": "M 25 -43.3 L -24.74 -43.15 L -49.74 0.15 L -25 43.3 M 24.74 43.15 L 49.74 -0.15",
  "BR-BL": "M 50 0 L 25 -43 L -25 -43 L -50 0 M -25 43 L 25 43",
  "BR-BM": "M 50.38 -0.65 L 25.38 -43.65 L -24.64 -43.65 L -49.63 -0.66 L -24.63 42.34",
  "BR-TL": "M 50 0 L 25 -43 L -25 -43 M -50 0 L -25 43 L 25 43",
  "BR-TM": "M -25 -43.3 L -49.74 -0.15 L -24.74 43.15 L 25 43.3 M 49.74 0.15 L 24.74 -43.15",
  "TL-BM": "M 25 43.3 L 49.74 0.15 L 24.74 -43.15 L -25 -43.3 M -49.74 -0.15 L -24.74 43.15",
  "TL-BR": "M 50 0 L 25 -43 L -25 -43 M -50 0 L -25 43 L 25 43",
  "TL-TM": "M -49.79 -0.36 L -24.79 42.64 L 25.21 42.64 L 50.2 -0.36 L 25.2 -43.36",
  "TL-TR": "M -50 0 L -25 43 L 25 43 L 50 0 M 25 -43 L -25 -43",
  "TM-BL": "M -25 43.3 L 24.74 43.15 L 49.74 -0.15 L 25 -43.3 M -24.74 -43.15 L -49.74 0.15",
  "TM-BM": "M -25 -43.3 L -49.74 -0.15 L -24.74 43.15 M 25 43.3 L 49.74 0.15 L 24.74 -43.15",
  "TM-BR": "M -25 -43.3 L -49.74 -0.15 L -24.74 43.15 L 25 43.3 M 49.74 0.15 L 24.74 -43.15",
  "TM-TR": "M -24.79 -43.66 L -49.53 -0.51 L -24.53 42.8 L 25.2 42.94 L 49.94 -0.21",
  "TR-BL": "M 25 -43.3 L -24.74 -43.15 L -49.74 0.15 M -25 43.3 L 24.74 43.15 L 49.74 -0.15",
  "TR-BM": "M 25 -43.3 L -24.74 -43.15 L -49.74 0.15 L -25 43.3 M 24.74 43.15 L 49.74 -0.15",
  "TR-BR": "M 25.21 -43.66 L -24.53 -43.51 L -49.54 -0.2 L -24.8 42.94 L 24.94 42.79",
  "TR-TL": "M -50 0 L -25 43 L 25 43 L 50 0 M 25 -43 L -25 -43",
};

export const Exits2Direction = {
  TL: "NW",
  TM: "N",
  TR: "NE",
  BR: "SE",
  BM: "S",
  BL: "SW",
};

export const Exits = {
  TL: ["TL"],
  TM: ["TM"],
  TR: ["TR"],
  BR: ["BR"],
  BM: ["BM"],
  BL: ["BL"],
  "BL-BR": "BL-BR".split("-"),
  "BL-BM": "BL-BM".split("-"),
  "BL-TL": "BL-TL".split("-"),
  "BL-TM": "BL-TM".split("-"),
  "BL-TR": "BL-TR".split("-"),
  "BM-BL": "BM-BL".split("-"),
  "BM-BR": "BM-BR".split("-"),
  "BM-TL": "BM-TL".split("-"),
  "BM-TM": "BM-TM".split("-"),
  "BM-TR": "BM-TR".split("-"),
  "BR-BL": "BR-BL".split("-"),
  "BR-BM": "BR-BM".split("-"),
  "BR-TR": "BR-TR".split("-"),
  "BR-TL": "BR-TL".split("-"),
  "BR-TM": "BR-TM".split("-"),
  "TL-BL": "TL-BL".split("-"),
  "TL-BM": "TL-BM".split("-"),
  "TL-BR": "TL-BR".split("-"),
  "TL-TM": "TL-TM".split("-"),
  "TL-TR": "TL-TR".split("-"),
  "TM-BL": "TM-BL".split("-"),
  "TM-BM": "TM-BM".split("-"),
  "TM-BR": "TM-BR".split("-"),
  "TM-TL": "TM-TL".split("-"),
  "TM-TR": "TM-TR".split("-"),
  "TR-BL": "TR-BL".split("-"),
  "TR-BM": "TR-BM".split("-"),
  "TR-BR": "TR-BR".split("-"),
  "TR-TL": "TR-TL".split("-"),
  "TR-TM": "TR-TM".split("-"),
  NONE: [],
};

const SingleExitFrames = ["TL", "TM", "TR", "BR", "BM", "BL"].reverse();
const AdjustmentHexRotations = SingleExitFrames.reverse();
const RotateDiamonds = ["DTL", "DTR", "DMR", "DBR", "DBL", "DML"].reverse();
const StraightFrames = ["BM-TM", "BL-TR", "TL-BR", "TM-BM", "TR-BL", "BR-TL"];
const SameSideFrames = ["BL-TL", "TL-TM", "TM-TR", "TR-BR", "BR-BM", "BM-BL"];
const RightCornerFrames = ["BM-TR", "BL-BR", "TL-BM", "TM-BL", "TR-TL", "BR-TM"];
const LeftCornerFrames = ["BM-TL", "BL-TM", "TL-TR", "TM-BR", "TR-BM", "BR-BL"];

export const rotate = (holeFrame, rotations, set = SingleExitFrames) => {
  if (rotations === 0) {
    return holeFrame;
  }

  const indexOfCurrentRotation = set.indexOf(holeFrame);
  const nextHoleFrame = indexOfCurrentRotation === set.length - 1 ? set[0] : set[indexOfCurrentRotation + 1];

  return rotate(nextHoleFrame, rotations - 1, set);
};

export const rotateAdjustmentHex = (holeFrame, rotations) => {
  if (rotations === 0 || holeFrame === "CM") {
    return holeFrame;
  }

  const set = RotateDiamonds.includes(holeFrame) ? RotateDiamonds : AdjustmentHexRotations;

  const indexOfCurrentRotation = set.indexOf(holeFrame);
  const nextHoleFrame = indexOfCurrentRotation === set.length - 1 ? set[0] : set[indexOfCurrentRotation + 1];

  return rotateAdjustmentHex(nextHoleFrame, rotations - 1);
};

export const getRotations = (degrees) => Math.floor(degrees / 60);

const getRotatedFrame = (holeFrame, rotation) => {
  if (holeFrame === "NONE") {
    return HoleFrame.NONE;
  }

  const rotations = Math.floor(rotation / 60);

  if (SingleExitFrames.includes(holeFrame)) {
    const rotatedHoleFrame = rotate(holeFrame, rotations, SingleExitFrames);

    return HoleFrame[rotatedHoleFrame];
  }
  if (StraightFrames.includes(holeFrame)) {
    const rotatedHoleFrame = rotate(holeFrame, rotations, StraightFrames);

    return HoleFrame[rotatedHoleFrame];
  }
  if (SameSideFrames.includes(holeFrame)) {
    const rotatedHoleFrame = rotate(holeFrame, rotations, SameSideFrames);

    return HoleFrame[rotatedHoleFrame];
  }

  if (RightCornerFrames.includes(holeFrame)) {
    const rotatedHoleFrame = rotate(holeFrame, rotations, RightCornerFrames);

    return HoleFrame[rotatedHoleFrame];
  }
  if (LeftCornerFrames.includes(holeFrame)) {
    const rotatedHoleFrame = rotate(holeFrame, rotations, LeftCornerFrames);

    return HoleFrame[rotatedHoleFrame];
  }

  console.error("missing for: ", holeFrame, rotation);

  return null;
};

export const getTileHoleFrame = (tileMeta, rotation) => {
  if (!tileMeta) {
    return null;
  }

  const path = getRotatedFrame(tileMeta.holeFrame, rotation);
  if (!path) {
    return null;
  }

  return ({ colour }) => <PathForHoleFrame colour={colour} path={path} />;
};
