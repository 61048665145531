import React from "react";
import BrushShapes from "./brushes";
import { matrix } from "./math";
import meta from "./meta.json";
import { getTileHoleFrame } from "./PathForHoleFrame";

const hexImageWidth = 120;
const hexImageHeight = 105;
const adjustmentHexImageWidth = 37;
const adjustmentHexImageHeight = 32.375;

const HexagonOutline = ({ colour }) => (
  <path
    opacity="0.5"
    d="M 25 -43 L -25 -43 L -50 0 L -25 43 L 25 43 L 50 0 Z"
    stroke={colour}
    fill="none"
    strokeWidth="1"
  />
);

const AdjustmentHexOutline = ({ colour, onClick, id }) => (
  <g>
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M -16.65 -28.67 L -33.31 -28.67 L -41.65 -14.34 L -33.31 0 L -16.65 0 L -8.31 -14.34 Z"
      onClick={() => {
        onClick("TL", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 8.33 -43 L -8.33 -43 L -16.67 -28.67 L -8.33 -14.33 L 8.33 -14.33 L 16.67 -28.67 Z"
      onClick={() => {
        onClick("TM", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 33.32 -28.67 L 16.66 -28.67 L 8.32 -14.34 L 16.66 0 L 33.32 0 L 41.66 -14.34 Z"
      onClick={() => {
        onClick("TR", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 33.32 0 L 16.66 0 L 8.32 14.33 L 16.66 28.67 L 33.32 28.67 L 41.66 14.33 Z"
      onClick={() => {
        onClick("BR", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 8.33 14.33 L -8.33 14.33 L -16.67 28.67 L -8.33 43 L 8.33 43 L 16.67 28.67 Z"
      onClick={() => {
        onClick("BM", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M -16.65 0 L -33.31 0 L -41.65 14.33 L -33.31 28.67 L -16.65 28.67 L -8.31 14.33 Z"
      onClick={() => {
        onClick("BL", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 8.33 -14.33 L -8.33 -14.33 L -16.67 0 L -8.33 14.33 L 8.33 14.33 L 16.67 0 Z"
      onClick={() => {
        onClick("CM", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M -16.67 -28.67 L -8.31 -43 L -24.97 -43 L -33.28 -28.66 L -16.67 -28.67 Z"
      onClick={() => {
        onClick("DTL", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 33.32 -28.67 L 24.98 -43 L 8.31 -43 L 16.67 -28.67 L 33.32 -28.67 Z"
      onClick={() => {
        onClick("DTR", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 50 0 L 41.66 -14.33 L 33.32 0 L 41.66 14.33 L 50 0 Z"
      onClick={() => {
        onClick("DMR", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M 24.98 43 L 33.32 28.68 L 16.67 28.67 L 8.24 43.01 L 24.98 43 Z"
      onClick={() => {
        onClick("DBR", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M -8.34 43.01 L -16.67 28.67 L -33.29 28.67 L -24.98 43 L -8.34 43.01 Z"
      onClick={() => {
        onClick("DBL", id);
      }}
    />
    <path
      fill="white"
      fillOpacity="0.3"
      strokeWidth="1"
      stroke={colour}
      d="M -33.28 0 L -41.65 -14.33 L -50 0 L -41.65 14.33 L -33.28 0 Z"
      onClick={() => {
        onClick("DML", id);
      }}
    />
  </g>
);

const AdjustmentHexes = ({ adjustments, image, outline }) => (
  <g>
    {adjustments.CM && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75}
          y={image.y + adjustmentHexImageHeight + 11}
          href={BrushShapes.hazards[adjustments.CM]}
        />
      </g>
    )}
    {adjustments.TL && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 - 24.75}
          y={image.y + adjustmentHexImageHeight + 11 - 14.5}
          href={BrushShapes.hazards[adjustments.TL]}
        />
      </g>
    )}
    {adjustments.TM && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75}
          y={image.y + adjustmentHexImageHeight + 11 - 29}
          href={BrushShapes.hazards[adjustments.TM]}
        />
      </g>
    )}
    {adjustments.TR && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 + 24.75}
          y={image.y + adjustmentHexImageHeight + 11 - 14.5}
          href={BrushShapes.hazards[adjustments.TR]}
        />
      </g>
    )}
    {adjustments.BR && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 + 24.75}
          y={image.y + adjustmentHexImageHeight + 11 + 14.5}
          href={BrushShapes.hazards[adjustments.BR]}
        />
      </g>
    )}
    {adjustments.BM && (
      <g>
        <g>
          <image
            width={adjustmentHexImageWidth}
            height={adjustmentHexImageHeight}
            x={image.x + adjustmentHexImageWidth + 4.75}
            y={image.y + adjustmentHexImageHeight + 11 + 29}
            href={BrushShapes.hazards[adjustments.BM]}
          />
        </g>
      </g>
    )}
    {adjustments.BL && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 - 24.75}
          y={image.y + adjustmentHexImageHeight + 11 + 14.5}
          href={BrushShapes.hazards[adjustments.BL]}
        />
      </g>
    )}
    {adjustments.DTL && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 - 24.75}
          y={image.y + adjustmentHexImageHeight + 11 - 14.5 - 29}
          href={BrushShapes.hazards[adjustments.DTL]}
        />
      </g>
    )}
    {adjustments.DTR && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 + 24.75}
          y={image.y + adjustmentHexImageHeight + 11 - 14.5 - 29}
          href={BrushShapes.hazards[adjustments.DTR]}
        />
      </g>
    )}
    {adjustments.DML && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 - 49.5}
          y={image.y + adjustmentHexImageHeight + 11}
          href={BrushShapes.hazards[adjustments.DML]}
        />
      </g>
    )}
    {adjustments.DBR && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 + 24.75}
          y={image.y + adjustmentHexImageHeight + 11 + 14.5 + 29}
          href={BrushShapes.hazards[adjustments.DBR]}
        />
      </g>
    )}
    {adjustments.DBL && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 - 24.75}
          y={image.y + adjustmentHexImageHeight + 11 + 14.5 + 29}
          href={BrushShapes.hazards[adjustments.DBL]}
        />
      </g>
    )}
    {adjustments.DMR && (
      <g>
        <image
          width={adjustmentHexImageWidth}
          height={adjustmentHexImageHeight}
          x={image.x + adjustmentHexImageWidth + 4.75 + 49.5}
          y={image.y + adjustmentHexImageHeight + 11}
          href={BrushShapes.hazards[adjustments.DMR]}
        />
      </g>
    )}
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.CM && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 8.33 -14.33 L -8.33 -14.33 L -16.67 0 L -8.33 14.33 L 8.33 14.33 L 16.67 0 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.TL && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M -16.65 -28.67 L -33.31 -28.67 L -41.65 -14.34 L -33.31 0 L -16.65 0 L -8.31 -14.34 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.TM && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 8.33 -43 L -8.33 -43 L -16.67 -28.67 L -8.33 -14.33 L 8.33 -14.33 L 16.67 -28.67 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.TR && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 33.32 -28.67 L 16.66 -28.67 L 8.32 -14.34 L 16.66 0 L 33.32 0 L 41.66 -14.34 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.BR && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 33.32 0 L 16.66 0 L 8.32 14.33 L 16.66 28.67 L 33.32 28.67 L 41.66 14.33 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.BM && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 8.33 14.33 L -8.33 14.33 L -16.67 28.67 L -8.33 43 L 8.33 43 L 16.67 28.67 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.BL && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M -16.65 0 L -33.31 0 L -41.65 14.33 L -33.31 28.67 L -16.65 28.67 L -8.31 14.33 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DTL && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M -16.67 -28.67 L -8.31 -43 L -24.97 -43 L -33.28 -28.66 L -16.67 -28.67 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DTR && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 33.32 -28.67 L 24.98 -43 L 8.31 -43 L 16.67 -28.67 L 33.32 -28.67 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DMR && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 50 0 L 41.66 -14.33 L 33.32 0 L 41.66 14.33 L 50 0 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DBR && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M 24.98 43 L 33.32 28.68 L 16.67 28.67 L 8.24 43.01 L 24.98 43 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DBL && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M -8.34 43.01 L -16.67 28.67 L -33.29 28.67 L -24.98 43 L -8.34 43.01 Z"
          />
        </g>
      )}
    </g>
    <g transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}>
      {adjustments.DML && (
        <g>
          <path
            fill="none"
            strokeWidth="1"
            stroke="orange"
            d="M -33.28 0 L -41.65 -14.33 L -50 0 L -41.65 14.33 L -33.28 0 Z"
          />
        </g>
      )}
    </g>
  </g>
);

const HexagonError = () => (
  <path
    opacity="0.5"
    d="M 25 -43 L -25 -43 L -50 0 L -25 43 L 25 43 L 50 0 Z"
    fill="red"
  />
);

const Background = () => (
  <rect width="1000" height="999" x="0" y="0" rx="3" ry="3" fill="green" />
);

const BaseTiles = ({ positions, highlight }) => {
  return positions.map(({ image, ref, tile, rotation }) => {
    return (
      <g key={ref.id}>
        <g
          id={ref.id}
          transform={matrix(rotation, image.x + 7, image.y + 12, ref.id)}
        >
          <image
            width={hexImageWidth - 15}
            height={hexImageHeight - 10}
            x={image.x + 7}
            y={image.y + 12}
            href={tile || BrushShapes.rough}
            opacity={highlight === ref.id ? 0.5 : 1}
          />
        </g>
      </g>
    );
  });
};

const CourseBase = ({
  onClick,
  onMouseMove,
  onMouseLeave,
  onMouseEnter,
  positions,
  tilePlacementErrors = [],
  holeNumbers = [],
  greenNumbers = [],
  highlight,
  showPar,
  showGrid,
  showCursor,
  showIds,
  showGreenNumbers,
  showHoleNumbers,
  showPositions,
  brush,
  adjustmentBrush,
  rotation,
  x,
  y,
  brushShape,
  placeAdjustmentHex,
  adjustments,
  isErasing,
}) => {
  const isSelectingAdjustmentHexLocation = !!adjustmentBrush;

  return (
    <div
      className="flex-0 border-white border rounded border-solid cursor-grab flex justify-start items-start"
      style={{ width: 1000, height: 1000 }}
    >
      <svg
        width="1000"
        height="1000"
        viewBox={`0 0 1000 1000`}
        onClick={isSelectingAdjustmentHexLocation ? undefined : onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <Background />
        <g transform={`scale(0.98, 0.98)`}>
          <BaseTiles positions={positions} highlight={highlight} />
          {positions.map(({ image, ref, tile, brush, outline }) => {
            const holeNumber = holeNumbers.find(({ id }) => id === ref.id);
            const greenNumber = greenNumbers.find(({ id }) => id === ref.id);

            const showAdjustmentOverlay =
              highlight === ref.id &&
              !!tile &&
              isSelectingAdjustmentHexLocation;

            return (
              <g key={`overlay-${ref.id}`}>
                {adjustments[ref.id] && (
                  <g transform={matrix(0, image.x + 7, image.y + 12, ref.id)}>
                    <AdjustmentHexes
                      adjustments={adjustments[ref.id]}
                      image={image}
                      outline={outline}
                    />
                  </g>
                )}
                {showAdjustmentOverlay && (
                  <g
                    transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}
                    key={`${ref.id}-outline`}
                  >
                    <AdjustmentHexOutline
                      colour={"red"}
                      id={ref.id}
                      onClick={placeAdjustmentHex}
                    />
                  </g>
                )}
                {!isSelectingAdjustmentHexLocation && (
                  <g>
                    <g>
                      {showPar && meta[brush] && (
                        <text
                          fill="white"
                          fontSize="36"
                          x={
                            image.x +
                            hexImageWidth / 2 -
                            10 * meta[brush].par.length
                          }
                          y={image.y + hexImageHeight / 2 + 15}
                        >
                          {meta[brush].par.join(",")}
                        </text>
                      )}
                    </g>
                    <g>
                      {showIds && meta[brush] && (
                        <text
                          fill="white"
                          fontSize="36"
                          x={image.x + hexImageWidth / 2 - 25}
                          y={image.y + hexImageHeight / 2 + 15}
                        >
                          {meta[brush].displayText}
                        </text>
                      )}
                    </g>
                    <g>
                      {showHoleNumbers && holeNumber && (
                        <text
                          fill="white"
                          fontSize="36"
                          x={
                            image.x +
                            hexImageWidth / 2 -
                            10 * meta[brush].par.length
                          }
                          y={image.y + hexImageHeight / 2 + 15}
                        >
                          {holeNumber.holeNumber}
                        </text>
                      )}
                    </g>
                    <g>
                      {showGreenNumbers && greenNumber && (
                        <text
                          fill="white"
                          fontSize="36"
                          x={
                            image.x +
                            hexImageWidth / 2 -
                            10 * greenNumber.greens.length
                          }
                          y={image.y + hexImageHeight / 2 + 15}
                        >
                          {greenNumber.greens.join(",")}
                        </text>
                      )}
                    </g>
                    <g>
                      {showPositions && (
                        <text
                          fill="white"
                          fontSize="36"
                          x={image.x + hexImageWidth / 2 - 30}
                          y={image.y + hexImageHeight / 2 + 15}
                        >
                          {ref.id}
                        </text>
                      )}
                    </g>
                  </g>
                )}
              </g>
            );
          })}
          {positions
            .filter(() => showGrid)
            .map(({ outline, ref }) => (
              <g
                transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}
                key={`${ref.id}-outline`}
              >
                <HexagonOutline colour={"rgb(255, 255, 255)"} />
              </g>
            ))}
          {positions
            .filter(({ brush }) => brush)
            .map(({ outline, ref, brush, rotation }) => {
              const TileHoleFrame = getTileHoleFrame(meta[brush], rotation);
              const holeNumber = holeNumbers.find(({ id }) => id === ref.id);

              return (
                <g
                  transform={`matrix(0.97, 0, 0, 0.97, ${outline.x}, ${outline.y})`}
                  key={`${ref.id}-hole-frame`}
                >
                  {TileHoleFrame && (
                    <TileHoleFrame
                      colour={holeNumber ? holeNumber.colour : null}
                    />
                  )}
                </g>
              );
            })}
          {tilePlacementErrors
            .filter(() => !isSelectingAdjustmentHexLocation)
            .map(({ outline, ref }) => {
              return (
                <g
                  transform={`matrix(1, 0, 0, 1, ${outline.x}, ${outline.y})`}
                  key={`${ref.id}-error`}
                >
                  <HexagonError />
                </g>
              );
            })}
          {showCursor && (brush || isSelectingAdjustmentHexLocation) && (
            <g>
              <g
                transform={matrix(
                  rotation,
                  x - hexImageWidth / 4,
                  y - hexImageHeight / 4
                )}
              >
                <image
                  width={120}
                  height={105}
                  x={
                    x -
                    hexImageWidth / 4 +
                    (isSelectingAdjustmentHexLocation ? 50 : 0)
                  }
                  y={
                    y -
                    hexImageHeight / 4 +
                    (isSelectingAdjustmentHexLocation ? 50 : 0)
                  }
                  href={brushShape}
                />
              </g>
              {!isSelectingAdjustmentHexLocation && !isErasing && (
                <text x={x + 75} y={y} fill="white">
                  Press 'R' to rotate
                </text>
              )}
            </g>
          )}
        </g>
      </svg>
    </div>
  );
};

const Course = CourseBase;

export default Course;
