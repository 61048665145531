import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import HoleSequencing from "./toolbox/HoleSequencing";
import PaletteDisplay from "./Palette/PaletteDisplay";
import AdjustmentPaletteDisplay from "./toolbox/AdjustmentPaletteDisplay";
import ExpansionMeta from "./toolbox/ExpansionMeta";

const selectedTab = "border-b-4 border-brand-yellow mb-0";
const unselectedTab = "border-b-4 border-gray-400 mb-0";

const Toolbox = ({
  used,
  usedAdjustments,
  tileMouseIsOver,
  holeStartTiles,
  onMoveHole,
  greenConfiguration,
  onGreenCardSetHandler,
  status,
  windConfiguration,
  onWindConfigurationChange,
  withErrors,
  teeboxes,
  greens,
  holeNumbers,
  positions,
  publish,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onMoveTo = (hole, position) => {
    onMoveHole(hole, position);
  };

  return (
    <Tabs
      style={{ height: 1000, width: 280 }}
      className="flex-col flex justify-start items-start flex-1 rounded bg-white w-full"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <TabList
        style={{ height: 50 }}
        className="flex-row flex flex-0 justify-center items-end w-full"
      >
        <Tab
          className={`flex flex-1 cursor-pointer ${tabIndex === 0 ? selectedTab : unselectedTab}`}
        >
          <span className="text-center w-full">Tiles</span>
        </Tab>
        <Tab
          className={`flex flex-1 cursor-pointer ${tabIndex === 1 ? selectedTab : unselectedTab}`}
        >
          <span className="text-center w-full">Adjust</span>
        </Tab>
        <Tab
          className={`flex flex-1 cursor-pointer ${tabIndex === 2 ? selectedTab : unselectedTab}`}
        >
          <span className="text-center w-full">Order</span>
        </Tab>
        <Tab
          className={`flex flex-1 cursor-pointer ${tabIndex === 3 ? selectedTab : unselectedTab}`}
        >
          <span className="text-center w-full">Publish</span>
        </Tab>
      </TabList>

      <TabPanel>
        <PaletteDisplay height={950} used={used} highlight={tileMouseIsOver} />
      </TabPanel>
      <TabPanel>
        <AdjustmentPaletteDisplay height={950} used={usedAdjustments} />
      </TabPanel>
      <TabPanel className="w-full">
        <HoleSequencing height={950} holeStartTiles={holeStartTiles} onMoveTo={onMoveTo} />
      </TabPanel>
      <TabPanel className="w-full">
        <ExpansionMeta
          greenConfiguration={greenConfiguration}
          onGreenCardSetHandler={onGreenCardSetHandler}
          status={status}
          windConfiguration={windConfiguration}
          onWindConfigurationChange={onWindConfigurationChange}
          withErrors={withErrors}
          teeboxes={teeboxes}
          greens={greens}
          holeNumbers={holeNumbers}
          positions={positions}
          publish={publish}
        />
      </TabPanel>
    </Tabs>
  );
};

export default Toolbox;
