import React from "react";
import { H2, P } from "../brand/typography";
import { ModeLinks } from "./ModeLinks";

export const SpanishModes = () => {
  return (
    <>
      <H2>MÚLTIPLES MANERAS DE JUGAR</H2>
      <P>
        18 Holes, como el golf, tiene una variedad de modos de juego diferentes.
        El método primario descrito en este libro mantiene a todos los jugadores
        compitiendo por cada hoyo. El golf es un juego difícil en el que puede
        costarte recuperarte si te quedas atrás. Si te gusta ese tipo de juego o
        quieres una experiencia de golf más realista, entonces puede que te
        interese el Golf Racing, Stroke Play o Stableford.
      </P>
      <P>
        Si tu grupo prefiere los juegos que mantienen a todos compitiendo por
        cada punto, entonces prueba Match Play y Skins.
      </P>
      <P>
        Algunas variaciones se alejan un poco más de la calle. Son para personas
        que quieren una experiencia de golf menos tradicional. Las variaciones
        de Chaos Golf y Golf Kelly encajan en esta descripción.
      </P>
      <P>
        Si quieres una experiencia en solitario, prueba Solo Chaos Golf,
        Stableford o Stroke Play.
      </P>
      <P>
        Si quieres un juego para dos jugadores, prueba Chaos Teams: nuestra
        experiencia dedicada para dos jugadores. O, uno de Match Play, Skins,
        Stroke Play, Stableford o Golf Racing.
      </P>
      <P>
        Cada modo de juego viene con una sección que describe el modo de juego,
        cuántos jugadores admite y cuándo podría interesarte este juego. A la
        derecha, tenemos la sección de Match Play, el conjunto de reglas
        descritas hasta ahora.
      </P>
      <P>Índice de variaciones:</P>
      <ModeLinks />
    </>
  );
};
