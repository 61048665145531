import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P } from "../../brand/typography";
import { FromMatchPlay } from "./FromMatchPlay";
import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FrenchModeGolfRacing = () => {
  return (
    <>
      <BackToModesLink />
      <H1>GOLF RACING</H1>
      <P>
        C'est du golf, mais c'est une course ! Tout le monde commence sur le
        premier tee box et doit compléter les 18 trous pour gagner.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>2 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous voulez l'expérience du Stroke Play et que vous n'avez pas de stylo.
        Dans ce mode, le parcours entier permet de marquer des points.
      </P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Le premier joueur à atteindre le 18e green après avoir atteint chaque
        green dans l'ordre gagne. 
      </P>

      <H3>Variations des règles</H3>
      <FromMatchPlay />

      <H4>Comment jouer</H4>
      <P>
        Un joueur ne peut passer au tee box suivant qu'après avoir atteint le
        green précédent. Les joueurs se retrouvent alors répartis sur plusieurs
        trous dans la course pour le 18e.
      </P>

      <H4>Marquage des points</H4>
      <P>
        Les joueurs doivent se rendre sur chaque green dans l'ordre. Le premier
        joueur à atteindre le 18e green gagne. En cas d'égalité, le joueur qui a
        le plus de jetons de « trou en un » gagne.
      </P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <P>Aucune</P>
    </>
  );
};

const EnglishModeGolfRacing = () => {
  return (
    <>
      <BackToModesLink />
      <H1>GOLF RACING</H1>
      <P>
        It’s golf, but it’s a race! Everyone starts on the first tee box, and
        they have to complete all 18 Holes to win.
      </P>

      <H3>PLAYS</H3>
      <P>2 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        When you want the Stroke Play experience and you don't have a pen. In
        this mode the entire course is the scoring track.
      </P>

      <H3>HOW TO WIN</H3>
      <P>
        The first player to reach the 18th green after reaching each green in
        order wins.
      </P>

      <H3>Rule Variations</H3>
      <FromMatchPlay />

      <H4>Game Play</H4>
      <P>
        A player may progress to the next tee box only after reaching the prior
        green. As such players end up spread across multiple holes in the race
        for the 18th.
      </P>

      <H4>Scoring</H4>
      <P>
        Players must visit every green in order. The first player to reach the
        18th green wins. In the event of a tie, the player with the most
        hole-in-one tokens is the winner.
      </P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <P>None</P>
    </>
  );
};

const GermanModeGolfRacing = () => {
  return (
    <>
      <BackToModesLink />
      <H1>GOLF RACING</H1>
      <P>
        Es ist Golf, aber es ist ein Rennen! Jeder startet am ersten
        Abschlagplatz und muss alle 18 Löcher absolvieren, um zu gewinnen.
      </P>

      <H3>SPIELER</H3>
      <P>2 - 5 SPIELER</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        Wenn du die Erfahrung des Stroke Play machen willst und keinen Stift
        hast. In diesem Modus steht der gesamte Golfplatz zum Punkten zur
        Verfügung.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Der erste Spieler, der das 18. Green erreicht, nachdem er jedes Green
        der Reihenfolge nach erreicht hat, gewinnt.
      </P>

      <H3>Regelvariationen</H3>
      <FromMatchPlay />

      <H4>Gameplay</H4>
      <P>
        Ein Spieler darf erst dann zum nächsten Abschlagsfeld vorrücken, wenn er
        das vorherige Green erreicht hat. Daher landen Spieler im Rennen zum 18.
        Loch über das gesamte Feld verteilt.
      </P>

      <H4>Punkten</H4>
      <P>
        Die Spieler müssen nacheinander jedes Green erreichen. Der erste
        Spieler, der das 18. Green erreicht, gewinnt. Im Falle eines
        Gleichstandes ist der Spieler mit den meisten Hole-in-One-Tokens der
        Gewinner.
      </P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <P>Keine</P>
    </>
  );
};

const SpanishModeGolfRacing = () => {
  return (
    <>
      <BackToModesLink />
      <H1>GOLF RACING</H1>
      <P>
        ¡Es el golf, pero es una carrera! Todos comienzan en el primer tee de
        salida, y tienen que completar los 18 hoyos para ganar.
      </P>

      <H3>JUEGOS</H3>
      <P>2 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Cuando quieres la experiencia del Stroke Play y no tienes un bolígrafo.
        En este modo, todo el campo es la pista de puntuación.
      </P>

      <H3>CÓMO GANAR</H3>
      <P>
        El primer jugador que llegue al green 18 después de alcanzar cada green
        en orden gana.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromMatchPlay />

      <H4>Juego</H4>
      <P>
        Un jugador puede avanzar al siguiente tee de salida solo después de
        llegar al green anterior. Como estos jugadores acaban repartidos en
        múltiples hoyos en la carrera por el 18.
      </P>

      <H4>Puntuación</H4>
      <P>
        Los jugadores deben visitar cada green en orden. El primer jugador que
        llegue al green 18 gana. En caso de empate, el jugador con más fichas de
        "hoyo en uno" es el ganador.
      </P>

      <H3>PARTES NECESARIAS PARA ANOTAR</H3>
      <P>Ninguna</P>
    </>
  );
};

const ItalianModeGolfRacing = () => {
  return (
    <>
      <BackToModesLink />
      <H1>GOLF RACING</H1>
      <P>
        È golf, ma è una corsa! Tutti quanti iniziano sul primo tee box e per
        vincere devono completare tutte le 18 Buche.
      </P>

      <H3>GIOCATORI</H3>
      <P>2 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Quando vuoi l’esperienza Stroke Play e non hai una penna. In questa
        modalità l’intero percorso è il tracciato di punteggio.
      </P>

      <H3>COME SI VINCE</H3>
      <P>
        Vince il primo giocatore che avrà raggiunto il 18º green dopo aver
        raggiunto ogni green in ordine.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromMatchPlay />

      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>
        Un giocatore può progredire al tee box successivo solo dopo aver
        raggiunto il green precedente. Per questo motivo, i giocatori finiscono
        per essere sparsi su varie buche nella loro corsa verso la 18ª.
      </P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        I giocatori devono visitare ogni green in ordine. Vince il primo
        giocatore che avrà raggiunto il 18º green. Nell’eventualità di un
        pareggio, a vincere sarà il giocatore con il maggior numero di segnalini
        Hole-in-One.
      </P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <P>None</P>
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeGolfRacing />}
          {language === "fr" && <FrenchModeGolfRacing />}
          {language === "it" && <ItalianModeGolfRacing />}
          {language === "es" && <SpanishModeGolfRacing />}
          {language === "de" && <GermanModeGolfRacing />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
