import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Twitter, Facebook } from "react-social-sharing";
import { toast } from "react-toastify";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { withUserConfiguration } from "../user/UserConfigurationProvider";
import { PublishedDesigner } from "../navigation/locations";
import { Link, useHistory } from "react-router-dom";
import { getCourse, getPrintUrl } from "../services/public";
import { refreshMetadata } from "../services/private";
import { ButtonButtonAlt } from "../common/Button";
import { withToken } from "../auth/react-auth0-wrapper";
import putter from "../images/putter.png";

const canRefresh = process.env.NODE_ENV !== "production";

const Attribute = ({ label, value, classNameValue }) => {
  return (
    <div className="flex-col p-1 w-full">
      <p className="bg-brand-green uppercase p-1 text-white text-center rounded-t">{label}</p>
      <p
        className={`rounded-b border-r border-b border-l text-center p-1 border-gray-400 items-center justify-center flex ${classNameValue}`}
      >
        {value}
      </p>
    </div>
  );
};

const CustomAttribute = ({ label, children, classNameValue }) => {
  return (
    <div className="flex-col p-1 w-full">
      <p className="bg-brand-green uppercase p-1 text-white text-center rounded-t">{label}</p>
      <div
        className={`rounded-b border-r border-b border-l text-center p-1 border-gray-400 items-center justify-center flex ${classNameValue}`}
      >
        {children}
      </div>
    </div>
  );
};

const CourseBase = ({ course, units, showCourse, token, onTokenExpired }) => {
  let history = useHistory();
  const shareMessage = `${course.displayName} by ${course.designerName}\r\n\r\n#18holesgame\r\n\r\n`;
  const title = course.displayName;
  const description = `${course.displayName} by ${course.designerName}`;
  const url = `https://18holesgame.com/p/${course.id}`;

  const onRefreshMetadata = async () => {
    await refreshMetadata(token, course.id, onTokenExpired, history);
  };

  const onLowInk = async () => {
    try {
      const url = await getPrintUrl(course.id);

      setTimeout(() => {
        window.open(url, "_blank");
      });
    } catch {
      toast.error(
        "Unable to find printable version of course! Please contact hello@seabrook-studios.com and let us know"
      );
    }
  };

  const onPrint = async () => {
    window.print();
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={course.guide} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={course.guide} />
      </Helmet>
      <SiteNavigation />
      <div className="p-4">
        {course.unlisted && (
          <div className="flex-1 flex justify-center items-center bg-red-200 border rounded p-2">
            <p className="text-red-900">Course is unlisted. Only people with direct link will see course.</p>
          </div>
        )}
        <div className="flex-row flex-1 flex justify-between items-center">
          <p className="text-4xl uppercase pl-1 pb-0">{course.displayName}</p>
          <div className="flex-1 flex justify-end items-center">
            {canRefresh && (
              <ButtonButtonAlt enabled={true} onClick={onRefreshMetadata}>
                Refresh
              </ButtonButtonAlt>
            )}
            <ButtonButtonAlt style="ml-2 print:hidden" enabled={true} onClick={onLowInk}>
              Print (Low Ink)
            </ButtonButtonAlt>
            <ButtonButtonAlt style="mx-2 print:hidden" enabled={true} onClick={onPrint}>
              Print (Guide)
            </ButtonButtonAlt>
            {/* <ButtonButtonAlt enabled={true} onClick={onRefreshMetadata}>
              Unlist
            </ButtonButtonAlt> */}
            <Twitter solid small message={shareMessage} link={window.location} />
            <Facebook solid small message={shareMessage} link={window.location} />
          </div>
        </div>
        <Link to={PublishedDesigner(course.username)} className="text-md pl-1">
          By {course.designerName}
        </Link>
        <div className="flex flex-row items-center justify-start pt-1">
          <Attribute label="holes" value={course.holes} />
          <Attribute label="Par" value={course.par} />
          <Attribute label="Stableford" value={`${course.stableford.min} - ${course.stableford.max}`} />
          <Attribute label="Solo" value={course.solo || "-"} />
          <Attribute label="Difficulty" value={course.difficulty} />
          {/* <Attribute label="Likes" value={0} /> */}
        </div>
        <div className="flex flex-row items-center justify-start pt-1">
          <Attribute label="Course length" value={`${course.distance[units]}${units}`} classNameValue="h-12" />
          <CustomAttribute label="GREEN CARDS" classNameValue="h-12">
            <div className="flex flex-row flex-1">
              <div className="flex flex-col flex-1 justify-center items-center">
                <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                <p>{course.greenConfiguration.oneClub}</p>
              </div>
            </div>
            <div className="flex flex-row flex-1">
              <div className="flex flex-col flex-1 justify-center items-center">
                <div className="flex flex-row flex-1 justify-center items-center">
                  <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                  <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                </div>
                <p>{course.greenConfiguration.twoClub}</p>
              </div>
            </div>
            <div className="flex flex-row flex-1">
              <div className="flex flex-col flex-1 justify-center items-center">
                <div className="flex flex-row flex-1 justify-center items-center">
                  <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                  <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                  <img src={putter} className="w-3 h-3" alt="one putter difficulty" />
                </div>
                <p>{course.greenConfiguration.threeClub}</p>
              </div>
            </div>
          </CustomAttribute>
          <CustomAttribute label="WIND CARDS" classNameValue="h-12">
            <div className="grid grid-cols-8 flex-1 h-12">
              <p className={`uppercase text-center border-r`}>TL</p>
              <p className={`uppercase text-center border-r`}>TM</p>
              <p className={`uppercase text-center border-r`}>TR</p>
              <p className={`uppercase text-center border-r`}>BR</p>
              <p className={`uppercase text-center border-r`}>BM</p>
              <p className={`uppercase text-center border-r`}>BL</p>
              <p className={`uppercase text-center border-r`}>S</p>
              <p className={`uppercase text-center`}>C</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.TL}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.TM}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.TR}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.BR}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.BM}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.BL}</p>
              <p className={`uppercase text-center border-r`}>{course.windConfiguration.S}</p>
              <p className={`uppercase text-center`}>{course.windConfiguration.C}</p>
            </div>
          </CustomAttribute>
          <Attribute
            label="Table Space"
            value={`${course.playWidth[units]} x ${course.playHeight[units]}${units}`}
            classNameValue="h-12"
          />
        </div>
        <div className="flex flex-row justify-center items-center pt-3">
          <img
            className="object-contain"
            style={{ maxWidth: 600 }}
            src={showCourse ? course.course : course.guide}
            alt={`Tile layout for ${course.displayName}`}
          />
        </div>
        {course.hasMinimap && (
          <div className="flex flex-row justify-center items-center pt-3">
            <img
              className="object-contain"
              style={{ maxWidth: 600 }}
              src={course.adjust}
              alt={`Adjustment tile placement for ${course.displayName}`}
            />
          </div>
        )}
        <div className="flex-col p-1 w-full">
          <p className="bg-brand-green uppercase p-1 text-white text-center rounded-t">TILES USED</p>
          <div className="flex-row flex-1 items-start justify-start bg-red flex flex-wrap rounded-b border-r border-b border-l text-center border-gray-400">
            {course.tilesUsed.map((tile, i) => {
              const offset = i % 2 === 0;
              const highlight = offset ? "bg-gray-300" : "bg-gray-400";

              return (
                <p key={tile} className={`w-1/5 uppercase p-1 text-center ${highlight}`}>
                  {tile}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const CourseHome = withToken(withUserConfiguration(CourseBase));

const CourseDataFetcher = ({ username, title }) => {
  let history = useHistory();
  const id = `${username}/${title}`;
  const [course, setCourse] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getCourse(id, history).then((course) => {
      setCourse(course);
      setLoaded(true);
    });
  }, []);

  return loaded && <CourseHome course={course} />;
};

const CourseRouteHandler = ({
  match: {
    params: { username, title },
  },
}) => <CourseDataFetcher username={username} title={title} />;

export default CourseRouteHandler;
