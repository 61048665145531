import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P, ul } from "../../brand/typography";
import {
  FastTravelRulesEnglish,
  FastTravelRulesFrench,
  FastTravelRulesItalian,
  FastTravelRulesSpanish,
  FastTravelRulesGerman,
} from "./FastTravelRules";
import { FromChaosGolf } from "./FromChaosGolf";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";

const FrenchModeChaosTeams = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Chaos Teams</H1>
      <P>
        Une variante à deux joueurs du Chaos Golf. Chaque équipe compte deux
        golfeurs et cinq clubs. Ils désignent le golfeur qu'ils déplacent après
        avoir pioché leur carte coup.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>2 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>Vous aimez le Chaos Golf et vous n'êtes que deux.</P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Un point pour chaque drapeau revendiqué. Le score le plus élevé, une
        fois que tous les trous ont été atteint, gagne. Si une équipe atteint 10
        drapeaux elle gagne automatiquement.
      </P>

      <H3>Variations des règles</H3>
      <FromChaosGolf />

      <H4>Configuration</H4>
      <P>
        Ne placez qu'un seul drapeau sur chaque green. L'ordre n'a pas
        d’importance.
      </P>

      <H4>Comment jouer</H4>
      <P>
        Lorsqu'un golfeur atterrit sur un tee box, ce golfeur doit être celui
        qui reçoit le tour supplémentaire.
      </P>

      <H4>Marquage des points</H4>
      <P>
        Le jeu se termine lorsque tous les drapeaux ont été pris ou lorsqu'une
        équipe a gagné 10 drapeaux. L'équipe ayant le plus grand nombre de
        points gagne.
      </P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <ul className={ul}>
        <li>Drapeaux</li>
      </ul>

      <FastTravelRulesFrench mode="Chaos Teams" />
    </>
  );
};

const SpanishModeChaosTeams = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Chaos Teams</H1>
      <P>
        Una variante para dos jugadores de Chaos Golf. Cada equipo tiene dos
        golfistas y cinco palos. Nombran al golfista al que se mueven después de
        haber sacado su carta de tiro.
      </P>

      <H3>Jugadas</H3>
      <P>2 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>Te gusta Chaos Golf y solo son dos personas.</P>

      <H3>CÓMO GANAR</H3>
      <P>
        Un punto por cada bandera reclamada. Después de completar todos los
        hoyos, la puntuación más alta gana o si un equipo llega a 10 banderas.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromChaosGolf />

      <H4>Configuración</H4>
      <P>
        Coloca solo una bandera en cada green. El orden no es significativo.
      </P>

      <H4>Juego</H4>
      <P>
        Cuando un golfista cae en un tee de salida, dicho golfista debe ser el
        que recibe la tirada extra.
      </P>

      <H4>Puntuación</H4>
      <P>
        El juego termina cuando todas las banderas son reclamadas o un equipo ha
        marcado 10 banderas. El equipo con la mayor puntuación gana.
      </P>

      <H3>PARTES NECESARIAS PARA ANOTAR</H3>
      <ul className={ul}>
        <li>Banderas</li>
      </ul>

      <FastTravelRulesSpanish mode="Chaos Teams" />
    </>
  );
};

const EnglishModeChaosTeams = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Chaos Teams</H1>
      <P>
        A two-player variant of Chaos Golf. Each team has two golfers and five
        clubs. They nominate which golfer they are moving after they have drawn
        their shot card.
      </P>

      <H3>Plays</H3>
      <P>2 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>You like Chaos Golf and there are only two of you.</P>

      <H3>HOW TO WIN</H3>
      <P>
        One point for each flag claimed. The highest score, after all holes
        completed, wins or if a team reaches 10 flags.
      </P>

      <H3>Rule Variations</H3>
      <FromChaosGolf />

      <H4>Setup</H4>
      <P>Place only one flag on each green. The order is not significant.</P>

      <H4>Game Play</H4>
      <P>
        When a golfer lands on a tee box, that golfer must be the one that
        receives the additional turn.
      </P>

      <H4>Scoring</H4>
      <P>
        The game ends when all flags are claimed or one team has scored 10
        flags. The team with the highest score wins.
      </P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>Flags</li>
      </ul>

      <FastTravelRulesEnglish mode="Chaos Teams" />
    </>
  );
};
const GermanModeChaosTeams = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Chaos Teams</H1>
      <P>
        Eine Zwei-Spieler-Variante des Chaos-Golfs. Jedes Team besteht aus zwei
        Golfern und fünf Schlägern. Du gibst an, welchen Golfer du bewegst,
        nachdem du deine Schlag-Karte gezogen hast.
      </P>

      <H3>Spieler</H3>
      <P>2 Spieler</P>

      <H3>PIELE DIESEN MODUS WENN ...</H3>
      <P>… du Chaos Golf magst und ihr nur zu zweit seid.</P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Ein Punkt für jede beanspruchte Fahne. Die höchste Punktzahl, nach
        Abschluss aller Löcher, gewinnt oder wenn eine Mannschaft 10 Fahnen
        erreicht.
      </P>

      <H3>Regelvariationen</H3>
      <FromChaosGolf />

      <H4>Setup</H4>
      <P>
        Platziere nur eine Fahne auf jedem Green. Die Reihenfolge ist nicht
        signifikant.
      </P>

      <H4>Gameplay</H4>
      <P>
        Wenn ein Golfer auf einem Abschlagplatz landet, muss dieser Golfer
        derjenige sein, der den zusätzlichen Turn erhält.
      </P>

      <H4>Punkten</H4>
      <P>
        Das Spiel endet, wenn alle Fahnen beansprucht werden oder eine
        Mannschaft 10 Fahnen geschossen hat. Die Mannschaft mit der höchsten
        Punktzahl gewinnt.
      </P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <ul className={ul}>
        <li>Fahnen</li>
      </ul>

      <FastTravelRulesGerman mode="Chaos Teams" />
    </>
  );
};

const ItalianModeChaosTeams = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Chaos Teams</H1>
      <P>
        Una variante a due giocatori di Chaos Golf. Ogni squadra ha due golfisti
        e cinque mazze e nomina quale golfista sposta dopo aver pescato la carta
        tiro.
      </P>

      <H3>GIOCATORI</H3>
      <P>2</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>Ti piace il Chaos Golf e siete solo in due.</P>

      <H3>COME SI VINCE</H3>
      <P>
        Un punto per ogni bandierina presa. Vince chi, una volta completate
        tutte le buche, avrà ottenuto il punteggio più alto oppure la squadra
        che avrà raggiunto le 10 bandierine.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromChaosGolf />

      <H4>PREPARAZIONE</H4>
      <P>
        Posiziona solo una bandierina su ogni green. L’ordine non è importante.
      </P>

      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>Il golfista che atterra su un tee box riceverà il turno aggiuntivo.</P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        La partita termina quando vengono prese tutte le bandierine oppure
        quando una squadra avrà accumulato 10 bandierine. Vinche la squadra con
        il punteggio più alto.
      </P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <ul className={ul}>
        <li>Bandierine</li>
      </ul>

      <FastTravelRulesItalian mode="Chaos Teams" />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeChaosTeams />}
          {language === "fr" && <FrenchModeChaosTeams />}
          {language === "it" && <ItalianModeChaosTeams />}
          {language === "es" && <SpanishModeChaosTeams />}
          {language === "de" && <GermanModeChaosTeams />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
