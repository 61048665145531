import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P } from "../../brand/typography";
import { FromMatchPlay } from "./FromMatchPlay";
import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const StablefordScoreTable = ({
  par = "Par",
  turns = "Tours",
  points = "Points",
}) => (
  <table className="border-collapse w-full">
    <thead>
      <tr className="bg-brand-green w-full">
        <th className="text-white font-bold text-center w-1/5">{par}</th>
        <th className="text-white font-bold text-center w-1/5">{turns}</th>
        <th className="text-white font-bold text-center w-1/5">{points}</th>
      </tr>
    </thead>
    <tbody>
      <tr className="bg-yellow-200">
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">5+</td>
        <td className="text-center w-1/5">0</td>
      </tr>
      <tr className="bg-yellow-400">
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">1</td>
      </tr>
      <tr className="bg-yellow-200">
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">2</td>
      </tr>
      <tr className="bg-yellow-400">
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">2</td>
        <td className="text-center w-1/5">3</td>
      </tr>
      <tr className="bg-yellow-200">
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">1</td>
        <td className="text-center w-1/5">4</td>
      </tr>
      <tr className="bg-green-400">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">6+</td>
        <td className="text-center w-1/5">0</td>
      </tr>
      <tr className="bg-green-200">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">1</td>
      </tr>
      <tr className="bg-green-400">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">2</td>
      </tr>
      <tr className="bg-green-200">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">3</td>
      </tr>
      <tr className="bg-green-400">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">2</td>
        <td className="text-center w-1/5">4</td>
      </tr>
      <tr className="bg-green-200">
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">1</td>
        <td className="text-center w-1/5">5</td>
      </tr>
      <tr className="bg-blue-400">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">7+</td>
        <td className="text-center w-1/5">0</td>
      </tr>
      <tr className="bg-blue-200">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">6</td>
        <td className="text-center w-1/5">1</td>
      </tr>
      <tr className="bg-blue-400">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">2</td>
      </tr>
      <tr className="bg-blue-200">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">4</td>
        <td className="text-center w-1/5">3</td>
      </tr>
      <tr className="bg-blue-400">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">3</td>
        <td className="text-center w-1/5">4</td>
      </tr>
      <tr className="bg-blue-200">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">2</td>
        <td className="text-center w-1/5">5</td>
      </tr>
      <tr className="bg-blue-400">
        <td className="text-center w-1/5">5</td>
        <td className="text-center w-1/5">1</td>
        <td className="text-center w-1/5">6</td>
      </tr>
    </tbody>
  </table>
);

const EnglishModeStableford = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stableford</H1>
      <P>
        Stableford is a more forgiving scoring system than Stroke Play as it
        limits how badly you can do on a single turn. It also sets a cap on the
        maximum you can score per hole.
      </P>

      <H3>PLAYS</H3>
      <P>1 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You want a more authentic golfing experience and a scoring system that
        is more forgiving than traditional golf.
      </P>

      <H3>HOW TO WIN</H3>
      <P>
        Most points wins. Stableford is a scoring system where you get 2 points
        for achieving par. Score bonus points for beating par (under) and lose
        points for scoring above par. The highest score per hole is 4, 5 or 6
        depending on if it’s a par 3, 4 or 5. The lowest score per hole is zero
        (0).
      </P>

      <H3>Rule Variations</H3>
      <FromMatchPlay />

      <H4>Game Play</H4>
      <P>
        When a player reaches the green, they wait for everyone else to complete
        the hole before moving on.
      </P>

      <H4>Scoring</H4>
      <P>
        Score each hole as you complete it. Count your turns (including missed
        turns and out of bounds) and compare to the course par rating.
      </P>
      <table className="border-collapse w-full">
        <thead>
          <tr className="bg-brand-green w-full">
            <th className="text-white font-bold text-center w-2/5">Points</th>
            <th className="text-white font-bold text-left w-3/5">Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">2</td>
            <td className="text-left w-3/5">Base Score for each hole</td>
          </tr>
          <tr className="bg-gray-400">
            <td className="text-center w-2/5">+1</td>
            <td className="text-left w-3/5">
              For each turn under par. For example: on a par 4 if you took 3
              turns to reach the green, you score +1 bonus point (a total of 3
              points)
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">-1</td>
            <td className="text-left w-3/5">
              For each turn over par. For example: on a par 4, if you took 5
              turns to reach the green you lose 1 point (for a total of 1 point)
            </td>
          </tr>
        </tbody>
      </table>
      <P>Zero (0) is the lowest a player can score on a hole.</P>
      <P>
        Alternatively, you can use this table to look up your score for each
        hole.
      </P>
      <StablefordScoreTable turns="Turns" />
    </>
  );
};

const GermanModeStableford = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stableford</H1>
      <P>
        Stableford ist ein einfacheres Punktesystem als das Stroke Play, da es
        begrenzt, wie schlecht man in einer einzigen Runde abschneiden kann. Es
        setzt auch eine Obergrenze für die maximale Punktzahl, die du pro Loch
        erzielen kannst.
      </P>

      <H3>SPIELER</H3>
      <P>1 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        … du ein authentischeres Golferlebnis suchst und ein Punktesystem
        willst, das verzeihender ist als das traditionelle Golf.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Die meisten Punkte gewinnen. Stableford ist ein Punktesystem, bei dem du
        für das Erreichen von Par 2 Punkte erhältst. Du erhältst Bonuspunkte für
        das Punkten von Par (unter) und verlierst Punkte für das Punkten über
        Par. Die höchste Punktzahl pro Loch beträgt 4, 5 oder 6, je nachdem, ob
        es sich um ein Par 3, 4 oder 5 handelt. Die niedrigste Punktzahl pro
        Loch ist Null (0).
      </P>

      <H3>Regelvariationen</H3>
      <FromMatchPlay />

      <H4>Gameplay</H4>
      <P>
        Wenn ein Spieler das Green erreicht, wartet er darauf, dass alle anderen
        das Loch vervollständigen, bevor er weiterspielt.
      </P>

      <H4>Punkten</H4>
      <P>
        Punkte jedes Loch, wenn du es abschließt. Zähle deine Schwünge
        (einschließlich verpasster Schwünge und Out-of-Bounds) und vergleiche
        sie mit der Par-Bewertung des Golfplatzes.
      </P>
      <table className="border-collapse w-full">
        <thead>
          <tr className="bg-brand-green w-full">
            <th className="text-white font-bold text-center w-2/5">Punkte</th>
            <th className="text-white font-bold text-left w-3/5">Basis</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">2</td>
            <td className="text-left w-3/5">Basis-Punkte für jedes Loch</td>
          </tr>
          <tr className="bg-gray-400">
            <td className="text-center w-2/5">+1</td>
            <td className="text-left w-3/5">
              Für jede Runde unter Par. Zum Beispiel: Bei einem Par 4, wenn du
              mit 3 Runden das Green erreicht hast, erhältst du +1 Bonuspunkt
              (insgesamt 3 Punkte)
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">-1</td>
            <td className="text-left w-3/5">
              Für jede Runde über Par. Beispiel: Bei einem Par 4 verlierst du 1
              Punkt, wenn du mit 5 Runden das Green erreicht hast (also
              insgesamt 1 Punkt).
            </td>
          </tr>
        </tbody>
      </table>
      <P>
        Null (0) ist die niedrigste Punktzahl, die ein Spieler an einem Loch
        erzielen kann.
      </P>
      <P>
        Alternativ kannst du diese Tabelle verwenden, um deinen Punktestand für
        jedes Loch nachzuschlagen.
      </P>
      <StablefordScoreTable par="Loch Par" turns="Runden" points="Punkte" />
    </>
  );
};

const SpanishModeStableford = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stableford</H1>
      <P>
        Stableford es un sistema de puntuación más indulgente que el Stroke
        Play, ya que limita lo mal que puedes hacerlo en un solo turno. También
        limita el máximo que puedes anotar por hoyo.
      </P>

      <H3>JUEGOS</H3>
      <P>1 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Quieres una experiencia de golf más auténtica y un sistema de puntuación
        más indulgente que el del golf tradicional.
      </P>

      <H3>CÓMO GANAR</H3>
      <P>
        El jugador con más puntos gana. Stableford es un sistema de puntuación
        en el que obtienes 2 puntos por alcanzar el par. Ganas puntos extra por
        hacer el hoyo bajo par y pierdes puntos por hacerlo sobre par. La
        puntuación más alta por hoyo es 4, 5 o 6, dependiendo de si es un par 3,
        4 o 5. La puntuación más baja por hoyo es cero (0).
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromMatchPlay />

      <H4>Juego</H4>
      <P>
        Cuando un jugador llega al green, espera a que todos los demás completen
        el hoyo antes de seguir adelante.
      </P>

      <H4>Puntuación</H4>
      <P>
        Anota cada hoyo a medida que lo completas. Cuenta tus turnos (incluyendo
        los perdidos y los fuera de los límites) y compáralas con la
        calificación de par del campo.
      </P>
      <table className="border-collapse w-full">
        <thead>
          <tr className="bg-brand-green w-full">
            <th className="text-white font-bold text-center w-2/5">Puntos</th>
            <th className="text-white font-bold text-left w-3/5">Razón</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">2</td>
            <td className="text-left w-3/5">puntuación base para cada hoyo</td>
          </tr>
          <tr className="bg-gray-400">
            <td className="text-center w-2/5">+1</td>
            <td className="text-left w-3/5">
              por cada turno bajo par. Por ejemplo: en un par 4, si necesitas 3
              turnos para alcanzar el green, obtienes +1 punto extra (un total
              de 3 puntos)
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">-1</td>
            <td className="text-left w-3/5">
              por cada turno sobre par. Por ejemplo: en un par 4, si necesitas 5
              turnos para alcanzar el green, pierdes 1 punto (un total de 1
              punto)
            </td>
          </tr>
        </tbody>
      </table>
      <P>Cero (0) es lo más bajo que un jugador puede anotar en un hoyo.</P>
      <P>
        Alternativamente, puedes usar esta tabla para buscar tu puntuación en
        cada hoyo.
      </P>
      <StablefordScoreTable par="Par de hoyo" turns="Turnos" points="Puntos" />
    </>
  );
};

const FrenchModeStableford = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stableford</H1>
      <P>
        Stableford est un système de notation plus indulgent que Stroke Play car
        il limite les dégâts que vous pouvez faire sur un seul tour. Il fixe
        également un plafond au nombre de points maximum que vous pouvez marquer
        par trou
      </P>

      <H3>Nombre de joueurs</H3>
      <P>1 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous voulez une expérience de golf plus authentique et un système de
        notation plus tolérant que le golf traditionnel.
      </P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Celui qui a le plus de points gagne. Stableford est un système de
        notation dans lequel vous obtenez 2 points pour avoir atteint le par.
        Vous obtenez des points bonus si vous battez le par (en dessous) et vous
        perdez des points si vous dépassez le par. Le score le plus élevé par
        trou est de 4, 5 ou 6 selon qu'il s'agit d'un par 3, 4 ou 5. Le score le
        plus bas par trou est de zéro (0).
      </P>

      <H3>Variations des règles</H3>
      <FromMatchPlay />

      <H4>Comment jouer</H4>
      <P>
        Lorsqu'un joueur atteint le green, il attend que tous les autres aient
        terminé ce trou avant de passer à la suite. 
      </P>

      <H4>Marquage des points</H4>
      <P>
        Notez chaque trou au fur et à mesure que vous les atteignez. Comptez vos
        tours (y compris les tours que vous avez dû passer et les tours hors
        limites) et comparez-les à l’aide des indications ci-dessous.
      </P>
      <table className="border-collapse w-full">
        <thead>
          <tr className="bg-brand-green w-full">
            <th className="text-white font-bold text-center w-2/5">Points</th>
            <th className="text-white font-bold text-left w-3/5">Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">2</td>
            <td className="text-left w-3/5">Score de base pour chaque trou</td>
          </tr>
          <tr className="bg-gray-400">
            <td className="text-center w-2/5">+1</td>
            <td className="text-left w-3/5">
              Pour chaque tour sous le par. Par exemple : sur un par 4, s’il
              vous a fallu 3 tours pour atteindre le green, vous marquez +1
              point de bonus (soit un total de 3 points)
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">-1</td>
            <td className="text-left w-3/5">
              Pour chaque tour au-dessus du par. Par exemple : sur un par 4,
              s’il vous a fallu 5 tours pour atteindre le green, vous perdez 1
              point (soit un total de 1 point)
            </td>
          </tr>
        </tbody>
      </table>
      <P>
        Zéro (0) est le plus petit score qu'un joueur peut obtenir sur un trou.
      </P>
      <P>
        Vous pouvez également utiliser ce tableau pour connaître votre score
        pour chaque trou.
      </P>
      <StablefordScoreTable />
    </>
  );
};

const ItalianModeStableford = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stableford</H1>
      <P>
        Stableford è un sistema di punteggio più tollerante rispetto a Stroke
        Play poiché limita il tuo massimo negativo per ogni singolo turno.
        Inoltre imposta un tetto al punteggio massimo che puoi accumulare per
        ogni buca.
      </P>

      <H3>GIOCATORI</H3>
      <P>1 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Vuoi un’esperienza golfistica più autentica e un sistema di punteggi che
        sia più indulgente rispetto al golf classico.
      </P>

      <H3>COME SI VINCE</H3>
      <P>
        Chi fa più punti vince. Stableford è un sistema di punteggio nel quale
        si ottengono 2 punti per aver aggiunto il par. Si accumulano punti bonus
        quando si batte il par (sotto) e si perdono punti quando si va sopra il
        par. Il punteggio più alto per ogni buca è 4, 5 o 6 a seconda che sia un
        par 3, 4 o 5. Il punteggio più basso per una buca è zero (0).
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromMatchPlay />

      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>
        Quando un giocatore raggiunge il green, attende che tutti gli altri
        avranno completato la buca prima di continuare
      </P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        Segna ciascuna buca quando la completi. Conta i tuoi turni (inclusi i
        turni saltati e i fori percorso) e confrontali con il par del percorso.
      </P>
      <table className="border-collapse w-full">
        <thead>
          <tr className="bg-brand-green w-full">
            <th className="text-white font-bold text-center w-2/5">Points</th>
            <th className="text-white font-bold text-left w-3/5">Motivo</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">2</td>
            <td className="text-left w-3/5">Punteggio Base per ogni buca</td>
          </tr>
          <tr className="bg-gray-400">
            <td className="text-center w-2/5">+1</td>
            <td className="text-left w-3/5">
              +1 Per ogni turno sotto il par. Ad esempio: su un par 4 se hai
              impiegato 3 turni per raggiungere il green, accumuli +1 punto
              bonus (un totale di 3 punti)
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td className="text-center w-2/5">-1</td>
            <td className="text-left w-3/5">
              Per ogni turno sopra il par. Ad esempio: su un par 4, se hai
              impiegato 5 turni per raggiungere il green perdi 1 punto (per un
              totale di 1 punto)
            </td>
          </tr>
        </tbody>
      </table>
      <P>
        Zero (0) è il punteggio più basso che un giocatore può fare per una
        buca.
      </P>
      <P>
        In alternativa, puoi usare questa tabella per guardare il punteggio per
        ogni buca.
      </P>
      <StablefordScoreTable par="Par Buca" turns="Turni" points="Punti" />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeStableford />}
          {language === "fr" && <FrenchModeStableford />}
          {language === "it" && <ItalianModeStableford />}
          {language === "es" && <SpanishModeStableford />}
          {language === "de" && <GermanModeStableford />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
