const Cosine = {
  0: 1,
  60: 0.5,
  120: -0.5,
  180: -1,
  240: -0.5,
  300: 0.5,
};

const Sine = {
  0: 0,
  60: 0.86603,
  120: 0.86603,
  180: 0,
  240: -0.86603,
  300: -0.86603,
};

const HalfHexWidth = 99.8 / 2;
const HalfHexHeight = 90.3 / 2;
const AdjustX = 2.6;
const AdjustY = 2.35;

export const matrix = (degrees, x, y, id) => {
  if (degrees === 0) {
    return `matrix(1, 0, 0, 1, 0, 0)`;
  }

  const ca = Cosine[degrees];
  const sa = Sine[degrees];
  const cx = x + HalfHexWidth + AdjustX;
  const cy = y + HalfHexHeight + AdjustY;

  const tx3 = cx * (1 - ca) + cy * sa;
  const ty3 = -cx * sa + cy * (1 - ca);

  return `matrix(${ca}, ${sa}, ${-sa}, ${ca}, ${tx3}, ${ty3})`;
};
