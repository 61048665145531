import React from "react";

const h1 =
  "font-bold text-3xl print:text-2xl p-8 print:p-4 text-center w-full uppercase";
const h2 =
  "font-bold text-2xl print:text-xl pt-4 print:pt-2 pb-4 print:pb-2 uppercase";
const h3 = "font-bold text-xl print:text-lg pt-4 print:pt-2 uppercase";
const h4 = "font-bold text-lg print:text-md pt-4 print:pt-2 uppercase";
const h5 = "font-bold text-md print:text-sm pt-4 print:pt-2 uppercase";
const h6 = "font-bold text-xs print:text-xs pt-4 print:pt-2 uppercase";
export const note = "pt-2 text-sm";
export const link =
  "text-brand-green hover:text-brand-yellow underline cursor-pointer";
export const ul = "list-disc list-inside";
export const ol = "list-decimal list-inside";

export const A = ({ children, ...props }) => (
  <a {...props} className={link}>
    {children}
  </a>
);

export const H1 = ({ children, ...props }) => (
  <h1 className={h1} {...props}>
    {children}
  </h1>
);
export const H2 = ({ children, ...props }) => (
  <h2 className={h2} {...props} style={{ breakAfter: "avoid-page" }}>
    {children}
  </h2>
);

export const H3 = ({ children, id = null, href = null, style, ...props }) => (
  <h3
    id={id}
    className={h3}
    {...props}
    style={{ breakAfter: "avoid-page", ...style }}
  >
    {href ? <A href={href}>{children}</A> : children}
  </h3>
);

export const H4 = ({ children, id = null, href = null, style, ...props }) => (
  <h4
    id={id}
    className={h4}
    {...props}
    style={{ breakAfter: "avoid-page", ...style }}
  >
    {href ? <A href={href}>{children}</A> : children}
  </h4>
);

export const H5 = ({ children, id = null, href = null, style, ...props }) => (
  <h5
    id={id}
    className={h5}
    {...props}
    style={{ breakAfter: "avoid-page", ...style }}
  >
    {href ? <A href={href}>{children}</A> : children}
  </h5>
);

export const H6 = ({ children, id = null, href = null, style, ...props }) => (
  <h6
    id={id}
    className={h6}
    {...props}
    style={{ breakAfter: "avoid-page", ...style }}
  >
    {href ? <A href={href}>{children}</A> : children}
  </h6>
);

export const P = ({ className = "", children }) => (
  <p className={`pt-1 pb-1 w-full md:w-4/5 print:text-sm ${className}`}>
    {children}
  </p>
);
