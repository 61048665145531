import React from "react";
import { withAdjustmentPaletteTiles } from "../Palette/AdjustmentHexPaletteControl";
import Tile from "../Palette/Tile/Tile";

const noop = () => null;

const AdjustmentPaletteDisplayBase = ({
  tiles,
  height,
  onSelectTile,
  selectedTile,
  selectedFace,
  used,
  highlight,
}) => {
  return (
    <div
      className="flex flex-1 flex-col justify-start items-start bg-white rounded-b whitespace-no-wrap overflow-y-scroll overflow-x-hidden"
      style={{ height }}
    >
      {tiles.map(({ tile, a, b, unavailable }) => {
        const clickHandlerSideA = unavailable ? noop : onSelectTile(tile, a);
        const clickHandlerSideB = unavailable ? noop : onSelectTile(tile, b);

        return (
          <Tile
            key={tile}
            unavailable={unavailable}
            a={a}
            b={b}
            displayId={null}
            isSelected={selectedTile === tile}
            isMouseOverOnCourse={highlight === a || highlight === b}
            clickHandlerSideA={clickHandlerSideA}
            clickHandlerSideB={clickHandlerSideB}
            showSideAUnavailable={selectedFace === b || used.includes(b)}
            showSideBUnavailable={selectedFace === a || used.includes(a)}
            isAdjustment={true}
          />
        );
      })}
    </div>
  );
};

const AdjustmentPaletteDisplay = withAdjustmentPaletteTiles(
  AdjustmentPaletteDisplayBase
);

export default AdjustmentPaletteDisplay;
