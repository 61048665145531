import React from "react";
import { Link } from "react-router-dom";
import { Designer, Published } from "../navigation/locations";
import { Private } from "./Private";

const Pill = ({ name, colour = "bg-gray-200" }) => (
  <span
    className={`inline-block ${colour} rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-1`}
  >
    {name}
  </span>
);

export const CourseCard = ({
  id,
  isPrivate,
  title,
  designer,
  holes,
  image,
  official,
  tags = [],
}) => {
  const href = isPrivate ? Designer(id) : Published(id);
  return (
    <Link className="md:max-w-xs w-full p-1 hover:bg-gray-100" to={href}>
      <div
        className="border-r border-t border-l border-gray-400 h-64 flex-none bg-contain rounded-t text-center overflow-hidden flex-row flex justify-end items-start bg-center bg-no-repeat"
        style={{
          minHeight: 350,
          backgroundImage: `url(${image})`,
        }}
        title={`Course layout of ${title}`}
      >
        {isPrivate && <Private />}
      </div>
      <div className="border-r border-b border-l border-gray-400 rounded-b p-4 flex flex-col justify-between leading-normal">
        <div className="mb-8 flex-col flex justify-start items-start">
          <div className="text-gray-900 font-bold text-xl whitespace-no-wrap">{title}</div>
          <div className="text-sm">
            {
              <p className="text-gray-900 leading-none">
                {isPrivate ? "Under construction" : `By ${designer}`}
              </p>
            }
          </div>
        </div>
        <div className="items-start justify-start h-16">
          {holes === 9 && <Pill name="9 Holes" />}
          {holes < 18 && <Pill name="Short Course" />}
          {holes === 18 && <Pill name="18 Holes" />}
          {official && <Pill name="Official" />}
          {!tags.includes("coastlines") && !tags.includes("coastlines-exclusive") && (
            <Pill name="Base Game" />
          )}
          {tags.includes("coastlines") && <Pill name="Expansion Required" colour="bg-yellow-300" />}
          {tags.includes("coastlines-exclusive") && (
            <Pill name="Exclusive Required" colour="bg-red-300" />
          )}
        </div>
      </div>
    </Link>
  );
};
