import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";
import { SingleOptionPanel, TwoOptionPanel } from "./DownloadPanels";

const More = () => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-8 md:w-4/5">
          <h2 className="font-bold text-lg pt-4">Print and Play</h2>
          <p>
            All print and play files have moved on to Google Drive. If you don't have access to the print and play files
            and you should (because you bought access); please contact: hello@seabrook-studios.com
          </p>
          <SingleOptionPanel
            title="18 Holes"
            href="https://drive.google.com/drive/folders/13NkdOnptM7AfPNrYgF_7njFCNX8P45e5"
          />
          <SingleOptionPanel
            title="18 Holes - Putting, Wind and Coastlines Expansion"
            href="https://drive.google.com/drive/folders/1a2u75M5Pfk8BQ1EHEVsdB2pn5nNKlqsa"
          />
          <SingleOptionPanel
            title="18 Holes - Putting, Wind and Coastlines Expansion Exclusive"
            href="https://drive.google.com/drive/folders/15Bo9k_OObIPS933QbnEGhePOtav_l37V"
          />
          <SingleOptionPanel
            title="18 Holes - Course Architect"
            href="https://drive.google.com/drive/folders/180umQMJ9o1ulzIxjmZMXtfhyz6lcROPC"
          />
          <SingleOptionPanel
            title="18 Holes - Course Architect Exclusive"
            href="https://drive.google.com/drive/folders/1fqObTHrb68OGje6Xf37baRje2qctBLm5"
          />
          <em className="pt-2 text-xs">You may only use these files for personal use.</em>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default More;
