import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P, ul } from "../../brand/typography";
import { FromMatchPlay } from "./FromMatchPlay";
import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FrenchModeStrokePlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stroke Play</H1>
      <P>
        Le golf traditionnel. Votre objectif est d'atteindre le 18e green avec
        le score le plus bas. Vous marquez 1 point pour chaque tour que vous
        prenez, que vous sautez et pour ceux que vous passez hors-jeu.  
      </P>
      <P>Vous aurez besoin d'un stylo et d’une feuille pour ce jeu. </P>

      <H3>Nombre de joueurs</H3>
      <P>1 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>Vous voulez une expérience de golf traditionnelle.</P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Les joueurs marquent un point pour chaque carte de club jouée, chaque
        atterrissage sur un obstacle et chaque tour hors-jeu. Le joueur ayant le
        score le plus bas gagne.
      </P>

      <H3>Variations des règles</H3>
      <FromMatchPlay />

      <H4>Comment jouer</H4>
      <P>
        Lorsqu'un joueur atteint le green, il attend que tous les autres aient
        terminé le trou avant de passer à la suite. 
      </P>
      <P>
        Les joueurs qui atterrissent dans l'eau ne passent pas un tour, mais
        ajoutent un point à leur score.
      </P>
      <P>
        Les joueurs qui se retrouvent hors-jeu ajoutent un point à leur score.
      </P>

      <H4>Marquage des points</H4>
      <P>
        Avec un stylo et du papier, compter au fur et à mesure le nombre de tour
        de chaque joueur.
      </P>
      <P>
        Chaque parcours aura un score de par. Même si vous ne gagnez pas
        aujourd'hui, battre le par est un exploit.
      </P>
      <P>
        Conservez une trace de vos scores. Lorsque vous reviendrez sur le même
        parcours, essayez de battre votre meilleur score.
      </P>
    </>
  );
};

const EnglishModeStrokePlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stroke Play</H1>
      <P>
        Traditional golf. Your objective is to reach the 18th green with the
        lowest score. You score 1 point for each turn you take, turn missed, and
        out of bounds.
      </P>
      <P>You’ll need a pen and paper for this one.</P>

      <H3>PLAYS</H3>
      <P>1 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>When you want a traditional golf experience.</P>

      <H3>HOW TO WIN</H3>
      <P>
        Players score one point for each club card played, water hazards landed
        in, or out of bounds. The player with the lowest score wins.
      </P>

      <H3>Rule Variations</H3>
      <FromMatchPlay />

      <H4>Game Play</H4>
      <P>
        When a player reaches the green, they wait for everyone else to complete
        the hole before moving on.
      </P>
      <P>
        Players who land in the water do not miss a turn, but add one to their
        score.
      </P>
      <P>Players who end up out of bounds add one to their score.</P>

      <H4>Scoring</H4>
      <P>With pen and paper, use tally marks to track each player's turn.</P>
      <P>
        Each course will have a par score. Even if you don’t win today, beating
        par is an achievement .
      </P>
      <P>
        Keep a record of your scores. When you come to play the same course
        again, try and beat your previous best.
      </P>
    </>
  );
};

const GermanModeStrokePlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stroke Play</H1>
      <P>
        Traditionelles Golf. Dein Ziel ist es, das 18. Green mit der niedrigsten
        Punktzahl zu erreichen. Du erhältst 1 Punkt für jede Runde, die du
        brauchst, für jede verpasste Runde und für jedes Out-of-Bounds.
      </P>
      <P>Hierfür benötigst du Stift und Papier. </P>

      <H3>SPIELER</H3>
      <P>1 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>… du dir ein traditionelles Golferlebnis wünschst.</P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Die Spieler erhalten einen Punkt für jede gespielte Golfschläger-Karte,
        für jedes Wasserhindernis, in dem sie landen, und für jedes Mal, wenn
        sie außerhalb des Spielfeldes landen. Der Spieler mit der niedrigsten
        Punktzahl gewinnt.
      </P>

      <H3>Regelvariationen</H3>
      <FromMatchPlay />

      <H4>Gameplay</H4>
      <P>
        Wenn ein Spieler das Green erreicht, wartet er darauf, dass alle anderen
        das Loch vervollständigen, bevor er weiterspielt.
      </P>
      <P>
        Spieler, die im Wasser landen, verpassen keine Runde, sondern addieren
        eine Runde zu ihrem Punktestand.
      </P>
      <P>
        Spieler, die außerhalb des Spielfeldes landen, zählen einen Punkt zu
        ihrem Punktestand hinzu.
      </P>

      <H4>Punktevergabe</H4>
      <P>
        Verwende Stift und Papier, verwende Striche, um jeden Zug aller Spieler
        zu verfolgen.
      </P>
      <P>
        Jeder Golfplatz wird Par-Punkte haben. Auch wenn du heute nicht
        gewinnst: den Par zu schlagen ist eine Leistung.
      </P>
      <P>
        Führe Buch über deine Punktzahl. Wenn du den gleichen Platz noch einmal
        spielst, versuche, deine bisherige Bestleistung zu schlagen.
      </P>
    </>
  );
};

const SpanishModeStrokePlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stroke Play</H1>
      <P>
        Golf tradicional. Tu objetivo es llegar al green 18 con la puntuación
        más baja. Anota un punto por cada turno que hagas, turno perdido y fuera
        de los límites.
      </P>
      <P>Necesitarás un bolígrafo y un papel para esto. </P>

      <H3>JUEGOS</H3>
      <P>1 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>Cuando quieres una experiencia de golf tradicional.</P>

      <H3>CÓMO GANAR</H3>
      <P>
        Los jugadores anotan un punto por cada carta de palos que juegan,
        hazards de agua en que caen dentro o fuera de los límites. El jugador
        con la puntuación más baja gana.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromMatchPlay />

      <H4>Juego</H4>
      <P>
        Cuando un jugador llega al green, espera a que todos los demás completen
        el hoyo antes de seguir adelante.
      </P>
      <P>
        Los jugadores que caen en el agua no pierden turno, pero añaden un punto
        a su puntuación.
      </P>
      <P>
        Los jugadores que terminan fuera de los límites añaden uno a su
        puntuación.
      </P>

      <H4>Puntuación</H4>
      <P>
        Con bolígrafo y papel, usa marcas de conteo para registrar el turno de
        cada jugador.
      </P>
      <P>
        Cada campo tendrá una puntuación de par. Incluso si no ganas hoy, ganar
        al par es un logro.
      </P>
      <P>
        Lleva un registro de tus puntuaciones. Cuando vuelvas a jugar en el
        mismo campo, trata de superar tu puntuación mejor anterior.
      </P>
    </>
  );
};

const ItalianModeStrokePlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Stroke Play</H1>
      <P>
        Golf tradizionale. Il tuo obiettivo è di raggiungere il 18º green con il
        punteggio più basso. Fai un punto per ogni turno, per ogni turno saltato
        e per ogni fuori percorso.
      </P>
      <P>Ti serviranno carta e penna per giocare a questa variante. </P>

      <H3>GIOCATORI</H3>
      <P>1 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>Vuoi un’esperienza golfistica tradizionale.</P>

      <H3>COME SI VINCE</H3>
      <P>
        I giocatori mettono a segno un punto per ogni carta mazza giocata, per
        ogni ostacolo d’acqua su cui atterrano o per ogni fuori campo. Vincerà
        il giocatore che avrà totalizzato il punteggio più basso.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromMatchPlay />

      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>
        Quando un giocatore raggiunge il green, attende che tutti gli altri
        avranno completato la buca prima di continuare.
      </P>
      <P>
        I giocatori che atterrano sull’acqua non saltano un turno, ma aggiungono
        uno al loro punteggio.
      </P>
      <P>I giocatori che finiscono fuori campo aggiungono uno al punteggio.</P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        Con carta e penna, usa i segnalini per tracciare il turno di ogni
        giocatore.
      </P>
      <P>
        Ogni percorso avrà un punteggio di par. Anche se non vinci oggi, battere
        il par è un traguardo.
      </P>
      <P>
        Tieni traccia dei tuoi punteggi. Quando arrivi a giocare nuovamente lo
        stesso percorso, prova a battere il tuo record precedente.
      </P>
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeStrokePlay />}
          {language === "fr" && <FrenchModeStrokePlay />}
          {language === "it" && <ItalianModeStrokePlay />}
          {language === "es" && <SpanishModeStrokePlay />}
          {language === "de" && <GermanModeStrokePlay />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
