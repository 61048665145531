import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, P, ul } from "../../brand/typography";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";
import { NoTranslationAvailable } from "./NoTranslationAvailable";
import { FromMatchPlay } from "./FromMatchPlay";

const EnglishMode18Cards = () => {
  return (
    <>
      <BackToModesLink />
      <H1>18 Cards Solo</H1>
      <P>
        18 Clubs, 1 course. Can you navigate these 9 holes courses in this
        puzzlesque game mode.
      </P>
      <H3>Plays</H3>
      <P>1 player</P>
      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You like a challenge and want to carefully think about which club you
        use for which shot. You will only get to use each club once.
      </P>
      <H3>HOW TO WIN</H3>
      <P>Your best score wins. See scoring below.</P>
      <H3>Rule Variations</H3>
      <FromMatchPlay />
      <H4>Setup</H4>
      <P>
        Build one of the 9-hole courses that are marked as supporting "18
        Cards". Place 9 flags on the course and adorn the course with trees as
        required.
      </P>
      <P>
        Take one of each club. This is all the clubs that don't have a 3+ or 5+
        in the bottom corner. You should have 18 clubs. It may help to spread
        them out as three columns of six clubs.
      </P>
      <P>
        Take out the 1-point tokens and set them aside. You won't need them. I
        believe in you!
      </P>
      <H4>Game Play</H4>
      <P>
        You will play this mode as per 18 Holes solo play with a few key
        differences.
      </P>
      <P>
        On your turn, you will select a club to play and use it's powers as per
        standard play. Once the club has been used it is flipped over. You may
        not use this club again during the game.
      </P>
      <P>
        You may choose to move 1 hex at any time <b>without</b> using a club.
        Instead, you will take a 1-point token.
      </P>
      <P>
        If you land on a water hazard, take a 1-point token and play as rough.
      </P>
      <P>
        If you land in a bunker, take a 1-point token and play as rough. You do
        not need to exit the bunker in this variation.
      </P>
      <P>
        If you land out-of-bounds, take a 1-point token and retake your shot
        from where you originally hit it. You must restore the club you used
        back to your hand. You may choose to use a different club to retake the
        shot.
      </P>
      <H4>Scoring</H4>
      <P>
        You will score 1 point for every 1-point token that you have collected
        during play.
      </P>
      <P>You will score -1 point for every unused club in your hand.</P>
      <P>0 points is a par score and is an excellent performance.</P>
      <P>
        Negative scores (e.g. 1 under par or -1) is an outstanding performance.
      </P>
      <P>
        Positive scores (e.g. 1 over par or +1) are good performances. Know that
        this game game is difficult and you will need a little bit of luck with
        you.
      </P>
      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>1-point tokens</li>
      </ul>
    </>
  );
};

const FrenchMode18Cards = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishMode18Cards />
    </>
  );
};

const SpanishMode18Cards = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishMode18Cards />
    </>
  );
};

const GermanMode18Cards = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishMode18Cards />
    </>
  );
};

const ItalianMode18Cards = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishMode18Cards />
    </>
  );
};

const Modes18Cards = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishMode18Cards />}
          {language === "fr" && <FrenchMode18Cards />}
          {language === "it" && <ItalianMode18Cards />}
          {language === "es" && <SpanishMode18Cards />}
          {language === "de" && <GermanMode18Cards />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(Modes18Cards);
