import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { CourseCard } from "./CourseCard";
import { withAllCourses } from "./PublicCoursesProvider";
import { Filters } from "./Filters";
import { withUserConfiguration } from "../user/UserConfigurationProvider";

const BrowseCoursesBase = ({ courses, showCourse }) => {
  return (
    <>
      <SiteNavigation />
      <Filters />
      <div className="flex flex-wrap justify-start">
        {courses.map((course) => (
          <CourseCard
            isPrivate={false}
            key={course.id}
            title={course.displayName}
            designer={course.designer}
            holes={course.holes}
            id={course.id}
            image={showCourse ? course.course : course.guide}
            official={course.official}
          />
        ))}
      </div>
    </>
  );
};

const BrowseCourses = withUserConfiguration(withAllCourses(BrowseCoursesBase));

export default BrowseCourses;
