import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { CourseCard } from "../Browse/CourseCard";
import { withMyCourses } from "./PrivateCoursesProvider";
import { NewCourse } from "./NewCourse";
import { ensureLoggedIn } from "../auth/react-auth0-wrapper";
import { withUserConfiguration } from "../user/UserConfigurationProvider";

const unpublishedCourseView = (id) => `https://assets.18holesgame.com/courses/${id}.course.thumb.png`;
const unpublishedGuideiew = (id) => `https://assets.18holesgame.com/courses/${id}.guide.thumb.png`;

const MyCoursesBase = ({ showCourse, courses }) => {
  return (
    <>
      <SiteNavigation />
      <div className="flex items-center justify-between flex-row flex-wrap bg-brand-green p-1">
        <NewCourse />
      </div>
      <div
        className="grid"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(16rem, 1fr))",
        }}
      >
        {courses.map((course) => (
          <CourseCard
            isPrivate={true}
            key={course.id}
            title={course.displayName}
            designer={course.designer}
            holes={course.holes}
            id={course.id}
            image={showCourse ? unpublishedCourseView(course.id) : unpublishedGuideiew(course.id)}
            tags={course.tags}
          />
        ))}
      </div>
    </>
  );
};

const MyCourses = withUserConfiguration(ensureLoggedIn(withMyCourses(MyCoursesBase)));

export default MyCourses;
