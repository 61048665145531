import React from "react";
import { Link } from "react-router-dom";
import courseTile from "./images/course-tile.png";
import adjustmentTile from "./images/adjustment-tiles.png";
import clubCard from "./images/es/sample-club.png";
import clubCardWithPlayerMarker from "./images/es/sample-club-with-player-count.png";
import soloCard from "./images/sample-solo.png";
import ruleBook from "./images/rule-book-cover.png";
import courseBook from "./images/course-book-cover.png";
import golferCard from "./images/es/sample-golfer.png";
import shotCard from "./images/sample-shot.png";
import token1 from "./images/1-point.png";
import token2 from "./images/2-points.png";
import token5 from "./images/5-points.png";
import meeple from "./images/meeple.png";
import flags from "./images/flag.png";
import trees from "./images/tree.png";
import holeInOne from "./images/final-hole-in-one-token.png";
import soloMarker from "./images/final-solo-marker.png";
import courseTileWithHighlight from "./images/course-tile-with-highlight.png";
import teeToGreen from "./images/from-tee-to-green.png";
import greenHex from "./images/green-hex.png";
import bunkerHex from "./images/bunker-hex.png";
import treeHex from "./images/tree-hex.png";
import teeboxHex from "./images/teebox-hex.png";
import clubhouse from "./images/clubhouse.png";
import hybrid8 from "./images/es/8-hybrid.png";
import iron9 from "./images/es/9-iron.png";
import iron6 from "./images/es/6-iron.png";
import iron4 from "./images/es/4-iron.png";
import iron3 from "./images/es/3-iron.png";
import wood5 from "./images/es/5-wood.png";
import lobWedge from "./images/es/lw.png";
import driver from "./images/es/driver.png";
import deckC from "./images/deck-c.png";
import deckCStraight from "./images/deck-c-straight.png";
import deckCRight from "./images/deck-c-right.png";
import deck3Left from "./images/deck-3-left.png";
import deck3Super from "./images/es/deck-3-super.png";
import deck4Left from "./images/deck-4-left.png";
import deck4Straight from "./images/deck-4-straight.png";
import deck5right from "./images/deck-5-right.png";
import wildCard from "./images/deck-4-wild.png";
import shuffle from "./images/es/shuffle.png";
import superHit from "./images/es/super-hit.png";
import shortHit from "./images/es/short-hit.png";
import longestDriveCard from "./images/es/longest-drive.png";
import roughHex from "./images/rough-hex.png";
import clareExample from "./images/clare-example.png";
import bunkerOutcomes from "./images/bunker-outcomes.png";
import exitBunkerExample from "./images/exit-bunker.png";
import exitBunkerShotCard from "./images/exit-bunker-shot-card.png";
import roughExample1 from "./images/rough-example-1.png";
import roughExample2 from "./images/rough-example-2.png";
import chippingExample from "./images/chipping-example.png";
import golfer1a from "./images/es/golfer-1-a.png";
import golfer1b from "./images/es/golfer-1-b.png";
import golfer2a from "./images/es/golfer-2-a.png";
import golfer2b from "./images/es/golfer-2-b.png";
import golfer3a from "./images/es/golfer-3-a.png";
import golfer3b from "./images/es/golfer-3-b.png";
import golfer4a from "./images/es/golfer-4-a.png";
import golfer4b from "./images/es/golfer-4-b.png";
import golfer5a from "./images/es/golfer-5-a.png";
import golfer5b from "./images/es/golfer-5-b.png";
import golfer6a from "./images/es/golfer-6-a.png";
import golfer6b from "./images/es/golfer-6-b.png";
import golfer7a from "./images/es/golfer-7-a.png";
import golfer7b from "./images/es/golfer-7-b.png";
import golfer8a from "./images/es/golfer-8-a.png";
import golfer8b from "./images/es/golfer-8-b.png";
import {
  ModesChaosGolf,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  PublicRules,
} from "../navigation/locations";
import {
  Section,
  BoxPart,
  SideBySide,
  SideBySideSection,
  FirstGame,
  GreenPanel,
  Example,
  AlwaysSideBySide,
  HybridCardsSpanish,
  StandardPortraitCardSize,
  StandardLandscapeCardSize,
  SmallPortraitCardSize,
  SmallHex,
  CourseTileSize,
  WaterHazards,
} from "./RuleBookParts";
import { A, H1, H2, note, P, H3, H4, H5, link, ul } from "../brand/typography";

const language = "es";
export const RuleBookSpanish = () => {
  return (
    <div className="flex flex-col justify-start items-start p-8 md:w-5/5 print:block">
      <H1>Libro de reglas</H1>
      <div className="self-center print:hidden">
        <A href={PublicRules} target="_blank">
          Download as PDF
        </A>
      </div>
      <div className="p-4">
        <H2>TABLA DE CONTENIDO</H2>
        <div className="flex flex-col">
          <A href="#box-contents">CONTENIDO DE LA CAJA</A>
          <A href="#establishing-shot">FIJANDO EL TIRO DEL TEE</A>
          <A href="#overview">DETALLES</A>
          <A href="#from-tee-box-to-green">DE TEE DE SALIDA A GREEN</A>
          <A href="#setup">CONFIGURACIÓN</A>
          <A href="#playing-a-round">JUGANDO UNA RONDA</A>
          <A href="#rough">JUGANDO DESDE EL ROUGH</A>
          <A href="#chipping">CHIPEAR</A>
          <A href="#super-short-wild">CARTAS SÚPER, CORTA, DE BARAJADO Y COMODINES</A>
          <A href="#hazards">HAZARDS Y OTRAS PIEZAS</A>
          <A href="#golfers">HABILIDADES DEL GOLFISTA</A>
          <A href="#game-modes">MÚLTIPLES MANERAS DE JUGAR</A>
        </div>
      </div>
      <Section id="box-contents" title="CONTENIDO DE LA CAJA">
        <div className="flex flex-col md:flex-row justify-between items-center md:flex-wrap print:w-full print:flex-row print:flex-wrap">
          <BoxPart src={courseTile} text="49 piezas de campo" size={200} />
          <BoxPart src={adjustmentTile} text="21 piezas de ajuste" size={66} />
          <BoxPart src={meeple} size={150} text="5 golfistas" />
          <BoxPart src={golferCard} size={150} text="8 cartas de golf" />
          <BoxPart src={clubCard} size={200} text="34 cartas de palos" />
          <BoxPart src={shotCard} size={150} text="74 cartas de tiro" />
          <BoxPart src={soloCard} size={200} text="24 cartas de Solo Chaos Golf" />
          <BoxPart src={token1} size={66} text="18 fichas de 1 punto" />
          <BoxPart src={token2} size={66} text="18 fichas de 2 puntos" />
          <BoxPart src={token5} size={100} text="18 fichas de 5 puntos" />
          <BoxPart src={holeInOne} size={66} text={`18 fichas de "hoyo en uno"`} />
          <BoxPart src={soloMarker} size={66} text="1 marcador de turnos para jugar solo" />
          <BoxPart src={flags} size={66} text="18 banderas y soportes" />
          <BoxPart src={trees} size={66} text="14 árboles" />
          <BoxPart src={ruleBook} size={66} text="Este manual" />
          <BoxPart src={courseBook} size={66} text="Libro de campo" />
        </div>
        <em className={note}>
          Algunos modos de juego requieren bolígrafo y papel para anotar (no incluido).
        </em>
      </Section>
      <Section id="establishing-shot" title="FIJANDO EL TIRO DEL TEE">
        <P>
          <em>
            Hay una reunión de la junta directiva en lo profundo de la sede corporativa del gigante
            deportivo internacional Golf Corp. Esta reunión determinará el futuro del golf.
          </em>
        </P>
        <P>
          Estimados miembros de la junta, la situación parece grave. La asistencia está
          disminuyendo, los avances en la tecnología del club han hecho nuestro juego demasiado
          fácil. Con el aumento de la competencia por su atención, la gente ya no puede pasarse 4
          horas jugando al golf. La baja asistencia presiona a nuestros campos para mantenerse a
          flote. Grupos de promotores inmobiliarios han empezado a acosar a los propietarios para
          convertir nuestras preciosas calles en fincas sin alma.
        </P>
        <P>
          No todo está perdido. Un equipo de nuestras mejores mentes está ideando la próxima
          iteración de nuestro gran juego. El equipo ha ideado un par de ajustes en las reglas que
          nos sacarán de la trampa de arena y nos llevarán al hoyo.
        </P>
        <P>
          Los propietarios de los campos necesitan más gente en ellos, y no hemos logrado mejorar el
          número de horas buenas al día. Vamos a cambiar nuestro enfoque e introducir el juego
          simultáneo. Más gente jugando al golf a la vez significa más green fees. Nuestra
          tecnología patentada HOLOBALL™ hará que jugar al mismo tiempo sea seguro para todos los
          miembros de un grupo.
        </P>
        <P>
          Es una tecnología fantástica. Pensé que la flotilla de drones rastreando y deteniendo
          bolas de golf iba a ser la ganadora. Pero esto... Esto de hacer que la bola de golf sea un
          dron es inteligente. Bolas de golf que nunca se pierden. Bolas de golf que pueden
          detenerse antes de que golpeen a alguien y luego reiniciar su vuelo. Bolas de golf que
          registran tu puntuación.
        </P>
        <P>
          Bueno, admito que ha habido algunas heridas. Se podría decir que la tecnología es todavía
          un poco tosca. Pero, creo que podemos empezar a extenderlo a algunos de los campos que
          están luchando por mantenerse a la par.
        </P>
        <P>
          Antes teníamos una gran relación con los fabricantes de palos. Sin embargo, últimamente,
          cada nuevo avance en la tecnología del golf lo hace más difícil, ya sea las bolas con
          guía, los superdrivers o los recientemente lanzados wedges magnéticos de arena.
        </P>
        <P>
          Para contrarrestar el beneficio de los fabricantes de palos por la desaparición de nuestro
          gran juego, tenemos algunos cambios que no se dirigirán directamente a ellos, sino que
          reducirán su influencia.
        </P>
        <P>
          Desde siempre, el golf no ha consistido solo en la ejecución de los tiros, sino en la
          selección de los mismos. Reduciremos el límite de palos de 14 a 5 obligando a los
          jugadores a pensar más en qué palos toman y cuándo los usan. El golf también consiste en
          planificar y elaborar tu juego, para ello no permitiremos que los jugadores usen el mismo
          palo hasta que hayan usado todos sus otros palos.
        </P>
        <P>Miembros de la junta. ¡Bienvenidos a la próxima era del golf!</P>
        <P>
          <em>La sala de juntas estalla en aplausos.</em>
        </P>
      </Section>
      <Section id="overview" title="Detalles">
        <P>
          Han pasado diez años desde esa reunión de la junta. El golf nunca ha sido más popular.
          Junto con la invención de la tecnología HOLOBALL™, se han creado nuevas formas de jugar al
          golf, y el deporte está prosperando. Eres un golfista en esta era dorada del golf. Prepara
          tus palos y, luego, compite en el campo con una cuidadosa selección del tiro y una
          inteligente navegación de obstáculos.
        </P>
        <P>
          El juego se desarrolla en los 18 hoyos de un campo de golf, cada uno de los cuales
          requiere que el jugador use uno o más palos. Después de jugar con un palo, los jugadores
          no pueden usar ese palo de nuevo hasta que no jueguen con todos los demás. Los golfistas
          ganan puntos llegando primero a los greens. El ganador es el golfista con más puntos
          anotados.
        </P>
        <P>
          Este libro contiene las reglas de muchos modos diferentes, incluyendo el solitario, el de
          dos jugadores, las carreras de golf y el Chaos Golf. Cada modo de juego te permite adaptar
          la experiencia a tus grupos de juego. La primera parte de este libro cubre el conjunto de
          reglas estándar llamado Match Play.
        </P>
        <SideBySide title="PIEZAS de campo" right={courseTileWithHighlight} size={200}>
          <P>
            Usa estas piezas para construir el campo en el que juegas. Hay 49 piezas de doble cara
            que puedes usar para crear un nuevo campo cada vez que juegas.
          </P>
          <P>
            Las piezas tienen uno de los tres niveles de dificultad indicados con estrellas en la
            esquina de cada pieza. Cada pieza tiene un número y una flecha que apunta a la dirección
            principal.
          </P>
          <P>
            Ve el folleto adjunto para diseños de campos e instrucciones sobre cómo construir
            campos.
          </P>
        </SideBySide>
        <SideBySide title="Piezas de ajuste" right={adjustmentTile} size={132} height="132">
          <P>
            Estos minihexágonos pueden colocarse encima de las piezas del campo para modificar la
            dificultad del mismo. Para un juego más relajado, cubre los hazards con piezas con el
            lado de rough arriba. Si buscas un desafío, usa el lado del hazard para ajustar los
            existentes o añadir otros nuevos.
          </P>
        </SideBySide>
        <SideBySide title="Cartas de golfistas" right={golferCard} size={200}>
          <P>
            Los jugadores seleccionan cartas de golfistas que proporcionan una habilidad especial
            única. Las habilidades del lado A reducen la dificultad, mientras que el lado B suelen
            contener restricciones. Eliges el lado A o el lado B cuando juegas.
          </P>
        </SideBySide>
        <SideBySide title="Cartas de palos" right={clubCard} size={250}>
          <P>
            Cada palo tiene una habilidad única o te dice de qué mazo de cartas robar. Solo puedes
            sacar cinco palos en el campo. Una vez usados, no puedes volver a usarlos hasta que
            hayas usado todos tus otros palos.
          </P>
        </SideBySide>
        <SideBySide title="Cartas de tiro" right={shotCard} size={200}>
          <P>
            Estas cartas están organizadas en cinco mazos y representan las diferentes distancias y
            direcciones de tus tiros. Cada uno contiene cartas que golpean la bola a la izquierda, a
            la derecha y, en algunos casos, tú eliges la dirección.
          </P>
          <P>
            Un mapa de vuelo en cada carta te ayuda a trazar el paso de tu bola. El hexágono naranja
            marca donde cae tu bola. Al golpear la bola desde el rough, viaja un hexágono menos.
            Ver&nbsp;
            <A href="#rough">JUGANDO DESDE EL ROUGH</A>.
          </P>
          <P>
            También usas cartas de tiro para salir de los búnkers. El icono del resultado del búnker
            está en la esquina inferior derecha. Ver&nbsp;
            <A href="#4d">SALIR DE BUNKERS, GREENS Y GANAR</A>.
          </P>
        </SideBySide>
        <SideBySide
          title="Marcadores de green / Fichas de puntuación"
          right={courseTileWithHighlight}
          size={200}
        >
          <P>
            Las fichas circulares tienen un doble propósito. La cara delantera tiene los greens
            numerados del 1 al 18. Como las piezas del campo no tienen números en los greens,
            colocar las fichas con números hacia arriba ayuda a seguir el orden de los hoyos.
          </P>
          <P>
            En muchos de los modos de juego de 18 Holes, se utilizan las fichas para llevar el
            marcaje. Estos lados muestran valores de puntos de 5, 2 y 1. Ver&nbsp;
            <A href="#game-modes">MÚLTIPLES FORMAS DE JUGAR</A>.
          </P>
          <P>
            Hay marcadores de repuesto de 1, 2 y 5 puntos. Estos no tienen números de green. Por
            favor, ponlos en un lugar seguro en caso de que los necesites.
          </P>
        </SideBySide>
        <SideBySide title="Marcador de hoyo en uno" right={holeInOne} size={200}>
          <P>
            Estos se recogen cuando consigues un hoyo en uno. En el caso de empate, el jugador con
            más marcadores de hoyo en uno es el ganador.
          </P>
        </SideBySide>
        <SideBySide title="Cartas de Solo Chaos Golf" right={soloCard} size={250}>
          <P>
            Usa este mazo de cartas para organizar el flujo del Solo Chaos Golf y ajustar la
            dificultad. Estas cartas tienen dos símbolos diferentes. Círculos de un solo color que
            representan los turnos en los que solo el jugador puntúa y círculos de dos tonos en los
            que tanto tú como tus oponentes automatizados puntuáis. Estos son turnos en los que tus
            oponentes pueden anotar. Ver&nbsp;
            <A href={ModesChaosGolfSolo}>SOLO CHAOS GOLF</A>.
          </P>
        </SideBySide>
        <SideBySide title="Marcador de TURNOS para jugar solo" right={soloMarker} size={200}>
          <P>Se usa para controlar en qué turno estás durante un juego de Solo Chaos Golf.</P>
        </SideBySide>
        <SideBySide title="Banderas" right={flags} size={200}>
          <P>
            Estas banderas se colocan en los greens para indicar a dónde apuntas. Hay una para cada
            hoyo, numeradas del 1 al 18, y tres banderas de repuesto (sin numerar). Guarda las
            banderas adicionales para que no se pierdan.
          </P>
        </SideBySide>
        <SideBySide title="Árboles" right={trees} size={200}>
          <P>
            Estos árboles son adornos que puedes poner en tu campo. Colócalos sobre los hazards de
            árboles para hacerlos más prominentes. Solo hay 14 árboles, así que tendrás que moverlos
            a lo largo del campo mientras juegas.
          </P>
        </SideBySide>
        <SideBySide title="GOLFISTAS" right={meeple} size={200}>
          <P>
            Cada jugador tiene una figura de golfista que lo representa en el campo. No hay
            diferencia entre ellos, así que elige el color que más te guste.
          </P>
        </SideBySide>
      </Section>
      <SideBySideSection
        id="from-tee-box-to-green"
        title="De tee de salida a green"
        right={teeToGreen}
        size="450"
        height="1024"
      >
        <P>
          Un juego de 18 Holes se juega en un campo que consiste en uno o más hoyos. La mayoría de
          los campos tienen 18 hoyos, aunque es común tener campos de 9 hoyos más cortos y rápidos.
          Anotas un punto por cada hoyo que ganas. Ganas un hoyo al llegar al green antes que tus
          oponentes. Múltiples jugadores anotan en caso de empate.
        </P>
        <P>
          El green es el pequeño hexágono con una pequeña bandera negra. A la derecha tenemos un
          solo hoyo hecho con tres piezas de campo. La pieza superior contiene el green. El tee de
          salida está en la pieza inferior. La calle corre desde el tee de salida hasta el green.
        </P>
        <P>
          Un hoyo te hará empezar con tu golfista en el tee de salida y tu tarea es llegar al green.
          Juegas con palos que moverán a tu golfista. No tienes que seguir la calle hasta el green.
          Puede ser una buena idea porque tus tiros irán más lejos cuando juegues desde la calle.
        </P>
        <P>
          Solo hay un par de piezas sobre las que no puedes aterrizar. Estos son los hazards de
          árboles y la casa club. El hazard de árboles tendrá una valla blanca a su alrededor.{" "}
          <A href="#hazards">La casa club se puede ver en la página 15</A>. Puedes aterrizar en las
          calles y greens de otros hoyos y puedes aterrizar en las piezas que contienen otros
          golfistas. Todo esto gracias a HOLOBALL™.
        </P>
        <P>
          Aunque puedes caer en la mayoría de las piezas del juego, no todas son recomendables.
          Algunos contienen agua, lo que te hará perder un turno, y otros contienen búnkers, de los
          que tendrás que salir antes de continuar. El verde oscuro que hay en cada una de las otras
          piezas es el rough y eso reducirá la distancia de tus tiros. Ver&nbsp;
          <A href="#hazards">HAZARDS Y OTRAS PIEZAS</A>.
        </P>
        <P>
          There are lots of ways to custoHay muchas maneras de personalizar tu experiencia de 18
          Holes. Los diferentes modos de juego te dan diferentes experiencias y desafíos. Puedes
          ajustar los campos para hacerlos más fáciles o más difíciles. Los ocho golfistas que
          entran en el juego te permiten hacer el juego más fácil o más difícil en base a los
          jugadores. Probando las diferentes opciones, estamos seguros de que encontrarás una
          experiencia de juego que disfrutarás y a la que volverás.
        </P>
      </SideBySideSection>
      <Section id="setup" title="Configuración">
        <H3>CREAR EL CAMPO</H3>
        <P>
          Puedes configurar el campo usando uno de los diseños del folleto del campo o diseñar el
          tuyo propio. La última página del folleto del campo tiene instrucciones sobre cómo crear
          campos.
        </P>
        <SideBySide right={greenHex} size={132}>
          <P>
            Una vez que hayas hecho tu campo, coloca una bandera en cada hoyo. Pon la primera
            bandera en el green (derecha) para el primer hoyo y continúa hasta el green 18. Si estás
            jugando con árboles, colócalos en los primeros 14 árboles del campo. Los hazards de
            árboles son los hexágonos con pequeñas vallas blancas a su alrededor.
          </P>
        </SideBySide>
        <P>Es hora de que todos elijan un golfista y saquen palos.</P>
        <FirstGame lang={language}>
          <A
            href="https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5"
            target="_blank"
          >
            Consulta Starting Course.
          </A>
        </FirstGame>
        <H3>SELECCIONA GOLFISTA</H3>
        <P>
          Cada jugador selecciona una carta de golfista para jugar. Puedes dejar que los jugadores
          escojan el lado que quieran o repartir uno al azar.
        </P>
        <P>
          Cada carta de golfista tiene un lado A y un lado B. Los golfistas del lado A tienden a
          hacer el juego más fácil, y los del lado B tienden a hacer el juego más difícil.
        </P>
        <P>
          La selección de los golfistas ocurre antes de la selección del palo, ya que algunos
          golfistas influyen en la fase de selección del palo.
        </P>
        <FirstGame lang={language}>
          Salta las cartas de golfista hasta que estés listo para añadir dificultad.
        </FirstGame>
        <H3 style={{ breakBefore: "page" }}>SACAR PALOS</H3>
        <P>Los jugadores sacarán palos hasta que tengan 5 cada uno.</P>
        <SideBySide right={clubCardWithPlayerMarker} size={250}>
          <P>
            Baraja las cartas de los palos. Si juegas en solitario o en pareja, quita los palos que
            muestren 3+ o 5+ en la esquina inferior derecha. En un juego de 3 o 4 jugadores, quita
            las cartas de 5+.
          </P>
        </SideBySide>
        <P>
          Reparte el mismo número de cartas a cada jugador. Las cartas que quedan no se usan y se
          devuelven a la caja sin mostrarlas.
        </P>
        <P>
          Cada jugador elige un palo de las cartas repartidas y lo coloca delante de ellos, boca
          abajo. Pasan las cartas restantes a la persona de su izquierda. De nuevo, cada jugador
          selecciona una carta de las cuatro restantes, la coloca boca abajo delante de ellos y pasa
          el resto a la izquierda. Continúa hasta que todos los jugadores tengan cinco palos. Esta
          es su mano. Cada jugador también necesitará espacio para poner los palos usados delante de
          ellos.
        </P>
        <P>
          Si un jugador tiene The Shark, Iron Jane o Slugger, consultar&nbsp;
          <A href="#golfers">Habilidades de golfista</A> para las reglas que cambian la forma de
          sacar cartas.
        </P>
        <P>
          <em>
            Robar cartas es tu primera oportunidad de obtener una ventaja. Las características del
            campo determinan la eficacia de algunos palos. Mira el campo para ver qué palos crees
            que van a serte útiles.
          </em>
        </P>
        <FirstGame lang={language}>
          En la cara de algunas cartas de palos hay una A, B, C, D o E. En lugar de escoger, da las
          A al jugador 1, las B al jugador 2, etc.
        </FirstGame>
        <H3>COMIENZA A JUGAR</H3>
        <P>
          Ya estás listo para empezar a jugar. Coloca a todos los golfistas en el tee de salida del
          primer hoyo. Todos los jugadores deben tener sus palos en la mano, ocultándoselos a los
          demás jugadores.
        </P>
        <P>
          Coge los 5 mazos de tiro, tienen marcado C, 2, 3, 4 o 5 en la parte de atrás. Barájalos y
          ponlos al alcance de todos los jugadores.
        </P>
      </Section>
      <Section id="playing-a-round" title="Jugando una ronda">
        <GreenPanel>
          <H3>Jugando una ronda</H3>
          <P>CADA RONDA DE 18 HOLES SE DIVIDE EN ESTOS PASOS</P>
          <H4 href="#selecting-clubs">SELECCIONAR LOS PALOS</H4>
          <ul className="pl-4">
            <li>
              <A href="#1">
                1.
                <span className="pl-3">Resetear las manos vacías</span>
              </A>
            </li>
            <li>
              <A href="#2">
                2.
                <span className="pl-3">Seleccionar y revelar los palos</span>
              </A>
            </li>
            <li>
              <A href="#3">
                3.<span className="pl-3">Determinar el orden de juego</span>
              </A>
            </li>
          </ul>
          <H4 href="#on-your-turn">EN TU TURNO</H4>
          <ul className="pl-4">
            <li>
              <A href="#4">
                4.<span className="pl-3">Completa una acción</span>
              </A>
            </li>
            <ul className="pl-4">
              <li>
                <A href="#4a">
                  A.<span className="pl-3">Mover un hexágono</span>
                </A>
              </li>
              <li>
                <A href="#4b">
                  B.<span className="pl-3">Jugar la carta del palo</span>
                </A>
              </li>
              <li>
                <A href="#4c">
                  C.<span className="pl-3">Intentar un gran golpe</span>
                </A>
              </li>
              <li>
                <A href="#4d">
                  D.<span className="pl-3">Salir del búnker</span>
                </A>
              </li>
              <li>
                <A href="#4e">
                  E.<span className="pl-3">Resetear tu mano</span>
                </A>
              </li>
            </ul>
            <li>
              <A href="#5">
                5.<span className="pl-3">Descartar palo.</span>
              </A>
            </li>
          </ul>
          <H4 href="#reaching-the-green">ALCANZAR EL GREEN</H4>
          <ul className="pl-4">
            <li>
              <A href="#6">
                6.<span className="pl-3">Ganar banderas</span>
              </A>
            </li>
            <li>
              <A href="#7">
                7.
                <span className="pl-3">Fichas de puntuación "hoyo en uno"</span>
              </A>
            </li>
            <li>
              <A href="#8">
                8.
                <span className="pl-3">Mueve a todos los jugadores al siguiente hoyo</span>
              </A>
            </li>
            <li>
              <A href="#9">
                9.
                <span className="pl-3">Eliminar cualquier efecto de los hazards </span>
              </A>
            </li>
          </ul>
        </GreenPanel>
        <H3 id="selecting-clubs" href="#selecting-clubs">
          SELECCIONAR LOS PALOS
        </H3>
        <H4 id="1" href="#1">
          1. RESETEAR LAS MANOS VACÍAS
        </H4>
        <P>Si no tienes palos en la mano, saca todos los palos descartados en tu mano.</P>
        <P>
          Si tienes un palo en la mano que no puedes usar, también puedes resetear tu mano. Por
          ejemplo, tienes el driver y no estás en el tee de salida.
        </P>
        <P>
          Si juegas con el lado A de Dorian, solo puedes resetear tus palos antes de dar el primer
          golpe de un hoyo.
        </P>
        <H4 id="2" href="#2">
          2. SELECCIONAR PALO
        </H4>
        <P>Cada jugador selecciona un palo de su mano y lo coloca boca abajo delante de ellos.</P>
        <P>
          Cuando todos los jugadores hayan hecho esto, revelan simultáneamente todos los palos. Una
          vez hecho esto, los jugadores no pueden cambiar de opinión.
        </P>
        <P>
          El jugador con Bunjil debe usar su poder antes de colocar un palo boca abajo. Si Bunjil
          está en su lado B y expone una carta de tiro, los jugadores que han colocado un palo boca
          abajo pueden cambiar de opinión.
        </P>
        <P>
          El jugador con Slick Rikesh con el lado A puede usar su poder después de la revelación y
          nombrar el palo de otro jugador para usarlo.
        </P>
        <H4 id="3" href="#3">
          3. DETERMINAR EL ORDEN DE JUEGO
        </H4>
        <P>
          Utiliza el orden de la iniciativa de cada palo para determinar el orden de juego. El palo
          con la menor iniciativa va primero. Puedes encontrar la iniciativa en la esquina superior
          izquierda de la carta del palo.
        </P>
        <P>
          Si Slick Rikesh está usando el palo de otro jugador, Slick Rikesh juega inmediatamente
          después del otro jugador.
        </P>
        <P>
          Si Swift está en su lado A, gana cualquier empate de iniciativa. Si Swift está en su lado
          B, pierde cualquier empate de iniciativa.
        </P>
        <P>
          Si Swift empata con Slick Rikesh que está pidiendo prestado un palo, gana en su lado A y
          pierde en su lado B.
        </P>
        <P>
          <em>
            Mirando los palos de abajo, el Híbrido 8 tiene una iniciativa de 5, y ese jugador va
            primero. El siguiente jugador es la persona con el Hierro 6 (iniciativa 12). El último
            jugador en salir es la persona que jugó la Madera 5 (iniciativa 20).
          </em>
        </P>
        <div className="flex flex-col md:flex-row justify-evenly items-center pt-4">
          <div>
            <img src={hybrid8} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron6} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={wood5} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H3 id="on-your-turn" href="#on-your-turn" style={{ breakBefore: "page" }}>
          EN TU TURNO
        </H3>
        <P>Siguiendo el orden de juego, cada jugador sigue su turno.</P>
        <H4 id="4" href="#4">
          4. COMPLETA UNA ACCIÓN
        </H4>
        <P>
          Cuando sea tu turno, realiza <strong>una</strong> de las siguientes acciones:
        </P>
        <ul className={ul}>
          <li>A. Mover un hexágono</li>
          <li>B. Jugar la carta del palo</li>
          <li>C. Intentar un gran golpe</li>
          <li>D. Salir del búnker</li>
          <li>E. Resetear tu mano</li>
        </ul>
        <P>Después de que hayas completado tu turno, descartas tu palo.</P>
        <H4 id="4a" href="#4a">
          4.a) Muévete un hexágono
        </H4>
        <P>
          A menos que estés en un búnker, tienes la opción de moverte un hexágono. Pon a tu golfista
          en uno de los hexágonos adyacentes. No puedes moverte a un hexágono con un hazard de árbol
          o fuera del tablero. Ver&nbsp;
          <A href="#hazards">HAZARDS Y OTRAS PIEZAS</A>.
        </P>
        <H4 id="4b" href="#4b">
          4.b) JUGAR CARTA DE PALO
        </H4>
        <P>Dependiendo del palo, podrás hacer una de las siguientes cosas:</P>
        <ul className={ul}>
          <li>Saca una carta del mazo de tiros que se muestra en la carta</li>
          <li>Saca una carta de uno de los dos mazos de tiros que se muestran</li>
          <li>Saca 2 cartas del mazo de tiros que se muestra en la carta</li>
          <li>Mueve tu golfista el número de hexágonos en línea recta</li>
        </ul>
        <P>
          La opción que tienes está escrita en cada palo. Jugar una carta de palo implica tres
          pasos: robar, decidir, mover.
        </P>
        <P>
          Para la mayoría de los palos, sacas tus cartas de tiros, decides en qué dirección golpear
          y luego mueves tu golfista en ese orden. Cuando se saca de la baraja de chip, hay que
          decidir la dirección antes de sacar una carta. Ver <A href="#chipping">CHIPEAR</A> en la
          página 13. Cuando se juega una madera, no se sacan cartas, ya que el palo siempre se mueve
          a una distancia determinada en línea recta.
        </P>
        <H5>Saca una carta de un solo tiro</H5>
        <P>
          La mayoría de los palos te permiten sacar una carta de un mazo de un solo tiro. Algunos
          palos te dan varios mazos para elegir, pero aun así solo puedes robar una carta.
        </P>
        <P>
          Escoge el mazo y saca la carta de arriba. Si la carta que se ha sacado tiene efectos
          especiales (súper, corto o barajado), entonces sigue las instrucciones escritas en la
          carta de tiro. Coloca cada carta de tiro adicional que saques encima de la anterior. Una
          vez que hayas terminado de robar, usa la carta de tiro para saber adónde mover tu
          golfista.
        </P>
        <P>
          Para más información sobre las cartas especiales, ver&nbsp;
          <A href="#super-short-wild">CARTAS SÚPER, CORTA, DE BARAJADO Y COMODINES</A>.
        </P>
        <P>
          Si la baraja está vacía cuando tengas que robar, baraja el montón de descartes y dale la
          vuelta para formar un nuevo mazo. No barajes las cartas que sacaste este turno en el nuevo
          mazo.
        </P>
        <P>
          Si juegas con Lucky, puedes lanzar tu habilidad y volver a sacar una carta en este punto.
        </P>
        <H5>Robando cartas de múltiples tiros</H5>
        <P>Al robar dos cartas de tiro, puedes elegir cuál de las cartas de tiro quieres usar.</P>
        <P>
          Coloca cada carta de tiro una al lado de la otra. Si una de las cartas de tiro te pide que
          saques más cartas (súper, corto, barajado), puedes elegir seguir las instrucciones de esas
          cartas y colocar las nuevas cartas sobre la que requirió robar carta. Cuando termines de
          robar, debes elegir una de las cartas superiores visibles. Usas la carta seleccionada para
          mover a tu golfista.
        </P>
        <Example>
          <P>
            En el siguiente ejemplo, Gustav ha terminado sacando 3 cartas de tiro. Debes elegir una
            de las dos cartas visibles de arriba.
          </P>
          <div className="flex flex-row justify-evenly items-start w-full">
            <div style={{ paddingTop: 50 }}>
              <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
            </div>
            <div
              style={{
                maxHeight: 275,
                paddingTop: 50,
                overflow: "hidden",
                width: "60%",
              }}
            >
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <img src={deck3Super} style={{ maxHeight: 200, height: 200 }} />
              </div>
              <div style={{ position: "relative", top: -235, left: 35 }}>
                <img src={deck4Straight} style={{ maxHeight: 200, height: 200 }} />
              </div>
            </div>
          </div>
        </Example>
        <H5>Mueve a tu golfista</H5>
        <P>
          El plan de vuelo que aparece en tu carta de tiro indica el recorrido de la bola. El icono
          del golfista en la carta de tiro es donde tu golfista está al comienzo del tiro. Mueve a
          tu golfista por los hexágonos numerados hasta llegar al hexágono final naranja. No tienes
          que golpearlo hacia el green o a lo largo de la calle.
        </P>
        <P>
          Si tu plan de vuelo pasa por un hexágono con árboles cercados, debes detenerte antes de
          entrar en cualquier hexágono con los árboles. Tu bola ha golpeado los árboles y se ha
          detenido.
        </P>
        <P>
          Si aterrizaste en un hazard (búnker, lago), sigue las reglas de la página&nbsp;
          <A href="#hazards">HAZARDS Y OTRAS PIEZAS</A>.
        </P>
        <AlwaysSideBySide right={roughHex} size={132}>
          <P>
            Si comenzaste tu turno en el hexágono rough (derecha), ve
            <A href="#rough">JUGANDO DESDE EL ROUGH”</A>.
          </P>
        </AlwaysSideBySide>
        <H5 style={{ breakBefore: "page" }}>Cambio de opinión</H5>
        <P>
          Si no has quitado el dedo de tu golfista, puedes alterar la dirección en la que has
          golpeado la bola.
        </P>
        <Example>
          <P>
            Clare se encuentra en una posición en la que no puede llegar al green de un solo golpe.
            Juega un 2 Hierro y saca una sola carta del mazo de tiros 4.
          </P>

          <P>
            La carta de tiro sacada se dobla hacia la izquierda. Tu mejor opción es golpear fuera de
            campo y usar la forma del tiro para aterrizar más allá del hazard de agua adyacente al
            green, en una excelente posición para tu próximo giro
          </P>
          <div style={{ maxHeight: 350, overflow: "hidden" }}>
            <img src={clareExample} style={{ maxHeight: 350 }} />
            <img
              src={shotCard}
              style={{
                maxHeight: 150,
                height: 150,
                position: "relative",
                top: -350,
                left: 250,
              }}
            />
          </div>
        </Example>
        <H4 id="4c" href="#4c" style={{ breakBefore: "page" }}>
          4.c) INTENTAR UN GRAN GOLPE
        </H4>
        <P>
          A veces necesitas más distancia. Puedes golpear tu palo más allá de las opciones que
          aparecen en la carta del palo. Para ello, en tu turno, anuncia al grupo que vas a realizar
          un GRAN GOLPE. Ahora coge una carta más de lo habitual, y todas las cartas deben ser de un
          mazo mayor que el mazo más alto que se muestra en tu carta de palos
        </P>
        <P>
          Después de que hayas sacado tus cartas, dáselas al jugador de tu izquierda. Ellos
          descartan una de las cartas de tiro. De las cartas que quedan, eliges una para jugar.
        </P>
        <P>
          Cualquier carta súper, corta o de volver a barajar se juega como de costumbre. Una Súper
          significa que tu Gran Golpe es aún más grande de lo esperado y una Corta probablemente te
          decepcionará durante tu tiempo de gran necesidad.
        </P>
        <P>
          No todos los palos permiten intentar un gran golpe, por ejemplo, los palos que se sacan
          del mazo de tiros 5. En la esquina superior de cada palo hay un símbolo que indica si
          puedes usarlo para un gran golpe. En el siguiente ejemplo, el Driver tiene un icono de No
          Gran Golpe mientras que el Hierro 9 muestra que puedes hacer un Gran Golpe.
        </P>
        <div className="flex flex-r0ow justify-evenly items-center">
          <div>
            <img src={driver} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron9} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>

        <GreenPanel>
          <H3>Grandes Golpes</H3>
          <SideBySide right={iron4} size={200}>
            <P>
              Si tu palo te permite robar de los mazos de tiro 3 y 4, entonces puedes robar dos
              cartas del mazo de tiro 5. No puedes robar dos cartas del mazo de tiro 4.
            </P>
          </SideBySide>
          <SideBySide right={iron3} size={200}>
            <P>
              Si tu carta de palos te permite sacar 2 cartas, entonces debes robar 3 cartas. En este
              caso, el otro jugador descartará 1 y tú elegirás de los 2 restantes.
            </P>
          </SideBySide>
        </GreenPanel>
        <H4 id="4d" href="#4d" style={{ breakBefore: "page" }}>
          4.D) SALIR DE BUNKERS
        </H4>
        <P>
          Cuando caes en un búnker, debes salir antes de continuar el juego. Antes de robar una
          carta de tiro, debes decidir la dirección en la que esperas moverte. Esta regla es la
          misma que la de chipear (ver <A href="#chipping">CHIPEAR</A>). El hexágono al que elegiste
          moverte es tu hexágono objetivo.
        </P>
        <P>
          Sacas una carta de un solo tiro de uno de los mazos que se muestran en la carta. En lugar
          de mirar la trayectoria de vuelo de la carta de tiro, mira el icono del resultado del
          búnker en la esquina inferior derecha.
        </P>
        <P>A continuación, se muestran cuatro resultados. De izquierda a derecha son estos:</P>
        <ul className="list-disc list-inside">
          <li>permanece en el búnker</li>
          <li>muévete a la izquierda de tu hexágono objetivo</li>
          <li>muévete a tu hexágono objetivo</li>
          <li>muévete a la derecha de tu hexágono objetivo</li>
        </ul>
        <div className="p-4 flex justify-center items-center">
          <img src={bunkerOutcomes} width={300} />
        </div>
        <P>
          Si sales del búnker, mueve a tu golfista al hexágono determinado por el icono del búnker.
        </P>
        <P>
          Cuando el palo que has elegido para jugar es el sand wedge, consideras el búnker como
          rough. No es necesario realizar la acción de salir del búnker. Tener el sand wedge en la
          mano o tu montón de cartas descartadas, no te protege de los búnkers.
        </P>
        <Example>
          <P>
            Sebastián se encuentra en un búnker (A). Declara que va a golpear hacia el green
            (flecha).
          </P>
          <P>
            Saca una carta de tiro, y el resultado revelado del búnker muestra que la bola va hacia
            la derecha.
          </P>
          <P>Sigue el icono del búnker y se mueve a la derecha (B) de la dirección que declaró.</P>
          <div style={{ height: 325, overflow: "hidden" }} className="p-4">
            <img
              src={exitBunkerShotCard}
              width={150}
              style={{ position: "relative", top: 100, left: 0 }}
            />
            <img
              src={exitBunkerExample}
              width={300}
              style={{ position: "relative", top: -225, left: 0 }}
            />
            <div
              style={{
                position: "relative",
                top: -249,
                left: 98,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </Example>
        <H4 id="4e" href="#4e" style={{ breakBefore: "page" }}>
          4.e) RESETEAR TU MANO
        </H4>
        <P>
          Puedes elegir no golpear la bola y resetear tu mano. Devuelve todos tus palos descartados
          a tu mano. Si estás jugando el lado A de Dorian, no puedes resetear tu mano en este
          momento.
        </P>
        <H4 id="5" href="#5">
          5. Descarte de palos
        </H4>
        <P>
          Tu turno termina aquí. Coloca el palo que usaste en tu montón de descartes. Si juegas una
          carta que nunca expira, devuélvela a tu mano. P. ej., Driver de slugger o palo de Dorian
          con lado A. Si estás jugando el lado B de Slick Rikesh o el lado B de Dorian, salta este
          paso.
        </P>
        <H3 id="reaching-the-green" href="#reaching-the-green">
          Alcanzando el Green
        </H3>
        <H4 id="6" href="#6">
          6. GANAR BANDERAS
        </H4>
        <P>
          Cuando un jugador llega al green, toma la bandera. Si varios jugadores llegan al green en
          la misma ronda, un jugador se lleva la bandera y los demás una ficha de 1 punto.
        </P>
        <H4 id="7" href="#7">
          7. FICHAS de puntuación "hoyo en uno"
        </H4>
        <P>
          Si anotaste un hoyo en uno, toma una ficha de hoyo en uno. Un hoyo en uno se anota en tu
          primer turno del hoyo cuando golpeas desde el tee de salida y caes en el green.
        </P>
        <H4 id="8" href="#8">
          8. PASAR AL SIGUIENTE HOYO
        </H4>
        <P>
          Después de que los jugadores hayan recibido sus fichas verdes, mueven a todos los
          jugadores al tee de salida del siguiente hoyo.
        </P>
        <H4 id="9" href="#9">
          9. ELIMINAR CUALQUIER EFECTO DE HAZARD
        </H4>
        <P>
          A los jugadores que estaban a punto de perder un turno debido a un hazard de agua se les
          quita este estatus.
        </P>
        <H3>Ganar el juego</H3>
        <P>
          Al final del campo, cada jugador cuenta la cantidad de banderas y fichas de 1 punto que
          tiene. Cada bandera y ficha vale un punto. El jugador con más puntos gana.
        </P>
        <P>
          En caso de empate, se cuentan las fichas de "hoyo en uno". El jugador con más fichas de
          hoyo en uno gana el empate. Si los jugadores siguen empatados, esos jugadores comparten la
          victoria.
        </P>
      </Section>
      <Section id="rough" title="JUGANDO DESDE EL ROUGH">
        <P>Cuando empiezas tu turno en una pieza rough, tu tiro viaja una distancia reducida.</P>
        <SideBySide right={wood5} size={250}>
          <P>
            Para los palos que van a una distancia fija (maderas), esto se representa por un segundo
            hexágono en tu palo con un símbolo de rough. Abajo: el Madera 5 va 2 hexágonos cuando se
            juega desde el rough y 3 desde una calle.
          </P>
        </SideBySide>
        <SideBySide right={deck5right} size={200}>
          <P>
            Para los palos que se sacan de los mazos de tiro, hay un símbolo de rough en la carta de
            tiro (ver derecha). Usa esto en lugar del hexágono naranja para determinar el destino
            final de tu golfista.
          </P>
        </SideBySide>
        <P>
          Se considera rough a cualquier pieza que no tenga calle. Se considera que un hazard de
          agua es rough (después de haber perdido el turno). Un hazard de agua en una calle (
          <A href="#hazards">Hazards y otras piezas"</A>) se considera calle. La mayor parte de un
          campo de golf es rough. El consejo general es que no te metas en esto.
        </P>
        <SideBySide RightComponent={HybridCardsSpanish} size={200}>
          <P>
            <em>
              Ningún palo híbrido (Híbrido 3, Híbrido 5 e Híbrido 8) sufre penalizaciones al golpear
              desde el rough.
            </em>
          </P>
        </SideBySide>
        <SideBySide right={deckCStraight} size={200}>
          <P>
            <em>
              Todas las cartas de tiro sacadas de la baraja de chip no sufren penalizaciones cuando
              se golpea desde el rough. No hay símbolos de rough en estas cartas (siempre se mueve 2
              hexágonos).
            </em>
          </P>
        </SideBySide>
        <Example>
          <P>Mackenzie (disco blanco) se encuentra en el rough.</P>
          <img src={roughExample1} width={300} />
          <P>Juega con un Hierro 2, y eso le permite sacar del Mazo de Tiros 4.</P>
          <div className="flex justify-center items-center w-full">
            <img src={deck4Left} width={StandardPortraitCardSize} />
          </div>
          <P>
            La carta de tiro se curva hacia la izquierda. Al trazar su movimiento, utiliza el
            símbolo de la carta de tiro para resolverlo.
          </P>
          <P>
            Debido a la reducida distancia, mueve dos hexágonos en línea recta y luego uno a la
            izquierda para caer donde se muestra el marcador del rough de la carta de tiro. En este
            ejemplo, ha caído de nuevo en la calle, una buena posición para su próximo tiro.
          </P>
          <img src={roughExample2} width={300} />
        </Example>
      </Section>
      <Section id="chipping" title="Chipear">
        <SideBySide right={deckC} size={StandardPortraitCardSize}>
          <P>
            El mazo de chip es diferente de los otros mazos de tiro. Estas cartas de tiro te dan la
            opción de pasar por encima de los árboles, no sufren penalización de rough y vienen con
            una restricción. Tienes que declarar la dirección en la que vas a chipear antes de robar
            otra carta.
          </P>
        </SideBySide>
        <H4>Declarar una dirección</H4>
        <P>
          Esta regla significa elegir cualquiera de las seis direcciones que salen del hexágono e
          informar a los demás jugadores. Puedes describirlo: "golpeo hacia el agua" o señalarlo. No
          puedes cambiar de dirección después de haber sacado una carta de tiro.
        </P>
        <H4>Robar cartas de tiro</H4>
        <P>Una vez que hayas declarado tu dirección, robas tus cartas como de costumbre.</P>
        <H4>Mueve a tu golfista</H4>
        <P>
          El principal cambio en el movimiento de tu golfista es que ya has declarado en qué
          dirección vas a golpear la bola. Mueve a tu golfista como de costumbre con una excepción.
        </P>
        <P>
          Si el hexágono adyacente a tu golfista es un árbol, chipeas sobre él y caes en el hexágono
          naranja de destino. Todavía no puedes caer en los árboles, si tu hexágono objetivo es un
          hazard de árboles, entonces caes en el hexágono sobre el que habrías chipeado. Si también
          es un hazard de árboles, tu golfista no se mueve.
        </P>
        <SideBySide right={lobWedge} size={StandardLandscapeCardSize}>
          <P>
            El lob wedge es un palo único en el que no necesitas declarar la dirección en la que vas
            a golpear la bola antes de jugarla.
          </P>
        </SideBySide>
        <Example>
          <P>
            Brock se encuentra en una posición incómoda con árboles que bloquean el progreso en la
            calle. Juegan con su fiel hierro 9 y señala la pieza a la que pretende chipear (flecha).
            Es un juego seguro, ya que la mayoría de las cartas de la baraja de chip, le pondrá en
            la calle.
          </P>
          <P>
            Luego, sacan una carta de tiro para ver qué les ha deparado el destino. Vale la pena, y
            obtienen una carta de tiro que deriva a la derecha y los pone de nuevo en la calle y en
            una posición excelente para su próximo tiro.
          </P>
          <div style={{ height: 350, overflow: "hidden" }}>
            <img src={chippingExample} width={350} />
            <img
              src={deckCRight}
              width={SmallPortraitCardSize}
              style={{ position: "relative", left: 0, top: -150 }}
            />
          </div>
        </Example>
      </Section>
      <Section id="super-short-wild" title="CARTAS SÚPER, CORTA, DE BARAJADO Y COMODINES">
        <H3>Súper y Corta</H3>
        <P>
          Con las cartas súper y cortas, las pones sobre la mesa y luego sacas una carta de tiro de
          reemplazo y la colocas sobre la parte superior de la carta súper o corta. Saca una carta
          de reemplazo del mazo que aparece en la carta súper o corta.
        </P>
        <P>
          Estas cartas se encadenan, así que es posible terminar de nuevo en el mazo del que
          partiste o pasar del mazo de tiro 2 al mazo de tiro 5.
        </P>
        <P>
          Un golpe súper de la baraja que resulta en un empate de una baraja que no sea la baraja de
          chip pierde su capacidad de pasar por encima de los árboles.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={superHit} width={StandardPortraitCardSize} />
          <img src={shortHit} width={StandardPortraitCardSize} />
        </div>
        <H3>De barajado</H3>
        <P>
          Cuando se saca una carta de barajar, se meten todos los descartes actuales de este mazo de
          tiro y se barajan. Pon esta carta sobre la mesa, y saca una carta de repuesto y colócala
          encima. Siempre saca la carta de reemplazo del mismo mazo.
        </P>
        <P>
          Las cartas que has robado hasta ahora en este turno, por ejemplo, cartas súper u otras
          cartas de palos que te permiten robar 2 cartas, no se vuelven a poner en el mazo.
        </P>
        <P>
          Si sacas una carta de barajado y no hay montón de descartes, no necesitas barajar el mazo.
          Todavía tienes que sacar una carta de reemplazo.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={shuffle} width={StandardPortraitCardSize} />
        </div>
        <H3 style={{ breakBefore: "page" }}>Comodines</H3>
        <P>
          Cuando robas una carta con múltiples resultados en ella, puedes elegir cuál quieres usar.
          Si una de las opciones está fuera de límites, entonces debes tomar la única opción que
          queda en juego.
        </P>
        <P>
          Ver <A href="#hazards">Hazards y otras piezas</A> para más información sobre fuera de
          límites.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={wildCard} width={StandardPortraitCardSize} />
        </div>
        <H3>CARTA DEL DRIVE MÁS LARGO</H3>
        <P>
          Cuando robes y juegues esta carta de tiro, guarda la carta de tiro más larga. Esta carta
          es tu recuerdo de que has hecho el trayecto más largo del juego y, si no ganas hoy, al
          menos te irás a casa con algo. No la carta, entiéndelo, esta se queda en la caja. Tienes
          la sensación de satisfacción de haber hecho el trayecto más largo.
        </P>
        <P>
          Esta carta de tiro viaja 6 hexágonos en línea recta. Se detiene en los árboles como lo
          hacen otras cartas de tiro.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={longestDriveCard} width={StandardPortraitCardSize} />
        </div>
      </Section>
      <Section id="hazards" title="HAZARDS Y OTRAS PIEZAS">
        <SideBySide title="Búnker" right={bunkerHex} size={SmallHex}>
          <P>
            El hazard de los búnkers se aplica a todos los turnos en los que empiezas en ese
            hexágono. Cuando estás en el búnker, tienes que salir. Ver&nbsp;
            <A href="#4d">“4.D) SALIR DE UN BÚNKER</A>.
          </P>
          <P>Puedes usar el sand wedge para tratar los bunkers como si fueran rough.</P>
        </SideBySide>
        <SideBySide title="Green" right={greenHex} size={SmallHex}>
          <P>
            Tu destino. Dependiendo del modo de juego, los jugadores que lleguen al green primero
            ganan la ronda. Cuando juegues desde el green de otro hoyo considera la calle de la
            pieza.
          </P>
        </SideBySide>
        <SideBySide title="Rough" right={roughHex} size={SmallHex}>
          <P>
            Los tirios desde este hexágono van un hexágono menos. Después de sacar tu tiro, busca el
            icono de rough en la carta de tiro. Este símbolo es donde cae tu bola. Ver&nbsp;
            <A href="#rough">JUGANDO DESDE EL ROUGH</A>.
          </P>
        </SideBySide>
        <div style={{ breakBefore: "page" }}>
          <SideBySide title="Fuera de los límites y CASA CLUB" right={clubhouse} size={SmallHex}>
            <P>
              Siempre que tu bola sale del campo, está fuera de los límites. La bola también se sale
              de los límites cuando se sale de los límites durante parte de su vuelo y regresa a una
              pieza. Cuando estás fuera de los límites, tu golfista se queda en el lugar donde se
              realiza el tiro. Devuelve el palo a tu mano; tu turno ha terminado.
            </P>
            <P>
              Caer en la casa club también se considera fuera de los límites a menos que, por
              supuesto, estés jugando en el 19.
            </P>
            <P>
              <em>
                Nota: Las piezas del campo con esquinas de diamante están en juego, puedes aterrizar
                en ellas.
              </em>
            </P>
          </SideBySide>
        </div>
        <div
          className="flex p-8 justify-center items-center"
          style={{ height: 300, overflow: "hidden" }}
        >
          <div className="pt-16">
            <img src={courseTile} width={CourseTileSize} />
            <div
              style={{
                position: "relative",
                top: -240,
                left: 35,
                borderRadius: 75,
                width: 75,
                height: 75,
                flex: 0,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H5>Variación</H5>
        <P>
          Creamos la regla de fuera de los límites porque la mayoría de los jugadores no podían
          navegar por el espacio de fuera de los límites sin una cuadrícula. Pero algunos jugadores
          pueden. Si todos los jugadores están de acuerdo en usar esta variación, entonces puedes
          permitir a los jugadores golpear a través de áreas que se salen del límite. Caer en un
          lugar fuera de los límites siempre es fuera de los límites.
        </P>
        <SideBySide title="Tee de salida" right={teeboxHex} size={SmallHex}>
          <P>
            El tee de salida es donde comienza cada hoyo. Estas piezas indican el par y cuántas
            piezas grandes del campo hay que añadir antes del green. Ve la última página del folleto
            de campos para más información sobre la construcción de campos.
          </P>
        </SideBySide>
        <SideBySide title="Árboles" right={treeHex} size={SmallHex}>
          <P>
            Los árboles detienen el vuelo de tu bola. Mueve tu bola un hexágono hacia atrás
            siguiendo la trayectoria de vuelo. Si el siguiente hexágono es un hazard, sigue las
            reglas para ese hazard.
          </P>
          <P>
            <em>
              Nota: solo los árboles con una valla a su alrededor detienen el vuelo de la bola.
            </em>
          </P>
        </SideBySide>
        <SideBySide title="Agua (Y calles con lagos)" RightComponent={WaterHazards} size={SmallHex}>
          <P>
            Los hazard de agua se aplican cuando la bola termina su vuelo en ese hexágono. Pierdes
            el próximo turno. Pon a tu golfista en el suelo. En tu siguiente turno, levanta a tu
            golfista y sáltate la ronda. En el turno siguiente, puedes jugar.
          </P>
          <P>
            Juega tu próximo tiro desde la pieza de agua. Juega con la pieza de agua como si fuera
            una calle con un lago. En ese caso, se considera que es una calle.
          </P>
          <P>The Shark ignores this hazard and plays the underlying tile.</P>
        </SideBySide>
      </Section>
      <Section id="golfers" title="Habilidades del golfista">
        <P>
          Hay ocho golfistas diferentes en 18 Holes, y cada uno de ellos aporta una experiencia
          diferente. Cada golfista tiene un lado A y un lado B. El lado A hace el juego más fácil, y
          el lado B hace el juego más difícil. Hay una A o una B en la esquina inferior derecha.
        </P>
        <H3>BUNJIL</H3>
        <SideBySide right={golfer2a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Inmediatamente antes de que Bunjil seleccione su palo para jugar, puede mirar la primera
            carta de un mazo una vez. Pon a Bunjil de lado (horizontal) para indicar que su poder se
            ha utilizado. El jugador no debe mostrar la carta a otros jugadores. Devuelve la carta
            boca abajo a la parte superior del mazo de tiros.
          </P>
          <P>Cuando el hoyo haya terminado, vuelve a poner a Bunjil en la orientación vertical.</P>
          <P>
            Bunjil solo puede buscar cartas que pueden robar legítimamente en los mazos de tiros.
          </P>
        </SideBySide>
        <SideBySide right={golfer2b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            Por su parte, Bunjil tiene que decidir entre quedarse con la carta de tiro y usarla en
            su próximo golpe o devolverla a la parte superior del mazo de tiro, boca arriba para que
            todos los jugadores la vean.
          </P>
          <P>
            Si Bunjil se queda con la carta, el jugador debe jugar un palo que pueda acceder a ese
            mazo de tiros.
          </P>
        </SideBySide>
        <H3>Dorian</H3>
        <SideBySide right={golfer8a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Después de repartir las cartas, antes del primer hoyo, el jugador con Dorian le dice al
            resto del grupo cuáles de sus palos nunca expiran. Siempre que uses este palo,
            devuélvelo a tu mano en lugar de descartarlo.
          </P>
          <P>
            Dorian no puede resetear sus palos durante un hoyo, pero puede resetearlos al final del
            hoyo si su mano solo contiene su palo que nunca expira (y cumple las reglas para un
            reseteo de mano).
          </P>
        </SideBySide>
        <SideBySide right={golfer8b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            Dorian nunca se deshace de sus palos después de usarlos. Dorian solo puede coger tres
            palos durante el reparto.
          </P>
        </SideBySide>
        <H3>Iron Jane</H3>
        <SideBySide right={golfer3a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Antes de repartir las cartas, el jugador con Iron Jane selecciona sus palos de entre los
            disponibles. Baraja las cartas restantes y repártelas a los demás jugadores.
          </P>
        </SideBySide>
        <SideBySide right={golfer3b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            Iron Jane por el lado A solo puede llevar cuatro palos. Iron Jane no puede coger dos del
            mismo palo.
          </P>
        </SideBySide>
        <H3>Lucky</H3>
        <SideBySide right={golfer6a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Durante el juego, una vez por mano, Lucky puede volver a sacar una carta de tiro. Para
            ello, el jugador con Lucky se lo dice al grupo de juego y luego pone a Lucky de lado
            (horizontal) para indicar que su poder se ha utilizado. El jugador entonces saca una
            nueva carta de tiro del mismo mazo que la anterior. Lucky debe jugar la nueva carta.
          </P>
          <P>Mientras que Lucky está en horizontal no puede usar este poder.</P>
          <P>Al resetear su mano, vuelve a poner a Lucky en vertical.</P>
        </SideBySide>
        <SideBySide right={golfer6b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            En cuanto a la ventaja de usar la habilidad de Lucky, el jugador descarta uno de sus
            otros palos. Si el jugador no tiene otros palos, no puede usar el poder de Lucky.
          </P>
          <P>Solo puedes usar el poder de Lucky una vez por hoyo..</P>
        </SideBySide>
        <H3>Slick Rikesh</H3>
        <SideBySide right={golfer4a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Después de que todos los jugadores hayan revelado sus cartas de palos, Slick Rikesh
            puede usar el palo elegido por otro jugador. La iniciativa del palo de Slick Rikesh es
            la misma que la del propietario original y juega inmediatamente después. Slick Rikesh
            solo puede usar su poder una vez por hoyo.
          </P>
          <P>
            Pon a Slick Rikesh de lado (horizontal) para indicar que su poder se ha utilizado.
            Vuelve a ponerlo en vertical después de cada hoyo.
          </P>
        </SideBySide>
        <SideBySide right={golfer4b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            El jugador con Slick Rikesh solo escoge una carta durante el reparto y puede escoger ese
            palo de cualquier ronda del reparto.
          </P>
          <P>
            Durante el juego, Slick Rikesh jugará con su palo como de costumbre (para determinar la
            iniciativa) y podrá entonces seleccionar cualquier palo descartado de otro jugador para
            utilizarlo. Si no hay palos descartados, Slick Rikesh debe jugar con su palo.
          </P>
        </SideBySide>
        <H3>Slugger</H3>
        <SideBySide right={golfer5a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            El bateador debe llevar el driver. Retira esta carta del mazo antes del reparto de palos
            y dásela al jugador con Slugger. Slugger seleccionará cuatro palos durante el reparto,
            saltándose el último turno.
          </P>
          <P>Durante el juego, nunca descartes el driver. Devuélvelo a tu mano.</P>
        </SideBySide>
        <SideBySide right={golfer5b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            Slugger con el lado A debe usar una madera o un driver en todos los tees de salida. Sí,
            incluyendo pares 3.
          </P>
        </SideBySide>
        <H3>SWIFT</H3>
        <SideBySide right={golfer7a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Durante el paso de determinar el orden de juego, resta uno de la iniciativa del palo
            jugado por Swift. Si la iniciativa de Swift se vincula con otro jugador, Swift va
            primero.
          </P>
        </SideBySide>
        <SideBySide right={golfer7b} size={StandardPortraitCardSize}>
          <H4>Lado B</H4>
          <P>
            Durante el paso de determinar el orden de juego, resta uno de la iniciativa del palo
            jugado por Swift. Si la iniciativa de Swift se relaciona con otro jugador, Swift va tras
            ese jugador.
          </P>
        </SideBySide>
        <H3>The Shark</H3>
        <SideBySide right={golfer1a} size={StandardPortraitCardSize}>
          <H4>Lado A</H4>
          <P>
            Los hazards de agua no son de interés para The Shark, incluyendo los de las calles.
            Trátalos como a la pieza subyacente.
          </P>
        </SideBySide>
        <H4>Lado B</H4>
        <SideBySide right={golfer1b} size={StandardPortraitCardSize}>
          <P>Por el lado A más The Shark no se puede llevar wedges.</P>
          <P>Las wedges incluyen el pitching wedge, el sand wedge y el lob wedge.</P>
          <P>
            Durante la fase de reparto de cartas, un jugador que tenga a The Shark debe pasar si los
            únicos palos disponibles son wedges. The Shark puede elegir entre las cartas que quedan
            después del reparto.
          </P>
        </SideBySide>
      </Section>
      <Section id="game-modes" title="MÚLTIPLES MANERAS DE JUGAR">
        <P>
          18 Holes, como el golf, tiene una variedad de modos de juego diferentes. El método
          primario descrito en este libro mantiene a todos los jugadores compitiendo por cada hoyo.
          El golf es un juego difícil en el que puede costarte recuperarte si te quedas atrás. Si te
          gusta ese tipo de juego o quieres una experiencia de golf más realista, entonces puede que
          te interese el Golf Racing, Stroke Play o Stableford.
        </P>
        <P>
          Si tu grupo prefiere los juegos que mantienen a todos compitiendo por cada punto, entonces
          prueba Match Play y Skins.
        </P>
        <P>
          Algunas variaciones se alejan un poco más de la calle. Son para personas que quieren una
          experiencia de golf menos tradicional. Las variaciones de Chaos Golf y Golf Kelly encajan
          en esta descripción.
        </P>
        <P>
          Si quieres una experiencia en solitario, prueba Solo Chaos Golf, Stableford o Stroke Play.
        </P>
        <P>
          Si quieres un juego para dos jugadores, prueba Chaos Teams: nuestra experiencia dedicada
          para dos jugadores. O, uno de Match Play, Skins, Stroke Play, Stableford o Golf Racing.
        </P>
        <P>
          Cada modo de juego viene con una sección que describe el modo de juego, cuántos jugadores
          admite y cuándo podría interesarte este juego. A la derecha, tenemos la sección de Match
          Play, el conjunto de reglas descritas hasta ahora.
        </P>
        <P>Índice de variaciones:</P>
        <ul className="list-disc list-inside">
          <li className={link}>
            <Link to={ModesChaosGolf}>Chaos Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosTeams}>Chaos Teams</Link>
          </li>
          <li className={link}>
            <Link to={ModesGolfRacing}>Golf Racing</Link>
          </li>
          <li className={link}>
            <Link to={ModesKellyGolf}>Kelly Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesMatchPlay}>Match Play</Link>
          </li>
          <li className={link}>
            <Link to={ModesSkins}>Skins</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
          </li>
          <li className={link}>
            <Link to={ModesStableford}>Stableford</Link>
          </li>
          <li className={link}>
            <Link to={ModesStrokePlay}>Stroke Play</Link>
          </li>
        </ul>
      </Section>
    </div>
  );
};
