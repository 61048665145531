import React from "react";
import download from "./download.png";

export const SingleOptionPanel = ({ title, dims, note = [], href = null, label = "download" }) => {
  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg p-4 flex flex-row justify-between items-center">
        <div className="flex-1">
          <h3 className="bg-gray-50 dims-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
            {title}
          </h3>
          <p className="text-sm leading-5 font-medium text-gray-900">{dims}</p>
          {note.map((text) => {
            return (
              <p key={text} className="text-xs leading-5 font-medium text-gray-900 italic pt-2">
                {text}
              </p>
            );
          })}
        </div>
        {href && (
          <div className="flex-0 w-24 align-center justify-center flex">
            <a
              href={href}
              target="_blank"
              className="text-center hover:opacity-50 flex flex-col items-center justify-center"
            >
              <img className="w-8 h-8" src={download} alt="download" />
              <p className="bg-gray-50 dims-left text-xs text-gray-500 uppercase">{label}</p>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const TwoOptionPanelBase = ({
  title,
  dims = "",
  note = [],
  option1text,
  option1href,
  option2text,
  option2href,
}) => {
  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg p-4 flex flex-row justify-between items-center">
        <div className="flex-1">
          <h3 className="bg-gray-50 dims-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
            {title}
          </h3>
          <p className="text-sm leading-5 font-medium text-gray-900">{dims}</p>
          {note.map((text) => {
            return (
              <p key={text} className="text-xs leading-5 font-medium text-gray-900 italic pt-2">
                {text}
              </p>
            );
          })}
        </div>
        {option1href && (
          <div className="flex-0 w-24 align-center justify-center flex">
            <a href={option1href} className="text-center hover:opacity-50 flex flex-col items-center justify-center">
              <img className="w-8 h-8" src={download} alt="download" />
              <p className="bg-gray-50 dims-left text-xs text-gray-500 uppercase">{option1text}</p>
            </a>
          </div>
        )}
        {option2href && (
          <div className="flex-0 w-24 align-center justify-center flex">
            <a href={option2href} className="text-center hover:opacity-50 flex flex-col items-center justify-center">
              <img className="w-8 h-8" src={download} alt="download" />
              <p className="bg-gray-50 dims-left text-xs text-gray-500 uppercase">{option2text}</p>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const TwoOptionPanel = ({ title, dims, note = [], bleed, noBleed }) => {
  return (
    <TwoOptionPanelBase
      title={title}
      dims={dims}
      note={note}
      option1text="bleed"
      option1href={bleed}
      option2text="no bleed"
      option2href={noBleed}
    />
  );
};
