import React from "react";
import { Twitter, Facebook } from "react-social-sharing";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { withUserConfiguration } from "../user/UserConfigurationProvider";
import { CourseCard } from "./CourseCard";
import { withAllCourses } from "./PublicCoursesProvider";

const UserCoursesBase = ({ showCourse, courses }) => {
  const designerName = courses && courses[0].designer;
  const shareMessage = `View all courses by ${designerName}\r\n\r\n#18holesgame\r\n\r\n`;

  return (
    <>
      <SiteNavigation />
      <div className="p-4">
        <div className="flex-row flex-1 flex justify-between items-center">
          <p className="text-4xl uppercase pl-1 pb-0">{designerName}</p>
          <div>
            <Twitter
              solid
              small
              message={shareMessage}
              link={window.location}
            />
            <Facebook
              solid
              small
              message={shareMessage}
              link={window.location}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-start">
          {courses.map((course) => (
            <CourseCard
              isPrivate={false}
              key={course.id}
              title={course.displayName}
              designer={course.designer}
              holes={course.holes}
              id={course.id}
              image={showCourse ? course.course : course.guide}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const FilteredCourses = ({ courses, username, showCourse }) => {
  const userCourses = courses.filter((course) =>
    course.id.startsWith(`${username}/`)
  );

  if (userCourses.length === 0) {
    return null;
  }

  return <UserCoursesBase courses={userCourses} showCourse={showCourse} />;
};

const RouteHandler = ({
  match: {
    params: { username },
  },
  courses,
  showCourse,
}) => (
  <FilteredCourses
    courses={courses}
    username={username}
    showCourse={showCourse}
  />
);

const userCourses = withUserConfiguration(withAllCourses(RouteHandler));

export default userCourses;
