import React, { useContext, useEffect, useState } from "react";
import { getPublicCourses } from "../services/public";

/**
 * @type {CourseFilter[]}
 */
const FilterIds = [
  "short",
  "full",
  "chaos",
  "solo",
  "easy",
  "medium",
  "hard",
  "18-cards",
  "coastlines",
  "coastlines-exclusive",
];

const Context = React.createContext({
  courses: [],
  coursesLoaded: false,
  requestFinished: true,
  filters: FilterIds.reduce((obj, filter) => ({ ...obj, [filter]: false }), {}),
  toggleFilter: () => {},
});

export const withAllCourses = (Component) => (props) => {
  const { courses, coursesLoaded } = useContext(Context);

  return <Component {...props} courses={courses} coursesLoaded={coursesLoaded} />;
};

export const withFilter = (Component) => (props) => {
  const { toggleFilter, filters } = useContext(Context);

  return <Component {...props} toggleFilter={toggleFilter} filters={filters} />;
};

/**
 * @type {Record<CourseFilter, *>}
 */
const Filters = {
  short: (enabled) => (course) => enabled ? course.holes < 18 : true,
  full: (enabled) => (course) => enabled ? course.holes === 18 : true,
  chaos: (enabled) => (course) => enabled ? false : true,
  solo: (enabled) => (course) => enabled ? course.solo : true,
  easy: (enabled) => (course) => enabled ? false : true,
  medium: (enabled) => (course) => enabled ? false : true,
  hard: (enabled) => (course) => enabled ? false : true,
  "18-cards": (enabled) => (course) => enabled ? (course.tags || []).includes("18 Cards") : true,
  coastlines: (enabled) => (course) => enabled ? (course.tags || []).includes("coastlines") : true,
  "coastlines-exclusive": (enabled) => (course) =>
    enabled ? (course.tags || []).includes("coastlines-exclusive") : true,
};

const PublicCoursesProvider = ({ children }) => {
  const [publicCourses, setPublicCourses] = useState([]);
  const [courses, setFilteredCourses] = useState([]);
  const [filters, setFilter] = useState(
    FilterIds.reduce((obj, filter) => ({ ...obj, [filter]: false }), {})
  );
  const [coursesLoaded, setCoursesLoaded] = useState(false);
  const [requestFinished, setRequestFinished] = useState(true);

  useEffect(() => {
    setRequestFinished(false);

    getPublicCourses().then((courses) => {
      courses.sort((a, b) => {
        var nameA = a.displayName.toUpperCase();
        var nameB = b.displayName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setPublicCourses(courses);
      setCoursesLoaded(true);
      setRequestFinished(true);
    });
  }, []);

  function toggleFilter(filter) {
    setFilter((current) => ({ ...current, [filter]: !current[filter] }));
  }

  useEffect(() => {
    const filtered = FilterIds.reduce((courses, filter) => {
      return courses.filter(Filters[filter](filters[filter]));
    }, publicCourses);

    setFilteredCourses(filtered);
  }, [publicCourses, filters]);

  return (
    <Context.Provider value={{ courses, coursesLoaded, requestFinished, filters, toggleFilter }}>
      {children}
    </Context.Provider>
  );
};

export default PublicCoursesProvider;
