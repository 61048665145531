import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P, ul } from "../../brand/typography";
import { FromMatchPlay } from "./FromMatchPlay";
import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FrenchModeSkins = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Skins</H1>
      <P>
        C'est comme le Match Play, sauf que chaque green vaut 1, 2 ou 5 points.
        Les joueurs doivent tenir compte de la valeur de chaque trou lorsqu'ils
        choisissent leurs clubs et effectuent leurs coups. Les égalités sur un
        trou donnent un jackpot.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>2 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous aimez le Match Play et vous voulez ajouter une autre dimension au
        jeu. Le processus de pioche et de sélection des clubs change, car les
        joueurs essaient d'avoir leurs meilleurs clubs disponibles pour avoir
        les trous qui ont le plus de valeur.
      </P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Le joueur qui a le plus de points gagne. Les greens valent 1, 2 ou 5
        points en fonction de la valeur du jeton dessus. Plusieurs joueurs
        marquent des points s'ils atteignent tous le green au cours du même
        tour.
      </P>

      <H3>Variations des règles</H3>
      <FromMatchPlay />

      <H4>Configuration</H4>
      <P>
        Faites-ceci avant la sélection du club :
        <ul className={ul}>
          <li>Placez un jeton de 5 points sur le 18e green.</li>
          <li>
            Donnez ensuite à chaque joueur un jeton de 5 points et demandez-lui
            de choisir un green sur lequel le placer. 
          </li>
          <li>
            Retournez tous les jetons de 1 et 2 points. Mélangez. Choisissez un
            jeton pour chaque green qui n'en a pas. Placez les jetons à
            l'endroit pour que tous les joueurs puissent voir la valeur de
            chaque green.
          </li>
        </ul>
      </P>
      <H4>Marquage des points</H4>
      <P>
        Un joueur qui atteint le green réclame le jeton qui s’y trouve. Si
        plusieurs joueurs atteignent le green au cours du même tour, déplacez
        tous les jetons de score vers le green suivant. Ce sera le jackpot !
      </P>
      <P>
        Le joueur qui a le plus de points gagne. Un score d’égalité se départage
        par des tours d'élimination.
      </P>

      <H4>Élimination</H4>
      <P>
        Si le 18e trou est à égalité et que le résultat décide du vainqueur,
        déplacez les jetons de score du 18e au 1er green. Si le résultat ne
        détermine pas le vainqueur, c'est le joueur en tête qui gagne.
      </P>
      <P>
        Tous les joueurs à égalité sont déplacés vers le 1er tee box.
        Réinitialisez la main de chacun et supprimez tout effet d’obstacle.
      </P>
      <P>
        Jouez normalement, le gagnant remporte le jackpot restant et le jeu se
        termine lorsque le jackpot est réclamé.
      </P>
      <P>
        En cas d'égalité, seuls les joueurs à égalité passent au trou suivant.
        Les autres joueurs sont éliminés.
      </P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <ul className={ul}>
        <li>Jetons à 5 points</li>
        <li>Jetons à 2 points</li>
        <li>Jetons à 1 point</li>
      </ul>
    </>
  );
};

const EnglishModeSkins = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Skins</H1>
      <P>
        It’s like Match Play, except each green is worth either 1, 2, or 5
        points. Players need to consider the value of each hole when picking
        their clubs and taking their shots. Tied holes jackpot.
      </P>

      <H3>PLAYS</H3>
      <P>2 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You like match play, and you want to add another dimension. The club
        draft and club selection process change as players try to have their
        best clubs available for tackling the high-value holes.
      </P>

      <H3>HOW TO WIN</H3>
      <P>
        The player with the most points wins. Greens are worth 1, 2 or 5 points
        based on the value of the token on it. Multiple players score if they
        all reach the green on the same turn.
      </P>

      <H3>Rule Variations</H3>
      <FromMatchPlay />

      <H4>Setup</H4>
      <P>
        Do this before club selection.
        <ul className={ul}>
          <li>Place a 5 point token on the 18th green.</li>
          <li>
            Give each player a 5 point token and have them select a green to
            place it on.
          </li>
          <li>
            Turn all the 1 and 2-point tokens point-side down. Shuffle. Select a
            token for each green without one. Place the tokens point side up so
            all players can see the value of each green.
          </li>
        </ul>
      </P>

      <H4>Scoring</H4>
      <P>
        Players who reach the green claim the token that is on the green. If
        multiple players reach the green on the same turn, move all scoring
        tokens to the next green. The value jackpots!
      </P>
      <P>
        The player with the most points wins. Tie break using elimination
        rounds.
      </P>

      <H4>Elimination</H4>
      <P>
        If the 18th hole was a tie and the result would decide the winner, move
        the scoring tokens from the 18th green to the 1st green. If the result
        won’t determine the winner, then the player in the lead wins.
      </P>
      <P>
        All players that tied are moved to the 1st tee box. Reset everyone’s
        hand and remove any hazard effects.
      </P>
      <P>
        Play as normal with the winner taking the remaining jackpot and the game
        ends when the jackpot is claimed.
      </P>
      <P>
        If there is a tie, only the players that tied progress to the next hole.
        The other players are eliminated.
      </P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>5-point tokens</li>
        <li>2-point tokens</li>
        <li>1-point tokens</li>
      </ul>
    </>
  );
};

const GermanModeSkins = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Skins</H1>
      <P>
        Es ist wie Match Play, nur dass jedes Green entweder 1, 2 oder 5 Punkte
        wert ist. Die Spieler müssen den Wert jedes Lochs berücksichtigen, wenn
        sie ihre Schläger aussuchen und ihre Schläge ausführen. Unentschiedene
        Löcher gehen in den Jackpot.
      </P>

      <H3>SPIELER</H3>
      <P>2 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        … du das Spiel magst und du eine weitere Dimension hinzufügen möchtest.
        Die Golfschläger-Auswahl und der Golfschläger-Auswahlprozess ändern
        sich, wenn die Spieler versuchen, ihre besten Schläger für die
        Bewältigung der schwierigeren Löcher zur Verfügung zu haben.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Der Spieler mit den meisten Punkten gewinnt. Die Greens sind 1, 2 oder 5
        Punkte wert, basierend auf dem Wert des darauf befindlichen Spielsteins.
        Mehrere Spieler erhalten Punkte, wenn sie alle im selben Zug das Green
        erreichen.
      </P>

      <H3>Regelvariationen</H3>
      <FromMatchPlay />

      <H4>Aufbau</H4>
      <P>
        Tu dies vor der Golfschläger-Auswahl.
        <ul className={ul}>
          <li>Lege einen 5-Punkte-Token auf das 18. Green.</li>
          <li>
            Gib jedem Spieler einen 5-Punkte-Token und lasse sie ein Green
            wählen, um ihn darauf zu setzen.
          </li>
          <li>
            Drehe alle 1- und 2-Punkt-Token mit der Punkte-Seite nach unten.
            Mische. Wähle einen Token für jedes Green-Feld ohne einen Token.
            Lege die Token mit der Punkte-Seite nach oben, so dass alle Spieler
            den Wert jedes Green sehen können.
          </li>
        </ul>
      </P>

      <H4>Punkten</H4>
      <P>
        Spieler, die das Green erreichen, beanspruchen das Feld, auf dem sich
        das Green befindet. Wenn mehrere Spieler im selben Zug das Green
        erreichen, wandern alle Token auf das nächste Green. Ein Werte-Jackpot!
      </P>
      <P>
        Der Spieler mit den meisten Punkten gewinnt. Tie-Break durch
        Eliminierungsrunden.
      </P>

      <H4>Eliminierung</H4>
      <P>
        Wenn das 18. Loch unentschieden war und das Ergebnis den Sieger
        bestimmen würde, verschiebe die Punkte-Token vom 18. auf das 1. Green.
        Wenn das Ergebnis den Sieger nicht bestimmt, gewinnt der Spieler, der in
        Führung liegt.
      </P>
      <P>
        Alle Spieler mit Gleichstand werden zum 1. Abschlagfeld bewegt. Setze
        die Hand aller zurück und entferne alle Hinderniseffekte.
      </P>
      <P>
        Spiele wie gewohnt, wobei der Gewinner den verbleibenden Jackpot erhält
        und das Spiel endet, wenn der Jackpot eingefordert wird.
      </P>
      <P>
        Bei Gleichstand kommen nur die Spieler, die einen Gleichstand erreicht
        haben, zum nächsten Loch. Die anderen Spieler scheiden aus.
      </P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <ul className={ul}>
        <li>5-Punkt-Token</li>
        <li>2-Punkt-Token</li>
        <li>1-Punkt-Token</li>
      </ul>
    </>
  );
};

const SpanishModeSkins = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Skins</H1>
      <P>
        Es como el Match Play, excepto que cada green vale 1, 2 o 5 puntos. Los
        jugadores deben considerar el valor de cada hoyo al escoger sus palos y
        hacer sus tiros. Jackpot de empate de hoyos.
      </P>

      <H3>JUEGOS</H3>
      <P>2 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Te gusta el juego de parejas, y quieres añadir otra dimensión. El
        proceso de robar palos y elegirlos cambia ya que los jugadores tratan de
        tener sus mejores palos disponibles para los hoyos de valor alto.
      </P>

      <H3>CÓMO GANAR</H3>
      <P>
        El jugador con más puntos gana. Los greens valen 1, 2 o 5 puntos según
        el valor de la ficha que llevan. Múltiples jugadores anotan si todos
        llegan al green en el mismo turno.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromMatchPlay />

      <H4>Configuración</H4>
      <P>
        Haz esto antes de la selección de palos.
        <ul className={ul}>
          <li>Coloca una ficha de 5 puntos en el green del 18.</li>
          <li>
            Dale a cada jugador una ficha de 5 puntos y haz que seleccione un
            green donde colocarla.
          </li>
          <li>
            Pon todas las fichas de 1 y 2 puntos con los puntos boca bajo.
            Baraja. Selecciona una ficha para cada green que no tenga. Coloca
            las fichas hacia arriba para que todos los jugadores puedan ver el
            valor de cada green.
          </li>
        </ul>
      </P>

      <H4>Puntuación</H4>
      <P>
        Los jugadores que llegan al green reclaman la ficha que está en el
        green. Si varios jugadores llegan al green en el mismo turno, mueven
        todas las fichas de puntuación al siguiente green. ¡Los jackpot de
        valor!
      </P>
      <P>
        El jugador con más puntos gana. Desempata usando rondas de eliminación.
      </P>

      <H4>Eliminación</H4>
      <P>
        Si hay empate en el hoyo 18 y el resultado decide el ganador, mueve las
        fichas de puntuación del green del 18 al 1. Si el resultado no determina
        el ganador, entonces gana el jugador que lleva la delantera.
      </P>
      <P>
        Todos los jugadores que empataron se mueven al primer tee de salida.
        Resetea la mano de todos y elimina cualquier efecto de hazard.
      </P>
      <P>
        Juega como siempre, el ganador se lleva el jackpot restante y el juego
        termina cuando se reclama el jackpot.
      </P>
      <P>
        Si hay empate, solo los jugadores que empataron avanzan al siguiente
        hoyo. Los demás jugadores quedan eliminados.
      </P>

      <H3>PARTES NECESARIAS PARA ANOTAR</H3>
      <ul className={ul}>
        <li>Fichas de 5 puntos</li>
        <li>Fichas de 2 puntos</li>
        <li>Fichas de 1 punto</li>
      </ul>
    </>
  );
};
const ItalianModeSkins = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Skins</H1>
      <P>
        È come Match Play, eccetto che ogni green può valere 1, 2, o 5 punti. È
        importante che i giocatori considerino il valore di ogni buca quando
        scelgono le mazze e fanno i tiri. Le buche in parità si accumulano nel
        jackpot.
      </P>

      <H3>GIOCATORI</H3>
      <P>2 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Ti piace il match play e vuoi aggiungere un’altra dimensione. La scelta
        e il processo di selezione delle mazze cambia dato che i giocatori
        devono cercare di avere le migliori mazze disponibili per tentare di
        conquistare le buche dal valore più alto.
      </P>

      <H3>COME SI VINCE</H3>
      <P>
        Il giocatore che accumula più punti vince. I green valgono 1, 2 o 5 a
        seconda del valore del segnalino che ci sta sopra. Più giocatori
        ottengono punti se raggiungono tutti il green nello stesso turno.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromMatchPlay />

      <H4>PREPARAZIONE</H4>
      <P>
        Fai questo prima della selezione delle mazze.
        <ul className={ul}>
          <li>Metti un segnalino da 5 punti sul 18º green.</li>
          <li>
            Dai a ciascun giocatore un segnalino da 5 punti e fagli selezionare
            un green su cui posizionarlo.
          </li>
          <li>
            Gira tutti i segnalini da 1 punto e 2 punti con il punteggio rivolto
            verso il basso. Mescola. Seleziona un segnalino per ogni green che
            non ne ha uno. Posiziona i segnalini con il punto rivolto verso
            l’alto in modo che tutti i giocatori possano vedere il valore di
            ogni green.
          </li>
        </ul>
      </P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        I giocatori che raggiungono il green reclamano il segnalino che si trova
        sul green. Se più giocatori raggiungono il green nello stesso turno,
        sposta tutti i segnalini del punteggio al green successivo. Il valore si
        accumula nel jackpot!
      </P>
      <P>
        Il giocatore che accumula più punti vince. Il punteggio di pareggio è
        determinate dai round di eliminazione.
      </P>

      <H4>ELIMINAZIONE</H4>
      <P>
        Se la 18ª buca finisce in parità e il risultato potrebbe decidere i
        vincitori, sposta i segnalini del punteggio dal 18º green al 1º green.
        Se il risultato non determina il vincitore, a vincere sarà il giocatore
        in testa.
      </P>
      <P>
        Tutti i giocatori in parità vengono spostati al 1º tee box. Azzera la
        mano di tutti gli altri e rimuovi qualsiasi effetto degli ostacoli.
      </P>
      <P>
        Gioca normalmente. La partita finisce quando verrà conquistato il
        jackpot.
      </P>
      <P>
        Se c’è un pareggio, solo i giocatori che hanno pareggiato proseguono
        alla buca successiva. Gli altri saranno eliminati.
      </P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <ul className={ul}>
        <li>Segnalini da 5 punti</li>
        <li>Segnalini da 2 punti</li>
        <li>Segnalini da 1 punto</li>
      </ul>
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeSkins />}
          {language === "fr" && <FrenchModeSkins />}
          {language === "it" && <ItalianModeSkins />}
          {language === "es" && <SpanishModeSkins />}
          {language === "de" && <GermanModeSkins />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
