import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, P } from "../../brand/typography";
import {
  FastTravelRulesEnglish,
  FastTravelRulesFrench,
  FastTravelRulesGerman,
  FastTravelRulesItalian,
  FastTravelRulesSpanish,
} from "./FastTravelRules";
import { FromChaosGolf } from "./FromChaosGolf";
import soloSetup from "../images/solo-setup.png";
import soloCard from "../images/sample-solo.png";
import soloCard2 from "../images/solo-card-2-points.png";
import soloCard3 from "../images/solo-card-3-points.png";
import soloCard5 from "../images/solo-card-5-points.png";
import soloMarker from "../images/final-solo-marker.png";
import { StandardLandscapeCardSize } from "../RuleBookParts";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";

const FrenchModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>CHAOS GOLF en solo</H1>
      <P>
        Le Chaos Golf contre trois adversaires qui scorent rapidement. Trois
        golfeurs adverses marquent si vous les laissez faire. Vous devez les
        dépasser avant qu'ils n'acquièrent chacun six greens.
      </P>
      <P>Ce mode de jeu utilise le paquet de carte Chaos Golf Solo.</P>

      <H3>Nombre de joueurs</H3>
      <P>Vous et trois adversaires automatisés.</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>Vous aimez Chaos Golf et vous êtes le seul à vouloir jouer…</P>

      <H3>COMMENT GAGNER</H3>
      <P>
        Le score de chaque joueur est la somme des jetons de 2 et 5 points qu'il
        a collectés. Le score le plus élevé gagne.
      </P>

      <H3>Variations des règles</H3>
      <FromChaosGolf />

      <H4>Configuration</H4>
      <P>
        Placez un golfeur pour chacun de vos trois adversaires sur chaque côté
        du plateau de jeu. Mélangez les jetons roses à 1 point et placez-les en
        trois piles de six jetons chacune. Placez une pile devant chaque
        adversaire. Les jetons doivent être à l'envers. Placez votre golfeur sur
        le tee box de départ.
      </P>
      <P>
        Ne piochez pas de carte de golfeur. Vos adversaires automatisés ne les
        utiliseront pas non plus.
      </P>
      <P>
        Choisissez directement vos clubs. Avec les clubs restants, mélangez et
        distribuez 5 cartes à chaque IA, face cachée. 
      </P>
      <P>
        Construisez un jeu de cartes de Golf Chaos Solo en fonction de la
        difficulté que vous souhaitez. Vous devez avoir au moins six icônes de
        green dans le paquet. Consultez la page suivante pour savoir comment
        construire un jeu de cartes.
      </P>

      <div className="flex justify-center items-center w-full">
        <img src={soloSetup} width={350} />
      </div>
      <H4>Comment jouer</H4>
      <P>
        Révélez le jeton supérieur à 1 point pour chaque adversaire. Ce trou est
        celui qu'ils marqueront lors de la prochaine phase de marquage de
        points.
      </P>
      <div className="flex flex-col justify-center items-center w-full pt-8">
        <img src={soloCard} width={StandardLandscapeCardSize} />
        <img
          src={soloMarker}
          width={75}
          style={{ position: "relative", left: -31, top: -150 }}
        />
      </div>
      <P>
        Révélez la carte supérieure du paquet de cartes Chaos Golf et placez le
        marqueur de tour solo au-dessus du premier symbole. Utilisez le marqueur
        de tour pour suivre votre progression.
      </P>
      <P>
        Il existe deux types de symboles pour marquer des points sur les cartes.
        Un cercle rempli avec « seul le joueur marque » écrit en dessous, et un
        cercle divisé à deux tons pour quand tout le monde marque.
      </P>
      <P>
        Pendant les tours réservés aux joueurs, vous jouez comme d'habitude et
        pouvez marquer n'importe quel green que vous pouvez atteindre.
      </P>
      <P>
        À la fin de chaque tour marqué par l'icône « score combiné », effectuez
        la phase de score combiné. Voir la page suivante pour plus
        d'informations.
      </P>
      <P>
        Après la phase de marquage des points, déplacez le marqueur de tour solo
        d'une case vers la droite. Avant de déplacer le jeton, s'il n'y a plus
        de place sur la carte, révélez une nouvelle carte du haut du paquet de
        cartes Golf Chaos Solo et placez votre marqueur de tour sur la première
        case.
      </P>
      <P>
        Un déplacement rapide est autorisé si vous atterrissez sur un tee box.
        Prenez votre tour supplémentaire après que vos adversaires aient joué
        leur tour et le décompte des greens éventuels. Vos adversaires ne
        peuvent pas effectuer de déplacement rapide.
      </P>
      <P>
        Si vous voulez faire un gros coup, mélangez les cartes de coups que vous
        avez en option et défaussez-en une au hasard.
      </P>

      <H4>Marquage des points</H4>
      <P>
        Lorsque vous ou un adversaire prenez le dernier jeton de 5 points, la
        partie se termine à la fin de ce tour. Le joueur qui a le plus de points
        gagne.
      </P>

      <H3>MARQUAGE COMBINÉ DES POINTS</H3>
      <P>
        Après avoir déplacé votre golfeur et avant de marquer des points sur les
        greens, regardez les greens ciblés de chaque adversaire. Donnez à
        l'adversaire le plus gros jeton restant sur les greens que vous ne visez
        pas.
      </P>
      <P>
        Si vous êtes en compétition pour un green, retournez le club supérieur
        de l'adversaire qui est en lice. Si la carte de votre adversaire a une
        valeur d'initiative inférieure à la vôtre, il prend le jeton à 5 points,
        et vous recevez le jeton à 2 points. Sinon, vous prenez le jeton à 5
        points et votre adversaire prend le jeton à 2 points.
      </P>
      <P>
        Si vous voulez savoir si vous allez gagner un green, vous pouvez révéler
        la carte de club d'un adversaire pour voir son initiative. Vous ne
        pouvez le faire qu'après avoir déterminé quel club vous allez jouer et
        avant de déplacer votre golfeur.
      </P>

      <H3>CONSTRUIRE UN JEU DE CARTES EN SOLO</H3>
      <P>
        Avant de jouer, vous devez faire un jeu de cartes qui représente la
        difficulté du jeu. Plus il y a de joueurs qui marquent des points dans
        le jeu sélectionné, plus c'est facile. Modifiez le jeu de cartes de
        manière à ce que chaque partie soit un défi.
      </P>
      <div className="flex justify-center items-center w-full">
        <img src={soloCard} width={StandardLandscapeCardSize} />
      </div>
      <P>
        Dans le coin supérieur droit de chaque carte de Chaos Golf solo se
        trouve le score de difficulté de la carte. Plus la valeur de la carte
        est élevée, plus votre jeu est difficile. Vous pouvez ajouter plusieurs
        cartes au jeu. Leur score combiné représente le rang du jeu. Plus le
        rang du jeu est élevé, plus le défi est grand. Les cinq cartes à droite
        représentent un jeu de cartes de rang 17.
      </P>
      <div
        className="flex flex-col justify-start items-center w-full"
        style={{ height: 350, overflow: "hidden" }}
      >
        <img
          src={soloCard5}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: 20 }}
        />
        <img
          src={soloCard2}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -90 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -200 }}
        />
        <img
          src={soloCard}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -310 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -420 }}
        />
      </div>
      <P>
        En bas de chaque carte se trouve un petit indicateur de drapeau qui vous
        indique le nombre de tours de marquage combiné des points sur cette
        carte. Chaque jeu de cartes Solo Chaos Golf nécessite au moins six tours
        de marquage combiné des points. Vous pouvez en avoir plus si vous le
        souhaitez, mais cela ne fait qu'ajouter de l'incertitude dans le jeu. Le
        rang de difficulté change par fourchette, par exemple de 21 à 25.
      </P>
      <P>
        Chaque parcours fourni dans le jeu est également accompagné d'un score
        de par solo. Si vous construisez un jeu de cartes égal à ce score, vous
        vivrez une expérience que nous considérons comme un vrai défi. Si vous
        pouvez gagner avec un rang supérieur, félicitations&nbsp;! 
      </P>
      <P>
        Si vous avez du mal à gagner, réduisez le rang du jeu pour continuer à
        vous amuser.
      </P>

      <FastTravelRulesFrench />
    </>
  );
};

const EnglishModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>SOLO CHAOS GOLF</H1>
      <P>
        Chaos Golf against three fast scoring opponents. Three opposing golfers
        score if you let them. You need to out-score them before they acquire
        six greens each.
      </P>
      <P>This game mode uses the solo chaos golf deck.</P>

      <H3>Plays</H3>
      <P>You and three automated opponents.</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>You like Chaos Golf and you are the only one who wants to play.</P>

      <H3>HOW TO WIN</H3>
      <P>
        Each player's score is the sum of their collected 2 and 5 point tokens.
        Highest score wins.
      </P>

      <H3>Rule Variations</H3>
      <FromChaosGolf />

      <H4>Setup</H4>
      <P>
        On one side of the playing board, place a golfer for each of your three
        opponents. Shuffle the pink 1-point tokens and put them in three stacks
        of six tokens each. Place one stack in front of each opponent. The
        tokens should be hole-side down. Place your golfer on the starting tee
        box.
      </P>
      <P>Skip golfer cards. Your automated opponents won’t use them either.</P>
      <P>
        Skip the draft. Pick the clubs you think will win. With the remaining
        clubs, shuffle and deal 5 to each AI face down.
      </P>
      <P>
        Build a deck of Solo Chaos Golf cards based on the difficulty you want.
        You need at least six green icons in the pack. See the next page for
        instructions on how to build a deck.
      </P>

      <div className="flex justify-center items-center w-full">
        <img src={soloSetup} width={350} />
      </div>
      <H4>Game Play</H4>
      <P>
        Reveal the top 1-point token for each opponent. This hole is the one
        they score at the next combined scoring phase.
      </P>
      <div className="flex flex-col justify-center items-center w-full pt-8">
        <img src={soloCard} width={StandardLandscapeCardSize} />
        <img
          src={soloMarker}
          width={75}
          style={{ position: "relative", left: -31, top: -150 }}
        />
      </div>
      <P>
        Reveal the top card of the Solo Chaos Golf deck and place the solo turn
        marker above the first symbol. Use the turn marker to track your
        progress.
      </P>
      <P>
        There are two types of scoring phase symbols on the cards. A filled
        circle with “player only scoring” written under it, and a two-toned
        split circle for the combined scoring round.
      </P>
      <P>
        During player only scoring rounds you play as usual and can score any
        green you can reach.
      </P>
      <P>
        At the end of every turn marked by the combined scoring icon, perform
        the combined scoring phase. See the next page for more information.
      </P>
      <P>
        After the scoring phase, move the solo turn marker one space to the
        right. Before moving the token, if there are no more spaces on the card,
        reveal a new card from the top of the Solo Chaos Golf Deck and place
        your turn marker above the first spot.
      </P>
      <P>
        Fast travel is permitted if you land on a tee box. Take your extra turn
        after your opponents have their turns and the scoring of any greens.
        Your opponents don't fast travel.
      </P>
      <P>
        If you want to do a Big Hit, shuffle the shot cards you have as options
        and discard one at random.
      </P>

      <H4>Scoring</H4>
      <P>
        When you or an opponent scores the last 5-point token, end the game
        after that round. The player with the most points wins.
      </P>

      <H3>COMBINED SCORING PHASE</H3>
      <P>
        After you have moved your golfer and before you score any greens, look
        at green targets for each opponent. For the greens that you are not on,
        give the largest token remaining on the green to the opponent.
      </P>
      <P>
        If you are competing for a green, flip the top club of the opponent that
        is in contention. If your opponent’s card has a lower initiative value
        compared to you, they take the 5-point token, and you receive the
        2-point token. Otherwise, you score the 5-point token and your opponent
        scores the 2-point token.
      </P>
      <P>
        If you want to know whether you’re going to win a green, you may reveal
        the club card of an opponent to see their initiative. You may only do
        this after you have determined which club you are going to play and
        before moving your golfer.
      </P>

      <H3>BUILDING A SOLO DECK</H3>
      <P>
        Before you play, you need to make a deck that represents the difficulty
        of the game. The more player scoring rounds in the pack, the easier it
        is. Tweak the deck so that each game provides a challenge.
      </P>
      <div className="flex justify-center items-center w-full">
        <img src={soloCard} width={StandardLandscapeCardSize} />
      </div>
      <P>
        In the top right corner of each solo chaos golf card is the difficulty
        score for the card. The higher the value of the card, the harder your
        game. You can add multiple cards to the deck. Their combined score is
        the rank of the deck. The higher the deck rank, the greater the
        challenge. The five cards to the right represent a deck of rank 17.
      </P>
      <div
        className="flex flex-col justify-start items-center w-full"
        style={{ height: 350, overflow: "hidden" }}
      >
        <img
          src={soloCard5}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: 20 }}
        />
        <img
          src={soloCard2}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -90 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -200 }}
        />
        <img
          src={soloCard}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -310 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -420 }}
        />
      </div>
      <P>
        At the bottom of each card is a little flag indicator that tells you how
        many combined scoring rounds are on this card. Every Solo Chaos Golf
        deck needs at least six combined scoring rounds. You can have more than
        six combined scoring rounds if you want to, all this does is add more
        uncertainty into the deck. The difficulty rank changes to a range, i.e.
        21-25.
      </P>
      <P>
        Each course provided in the game also comes with a solo par score. If
        you build a deck equalling this score, you have what we designers
        believe is a challenging experience. If you can win with a higher rank,
        congratulations!
      </P>
      <P>
        If you’re struggling to get a win, then reduce the rank of the deck to
        make sure you’re still having fun.
      </P>

      <FastTravelRulesEnglish />
    </>
  );
};

const GermanModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>SOLO-CHAOS-GOLF</H1>
      <P>
        Chaos Golf gegen drei schnell punktende Gegner. Drei gegnerische Golfer
        punkten, wenn du sie lässt. Du musst sie übertrumpfen, bevor sie jeweils
        sechs Greens erreicht haben.
      </P>
      <P>Dieser Spielmodus verwendet das Solo-Chaos-Golf-Stapel.</P>

      <H3>Spieler</H3>
      <P>Du und drei automatisierte Gegner.</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>… du Chaos-Golf magst und du der Einzige bist, der spielen will.</P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        Die Punktzahl jedes Spielers ergibt sich aus der Summe seiner
        gesammelten 2- und 5-Punkte-Token. Die höchste Punktzahl gewinnt.
      </P>

      <H3>Regelvariationen</H3>
      <FromChaosGolf />

      <H4>Aufbau</H4>
      <P>
        Platziere auf einer Seite des Spielbretts für jeden deiner drei Gegner
        einen Golfer. Mische die rosa 1-Punkt-Token und lege sie in drei Stapel
        zu je sechs Token. Lege vor jeden Gegner einen Stapel. Die Token sollten
        mit der Lochseite nach unten liegen. Platziere deinen Golfer auf den
        Start-Abschlagplatz.
      </P>
      <P>
        Überspringe Golferkarten. Deine automatisierten Gegner werden sie auch
        nicht verwenden.
      </P>
      <P>
        Überspringe die Schlägerauswahl. Wählen dir einfach die Schläger aus,
        von denen du glaubst, dass sie gewinnen werden. Mische die verbliebenen
        Schläger und gib 5 an jede KI verdeckt.
      </P>
      <P>
        Stelle ein Stapel mit Solo-Chaos-Golf-Karten zusammen, je nach
        gewünschtem Schwierigkeitsgrad. Du benötigst mindestens sechs
        Green-Symbole im Spiel. Auf der nächsten Seite findest du Anweisungen
        zum Erstellen eines Stapel.
      </P>

      <div className="flex justify-center items-center w-full">
        <img src={soloSetup} width={350} />
      </div>
      <H4>Gameplay</H4>
      <P>
        Enthülle den obersten 1-Punkt-Token für jeden Gegner. Dieses Loch ist
        das Loch, das sie in der nächsten kombinierten Punktephase erzielen
        werden.
      </P>
      <div className="flex flex-col justify-center items-center w-full pt-8">
        <img src={soloCard} width={StandardLandscapeCardSize} />
        <img
          src={soloMarker}
          width={75}
          style={{ position: "relative", left: -31, top: -150 }}
        />
      </div>
      <P>
        Decke die oberste Karte des Solo-Chaos-Golf-Stapel auf und platziere den
        Solo-Rundenmarker über dem ersten Symbol. Verwende den Rundenmarker, um
        deinen Fortschritt zu verfolgen.
      </P>
      <P>
        Es gibt zwei Arten von Symbolen für die Wertungsphase auf den Karten.
        Ein ausgefüllter Kreis mit der Aufschrift "nur Spieler punkten" darunter
        und ein zweifarbiger geteilter Kreis für die kombinierte Punkterunde.
      </P>
      <P>
        Während der „nur Spieler punkten“-Runden spielst du wie gewohnt und
        kannst jedes Green, das du erreichen kannst, punkten.
      </P>
      <P>
        Führe am Ende jeder Runde, die mit dem Symbol für die kombinierte
        Wertung gekennzeichnet ist, die Phase der kombinierten Wertung durch.
        Weitere Informationen findest du auf der nächsten Seite.
      </P>
      <P>
        Bewege nach der Punktephase den Solorundenmarker ein Feld nach rechts.
        Wenn keine Felder mehr auf der Karte sind, decke vor dem Verschieben des
        Token eine neue Karte des Solo-Chaos-Golf-Stapel auf und platziere
        deinen Rundenmarker auf dem ersten Feld.
      </P>
      <P>
        Schnellreisen ist erlaubt, wenn du auf einem Abschlagsfeld landest. Nimm
        deinen Extra-Zug, nachdem deine Gegner an der Reihe waren und jegliche
        Greens gepunktet wurden. Deine Gegner können die Schnellreise nicht
        nutzen.
      </P>
      <P>
        Wenn du einen Großen Schlag erzielen willst, mischst du die
        Schlag-Karten, die du als Optionen hast, und legst eine nach dem
        Zufallsprinzip ab.
      </P>

      <H4>Punkten</H4>
      <P>
        Wenn du oder ein Gegner das letzte 5-Punkte-Token erzielt hat, beendest
        du das Spiel nach dieser Runde. Der Spieler mit den meisten Punkten
        gewinnt.
      </P>

      <H3>KOMBINIERTE PUNKTEPHASE</H3>
      <P>
        Nachdem du deinen Golfer bewegt hast und bevor du auf den Greens
        punktest, schaue nach Green-Zielen für jeden Gegner. Für die Greens, auf
        denen du dich nicht befindest, gib dem Gegner den größten der auf dem
        Green verbleibenden Token.
      </P>
      <P>
        Wenn du um ein Green konkurrierst, drehe den Top-Schläger des Gegners
        um, der sich im Wettbewerb befindet. Wenn die Karte deines Gegners im
        Vergleich zu dir einen geringeren Initiativwert hat, nimmt er den
        5-Punkte-Token, und du erhältst den 2-Punkte-Token. Andernfalls erhältst
        du den 5-Punkte-Token und dein Gegner erhält den 2-Punkte-Token.
      </P>
      <P>
        Wenn du wissen willst, ob du ein Green gewinnen wirst, kannst du die
        Golfschläger-Karte eines Gegners aufdecken, um dessen Initiative zu
        sehen. Dies darfst du erst tun, nachdem du dich entschieden hast,
        welchen Schläger du spielen wirst und bevor du deinen Golfer bewegst.
      </P>

      <H3>EIN SOLO-Stapel ERSTELLEN</H3>
      <P>
        Bevor du spielst, musst du ein Stapel erstellen, das den
        Schwierigkeitsgrad des Spiels repräsentiert. Je mehr „nur Spieler
        punkten“- Runden in dem Stapel, desto einfacher ist es. Passe das Stapel
        so an, dass jedes Spiel eine Herausforderung darstellt.
      </P>
      <div className="flex justify-center items-center w-full">
        <img src={soloCard} width={StandardLandscapeCardSize} />
      </div>
      <P>
        In der oberen rechten Ecke jeder Solo Chaos-Golf-Karte ist der
        Schwierigkeitsgrad für die Karte angegeben. Je höher der Wert der Karte,
        desto schwieriger ist dein Spiel. Du kannst dem Stapel mehrere Karten
        hinzufügen. Ihre kombinierte Punktzahl ist der Rang des Stapels. Je
        höher der Rang des Stapels, desto größer die Herausforderung. Die fünf
        Karten auf der rechten Seite stellen ein Stapel vom Rang 17 dar.
      </P>
      <div
        className="flex flex-col justify-start items-center w-full"
        style={{ height: 350, overflow: "hidden" }}
      >
        <img
          src={soloCard5}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: 20 }}
        />
        <img
          src={soloCard2}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -90 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -200 }}
        />
        <img
          src={soloCard}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -310 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -420 }}
        />
      </div>
      <P>
        Unten auf jeder Karte befindet sich ein kleiner Fahnenindikator, der dir
        anzeigt, wie viele kombinierte Punkte-Runden sich auf dieser Karte
        befinden. Jedes Solo-Chaos-Golf-Stapel benötigt mindestens sechs
        kombinierte Punkte-Runden. Du kannst mehr als sechs kombinierte
        Punkte-Runden haben, wenn du möchtest, dies führt lediglich zu mehr
        Ungewissheit im Stapel. Der Schwierigkeitsgrad ändert sich in einen
        Bereich, z. B. 21-25.
      </P>
      <P>
        Jeder im Spiel angebotene Platz ist auch mit einem Solo-Par-Punktestand
        versehen. Wenn du ein Stapel erstellt, das diesem Punktestand
        entspricht, hast du etwas, von dem wir Designer glauben, dass es eine
        herausfordernde Erfahrung ist. Wenn du mit einem höheren Rang gewinnen
        kannst, herzlichen Glückwunsch!
      </P>
      <P>
        Wenn es dir schwerfällt, einen Sieg zu erlangen, dann reduziere den Rang
        des Stapel, um sicherzustellen, dass du immer noch Spaß hast.
      </P>

      <FastTravelRulesGerman />
    </>
  );
};

const SpanishModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>SOLO CHAOS GOLF</H1>
      <P>
        Chaos Golf contra tres oponentes de rápido marcaje. Tres golfistas
        opuestos anotan si los dejas. Tienes que superarlos antes de que
        adquieran seis verdes cada uno.
      </P>
      <P>Este modo de juego utiliza la baraja de Solo Chaos Golf.</P>

      <H3>Jugadas</H3>
      <P>Tú y tres oponentes automatizados.</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>Te gusta el Chaos Golf y eres el único que quiere jugar.</P>

      <H3>CÓMO GANAR</H3>
      <P>
        El puntaje de cada jugador es la suma de sus fichas de 2 y 5 puntos
        conseguidas. La puntuación más alta gana.
      </P>

      <H3>Variaciones de las reglas</H3>
      <FromChaosGolf />

      <H4>Configuración</H4>
      <P>
        En un lado del tablero de juego, coloca un golfista para cada uno de tus
        tres oponentes. Mezcla las fichas rosas de 1 punto y ponlas en tres
        montones de seis fichas cada uno. Coloca un montón delante de cada
        oponente. Las fichas deben tener el lado del hoyo hacia abajo. Pon a tu
        golfista en el tee de salida.
      </P>
      <P>
        No usas las cartas de golfista. Tus oponentes automatizados tampoco los
        usarán.
      </P>
      <P>
        No se roba. Escoge los palos que crees que ganarán. Con los palos
        restantes, baraja y reparte 5 a cada IA boca abajo.
      </P>
      <P>
        Haz un mazo de cartas de Solo Chaos Golf según la dificultad que
        quieras. Necesitas al menos seis iconos verdes en el paquete. Ve la
        siguiente página para las instrucciones de cómo construir un mazo.
      </P>

      <div className="flex justify-center items-center w-full">
        <img src={soloSetup} width={350} />
      </div>
      <H4>Juego</H4>
      <P>
        Revela la ficha de 1 punto más alta de cada oponente. Este hoyo es el
        que anotan en la siguiente fase de puntuación combinada.
      </P>
      <div className="flex flex-col justify-center items-center w-full pt-8">
        <img src={soloCard} width={StandardLandscapeCardSize} />
        <img
          src={soloMarker}
          width={75}
          style={{ position: "relative", left: -31, top: -150 }}
        />
      </div>
      <P>
        Revela la carta superior de la baraja de Solo Chaos Golf y coloca el
        marcador de turnos para jugar solo sobre el primer símbolo. Usa el
        marcador de turnos para seguir tu progreso.
      </P>
      <P>
        Hay dos tipos de símbolos de fase de puntuación en las cartas. Un
        círculo lleno con "player only scoring" escrito debajo y un círculo
        dividido de dos tonos para la ronda de puntuación combinada.
      </P>
      <P>
        Durante las rondas de anotación de los jugadores, juegas como siempre y
        puedes anotar cualquier green que puedas alcanzar.
      </P>
      <P>
        Al final de cada turno marcado por el icono de puntuación combinada,
        realiza la fase de puntuación combinada. Ve la siguiente página para más
        información.
      </P>
      <P>
        Después de la fase de puntuación, mueve el marcador de turnos para jugar
        solo un espacio a la derecha. Antes de mover la ficha, si no hay más
        espacios en la carta, revela una nueva carta de la parte superior de la
        baraja de Solo Chaos Golf y coloca tu marcador de turnos sobre el primer
        punto.
      </P>
      <P>
        Se permite viajar rápido si caes en un tee de salida. Juegas tu turno
        extra después de que tus oponentes jueguen sus turnos y la anotación de
        los greens. Tus oponentes no viajan rápido.
      </P>
      <P>
        Si quieres hacer un Gran Golpe, baraja las cartas de tiro que tienes
        como opciones y descarta una al azar.
      </P>

      <H4>Puntuación</H4>
      <P>
        Cuando un oponente o tú anotan la última ficha de 5 puntos, termina el
        juego después de esa ronda. El jugador con más puntos gana.
      </P>

      <H3>FASE DE PUNTUACIÓN COMBINADA</H3>
      <P>
        Después de mover tu golfista y antes de anotar cualquier green, mira los
        greens objetivo de cada oponente. Para los greens en los que no estás,
        dale al oponente la ficha más grande que quede en el green.
      </P>
      <P>
        Si estás compitiendo por un green, dale la vuelta al palo superior del
        oponente que está en disputa. Si la carta de tu oponente tiene un valor
        de iniciativa menor que tú, se lleva la ficha de 5 puntos y tú recibes
        la ficha de 2 puntos. De lo contrario, te anotas la ficha de 5 puntos y
        tu oponente se lleva la ficha de 2 puntos.
      </P>
      <P>
        Si quieres saber si vas a ganar un green, puedes revelar la carta del
        palo de un oponente para ver su iniciativa. Solo puedes hacer esto
        después de haber determinado qué palo vas a jugar y antes de mover a tu
        golfista.
      </P>

      <H3>HACIENDO UN MAZO PARA JUGAR SOLO</H3>
      <P>
        Antes de jugar, tienes que hacer una baraja que represente la dificultad
        del juego. Cuantas más rondas de puntuación de jugadores en el paquete,
        más fácil es. Ajusta la baraja para que cada juego sea un desafío.
      </P>
      <div className="flex justify-center items-center w-full">
        <img src={soloCard} width={StandardLandscapeCardSize} />
      </div>
      <P>
        En la esquina superior derecha de cada carta de Solo Chaos Golf está la
        puntuación de dificultad de la carta. Cuanto más alto sea el valor de la
        carta, más difícil será tu juego. Puedes añadir varias cartas a la
        baraja. Su puntuación combinada es el rango de la baraja. Cuanto más
        alto sea el rango de la baraja, mayor será el desafío. Las cinco cartas
        de la derecha representan una baraja de rango 17.
      </P>
      <div
        className="flex flex-col justify-start items-center w-full"
        style={{ height: 350, overflow: "hidden" }}
      >
        <img
          src={soloCard5}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: 20 }}
        />
        <img
          src={soloCard2}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -90 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -200 }}
        />
        <img
          src={soloCard}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -310 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -420 }}
        />
      </div>
      <P>
        En la parte inferior de cada carta hay un pequeño indicador de bandera
        que te dice cuántas rondas de puntuación combinadas hay en esta carta.
        Cada baraja de Solo Chaos Golf necesita al menos seis rondas de
        puntuación combinadas. Puedes tener más de seis rondas de puntuación
        combinadas si lo deseas, todo lo que esto hace es añadir más
        incertidumbre a la baraja. El rango de dificultad cambia a un rango, es
        decir, 21-25.
      </P>
      <P>
        Cada campo provisto en el juego también viene con una puntuación de par
        en solitario. Si haces una baraja que iguale a esta puntuación, tienes
        lo que los diseñadores creemos que es una experiencia desafiante. Si
        puedes ganar con un rango más alto, ¡felicidades!
      </P>
      <P>
        Si te cuesta conseguir una victoria, reduce el rango de la baraja para
        asegurarte de que sigues divirtiéndote.
      </P>

      <FastTravelRulesSpanish />
    </>
  );
};

const ItalianModeChaosGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>SOLO CHAOS GOLF</H1>
      <P>
        Chaos Golf contro tre avversari dal punteggio veloce. I tre golfisti
        avversari fanno punti se glielo lasci fare. Devi fare più punti di loro
        prima che acquisiscano sei green ciascuno.
      </P>
      <P>Questa modalità di gioco usa il mazzo solo chaos golf.</P>

      <H3>GIOCATORI</H3>
      <P>Tu e tre avversari automatici.</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>Ti piace il Chaos Golf e sei l’unico che vuole giocare.</P>

      <H3>COME SI VINCE</H3>
      <P>
        Il punteggio di ogni giocatore è la somma dei suoi segnalini da 2 e da 5
        punti raccolti. Vince chi avrà totalizzato il punteggio più alto.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromChaosGolf />

      <H4>PREPARAZIONE</H4>
      <P>
        Da un lato del tabellone di gioco, posiziona un golfista per ciascuno
        dei tuoi tre avversari. Mescola i segnalini rosa da 1 e mettili in tre
        pile da sei segnalini ciascuna. Posiziona una fila davanti a ciascun
        avversario. I segnalini devono essere rivolti con il foro verso il
        basso. Posiziona il tuo golfista sul tee box iniziale.
      </P>
      <P>
        Salta le carte golfista. Nemmeno i tuoi avversari automatici le
        useranno.
      </P>
      <P>
        Salta la selezione. Scegli le mazze che pensi possano essere le più
        utili per vincere. Mescola le mazze rimanenti e distribuiscine 5 a
        ciascuna AI a faccia in giù.
      </P>
      <P>
        Metti insieme un mazzo di carte Solo Chaos Golf in base alla difficoltà
        desiderata. Hai bisogno di almeno sei icone green nel pacchetto. Vedi la
        pagina successiva per le istruzioni su come costruire un mazzo.
      </P>

      <div className="flex justify-center items-center w-full">
        <img src={soloSetup} width={350} />
      </div>
      <H4>SVOLGIMENTO DEL GIOCO</H4>
      <P>
        Rivela il segnalino maggiore di un punto rispetto a ciascun avversario.
        Questa buca è quella che mettono a segno alla prossima fase di punteggio
        combinato.
      </P>
      <div className="flex flex-col justify-center items-center w-full pt-8">
        <img src={soloCard} width={StandardLandscapeCardSize} />
        <img
          src={soloMarker}
          width={75}
          style={{ position: "relative", left: -31, top: -150 }}
        />
      </div>
      <P>
        Rivela la prima carta del mazzo Solo Chaos Golf e posiziona il segna
        turni solitario sopra il primo simbolo. Usa il segna turni per tenere
        traccia del progresso.
      </P>
      <P>
        Ci sono due simboli per la fase di punteggio sulle carte. Un cerchio
        riempito con scritto “player only scoring” al di sotto, e un cerchio
        diviso a doppia tonalità per il round di punteggio combinato.
      </P>
      <P>
        Durante il round di punteggio riservato al giocatore tutto è come al
        solito e puoi mettere a segno ogni green che riesci a raggiungere.
      </P>
      <P>
        Alla fine di ogni turno contrassegnato dall’icona di punteggio
        combinato, esegui la fase di punteggio combinato. Vedi la pagina
        successiva per maggiori informazioni.
      </P>
      <P>
        Dopo la fase di punteggio, sposta il segna turni solitario di uno spazio
        verso destra. Prima di spostare il segnalino, se non ci sono spazi sulla
        carta, rivela una nuova carta dalla cima del mazzo Solo Chaos Golf e
        posiziona il tuo segna turni al di sopra del primo punto.
      </P>
      <P>
        Se atterri su un tee box ti è consentito fare il viaggio rapido. Fai il
        tuo turno extra dopo che i tuoi avversari avranno fatto i turni e messo
        a segno qualsiasi green. I tuoi avversari non possono fare viaggi
        rapidi.
      </P>
      <P>
        Se vuoi fare un Grande Colpo, mescola le carte tiro che hai come opzioni
        e scartane una a caso.
      </P>

      <H4>SEGNARE PUNTI</H4>
      <P>
        La partita terminerà dopo il round in cui tu o un avversario avrete
        acquisito l’ultimo segnalino da 5 punti. A vincere sarà il giocatore che
        avrà totalizzato il maggior numero di punti.
      </P>

      <H3>FASE DI PUNTEGGIO COMBINATA</H3>
      <P>
        Dopo aver spostato il tuo golfista e prima di mettere a segno un
        qualsiasi green, guarda gli obiettivi green per ciascun bersaglio. Per
        il green su cui non ci sei tu, dai all’avversario il token più grande
        rimasto sul green.
      </P>
      <P>
        Se sei in competizione per un green, capovolgi la mazza superiore
        dell’avversario all’interno della contesa. Se la carta del tuo
        avversario ha un’iniziativa di valore più basso in confronto alla tua,
        questi prende i segnalini da 5; tu ricevi il segnalino da 2 punti.
        Altrimenti, tu metti a segno il segnalino da 5 punti e il tuo avversario
        quello da 2 punti.
      </P>
      <P>
        Se vuoi sapere se potrai vincere un green, puoi rivelare la carta mazza
        di un avversario per vedere la sua iniziativa. Puoi farlo solo dopo aver
        determinato quale mazza giocherai e prima di spostare il tuo golfista.
      </P>

      <H3>COSTRUIRE UN MAZZO SOLITARIO</H3>
      <P>
        Prima di giocare, crea un mazzo che rappresenta la difficoltà del gioco.
        Più round di punteggio per il giocatore ci sono nel pacchetto, più il
        gioco sarà facile. Modifica il mazzo in modo che ogni partita sia una
        sfida.
      </P>
      <div className="flex justify-center items-center w-full">
        <img src={soloCard} width={StandardLandscapeCardSize} />
      </div>
      <P>
        Nell’angolo in alto a destra di ogni carta solo chaos golf si trova il
        punteggio di difficoltà per la carta. Più alto è il valore della carta,
        più difficile sarà la partita. Puoi aggiungere varie carte dal mazzo. Il
        loro punteggio combinato è il rango del mazzo. Più alto è il rango del
        mazzo, maggiore sarà la sfida. Le cinque carte a destra rappresentano un
        mazzo di rango 17.
      </P>
      <div
        className="flex flex-col justify-start items-center w-full"
        style={{ height: 350, overflow: "hidden" }}
      >
        <img
          src={soloCard5}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: 20 }}
        />
        <img
          src={soloCard2}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -90 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -200 }}
        />
        <img
          src={soloCard}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -310 }}
        />
        <img
          src={soloCard3}
          width={StandardLandscapeCardSize}
          style={{ position: "relative", top: -420 }}
        />
      </div>
      <P>
        In fondo a ogni carta si trova un indicatore bandierina che ti dice
        quanti round di punteggio combinati si trovano su questa carta. Ogni
        mazzo Solo Chaos Golf ha bisogno di almeno sei round di punteggio
        combinato. Puoi avere più di sei round di punteggio combinato se vuoi,
        tutto questo non fa che aggiungere incertezza al mazzo. Il rango di
        difficoltà cambia di una fascia, ad es. 21-25.
      </P>
      <P>
        Ogni percorso fornito nel gioco include anche un punteggio di par
        solitario. Se costruisci un mazzo che equivale a questo punteggio, avrai
        quello che noi ideatori crediamo sia un’esperienza impegnativa. Se
        riesci a vincere con un rango più alto, congratulazioni!
      </P>
      <P>
        Se fatichi a ottenere una vittoria, riduci il rango del mazzo per
        assicurarti di divertirti comunque.
      </P>

      <FastTravelRulesItalian />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeChaosGolf />}
          {language === "fr" && <FrenchModeChaosGolf />}
          {language === "it" && <ItalianModeChaosGolf />}
          {language === "es" && <SpanishModeChaosGolf />}
          {language === "de" && <GermanModeChaosGolf />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
