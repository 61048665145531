import React from "react";
import { H2, P } from "../brand/typography";
import { ModeLinks } from "./ModeLinks";

export const GermanModes = () => {
  return (
    <>
      <H2>MEHRERE SPIELMÖGLICHKEITEN</H2>
      <P>
        18 Holes, wie Golf, hat eine Vielzahl von verschiedenen Spielmodi. Bei
        der in diesem Buch beschriebenen primären Methode konkurrieren alle
        Spieler um jedes Loch. Golf ist ein hartes Spiel, bei dem es schwierig
        sein kann, aufzuholen, wenn man zurückfällt. Wenn dir diese Art von
        Spiel gefällt oder du ein realistischeres Golferlebnis wünscht, dann
        interessiert du dich vielleicht für Golf Rennen, Stroke Play oder
        Stableford.
      </P>
      <P>
        Wenn deine Gruppe Spiele bevorzugt, bei denen alle um jeden Punkt
        konkurrieren, dann versuche es mit Match Play und Skins.
      </P>
      <P>
        Einige Varianten entfernen sich etwas weiter vom Fairway. Diese sind für
        Leute, die ein weniger traditionelles Golferlebnis wünschen. Die Chaos
        Golf-Varianten und Kelly Golf passen auf diese Beschreibung.
      </P>
      <P>
        Wenn du eine Solo-Erfahrung haben möchtest, versuche Solo Chaos Golf,
        Stableford oder Stroke Play.
      </P>
      <P>
        Wenn du ein Spiel mit zwei Spielern willst, dann probiere Chaos Teams:
        unsere engagierte Erfahrung mit zwei Spielern. Oder auch Match Play,
        Skins, Stroke Play, Stableford oder Golf Racing.
      </P>
      <P>
        Jeder Spielmodus wird mit einem Abschnitt geliefert, der den Spielmodus
        beschreibt, wie viele Spieler er unterstützt und warum du dich für
        dieses Spiel interessieren könntest. Auf der rechten Seite haben wir den
        Abschnitt zum Match Play, dem bis hierher beschriebenen Regelsatz.
      </P>
      <P>Index der Variationen:</P>
      <ModeLinks />
    </>
  );
};
