import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, P, ul } from "../../brand/typography";

import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FrenchModeMatchPlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Match Play</H1>
      <P>
        18 Holes comme décrit dans ce livret de règles. Les joueurs s'affrontent
        pour gagner chaque trou indépendamment de leur performance sur les trous
        précédents. Le gagnant est le joueur qui a eu le plus de trous.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>2 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous voulez vivre l'expérience classique du 18 Holes ou bien si vous
        recherchez un jeu plus rapide qui permet à tous les joueurs de
        s'affronter pour chaque trou. En Match Play, un mauvais trou ne ruinera
        pas votre partie.
      </P>

      <H3>COMMENT GAGNER</H3>
      <P>
        1 point pour le premier joueur à atteindre le green. Plusieurs joueurs
        peuvent marquer des points s'ils atteignent le green au cours du même
        tour. En cas d'égalité, le joueur qui a le plus de jetons de « trou un
        un » gagne.
      </P>

      <H3>Variations des règles</H3>
      <P>Aucune</P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <ul className={ul}>
        <li>
          Les drapeaux et les grandes tuiles de green pour marquer des points
        </li>
        <li>Les jetons « Trou en un »</li>
      </ul>
    </>
  );
};

const EnglishModeMatchPlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Match Play</H1>
      <P>
        18 Holes as described in this rule book. Players compete to win each
        hole independent of their performance on prior holes. The winner is the
        player with most holes won.
      </P>

      <H3>Plays</H3>
      <P>2 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You want the classic 18 Holes experience or you’re looking for a quicker
        game that ensures all players can compete for each hole. In Match Play,
        having one bad hole will not ruin your game.
      </P>

      <H3>HOW TO WIN</H3>
      <P>
        1 point for the first player to reach the green. Multiple players score
        the points if they reach the green on the same round. In the event of a
        tie, the player with the most hole-in-one tokens wins.
      </P>

      <H3>Rule Variations</H3>
      <P>None</P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>Flags and large green tiles for scoring</li>
        <li>Hole-in-one tokens</li>
      </ul>
    </>
  );
};

const GermanModeMatchPlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Match Play</H1>
      <P>
        18 Holes, wie in diesem Regelbuch beschrieben. Die Spieler wetteifern
        darum, jedes Loch zu gewinnen, unabhängig von ihrer Leistung bei
        früheren Löchern. Der Gewinner ist der Spieler mit den meisten
        gewonnenen Löchern.
      </P>

      <H3>Spieler</H3>
      <P>2 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        … du das klassische 18-Loch-Erlebnis spielen willst oder du nach einem
        schnelleren Spiel suchst, das sicherstellt, dass alle Spieler um jedes
        Loch konkurrieren können. Im Match Play wird ein schlechtes Loch dein
        Spiel nicht ruinieren.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <P>
        1 Punkt für den ersten Spieler, der das Green erreicht. Mehrere Spieler
        erhalten die Punkte, wenn sie das Green in derselben Runde erreichen.
        Bei einem Gleichstand gewinnt der Spieler mit den meisten
        Hole-in-One-Token.
      </P>

      <H3>Regelvariationen</H3>
      <P>Keine</P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <ul className={ul}>
        <li>Fahnen und große Green-Plättchen für das Punkten</li>
        <li>Hole-in-One-Token</li>
      </ul>
    </>
  );
};

const ItalianModeMatchPlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Match Play</H1>
      <P>
        18 Holes proprio come descritto in questo manuale delle regole. I
        giocatori competono per vincere ciascuna buca a prescindere dai
        risultati alle ottenuti nelle buche precedenti. Il vincitore è il
        giocatore che si sarà aggiudicato il maggior numero di buche.
      </P>

      <H3>GIOCATORI</H3>
      <P>2 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Vuoi la classica esperienza di 18 Holes oppure cerchi una partita più
        veloce per assicurare a tutti i giocatori di competere per ogni buca. In
        Match Play, una buca sbagliata non rovinerà la partita.
      </P>

      <H3>COME SI VINCE</H3>
      <P>
        Un punto per il primo giocatore che raggiunge il green. Più giocatori
        ottengono i punti se raggiungono il green nello stesso round.
        Nell’eventualità di un pareggio, a vincere sarà il giocatorecon il
        maggior numero di token Hole-in-One.
      </P>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <P>Nessuna</P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <ul className={ul}>
        <li>Bandierine e caselle green grandi per il punteggio</li>
        <li>Segnalini Hole-in-One</li>
      </ul>
    </>
  );
};

const SpanishModeMatchPlay = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Match Play</H1>
      <P>
        18 Holes como se describe en este libro de reglas. Los jugadores
        compiten para ganar cada hoyo independientemente de su rendimiento en
        los hoyos anteriores. El ganador es el jugador con más hoyos ganados.
      </P>

      <H3>Jugadas</H3>
      <P>2 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Quieres la clásica experiencia de 18 Holes o buscas un juego más rápido
        que asegure que todos los jugadores puedan competir en cada hoyo. En el
        Match Play, tener un mal hoyo no te fastidiará el juego.
      </P>

      <H3>CÓMO GANAR</H3>
      <P>
        Un punto per il primo giocatore che raggiunge il green. Più giocatori
        ottengono i punti se raggiungono il green nello stesso round.
        Nell’eventualità di un pareggio, a vincere sarà il giocatorecon il
        maggior numero di token Hole-in-One.
      </P>

      <H3>Variaciones de las reglas</H3>
      <P>Ninguna</P>

      <H3>PARTES NECESARIAS PARA ANOTAR</H3>
      <ul className={ul}>
        <li>Banderas y piezas grandes de green para marcar</li>
        <li>Fichas de "Hoyo en uno"</li>
      </ul>
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeMatchPlay />}
          {language === "fr" && <FrenchModeMatchPlay />}
          {language === "it" && <ItalianModeMatchPlay />}
          {language === "es" && <SpanishModeMatchPlay />}
          {language === "de" && <GermanModeMatchPlay />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
