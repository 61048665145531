import React, { useState } from "react";
import putter from "../../images/putter.png";
import { validateCourseForPublish } from "../can-publish-course";
import { CourseDesignerButton } from "../../common/Button";

const GreenCardOption = ({ putters, onGreenCardSetHandler, initialValue = 0 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleMenu = () => {
    setExpanded((c) => !c);
  };

  const setGreenCardCount = (count) => {
    onGreenCardSetHandler(putters, count);
    toggleMenu();
  };

  const visibleStyle = expanded ? "visible" : "hidden";

  return (
    <div className="p-1 flex flex-row justify-center items-center mr-2 w-full">
      <div className="relative inline-block text-left w-full">
        <div className="flex flex-row flex-1 justify-between items-center w-full">
          <div className="flex-1 flex flex-row">
            <img src={putter} className="w-3 h-3 m-1" alt="one putter difficulty" />
            {putters >= 2 && (
              <img src={putter} className="w-3 h-3 m-1" alt="one putter difficulty" />
            )}
            {putters === 3 && (
              <img src={putter} className="w-3 h-3 m-1" alt="one putter difficulty" />
            )}
          </div>
          <button
            type="button"
            className="inline-flex flex flex-1 items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-7000"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={toggleMenu}
          >
            <span>{initialValue}</span>
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          className={`origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${visibleStyle} z-10`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {Array(19)
              .fill(0)
              .map((_, i) => i)
              .map((count) => {
                return (
                  <a
                    key={count}
                    href="#"
                    className="text-gray-700 block px-4 text-sm hover:bg-gray-300"
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${count}}`}
                    onClick={() => {
                      setGreenCardCount(count);
                    }}
                  >
                    {count}
                    {count >= 13 ? `${"\u00A0"}(exclusive)` : ""}
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const WindButton = ({ children, selected, onClick }) => {
  if (!children) {
    return (
      <div className="border b-1 border-white rounded p-1 w-5 h-5 flex justify-center items-center text-center text-xs"></div>
    );
  }

  const colour = selected ? "bg-green-300" : "bg-white";
  const border = selected ? "border-green-900" : "border-gray-300";

  return (
    <button
      onClick={onClick}
      className={`border b-1 rounded p-1 w-5 h-5 flex justify-center items-center text-center text-xs hover:bg-gray-300 ${colour} ${border}`}
    >
      {children}
    </button>
  );
};

const WindCardConfiguration = ({ value, label, alt = false, onChange }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      {!alt && <p className="text-center">{label}</p>}
      <div className="flex flex-row">
        <WindButton selected={value === "0"} onClick={() => onChange(label, 0)}>
          0
        </WindButton>
        <WindButton selected={value === "1"} onClick={() => onChange(label, 1)}>
          1
        </WindButton>
        <WindButton selected={value === "2"} onClick={() => onChange(label, 2)}>
          2
        </WindButton>
        <WindButton selected={value === "3"} onClick={() => onChange(label, 3)}>
          3
        </WindButton>
      </div>
      {alt && <p className="text-center">{label}</p>}
    </div>
  );
};

const WindCardConfigurationLong = ({ label, value, onChange, code, alt }) => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      {alt && <p className="text-center">{label}</p>}
      <div className="flex flex-row justify-between items-center">
        <WindButton selected={value === "0"} onClick={() => onChange(code, 0)}>
          0
        </WindButton>
        <WindButton selected={value === "1"} onClick={() => onChange(code, 1)}>
          1
        </WindButton>
        <WindButton selected={value === "2"} onClick={() => onChange(code, 2)}>
          2
        </WindButton>
        <WindButton selected={value === "3"} onClick={() => onChange(code, 3)}>
          3
        </WindButton>
      </div>
      {!alt && <p className="text-center">{label}</p>}
    </div>
  );
};

const WindCardConfigurationStrong = ({ value, onChange }) => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <p className="text-center">Strong</p>
      <div className="flex flex-row justify-between items-center">
        <WindButton selected={value === "0"} onClick={() => onChange("S", 0)}>
          0
        </WindButton>
        <WindButton selected={value === "1"} onClick={() => onChange("S", 1)}>
          1
        </WindButton>
        <WindButton></WindButton>
        <WindButton></WindButton>
      </div>
    </div>
  );
};

const ExpansionMetaBase = ({
  greenConfiguration,
  onGreenCardSetHandler,
  status,
  windConfiguration,
  onWindConfigurationChange,
  withErrors,
  teeboxes,
  greens,
  holeNumbers,
  positions,
  publish,
}) => {
  const validationErrors = validateCourseForPublish(
    withErrors,
    teeboxes,
    greens,
    holeNumbers,
    positions
  );
  return (
    <div className="flex flex-col justify-start items-start p-1">
      <div className="border rounded b-1 p-1 shadow w-full mt-1">
        <h1 className="text-xl font-bold pb-2">Validation</h1>
        {validationErrors.map((error) => {
          return <p key={error}>{error}</p>;
        })}
        {validationErrors.length === 0 && <p>No validation errors.</p>}
        <div className="self-end">
          <CourseDesignerButton enabled={validationErrors.length === 0} onClick={publish}>
            PUBLISH
          </CourseDesignerButton>
        </div>
      </div>
      {status === "base" && (
        <div className="border rounded b-1 p-1 shadow w-full bg-green-300 mt-1">
          <div className="text-center p-2">
            <p className="text-lg font-bold">This course is for everyone</p>
            <p>It only requires the base game.</p>
          </div>
        </div>
      )}
      {status === "coastlines" && (
        <div className="border rounded b-1 p-1 shadow w-full bg-yellow-300  mt-1">
          <div className="text-center p-2">
            <p className="text-lg font-bold">Requires Expansion</p>
            <p>This course requires the Putting, Wind and Coastlines Expansion to play.</p>
          </div>
        </div>
      )}
      {status === "coastlines-exclusive" && (
        <div className="border rounded b-1 p-1 shadow w-full bg-red-30 mt-1">
          <div className="text-center p-2">
            <p className="text-lg font-bold">Requires Exclusive Pack</p>
            <p>This course requires the Putting, Wind and Coastlines Exclusive pack to play.</p>
          </div>
        </div>
      )}
      <div className="border rounded b-1 p-1 shadow w-full mt-1">
        <h1 className="text-xl font-bold pb-2">Green Cards</h1>
        <GreenCardOption
          putters={1}
          onGreenCardSetHandler={onGreenCardSetHandler}
          initialValue={greenConfiguration.oneClub}
        />
        <GreenCardOption
          putters={2}
          onGreenCardSetHandler={onGreenCardSetHandler}
          initialValue={greenConfiguration.twoClub}
        />
        <GreenCardOption
          putters={3}
          onGreenCardSetHandler={onGreenCardSetHandler}
          initialValue={greenConfiguration.threeClub}
        />
      </div>
      <div className="border rounded b-1 p-1 shadow w-full mt-1">
        <h1 className="text-xl font-bold pb-2">Wind Cards</h1>
        <div className="flex items-center justify-center">
          <WindCardConfiguration
            label="TM"
            value={windConfiguration.TM}
            onChange={onWindConfigurationChange}
          />
        </div>
        <div className="flex items-center justify-center">
          <div className="flex flex-col justify-center items-center flex-1">
            <WindCardConfigurationLong
              label="TL"
              code="TL"
              value={windConfiguration.TL}
              onChange={onWindConfigurationChange}
            />
            <WindCardConfigurationLong
              label="BL"
              code="BL"
              value={windConfiguration.BL}
              onChange={onWindConfigurationChange}
            />
          </div>
          <svg width="100" height="100" viewBox={`0 0 100 100`} className="flex-1">
            <g transform="matrix(1, 0, 0, 1, 0, 0)">
              <path
                fill="white"
                strokeWidth="1"
                stroke="black"
                d="M 98.15 50 L 74.07 91.7 L 25.92 91.7 L 1.85 50 L 25.92 8.3 L 74.07 8.3 Z"
              />
            </g>
          </svg>
          <div className="flex flex-col justify-center items-center flex-1">
            <WindCardConfigurationLong
              label="TR"
              code="TR"
              value={windConfiguration.TR}
              onChange={onWindConfigurationChange}
              alt
            />
            <WindCardConfigurationLong
              label="BR"
              code="BR"
              value={windConfiguration.BR}
              onChange={onWindConfigurationChange}
              alt
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <WindCardConfiguration
            label="BM"
            value={windConfiguration.BM}
            onChange={onWindConfigurationChange}
            alt
          />
        </div>
        <div className="flex items-center justify-between">
          <WindCardConfigurationLong
            label="Calm"
            code="C"
            value={windConfiguration.C}
            onChange={onWindConfigurationChange}
            alt
          />
        </div>
        <div className="flex items-center justify-between">
          <WindCardConfigurationStrong
            value={windConfiguration.S}
            onChange={onWindConfigurationChange}
          />
        </div>
      </div>
    </div>
  );
};

const ExpansionMeta = ExpansionMetaBase;

export default ExpansionMeta;
