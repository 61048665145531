import React from "react";
import { Link } from "react-router-dom";
import {
  ModesChaosGolf,
  ModesChaosGolf2v2,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  ModesCroquetGolf,
  Modes18Cards,
} from "../navigation/locations";
import { link, ul } from "../brand/typography";

export const ModeLinks = () => (
  <ul className={ul}>
    <li className={link}>
      <Link to={Modes18Cards}>18 Cards Solo</Link>
    </li>
    <li className={link}>
      <Link to={ModesChaosGolf2v2}>2v2 Chaos Golf</Link>
    </li>
    <li className={link}>
      <Link to={ModesChaosGolf}>Chaos Golf</Link>
    </li>
    <li className={link}>
      <Link to={ModesChaosTeams}>Chaos Teams</Link>
    </li>
    <li className={link}>
      <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
    </li>
    <li className={link}>
      <Link to={ModesCroquetGolf}>Croquet Golf</Link>
    </li>
    <li className={link}>
      <Link to={ModesGolfRacing}>Golf Racing</Link>
    </li>
    <li className={link}>
      <Link to={ModesKellyGolf}>Kelly Golf</Link>
    </li>
    <li className={link}>
      <Link to={ModesMatchPlay}>Match Play</Link>
    </li>
    <li className={link}>
      <Link to={ModesSkins}>Skins</Link>
    </li>
    <li className={link}>
      <Link to={ModesStableford}>Stableford</Link>
    </li>
    <li className={link}>
      <Link to={ModesStrokePlay}>Stroke Play</Link>
    </li>
  </ul>
);
