import React from "react";
import { ButtonButtonAlt } from "../common/Button";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { useAuth0 } from "./react-auth0-wrapper";
import golfer from "../images/golfer.png";

const ShowLoginBase = () => {
  const { showLock } = useAuth0();

  return (
    <>
      <SiteNavigation />
      <div className="max-w-4xl flex items-center h-auto flex-wrap mx-auto my-0 mt-8">
        <div className="w-3/5 rounded-l-lg shadow-2xl bg-white opacity-75 mx-0">
          <div className="p-12 text-left">
            <h1 className="text-3xl font-bold pb-1">Become a Course Designer</h1>
            <p>
              By signing in you can create your own 18 Holes golf courses to play. You can publish your courses and let
              other players experience your designs.
            </p>
            <div className="mt-8">
              <ButtonButtonAlt enabled onClick={showLock}>
                Sign In
              </ButtonButtonAlt>
            </div>
          </div>
        </div>
        <div className="w-2/5">
          <img
            src={golfer}
            className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
            alt="18 Holes card for The Shark"
          />
        </div>
      </div>
    </>
  );
};

const ShowLogin = ShowLoginBase;

export default ShowLogin;
