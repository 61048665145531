import React from "react";
import { H2, P } from "../brand/typography";
import { ModeLinks } from "./ModeLinks";

export const ItalianModes = () => {
  return (
    <>
      <H2>Diversi Modi Per Giocare</H2>
      <P>
        18 Holes, come il golf, presenta modalità di gioco differenti. Il metodo
        principale descritto in questo manuale fa sì che tutti i giocatori siano
        in competizione per ogni buca. Il golf è un gioco duro dove può essere
        difficile rimettersi in pari se si rimane indietro. Se ti piace quel
        tipo di gioco o se vuoi un’esperienza golfistica più realistica,
        potrebbe interessarti Golf Racing, Stroke Play o Stableford.
      </P>
      <P>
        Se il tuo gruppo preferisce dei giochi in cui tutti quanti competono per
        ogni punto, allora prova Match Play e Skins.
      </P>
      <P>
        Alcune varianti si allontanano un po’ di più dal fairway. Queste sono
        dedicate a chi desidera un’esperienza di golf meno tradizionale. Le
        varianti Chaos Golf e Kelly Golf rientrano in questa descrizione.
      </P>
      <P>
        Se vuoi un’esperienza in solitaria, prova Solo Chaos Golf, Stableford o
        Stroke Play.
      </P>
      <P>
        Se vuoi una partita con due giocatori, prova Chaos Teams: la nostra
        esperienza dedicata per due giocatori. Oppure, uno a scelta fra Match
        Play, Skins, Stroke Play, Stableford o Golf Racing.
      </P>
      <P>
        Ogni modalità di gioco include una sezione che descrive la modalità di
        gioco, quali giocatori supporta e quando potrebbe interessarti questo
        genere di gioco. A destra abbiamo una sezione per Match Play, il set di
        regole descritto fino a questo punto.
      </P>
      <P>Indice delle Varianti:</P>
      <ModeLinks />
    </>
  );
};
