import axios from "axios";
import { BrowseCourses, MyCourses, Published } from "../navigation/locations";

const basePath = "https://api.18holesgame.com";

/**
 * @param {string} token
 * @returns {import("axios").AxiosRequestConfig}
 */
const options = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const createNewCourse = async (token, onTokenExpired) => {
  const body = { positions: [], displayName: "Unnamed Meadows" };
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(`${basePath}/course`, body, options)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};

/**
 *
 * @param {string} token
 * @param {function} onTokenExpired
 * @param {string} courseId
 * @param {CoursePosition[]} positions
 * @param {HoleNumber[]} holeNumbers
 * @param {GreenConfiguration} greenConfiguration
 * @param {string} displayName
 */
export const updateCourse = async (
  token,
  onTokenExpired,
  courseId,
  positions,
  holeNumbers,
  greenConfiguration,
  windConfiguration,
  displayName
) => {
  const body = { positions, displayName, holeNumbers, greenConfiguration, windConfiguration };
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(`${basePath}/course/${courseId}`, body, options).catch((error) => {
    if (error.response && error.response.status === 401) {
      console.info("Token has expired");
      onTokenExpired();
    }

    console.error(error);

    return Promise.reject();
  });
};

export const deleteCourse = async (token, onTokenExpired, courseId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.delete(`${basePath}/course/${courseId}`, options).catch((error) => {
    if (error.response && error.response.status === 401) {
      console.info("Token has expired");
      onTokenExpired();
    }

    console.error(error);

    return Promise.reject();
  });
};

export const getPrivateCourses = async (token, onTokenExpired) => {
  return axios
    .get(`${basePath}/courses`, options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};

export const getPrivateCourse = async (token, courseId, onTokenExpired, history) => {
  return axios
    .get(`${basePath}/course/${courseId}`, options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        onTokenExpired();
      }
      if (error.response && error.response.status === 404) {
        history.push(MyCourses);
      }

      console.error(error);

      return Promise.reject();
    });
};

export const publishCourse = async (token, courseId, onTokenExpired, history) => {
  return axios
    .post(`${basePath}/course/${courseId}/publish`, {}, options(token))
    .then((response) => response.data)
    .then((courseId) => {
      history.push(Published(courseId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        onTokenExpired();
      }
      if (error.response && error.response.status === 404) {
        history.push(MyCourses);
      }

      console.error(error);

      return Promise.reject();
    });
};

export const refreshMetadata = async (token, courseId, onTokenExpired, history) => {
  return axios.post(`${basePath}/course/${courseId}/refresh`, {}, options(token)).catch((error) => {
    if (error.response && error.response.status === 401) {
      onTokenExpired();
    }
    if (error.response && error.response.status === 404) {
      history.push(BrowseCourses);
    }

    console.error(error);

    return Promise.reject();
  });
};
