import React, { useContext, useEffect, useState } from "react";
import Auth0Lock from "auth0-lock";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { IsNewUser, Welcome } from "../navigation/locations";
import moment from "moment";

const lock = new Auth0Lock(
  "geiWVPWqcHnx15maipdoEb766TBNG39H",
  "seabrook-studios.au.auth0.com",
  {
    rememberLastLogin: false,
    auth: {
      sso: false,
      responseType: "token id_token",
      redirectUrl: window.location.origin,
      params: {
        prompt: "select_account",
      },
    },
    autoclose: true,
    allowShowPassword: true,
    allowSignUp: false,
  }
);

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (valueToStore) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } else {
        window.localStorage.removeItem(key);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const Auth0Context = React.createContext({
  token: null,
  isLoggedIn: false,
  showLock: () => {},
  getToken: () => {},
  onTokenExpired: () => {},
  logout: () => {},
});
export const useAuth0 = () => useContext(Auth0Context);

export const withToken = (Component) => (props) => {
  const { token, onTokenExpired } = useContext(Auth0Context);

  return <Component {...props} token={token} onTokenExpired={onTokenExpired} />;
};

export const withLoggedInStatus = (Component) => (props) => {
  const { isLoggedIn } = useContext(Auth0Context);

  return <Component {...props} isLoggedIn={isLoggedIn} />;
};

export const withLogout = (Component) => (props) => {
  const { logout } = useContext(Auth0Context);

  return <Component {...props} logout={logout} />;
};

export const ensureLoggedIn = (Component) => (props) => {
  let history = useHistory();
  const { isLoggedIn } = useContext(Auth0Context);

  if (!isLoggedIn) {
    history.push(Welcome);
    return null;
  }

  return <Component {...props} />;
};

export const Auth0Provider = ({ children }) => {
  const [token, setToken] = useLocalStorage("token", null);
  const [isLoggedIn, setLoggedIn] = useLocalStorage("isLoggedIn", false);

  lock.on("authenticated", (authResult) => {
    setToken(authResult.idToken);
    setLoggedIn(true);

    window.location.href = IsNewUser;
  });

  const logout = () => {
    setToken(null);
    setLoggedIn(false);
  };

  const onTokenExpired = () => {
    logout();
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    const decoded = jwt_decode(token);
    const expiry = decoded.exp;
    const now = moment().unix();

    if (expiry < now) {
      logout();
    }
  }, [token]);

  return (
    <Auth0Context.Provider
      value={{
        token,
        isLoggedIn,
        showLock: () =>
          lock.show({
            allowForgotPassword: false,
            allowSignUp: false,
            rememberLastLogin: true,
          }),
        getToken: () => token,
        onTokenExpired,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
