import React from "react";
import { Credits, Privacy } from "../navigation/locations";
import twitter from "../images/twitter.png";
import facebook from "../images/facebook.png";
import bgg from "../images/bgg.png";

const Footer = () => {
  return (
    <footer className="flex flex-0 items-center justify-center flex-col flex-wrap bg-brand-green p-4 print:hidden">
      <div className="flex items-center justify-center flex-row flex-wrap">
        <a
          className="p-4"
          href="https://twitter.com/18holesgame"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-8 h-8" src={twitter} alt="Twitter Logo" />
        </a>
        <a
          className="p-4"
          href="https://facebook.com/18holesgame"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-8 h-8" src={facebook} alt="Facebook Logo" />
        </a>
        <a
          className="p-4"
          href="https://boardgamegeek.com/boardgame/274234/18-holes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-8 h-8" src={bgg} alt="BoardGameGeek Logo" />
        </a>
      </div>
      <div className="flex items-center justify-center flex-row flex-wrap">
        <a
          href="https://seabrook-studios.com/presskit/18-holes/index.html"
          className="block inline-block mt-0 text-teal-200 hover:text-white mr-4 text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          Press Kit
        </a>
        <a
          href={Privacy}
          className="block inline-block mt-0 text-teal-200 hover:text-white mr-4 text-center"
        >
          Privacy
        </a>
        <a
          href={Credits}
          className="block inline-block mt-0 text-teal-200 hover:text-white mr-4 text-center"
        >
          Credits
        </a>
        <a
          href="https://shop.seabrook-studios.com"
          className="block inline-block mt-0 text-teal-200 hover:text-white mr-4 text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          Shop
        </a>
      </div>
      <div className="flex items-center justify-center flex-row flex-wrap pt-4">
        <a
          href="https://seabrook-studios.com"
          className="block inline-block mt-0 text-teal-200 hover:text-white mr-4 text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          ©2018-2020 Seabrook Studios Pty Ltd.
        </a>
      </div>
    </footer>
  );
};

export default Footer;
