import React from "react";
import { withUserConfiguration } from "./UserConfigurationProvider";

const Hexagon = ({ colour, colour2 }) => (
  <svg width="25" height="25" viewBox={`0 0 100 100`}>
    <g transform="matrix(0.85, 0, 0, 0.85, 0, 0)">
      <path
        fill={colour2}
        strokeWidth="1"
        stroke="black"
        d="M 98.15 50 L 74.07 91.7 L 25.92 91.7 L 1.85 50 L 25.92 8.3 L 74.07 8.3 Z"
      />
    </g>
    <g transform="matrix(0.85, 0, 0, 0.85, 15, 15)">
      <path
        fill={colour}
        strokeWidth="1"
        stroke="black"
        d="M 98.15 50 L 74.07 91.7 L 25.92 91.7 L 1.85 50 L 25.92 8.3 L 74.07 8.3 Z"
      />
    </g>
  </svg>
);

const CourseViewOrGuideBase = ({ showCourse, toggleView }) => {
  return (
    <div
      className="flex flex-row justify-center items-center p-1 md:p-2 cursor-pointer"
      onClick={toggleView}
    >
      <p className="text-white text-sm w-4 text-center">
        <Hexagon
          colour={showCourse ? "green" : "white"}
          colour2={showCourse ? "white" : "green"}
        />
      </p>
    </div>
  );
};
export const CourseViewOrGuide = withUserConfiguration(CourseViewOrGuideBase);
