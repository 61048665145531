import React from "react";
import { H2, P } from "../brand/typography";
import { ModeLinks } from "./ModeLinks";

export const FrenchModes = () => {
  return (
    <>
      <H2>DIFFÉRENTES FAÇONS DE JOUER</H2>
      <P>
        Les 18 Holes, comme le golf, ont plusieurs modes de jeu différents. La
        principale méthode décrite dans ce livre permet à tous les joueurs de
        s'affronter pour chaque trou. Le golf est un jeu difficile où il peut
        être difficile de rattraper son retard. Si vous aimez ce genre de jeu ou
        si vous souhaitez vivre une expérience de golf plus réaliste, vous
        pouvez vous intéresser au golf racing, au Stroke Play ou Stableford.
      </P>
      <P>
        Si votre groupe préfère les jeux où tout le monde se bat pour chaque
        point, essayez Match Play et Skins.
      </P>
      <P>
        Certaines variantes s'éloignent un peu plus du fairway. Elles
        s'adressent aux personnes qui souhaitent une expérience de golf moins
        traditionnelle. Les variantes Chaos Golf et Kelly Golf correspondent à
        cette description.
      </P>
      <P>
        Si vous voulez vivre une expérience en solo, essayez le Chaos Golf, le
        Stableford ou le Stroke Play.
      </P>
      <P>
        Si vous voulez jouer à deux, essayez Chaos Teams : notre expérience
        dédiée à deux joueurs. Vous pouvez aussi jouer en Match Play, Skins,
        Stroke Play, Stableford ou Golf Racing.
      </P>
      <P>
        Chaque mode de jeu est accompagné d'une section qui décrit le mode de
        jeu, le nombre de joueurs qu'il supporte et le moment où vous pourriez
        être intéressé par ce jeu. À droite, nous avons la section pour le Match
        Play, la règle décrite jusqu'à présent.
      </P>
      <P>Index des variations :</P>
      <ModeLinks />
    </>
  );
};
