import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import { useAuth0 } from "../auth/react-auth0-wrapper";
import { Account } from "../navigation/locations";
import {
  getUserAccount,
  updateUserAccount,
  updateUserDisplayName,
} from "../services/user";

const Context = React.createContext({
  username: "",
  designerName: "",
  canChangeUsername: false,
  hasCompleteAccount: false,
  hasFetchedAccountData: false,
  saveProfile: async () => {},
});

export const withAccountInfo = (Component) => (props) => {
  const {
    username,
    designerName,
    canChangeUsername,
    saveProfile,
    hasCompleteAccount,
    hasFetchedAccountData,
  } = useContext(Context);

  return (
    <Component
      {...props}
      username={username}
      designerName={designerName}
      canChangeUsername={canChangeUsername}
      saveProfile={saveProfile}
      hasCompleteAccount={hasCompleteAccount}
      hasFetchedAccountData={hasFetchedAccountData}
    />
  );
};

const UserConfigurationProvider = ({ children }) => {
  let history = useHistory();
  const { token, onTokenExpired } = useAuth0();

  const [username, setUsername] = useState("");
  const [designerName, setDesignerName] = useState("");
  const [canChangeUsername, setCanChangeUsername] = useState(false);
  const [hasCompleteAccount, setHasCompleteAccount] = useState(false);
  const [hasFetchedAccountData, setHasFetchedAccountData] = useState(false);

  const saveProfile = async (newUsername, newDesignerName) => {
    if (!canChangeUsername) {
      console.info("Can't change username, updating designer name only");
      await updateUserDisplayName(token, onTokenExpired, newDesignerName);
      setDesignerName(newDesignerName);
      return;
    }

    try {
      console.info("Updating user account");

      await updateUserAccount(
        token,
        onTokenExpired,
        newUsername,
        newDesignerName
      );

      setUsername(newUsername);
      setDesignerName(newDesignerName);
    } catch (e) {
      return "Username is taken. Please choose another";
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    getUserAccount(token, onTokenExpired).then((userAccount) => {
      setUsername(userAccount.username);
      setDesignerName(userAccount.designerName);
      setCanChangeUsername(userAccount.canChangeUsername);
      setHasFetchedAccountData(true);

      if (userAccount.username) {
        Bugsnag.setUser(userAccount.username, "", userAccount.designerName);
      }

      if (
        (!userAccount.username || !userAccount.designerName) &&
        window.location.pathname !== Account
      ) {
        history.push(Account);
      }
    });
  }, [token]);

  useEffect(() => {
    setHasCompleteAccount(!!username && !!designerName);
  }, [username, designerName]);

  return (
    <Context.Provider
      value={{
        username,
        designerName,
        canChangeUsername,
        saveProfile,
        hasCompleteAccount,
        hasFetchedAccountData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default UserConfigurationProvider;
