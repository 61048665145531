import React from "react";
import { H4, P } from "../../brand/typography";
import { GreenPanel } from "../RuleBookParts";

export const FastTravelRulesFrench = ({ mode = "Chaos Golf" }) => {
  return (
    <div className="pt-4">
      <GreenPanel>
        <H4 className="pb-1 font-bold text-lg uppercase">Déplacement rapide</H4>
        <P>
          Pendant le {mode}, si vous terminez votre tour sur un tee box, vous
          pouvez jouer un tour supplémentaire à la fin du tour. Prenez tous les
          tours supplémentaires dans l'ordre d'initiative. Si deux joueurs
          terminent leur tour sur un tee box, le joueur ayant l'initiative la
          plus faible prend son tour supplémentaire en premier.
        </P>
        <P>
          Lors de votre tour supplémentaire, vous pouvez choisir de jouer avec
          n'importe lequel des clubs disponibles, comme d'habitude. Chaque club
          joué est défaussé.
        </P>
        <P>
          Si vous n'avez pas de clubs en main, vous ne pouvez pas jouer votre
          tour supplémentaire.
        </P>
        <P>
          Si vous terminez un tour supplémentaire sur un tee box, vous pouvez
          prendre un autre tour supplémentaire. Vous pouvez continuer à le faire
          jusqu'à ce que vous n'ayez plus de clubs en main.
        </P>
      </GreenPanel>
    </div>
  );
};

export const FastTravelRulesEnglish = ({ mode = "Chaos Golf" }) => {
  return (
    <div className="pt-4">
      <GreenPanel>
        <H4 className="pb-1 font-bold text-lg uppercase">Fast Travel</H4>
        <P>
          During {mode}, if you end your turn on a tee box, you may take an
          extra turn at the end of the round. Take any extra turns in initiative
          order. If two players end their turn on a tee box, the player with the
          lowest initiative takes their extra turns first.
        </P>
        <P>
          On your extra turn, you may choose to play any of your available clubs
          as usual. Each club played is discarded.
        </P>
        <P>
          If you have no clubs in your hand, then you may not take any
          additional turns.
        </P>
        <P>
          If you end an extra turn on a tee box, you may take another extra
          turn. You can keep doing this until you have run out of clubs.
        </P>
      </GreenPanel>
    </div>
  );
};

export const FastTravelRulesGerman = ({ mode = "Chaos Golf" }) => {
  return (
    <div className="pt-4">
      <GreenPanel>
        <H4 className="pb-1 font-bold text-lg uppercase">Schnellreise</H4>
        <P>
          Wenn du während des {mode} deinen Zug an einem Abschlagplatz beendet
          hast, kannst du am Ende der Runde einen Extra-Schlag machen. Nimm alle
          zusätzlichen Runden in der Reihenfolge der Initiative. Wenn zwei
          Spieler ihren Zug an einem Abschlagplatz beenden, darf der Spieler mit
          der geringsten Initiative zuerst einen Extra-Zug machen.
        </P>
        <P>
          In deinem Extrazug kannst du wie gewohnt gegen einen deiner
          verfügbaren Golfschläger spielen. Jeder gespielte Schläger wird
          verworfen.
        </P>
        <P>
          Wenn du keine Schläger auf der Hand hast, darfst du keine weiteren
          Runden machen.
        </P>
        <P>
          Wenn du einen Extra-Zug an einem Abschlagplatz beendest, kannst du
          einen weiteren Extra-Zug machen. Dies kannst du so lange tun, bis du
          keine Schläger mehr hast.
        </P>
      </GreenPanel>
    </div>
  );
};

export const FastTravelRulesSpanish = ({ mode = "Chaos Golf" }) => {
  return (
    <div className="pt-4">
      <GreenPanel>
        <H4 className="pb-1 font-bold text-lg uppercase">Viaje rápido</H4>
        <P>
          Durante el {mode}, si terminas tu turno en un tee de salida, puedes
          tener un turno extra al final de la ronda. Juega cualquier turno extra
          en orden de iniciativa. Si dos jugadores terminan su turno en un tee
          de salida, el jugador con menor iniciativa juega primero sus turnos
          extra.
        </P>
        <P>
          En tu turno extra, puedes elegir jugar cualquiera de tus palos
          disponibles como siempre. Cada palo jugado se descarta.
        </P>
        <P>
          Si no tienes palos en la mano, entonces no puedes jugar turnos
          adicionales.
        </P>
        <P>
          Si terminas una vuelta extra en un tee de salida, puedes jugar otro
          turno. Puedes seguir haciendo esto hasta que se te acaben los palos.
        </P>
      </GreenPanel>
    </div>
  );
};

export const FastTravelRulesItalian = ({ mode = "Chaos Golf" }) => {
  return (
    <div className="pt-4">
      <GreenPanel>
        <H4 className="pb-1 font-bold text-lg uppercase">VIAGGIO RAPIDO</H4>
        <P>
          Durante il {mode}, se termini il tuo turno su un tee box potrai fare
          un turno extra alla fine del round. Esegui qualsiasi turno extra in
          ordine di iniziativa. Se due giocatori terminano il round sul tee box,
          farà il turno extra per primo quello con l’iniziativa più bassa.
        </P>
        <P>
          Nel tuo turno extra potrai come al solito scegliere di giocare con una
          qualsiasi delle tue mazze disponibili. Ogni mazza con cui hai giocato
          verrà scartata.
        </P>
        <P>
          Se non hai alcuna mazza in mano non potrai fare alcun turno extra.
        </P>
        <P>
          Se termini un turno extra su un tee box potrai fare un altro turno
          extra. Puoi continuare a fare così fino a che non finisci le mazze.
        </P>
      </GreenPanel>
    </div>
  );
};
