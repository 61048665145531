import React from "react";
import { useHistory } from "react-router-dom";
import { ensureLoggedIn } from "../auth/react-auth0-wrapper";
import { BrowseCourses } from "../navigation/locations";
import { withAccountInfo } from "./UserAccountProvider";

const IsNewUserBase = ({ hasCompleteAccount, hasFetchedAccountData }) => {
  let history = useHistory();

  if (hasCompleteAccount && hasFetchedAccountData) {
    setTimeout(() => {
      history.replace(BrowseCourses);
    }, 0);
  }

  return (
    <div className="w-full h-full fixed block top-0 left-0 bg-brand-green flex justify-center items-center">
      <svg
        className="animate-spin h-5 w-5 mr-3 text-black"
        width="100"
        height="100"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

const IsNewUSer = ensureLoggedIn(withAccountInfo(IsNewUserBase));

export default IsNewUSer;
