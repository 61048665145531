import React from "react";
import { Link } from "react-router-dom";
import courseTile from "./images/course-tile.png";
import adjustmentTile from "./images/adjustment-tiles.png";
import clubCard from "./images/en/sample-club.png";
import clubCardWithPlayerMarker from "./images/en/sample-club-with-player-count.png";
import soloCard from "./images/sample-solo.png";
import ruleBook from "./images/rule-book-cover.png";
import courseBook from "./images/course-book-cover.png";
import golferCard from "./images/en/sample-golfer.png";
import shotCard from "./images/sample-shot.png";
import token1 from "./images/1-point.png";
import token2 from "./images/2-points.png";
import token5 from "./images/5-points.png";
import meeple from "./images/meeple.png";
import flags from "./images/flag.png";
import trees from "./images/tree.png";
import holeInOne from "./images/final-hole-in-one-token.png";
import soloMarker from "./images/final-solo-marker.png";
import courseTileWithHighlight from "./images/course-tile-with-highlight.png";
import teeToGreen from "./images/from-tee-to-green.png";
import greenHex from "./images/green-hex.png";
import bunkerHex from "./images/bunker-hex.png";
import treeHex from "./images/tree-hex.png";
import teeboxHex from "./images/teebox-hex.png";
import clubhouse from "./images/clubhouse.png";
import hybrid8 from "./images/en/8-hybrid.png";
import iron9 from "./images/en/9-iron.png";
import iron6 from "./images/en/6-iron.png";
import iron4 from "./images/en/4-iron.png";
import iron3 from "./images/en/3-iron.png";
import wood5 from "./images/en/5-wood.png";
import lobWedge from "./images/en/lw.png";
import driver from "./images/en/driver.png";
import deckC from "./images/deck-c.png";
import deckCStraight from "./images/deck-c-straight.png";
import deckCRight from "./images/deck-c-right.png";
import deck3Left from "./images/deck-3-left.png";
import deck3Super from "./images/en/deck-3-super.png";
import deck4Left from "./images/deck-4-left.png";
import deck4Straight from "./images/deck-4-straight.png";
import deck5right from "./images/deck-5-right.png";
import wildCard from "./images/deck-4-wild.png";
import shuffle from "./images/en/shuffle.png";
import superHit from "./images/en/super-hit.png";
import shortHit from "./images/en/short-hit.png";
import longestDriveCard from "./images/en/longest-drive.png";
import roughHex from "./images/rough-hex.png";
import clareExample from "./images/clare-example.png";
import bunkerOutcomes from "./images/bunker-outcomes.png";
import exitBunkerExample from "./images/exit-bunker.png";
import exitBunkerShotCard from "./images/exit-bunker-shot-card.png";
import roughExample1 from "./images/rough-example-1.png";
import roughExample2 from "./images/rough-example-2.png";
import chippingExample from "./images/chipping-example.png";
import golfer1a from "./images/en/golfer-1-a.png";
import golfer1b from "./images/en/golfer-1-b.png";
import golfer2a from "./images/en/golfer-2-a.png";
import golfer2b from "./images/en/golfer-2-b.png";
import golfer3a from "./images/en/golfer-3-a.png";
import golfer3b from "./images/en/golfer-3-b.png";
import golfer4a from "./images/en/golfer-4-a.png";
import golfer4b from "./images/en/golfer-4-b.png";
import golfer5a from "./images/en/golfer-5-a.png";
import golfer5b from "./images/en/golfer-5-b.png";
import golfer6a from "./images/en/golfer-6-a.png";
import golfer6b from "./images/en/golfer-6-b.png";
import golfer7a from "./images/en/golfer-7-a.png";
import golfer7b from "./images/en/golfer-7-b.png";
import golfer8a from "./images/en/golfer-8-a.png";
import golfer8b from "./images/en/golfer-8-b.png";
import {
  ModesChaosGolf,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  PublicRules,
} from "../navigation/locations";
import {
  Section,
  BoxPart,
  SideBySide,
  SideBySideSection,
  FirstGame,
  GreenPanel,
  Example,
  AlwaysSideBySide,
  HybridCardsEnglish,
  StandardPortraitCardSize,
  StandardLandscapeCardSize,
  SmallPortraitCardSize,
  SmallHex,
  CourseTileSize,
  WaterHazards,
} from "./RuleBookParts";
import { A, H1, H2, note, P, H3, H4, H5, link, ul } from "../brand/typography";

export const RuleBookEnglish = () => {
  return (
    <div className="flex flex-col justify-start items-start p-8 md:w-5/5 print:block">
      <H1>Rule Book</H1>
      <div className="self-center print:hidden">
        <A href={PublicRules} target="_blank">
          Download as PDF
        </A>
      </div>
      <div className="p-4 print:block" style={{ breakAfter: "page" }}>
        <H2>TABLE OF CONTENTS</H2>
        <div className="flex flex-col">
          <A href="#box-contents">CONTENTS OF BOX</A>
          <A href="#establishing-shot">ESTABLISHING TEE-SHOT</A>
          <A href="#overview">OVERVIEW</A>
          <A href="#from-tee-box-to-green">FROM TEE BOX TO GREEN</A>
          <A href="#setup">SETUP</A>
          <A href="#playing-a-round">PLAYING A ROUND</A>
          <A href="#rough">PLAYING FROM THE ROUGH</A>
          <A href="#chipping">CHIPPING</A>
          <A href="#super-short-wild">SUPER, SHORT, SHUFFLE AND WILD CARDS</A>
          <A href="#hazards">HAZARDS &amp; OTHER TILES</A>
          <A href="#golfers">GOLFER ABILITIES</A>
          <A href="#game-modes">MULTIPLE WAYS TO PLAY</A>
        </div>
      </div>
      <Section id="box-contents" title="CONTENTS OF BOX">
        <div className="flex flex-col md:flex-row justify-between items-center md:flex-wrap print:w-full print:flex-row print:flex-wrap">
          <BoxPart src={courseTile} text="49 course tiles" size={200} />
          <BoxPart src={adjustmentTile} text="21 adjustment tiles" size={66} />
          <BoxPart src={meeple} size={150} text="5 golfers" />
          <BoxPart src={golferCard} size={150} text="8 golfer cards" />
          <BoxPart src={clubCard} size={200} text="34 club cards" />
          <BoxPart src={shotCard} size={150} text="74 shot cards" />
          <BoxPart src={soloCard} size={200} text="24 solo chaos golf cards" />
          <BoxPart src={token1} size={66} text="18 1-point tokens" />
          <BoxPart src={token2} size={66} text="18 2-point tokens" />
          <BoxPart src={token5} size={100} text="18 5-point tokens" />
          <BoxPart src={holeInOne} size={66} text="18 hole-in-one tokens" />
          <BoxPart src={soloMarker} size={66} text="1 solo turn marker" />
          <BoxPart src={flags} size={66} text="18 flags &amp; stands" />
          <BoxPart src={trees} size={66} text="14 trees" />
          <BoxPart src={ruleBook} size={66} text="This manuel" />
          <BoxPart src={courseBook} size={66} text="Course book" />
        </div>
        <em className={note}>
          Some game modes require a pen and paper for scoring (not included).
        </em>
      </Section>
      <Section id="establishing-shot" title="ESTABLISHING TEE-SHOT">
        <P>
          <em>
            There is a board meeting taking place, deep in the corporate headquarters of the
            international sporting behemoth, Golf Corp. This meeting will determine the future of
            golf.
          </em>
        </P>
        <P>
          Esteemed board members, things appear dire. Attendance is dropping, advances in club
          technology have made our game too easy. With increased competition for their attention,
          people are no longer able to spend 4 hours playing golf. Low attendance puts pressure on
          our courses to stay afloat. Packs of property developers have begun to hound the owners to
          convert our precious fairways into soulless estates.
        </P>
        <P>
          All is not lost. I’ve had a team of our best minds devising the next iteration of our
          great game. The team have come up with a couple of rule tweaks that will get us out of the
          sand trap and rolling towards the hole.
        </P>
        <P>
          The course owners need more people on their courses, and we have failed to improve on the
          number of good hours in the day. We’re going to change our approach and introduce
          simultaneous play. More people playing golf at once means more green fees. Our patented
          HOLOBALL™ technology will make it safe for everyone in a group to tee off at the same
          time.
        </P>
        <P>
          It is a fantastic technology. I thought the flotilla of drones tracking and stopping golf
          balls was going to be the winner. But this... This making the golf ball the drone is
          clever. Golf balls that never get lost. Golf balls that can stop before it hits someone
          and then restart their flight afterwards. Golf balls that track your score.
        </P>
        <P>
          Now, I admit there have been a few injuries. One might say that the technology is still a
          bit rough. But, I think we can start rolling it out to some of the courses that are
          struggling to stay under par.
        </P>
        <P>
          Now, we used to have a great relationship with club manufacturers. However, lately, each
          new advance in golf technology erodes the challenge, whether it be homing balls,
          super-drivers or the recently released magnetic sand wedges.
        </P>
        <P>
          To strike back at the club manufacturers profiteering off the demise of our great game, we
          have some changes that won’t target them directly but will reduce their influence.
        </P>
        <P>
          Golf has always been not just about shot execution but shot selection. We will reduce the
          club limit from 14 to 5 forcing players to think more about which clubs they take and when
          they use them. Golf is also about planning and working out your approach, for this we will
          not allow players to use the same club until they have used all their other clubs.
        </P>
        <P>Board members. Welcome to the next age of golf!</P>
        <P>
          <em>The board room erupts into applause.</em>
        </P>
      </Section>
      <Section id="overview" title="Overview">
        <P>
          It has been ten years since that board meeting. Golf has never been more popular. Along
          with the invention of HOLOBALL™ technology, new ways to play golf have been created, and
          the sport is thriving. You are a golfer in this golden era of golf. Draft your clubs and
          then compete on the course with careful shot selection and intelligent hazard navigation.
        </P>
        <P>
          The game plays over the 18 holes of a golf course–each hole requiring the player to use
          one or more clubs. After playing a club, players cannot use that club again until playing
          all other clubs. Golfers score points by reaching greens first. The winner is the golfer
          with the most points scored.
        </P>
        <P>
          This book contains rules for many different modes including solo, two-player, golf racing
          and chaos golf. Each game mode lets you tailor the experience for your playgroups. The
          first part of this book covers the standard rule set called Match Play.
        </P>
        <SideBySide title="Course TILES" right={courseTileWithHighlight} size={200}>
          <P>
            Use these tiles to build the course you play on. There are 49 double-sided tiles you can
            use to create a new course each time you play.
          </P>
          <P>
            The tiles have one of three levels of difficulty indicated using stars in the corner of
            each tile. Each tile has a number and an arrow that points in the main direction.
          </P>
          <P>
            See the accompanying booklet for course designs and instructions on how to build
            courses.
          </P>
        </SideBySide>
        <SideBySide title="Adjustment Tiles" right={adjustmentTile} size={132} height="132">
          <P>
            These mini hexes can be placed on top of the course tiles to modify the difficulty of
            the course. For a more relaxed game, cover up the hazards with rough-side up tiles. If
            you’re looking for a challenge, use the hazard side to adjust existing ones or add new
            ones.
          </P>
        </SideBySide>
        <SideBySide title="Golfer Cards" right={golferCard} size={200}>
          <P>
            Players select golfer cards that provide a unique special ability. The a-side abilities
            reduce the challenge while the b-side often contain some restrictions. You pick either
            a-side or b-side when playing.
          </P>
        </SideBySide>
        <SideBySide title="Club Cards" right={clubCard} size={250}>
          <P>
            Each club has a unique ability or tells you which shot deck to draw from. You can take
            only five clubs with you on the course. Once used, you cannot use it again until you
            have used all your other clubs.
          </P>
        </SideBySide>
        <SideBySide title="Shot Cards" right={shotCard} size={200}>
          <P>
            These cards are organised in five decks and represent the different distances and
            directions for your shots. Each contains cards that hit the ball left, right and in some
            cases, your choice of direction.
          </P>
          <P>
            A flight map on each card helps you plot the passage of your ball. The orange hex marks
            where your ball lands. When hitting the ball from the rough, it travels one hex less.
            See&nbsp;
            <A href="#rough">PLAYING FROM THE ROUGH</A>.
          </P>
          <P>
            You also use shot cards to get out of bunkers. The bunker outcome icon is in the bottom
            right corner. See&nbsp;
            <A href="#4d">EXITING BUNKERS, GREENS AND WINNING</A>.
          </P>
        </SideBySide>
        <SideBySide title="Green Markers / Score Tokens" right={courseTileWithHighlight} size={200}>
          <P>
            The circular tokens serve a dual purpose. The front face has greens numbered 1 to 18.
            Since the course tiles don’t have numbers on the greens, placing the tokens number-side
            up helps track the order of the holes.
          </P>
          <P>
            In many of the game modes in 18 Holes, you use the tokens score-side up for scoring.
            These sides show point values of 5, 2 and 1. See{" "}
            <A href="#game-modes">MULTIPLE WAYS TO PLAY</A>.
          </P>
          <P>
            There are spare 1pt, 2pt and 5pt markers. These have no green numbers. Please put them
            in a safe place in case you need them.
          </P>
        </SideBySide>
        <SideBySide title="Hole-in-one Marker" right={holeInOne} size={200}>
          <P>
            These are collected when you score a hole-in-one. In the event of a tie-break, the
            player with the most hole-in-one markers is the winner.
          </P>
        </SideBySide>
        <SideBySide title="Solo Chaos Golf Cards" right={soloCard} size={250}>
          <P>
            Use this deck of cards to organise the flow of the solo chaos golf and to adjust the
            difficulty. These cards have two different symbols. Single colour circles which
            represent turns where only the player scores and two-tone circles where both you and
            your automated opponents score. These are turns where your opponents can score. See{" "}
            <A href={ModesChaosGolfSolo}>SOLO CHAOS GOLF</A>.
          </P>
        </SideBySide>
        <SideBySide title="Solo TURN Marker" right={soloMarker} size={200}>
          <P>Used to track which turn you are on during a game of solo chaos golf.</P>
        </SideBySide>
        <SideBySide title="Flags" right={flags} size={200}>
          <P>
            These flags are placed onto greens to indicate where you are aiming. There is one for
            each hole numbered 1 to 18 and three spare (unnumbered) flags. Put the additional flags
            away for safekeeping.{" "}
          </P>
        </SideBySide>
        <SideBySide title="Trees" right={trees} size={200}>
          <P>
            These trees are decorations that you can put onto your course. Place them over tree
            hazards to make them more prominent. There are only 14 trees, so you’ll need to move
            them along the course as you play.
          </P>
        </SideBySide>
        <SideBySide title="GOLFERs" right={meeple} size={200}>
          <P>
            Each player has one golfer meeple that represents them on the course. There is no
            difference between them, so pick the colour you like best.
          </P>
        </SideBySide>
      </Section>
      <SideBySideSection
        id="from-tee-box-to-green"
        title="From Tee Box to Green"
        right={teeToGreen}
        size="450"
        height="1024"
      >
        <P>
          A game of 18 Holes is played on a course consisting of one or more holes. Most courses
          have 18 Holes although it’s common to have shorter, quicker 9 hole courses. You score a
          point for each hole you win. You win a hole by reaching the green before your opponents.
          Multiple players score in the event of a tie.
        </P>
        <P>
          The green is the small hex with a little black flag on it. To the right we have a single
          hole made of three course tiles. The top tile contains the green. The tee box is on the
          bottom tile. From the tee box to the green runs the fairway.
        </P>
        <P>
          A hole will have you starting with your golfer on the tee box and the task is to get to
          the green. You play clubs that will move your golfer. You don’t have to follow the fairway
          to the green. It can be a good idea though because your shots will go further when played
          from the fairway.
        </P>
        <P>
          There are only a couple of tiles that you cannot land on. These are tree hazards and the
          clubhouse. The tree hazard will have a white fence around it.{" "}
          <A href="#hazards">The clubhouse can be seen here.</A> You can land on the fairways and
          greens of other holes and you can land on tiles that contain other golfers. All this
          thanks to HOLOBALL™.
        </P>
        <P>
          While you can land on most tiles in the game, not all are recommended. Some contain water
          which will cause you to miss a turn and others contain bunkers which you will have to get
          out of before continuing on. The dark green that is on every other tile is the rough and
          that will reduce the distance of your shots. See{" "}
          <A href="#hazards">HAZARDS &amp; OTHER TILES.</A>.
        </P>
        <P>
          There are lots of ways to customise your 18 Holes experience. Different game modes give
          you different experiences and challenges. You can adjust courses to make them easier and
          harder. The eight golfers that come in the game let you make the game easier or harder on
          player basis. By trying the different options we’re confident you’ll find a gaming
          experience you will enjoy and come back to.
        </P>
      </SideBySideSection>
      <Section id="setup" title="Setup">
        <H3>BUILD THE COURSE</H3>
        <P>
          You can set up the course using one of the designs from the course booklet or design one
          of your own. The back page of the course booklet has instructions on how to create
          courses.
        </P>
        <SideBySide right={greenHex} size={132}>
          <P>
            Once you have built your course, place a flag on each hole. Put the 1st flag on the
            green (right) for the first hole and continue to the 18th green. If you’re playing with
            trees, place them on the first 14 trees on the course. The tree hazards are the hexes
            with little white fences around them.
          </P>
        </SideBySide>
        <P>It’s time for everyone to pick a golfer and draft clubs.</P>
        <FirstGame lang="en">
          <A
            href="https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5"
            target="_blank"
          >
            Try this Starting Course.
          </A>
        </FirstGame>
        <H3>SELECT GOLFER</H3>
        <P>
          Each player selects a golfer card to play. You can let the players pick the one and side
          they want or deal one out at random.
        </P>
        <P>
          Each golfer card has an a-side and a b-side. The a-side golfers tend to make the game
          easier, and the b-side golfers tend to make the game harder.
        </P>
        <P>
          Golfer selection happens before club selection as some golfers influence the club
          selection phase.
        </P>
        <FirstGame lang="en">
          Skip the golfer cards until you’re ready to add to the challenge.
        </FirstGame>
        <H3 style={{ breakBefore: "page" }}>DRAFT CLUBS</H3>
        <P>Players will draft for clubs until they have 5 each.</P>
        <SideBySide right={clubCardWithPlayerMarker} size={250}>
          <P>
            Shuffle the club cards. If playing a solo or two-player game, remove clubs that show 3+
            or 5+ in the bottom right corner. In a 3 or 4 player game remove the 5+ cards.
          </P>
        </SideBySide>
        <P>
          Deal the same number of cards to each player. Any remaining cards are not used and are
          returned to the box sight-unseen.
        </P>
        <P>
          Each player selects one club out of the cards dealt and places it in front of them, face
          down. They pass the remaining cards to the person on their left. Again, each player
          selects one card from the remaining four and places it face-down in front of them and
          passes the remainder to the left. Continue until all players have five clubs. This is
          their hand. Each player will also need space for a discard pile for used clubs in front of
          them.
        </P>
        <P>
          If a player has The Shark, Iron Jane or Slugger see{" "}
          <A href="#golfers">GOLFER ABILITIES</A> for rules that change the draft.
        </P>
        <P>
          <em>
            The draft is your first chance to gain an advantage. Course features determine the
            effectiveness of some clubs. Look at the course to see which clubs you think are going
            to be useful.
          </em>
        </P>
        <FirstGame lang="en">
          On the face of some club cards is an A,B,C,D or E. Instead of drafting, give the A’s to
          player 1, the B’s to player 2, etc.
        </FirstGame>
        <H3>BEGIN PLAY</H3>
        <P>
          You are now ready to start playing. Place all the golfers on the tee box of the first
          hole. All players should have their clubs in their hands concealed from other players.
        </P>
        <P>
          Take the 5 shot decks, these have C, 2, 3, 4 or 5 on the back. Shuffle and place within
          reach of all players.
        </P>
      </Section>
      <Section id="playing-a-round" title="Playing A Round">
        <GreenPanel>
          <H3>Playing A Round</H3>
          <P>EACH ROUND OF 18 HOLES IS DIVIDED INTO THESE STEPS.</P>
          <H4 href="#selecting-clubs">SELECTING CLUBS</H4>
          <ul className="pl-4">
            <li>
              <A href="#1">
                1.
                <span className="pl-3">Reset empty hands</span>
              </A>
            </li>
            <li>
              <A href="#2">
                2.
                <span className="pl-3">Select &amp; reveal clubs</span>
              </A>
            </li>
            <li>
              <A href="#3">
                3.<span className="pl-3">Determine play order</span>
              </A>
            </li>
          </ul>
          <H4 href="#on-your-turn">ON YOUR TURN</H4>
          <ul className="pl-4">
            <li>
              <A href="#4">
                4.<span className="pl-3">Complete one action</span>
              </A>
            </li>
            <ul className="pl-4">
              <li>
                <A href="#4a">
                  A.<span className="pl-3">Move one hex</span>
                </A>
              </li>
              <li>
                <A href="#4b">
                  B.<span className="pl-3">Play club card</span>
                </A>
              </li>
              <li>
                <A href="#4c">
                  C.<span className="pl-3">Attempt a big hit</span>
                </A>
              </li>
              <li>
                <A href="#4d">
                  D.<span className="pl-3">Exit bunker</span>
                </A>
              </li>
              <li>
                <A href="#4e">
                  E.<span className="pl-3">Reset your hand</span>
                </A>
              </li>
            </ul>
            <li>
              <A href="#5">
                5.<span className="pl-3">Discard club.</span>
              </A>
            </li>
          </ul>
          <H4 href="#reaching-the-green">REACHING THE GREEN</H4>
          <ul className="pl-4">
            <li>
              <A href="#6">
                6.<span className="pl-3">Score flags</span>
              </A>
            </li>
            <li>
              <A href="#7">
                7.<span className="pl-3">Score hole-in-one tokens</span>
              </A>
            </li>
            <li>
              <A href="#8">
                8.
                <span className="pl-3">Move all players to the next hole</span>
              </A>
            </li>
            <li>
              <A href="#9">
                9.
                <span className="pl-3">Remove any hazard effects</span>
              </A>
            </li>
          </ul>
        </GreenPanel>
        <H3 id="selecting-clubs" href="#selecting-clubs">
          SELECTING CLUBS
        </H3>
        <H4 id="1" href="#1">
          1. RESET Empty HANDs
        </H4>
        <P>If you have no clubs in your hand, draw all your discarded clubs into your hand.</P>
        <P>
          If you have a club in your hand that you cannot use, you may also reset your hand. e.g.
          you have the driver and are not on a tee box.
        </P>
        <P>
          If you are playing Dorian’s a-side, you may only reset your clubs before you tee off on a
          hole.
        </P>
        <H4 id="2" href="#2">
          2. SELECT CLUB
        </H4>
        <P>
          Each player selects one club from their hand and places it face down in front of them.
        </P>
        <P>
          When all players have done this, simultaneously reveal all clubs. Once done, players may
          not change their mind.
        </P>
        <P>
          The player with Bunjil must use their power before placing a club face-down. If Bunjil is
          on their b-side and exposes a shot card, players who have placed a club face down may
          change their mind.
        </P>
        <P>
          The player with Slick Rikesh on his a-side can use his power after the reveal and nominate
          another player’s club to use.
        </P>
        <H4 id="3" href="#3">
          3. DETERMINE PLAY ORDER
        </H4>
        <P>
          Use the initiative order of each club to determine the play order. The club with the
          lowest initiative goes first. You can find the initiative in the top left corner of the
          club card.
        </P>
        <P>
          If Slick Rikesh is using another player’s club, Slick Rikesh plays immediately after the
          other player.
        </P>
        <P>
          If Swift is on her a-side, she wins any initiative ties. If Swift is on her b-side, she
          loses any initiative ties.
        </P>
        <P>
          If Swift ties with Slick Rikesh who is borrowing a club, she wins on her a-side and loses
          on her b-side.
        </P>
        <P>
          <em>
            Looking at the clubs below, the 8 Hybrid has an initiative of 5, and that player takes
            their turn first. The next player is the person with the 6 Iron ( initiative 12). The
            last player to take their turn is the person who played the 5 Wood (initiative 20).
          </em>
        </P>
        <div className="flex flex-col md:flex-row print:flex-row justify-evenly items-center pt-4">
          <div>
            <img src={hybrid8} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron6} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={wood5} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H3 id="on-your-turn" href="#on-your-turn">
          ON YOUR TURN
        </H3>
        <P>Following the play order, each player takes a turn.</P>
        <H4 id="4" href="#4">
          4. COMPLETE ONE ACTION
        </H4>
        <P>
          When it is your turn perform <strong>one</strong> of the following actions:
        </P>
        <ul className={ul}>
          <li>A. Move one hex</li>
          <li>B. Play club card</li>
          <li>C. Attempt a big hit</li>
          <li>D. Exit bunker</li>
          <li>E. Reset your hand</li>
        </ul>
        <P>After you have completed your turn, you discard your club.</P>
        <H4 id="4a" href="#4a">
          4.a) MOVE ONE HEX
        </H4>
        <P>
          Unless in a bunker, you have the option of moving one hex. Place your golfer in one of the
          adjacent hexes. You cannot move onto a hex with a tree hazard or off the board. See{" "}
          <A href="#hazards">HAZARDS &amp; OTHER TILES</A>.
        </P>
        <H4 id="4b" href="#4b">
          4.b) PLAY CLUB CARD
        </H4>
        <P>Depending on the club, you will be able to do one of the following:</P>
        <ul className={ul}>
          <li>Draw 1 card from the shot deck shown on the card</li>
          <li>Draw 1 card from one of two shot decks shown</li>
          <li>Draw 2 cards from the shot deck shown on the card</li>
          <li>Move your golfer the number of hexes in a straight line</li>
        </ul>
        <P>
          Written on each club is the option you have. Playing a club card involves three steps:
          draw, decide, move.
        </P>
        <P>
          For most clubs, you draw your shot cards, decide which direction to hit and then move your
          golfer in that order. When drawing from the chip deck, you must decide the direction
          before you draw a card. See <A href="#chipping">CHIPPING</A>. When playing a wood, you do
          not draw any cards as your club always moves a set distance in a straight line.
        </P>
        <H5>Draw A Single Shot Card</H5>
        <P>
          Most clubs let you draw one card from a single shot deck. Some clubs give you multiple
          decks to choose from, but you may still only draw one card.
        </P>
        <P>
          Pick the deck and draw the top card. If the drawn card has special effects (super, short
          or shuffle), then follow the instructions written on the shot card. Place each additional
          shot card you draw on top of the previous one. Once you have finished drawing, use the
          shot card to work out where to move your golfer.
        </P>
        <P>
          For more information on special cards, see
          <A href="#super-short-wild">SUPER, SHORT, SHUFFLE AND WILD CARDS</A>.
        </P>
        <P>
          If the deck is empty whenever you go to draw, shuffle the discard pile and turn over to
          form a new deck. Do not shuffle cards you drew this turn into the new deck.
        </P>
        <P>
          If you are playing Lucky you may perform your ability and redraw a card at this point.
        </P>
        <H5>Drawing Multiple Shot Cards</H5>
        <P>When drawing 2 shot cards, you get to pick which of the shot cards you want to use.</P>
        <P>
          Place each shot card side-by-side. If one of the shot cards asks you to draw more cards
          (super, short, shuffle), you may choose to follow the instructions on those cards and
          place the new cards over the card that required the draw. When you have finished drawing,
          you must pick one from the top cards visible. You use the selected card to move your
          golfer.
        </P>
        <Example>
          <P>
            In the example below, Gustav has ended up drawing 3 shot cards. He must choose one of
            the top two visible cards.
          </P>
          <div className="flex flex-row justify-evenly items-start w-full">
            <div style={{ paddingTop: 50 }}>
              <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
            </div>
            <div
              style={{
                maxHeight: 275,
                paddingTop: 50,
                overflow: "hidden",
                width: "60%",
              }}
            >
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <img src={deck3Super} style={{ maxHeight: 200, height: 200 }} />
              </div>
              <div style={{ position: "relative", top: -235, left: 35 }}>
                <img src={deck4Straight} style={{ maxHeight: 200, height: 200 }} />
              </div>
            </div>
          </div>
        </Example>
        <H5>Move Your Golfer</H5>
        <P>
          The flight plan shown on your shot card indicates the travel of the ball. The golfer icon
          on the shot card is where your golfer is at the start of the shot. Move your golfer along
          the numbered hexes until you reach the orange finishing hex. You do not have to hit it
          towards the green or along the fairway.
        </P>
        <P>
          If your flight plan travels through a hex with fenced trees, then you must stop before
          entering any hex with the trees. Your ball has hit the trees and has stopped.
        </P>
        <P>
          If you landed in a hazard (bunker, lake), then follow the rules for{" "}
          <A href="#hazards">HAZARDS &amp; OTHER TILES</A>.
        </P>
        <AlwaysSideBySide right={roughHex} size={132}>
          <P>
            If you started your turn in the rough hex (right), see:{" "}
            <A href="#rough">PLAYING FROM THE ROUGH”</A>.
          </P>
        </AlwaysSideBySide>
        <H5 style={{ breakBefore: "page" }}>Changing your mind</H5>
        <P>
          If you have not taken your finger off your golfer, you may alter the direction you have
          hit the ball.
        </P>
        <Example>
          <P>
            Clare finds herself in a position where she cannot react the green in one stroke. She
            plays a 2 Icon and draws a single card from shot deck #4.
          </P>

          <P>
            The drawn shot card bends towards the left. Her best option is to hit off-course and use
            the shape of the shot to land beyond the water hazard adjacent to the green, in an
            excellent position for her next turn.
          </P>
          <div style={{ maxHeight: 350, overflow: "hidden" }}>
            <img src={clareExample} style={{ maxHeight: 350 }} />
            <img
              src={shotCard}
              style={{
                maxHeight: 150,
                height: 150,
                position: "relative",
                top: -350,
                left: 250,
              }}
            />
          </div>
        </Example>
        <H4 id="4c" href="#4c" style={{ breakBefore: "page" }}>
          4.c) ATTEMPT A BIG HIT
        </H4>
        <P>
          Sometimes you need more distance. You can hit your club further than the options displayed
          on the club card. To do so, on your turn, announce to the group that you are performing a
          BIG HIT. You now draw one more card than usual, and all cards must be from the deck one
          higher than the highest numbered deck shown on your club card.
        </P>
        <P>
          After you have drawn your cards, hand them to the player to your left. They discard one of
          the shot cards. Of the cards that remain, you choose one to play.
        </P>
        <P>
          Any super, short, or reshuffle cards play as usual. A super means your Big Hit is even
          bigger than expected and a short will likely disappoint during your time of great need.
        </P>
        <P>
          Not every club allows you to attempt a big hit, for example, clubs that already draw from
          shot deck #5. In the top corner of each club is a symbol that indicates whether you can
          use it for a big hit. In the example below, the driver has a No Big Hit icon while the 9
          Iron shows you can do a Big Hit.
        </P>
        <div className="flex flex-r0ow justify-evenly items-center">
          <div>
            <img src={driver} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron9} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>

        <GreenPanel>
          <H3>Big Hits</H3>
          <SideBySide right={iron4} size={200}>
            <P>
              If your club allows you to draw from shot decks #3 and #4, then you may draw two cards
              from shot deck #5. You may not draw two cards from shot deck #4.
            </P>
          </SideBySide>
          <SideBySide right={iron3} size={200}>
            <P>
              If your club card allows you to draw 2 cards then you must draw 3 cards. In this case
              the other player will discard 1 and you will pick from the remaining 2.
            </P>
          </SideBySide>
        </GreenPanel>
        <H4 id="4d" href="#4d" style={{ breakBefore: "page" }}>
          4.D) EXIT BUNKER
        </H4>
        <P>
          When you land in a bunker, you must get out before continuing play. Before drawing a shot
          card, you must decide the direction you hope to move. This rule is the same as chipping
          (see <A href="#chipping">« CHIPPING »</A>). The hex you chose to move into is your target
          hex.
        </P>
        <P>
          Draw a single shot card from one of the decks shown on the card. Instead of looking at the
          flight path of the shot card, look at the bunker outcome icon in the bottom right corner.
        </P>
        <P>There are four outcomes shown below. From left to right these are:</P>
        <ul className="list-disc list-inside">
          <li>remain in the bunker</li>
          <li>move to the left of your target hex</li>
          <li>move to your target hex</li>
          <li>move to the right of your target hex</li>
        </ul>
        <div className="p-4 flex justify-center items-center">
          <img src={bunkerOutcomes} width={300} />
        </div>
        <P>If you exit the bunker, move your golfer to the hex as determined by the bunker icon.</P>
        <P>
          When the club you have chosen to play is the sand wedge, you consider the bunker as rough.
          You do not need to perform the exit bunker action. If your sand wedge is in your hand or
          your discard pile, it does not protect you from bunkers.
        </P>
        <Example>
          <P>
            Sebastian finds himself in a bunker (A). He declares he is hitting towards the green
            (arrow).
          </P>
          <P>
            He draws a shot card, and the revealed bunker outcome shows the ball going to the right.
          </P>
          <P>He follows the bunker icon and moves to the right (B) of the direction he declared.</P>
          <div style={{ height: 325, overflow: "hidden" }} className="p-4">
            <img
              src={exitBunkerShotCard}
              width={150}
              style={{ position: "relative", top: 100, left: 0 }}
            />
            <img
              src={exitBunkerExample}
              width={300}
              style={{ position: "relative", top: -225, left: 0 }}
            />
            <div
              style={{
                position: "relative",
                top: -249,
                left: 98,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </Example>
        <H4 id="4e" href="#4e">
          4.e) RESET YOUR HAND
        </H4>
        <P>
          You may choose not to hit the ball and reset your hand. Return all your discarded clubs to
          your hand. If you are playing Dorian’s a-side, you may not reset your hand at this point.
        </P>
        <H4 id="5" href="#5">
          5. Discard Club
        </H4>
        <P>
          Your turn ends here. Place the club you used on your discard pile. If you play a card that
          never expires, return it to your hand. e.g. Slugger’s Driver or Dorian’s a-side club. If
          you are playing Slick Rikesh’s b-side or Dorian’s b-side, skip this step.
        </P>
        <H3 id="reaching-the-green" href="#reaching-the-green" style={{ breakBefore: "page" }}>
          Reaching the Green
        </H3>
        <H4 id="6" href="#6">
          6. SCORE FLAGS
        </H4>
        <P>
          When a player reaches the green, they take the flag. If multiple players reach the green
          in the same round, then one player takes the flag and the others a 1-point token.
        </P>
        <H4 id="7" href="#7">
          7. Score Hole-in-one TOKENS
        </H4>
        <P>
          If you scored a hole-in-one, take a hole-in-one token. A hole-in-one is scored on your
          first turn for the hole when you hit from the tee box and land on the green.
        </P>
        <H4 id="8" href="#8">
          8. MOVE TO THE NEXT HOLE
        </H4>
        <P>
          After the players have received their green tokens, move all players to the tee box of the
          next hole.
        </P>
        <H4 id="9" href="#9">
          9. REMOVE ANY HAZARD EFFECTS
        </H4>
        <P>
          Any players that were about to miss a turn because of a water hazard have this status
          removed.
        </P>
        <H3>Winning the Game</H3>
        <P>
          At the end of the course, each player counts the number of flags and 1-point tokens they
          have. Each flag and token is worth 1 point. The player with the most points wins.
        </P>
        <P>
          In the event of a tie, count up the hole-in-one tokens. The player with the most
          hole-in-one tokens wins the tie-break. If players are still tied, those players share the
          win.
        </P>
      </Section>
      <Section id="rough" title="PLAYING FROM THE ROUGH">
        <P>When you start your turn on a rough tile, your shot travels a reduced distance.</P>
        <SideBySide right={wood5} size={250}>
          <P>
            For clubs that go a fixed distance (woods), this is represented by a second hex on your
            club with a rough symbol. Below: the 5-Wood goes 2 hexes when played from the rough and
            3 from a fairway.
          </P>
        </SideBySide>
        <SideBySide right={deck5right} size={200}>
          <P>
            For clubs that draw from shot decks, there is a rough symbol on your shot card (see
            right). Use this instead of the orange hex when determining the final destination for
            your golfer.
          </P>
        </SideBySide>
        <P>
          Rough is considered any tile that does not have a fairway on it. A water hazard is deemed
          to be rough (after you have missed a turn). A water hazard on a fairway (
          <A href="#hazards">HAZARDS AND OTHER TILES</A>) is considered fairway. Most of a golf
          course is rough. General advice is to stay out of it.
        </P>
        <SideBySide RightComponent={HybridCardsEnglish} size={200}>
          <P>
            <em>
              All hybrid clubs (3 Hybrid, 5 Hybrid and 8 Hybrid) suffer no penalties when hitting
              from the rough.
            </em>
          </P>
        </SideBySide>
        <SideBySide right={deckCStraight} size={200}>
          <P>
            <em>
              All shot cards drawn from the chip deck suffer no penalties when hitting from the
              rough. There are no rough symbols on these cards (they always travel 2 hexes).
            </em>
          </P>
        </SideBySide>
        <Example>
          <P>Mackenzie (white disc) finds herself in the rough.</P>
          <img src={roughExample1} width={300} />
          <P>She plays a 2 Iron, and that lets her draw from Shot Deck #4.</P>
          <div className="flex justify-center items-center w-full">
            <img src={deck4Left} width={StandardPortraitCardSize} />
          </div>
          <P>
            The shot card curves left. When plotting out her movement, she uses the rough symbol on
            the shot card to work it out.
          </P>
          <P>
            Because of the reduced distance, she moves two hexes straight and then one to the left
            to land where the rough marker on the shot card shows. In this example, she has landed
            back on the fairway–a good position for her next shot.
          </P>
          <img src={roughExample2} width={300} />
        </Example>
      </Section>
      <Section id="chipping" title="Chipping">
        <SideBySide right={deckC} size={StandardPortraitCardSize}>
          <P>
            The chip deck is different from the other shot decks. These shot cards give you the
            option of going over trees, don’t suffer a rough penalty, and come with one constraint.
            You have to declare the direction you’re going to chip before you draw any cards.
          </P>
        </SideBySide>
        <H4>Declare a direction</H4>
        <P>
          This rule means picking any one of the six directions leaving the hex and telling the
          other players. You can either describe it: “hitting towards the water” or point to it. You
          may not change your direction after you have drawn a shot card.
        </P>
        <H4>Draw Shot Cards</H4>
        <P>Once you have declared your direction, you draw your cards as usual.</P>
        <H4>Move Your Golfer</H4>
        <P>
          The main change to the move your golfer phase is you have already declared which direction
          you are hitting the ball. Move your golfer as usual with one exception.
        </P>
        <P>
          If the hex adjacent to your golfer is a tree you chip over it and land in the orange
          destination hex. You still cannot land on trees, if your target hex is a tree hazard then
          you land on the hex you would have chipped over. If that is also a tree hazard, your
          golfer does not move.
        </P>
        <SideBySide right={lobWedge} size={StandardLandscapeCardSize}>
          <P>
            The Lob Wedge is a unique club in that you do not need to declare the direction you’re
            going to hit the ball before you play it.
          </P>
        </SideBySide>
        <Example>
          <P>
            Brock finds themself in an awkward position with trees blocking progress up the fairway.
            They play their trusty 9 iron and point to the tile they intend to chip over (arrow).
            It’s a safe play as most cards from the chip deck will put them on the fairway.
          </P>
          <P>
            They then draw a shot card from the chip deck to see what fate has dealt them. It pays
            off, and they get a shot card that drifts right putting them back onto the fairway and
            in an excellent position for their next shot.
          </P>
          <div style={{ height: 350, overflow: "hidden" }}>
            <img src={chippingExample} width={350} />
            <img
              src={deckCRight}
              width={SmallPortraitCardSize}
              style={{ position: "relative", left: 0, top: -150 }}
            />
          </div>
        </Example>
      </Section>
      <Section id="super-short-wild" title="SUPER, SHORT, SHUFFLE AND WILD CARDS">
        <H3>Super and ShorT</H3>
        <P>
          With the super and short cards, you put them down on the table and then draw a replacement
          shot card and place it over the top of the super or short card. Draw a replacement card
          from the deck listed on the super or short card.
        </P>
        <P>
          These cards chain, so it’s possible to end up back in the deck that you started from or,
          to go from Shot Deck #2 to Shot Deck #5.
        </P>
        <P>
          A super hit from the chip deck that results in a draw from a shot deck other than the chip
          deck loses its ability to go over trees.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={superHit} width={StandardPortraitCardSize} />
          <img src={shortHit} width={StandardPortraitCardSize} />
        </div>
        <H3>Shuffle</H3>
        <P>
          When you draw a shuffle card, put all current discards from this shot deck back in and
          shuffle them. Place this card on the table and draw a replacement card and place on top.
          Always draw the replacement card from the same deck.
        </P>
        <P>
          Cards that you have drawn so far this turn, e.g. super cards or other cards from clubs
          that let you draw 2 cards, are not put back into the deck.
        </P>
        <P>
          If you draw a shuffle card and there is no discard pile, you do not need to shuffle the
          deck. You still need to draw a replacement card.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={shuffle} width={StandardPortraitCardSize} />
        </div>
        <H3 style={{ breakBefore: "page" }}>Wild Cards</H3>
        <P>
          When you draw a card with multiple outcomes on it, you can choose which one you want to
          use. If one of the options is out-of-bounds, then you must take the only option that
          remains in play.
        </P>
        <P>
          See <A href="#hazards">HAZARDS AND OTHER TILES</A> for more information on out of bounds.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={wildCard} width={StandardPortraitCardSize} />
        </div>
        <H3>LONGEST DRIVE CARD</H3>
        <P>
          When you draw and play this shot card, keep the longest drive card. This card is your
          memento that you hit the longest drive in the game, and if you don’t win today, at least
          you’re going home with something. Not the card, you understand, that stays in the box. You
          get the sense of smug satisfaction of having hit the longest drive.
        </P>
        <P>
          This shot card travels 6 hexes in a straight line. It stops at trees as other shot cards
          do.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={longestDriveCard} width={StandardPortraitCardSize} />
        </div>
      </Section>
      <Section id="hazards" title="HAZARDS &amp; OTHER TILES">
        <SideBySide title="Bunker" right={bunkerHex} size={SmallHex}>
          <P>
            The bunkers hazard applies to all turns where you start in that hex. When you are in the
            bunker, you have to get out. See&nbsp;
            <A href="#4d">“4.D) EXIT BUNKER</A>.
          </P>
          <P>You may use the Sand Wedge to treat bunkers as rough.</P>
        </SideBySide>
        <SideBySide title="Green" right={greenHex} size={SmallHex}>
          <P>
            Your destination. Depending on the game mode, the players that reach the green first win
            the round. When playing from the green of another hole consider the tile fairway.
          </P>
        </SideBySide>
        <SideBySide title="Rough" right={roughHex} size={SmallHex}>
          <P>
            Shots from this hex go one less hex. After drawing your shot look for the rough icon on
            the shot card. This symbol is where your ball lands. See{" "}
            <A href="#rough">PLAYING FROM THE ROUGH</A>.
          </P>
        </SideBySide>
        <SideBySide title="Out-of-Bounds &amp; CLUBHOUSE" right={clubhouse} size={SmallHex}>
          <P>
            Whenever your ball leaves the course, it is out-of-bounds. The ball is also
            out-of-bounds when it goes out-of-bounds for part of its flight and returns to a tile.
            When you are out-of-bounds, your golfer remains where they hit the shot. Return your
            club to your hand; your turn is over.
          </P>
          <P>
            Landing on the clubhouse is also considered out-of-bounds unless of course, you are
            playing onto the 19th.
          </P>
          <P>
            <em>
              Note: The diamond corner pieces of the course tiles are in-play, you can land on them.
            </em>
          </P>
        </SideBySide>
        <div
          className="flex p-8 justify-center items-center"
          style={{ height: 300, overflow: "hidden" }}
        >
          <div className="pt-16">
            <img src={courseTile} width={CourseTileSize} />
            <div
              style={{
                position: "relative",
                top: -240,
                left: 35,
                borderRadius: 75,
                width: 75,
                height: 75,
                flex: 0,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H5>Variation</H5>
        <P>
          We devised the out of bounds rule because most players could not navigate the
          out-of-bounds space without a grid. But some players can. If the players all agree to use
          this variation then you may allow players to hit through out-of-bounds. Landing in
          out-of-bounds is always out-of-bounds.
        </P>
        <SideBySide title="Tee box" right={teeboxHex} size={SmallHex}>
          <P>
            The tee box is where each hole starts. These tiles indicate the par and how many large
            course tiles to add before the green. See the last page of the course booklet for more
            information on building courses.
          </P>
        </SideBySide>
        <SideBySide title="Trees" right={treeHex} size={SmallHex}>
          <P>
            Trees stop the flight of your ball. Move your ball one hex backwards along the flight
            path. If the next hex is a hazard, follow the rules for that hazard.
          </P>
          <P>
            <em>Note: only trees with a fence around them stop the flight of the ball.</em>
          </P>
        </SideBySide>
        <SideBySide
          title="Water (AND fairways with lakes)"
          RightComponent={WaterHazards}
          size={SmallHex}
        >
          <P>
            Water hazards apply when the ball ends its flight on that hex. You miss your next turn.
            Lay your golfer down. On your next turn, stand your golfer up and skip the round. On the
            turn after that, you can play.
          </P>
          <P>
            You play your next shot from the water tile. Play the water tile as rough unless it is a
            fairway with a lake. In that case, it is considered fairway.
          </P>
          <P>The Shark ignores this hazard and plays the underlying tile.</P>
        </SideBySide>
      </Section>
      <Section id="golfers" title="Golfer Abilities">
        <P>
          There are eight different golfers in 18 Holes, and each of them brings a different
          experience. Each golfer has an a-side and a b-side. The a-side makes the game easier and
          the b-side makes the game harder. There is an A or a B in the bottom right-hand corner.
        </P>
        <H3>BUNJIL</H3>
        <SideBySide right={golfer2a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            Immediately before Bunjil selects their club to play, they may look at the top card of
            one deck once. Turn Bunjil sideways (landscape) to indicate their power as used. The
            player must not show the card to other players. Return the card face down to the top of
            the shot deck.
          </P>
          <P>When the hole has ended, turn Bunjil back to the portrait orientation.</P>
          <P>Bunjil may only look at shot decks for cards they could legitimately draw from.</P>
        </SideBySide>
        <SideBySide right={golfer2b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            As per a-side plus Bunjil has to decide to either keep the shot card and use it on their
            next shot or return the card to the top of the shot deck, face up for all players to
            see.
          </P>
          <P>
            If Bunjil keeps the card, then the player must play a club that can access that shot
            deck.
          </P>
        </SideBySide>
        <H3>Dorian</H3>
        <SideBySide right={golfer8a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            After the card draft, before the first hole, the player with Dorian tells the rest of
            the group which of their clubs never expire. Whenever you use this club, return it to
            your hand instead of being discarded.
          </P>
          <P>
            Dorian may not reset their clubs during a hole but may reset their clubs at the end of a
            hole if their hand only contains their club that never expires (and meets the rules for
            a hand reset).
          </P>
        </SideBySide>
        <SideBySide right={golfer8b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            Dorian never discards their clubs after use. Dorian may only take three clubs during the
            draft.
          </P>
        </SideBySide>
        <H3>Iron Jane</H3>
        <SideBySide right={golfer3a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            Before the Club Draft, the player with Iron Jane selects their clubs from the clubs
            available. Shuffle the remaining cards and deal them to the other players.
          </P>
        </SideBySide>
        <SideBySide right={golfer3b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            As per the a-side plus Iron Jane may only carry four clubs. Iron Jane may not take two
            of the same club.
          </P>
        </SideBySide>
        <H3>Lucky</H3>
        <SideBySide right={golfer6a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            During play, once per hand, Lucky may redraw a shot card. To do this, the player with
            Lucky tells the playing group and then turns Lucky sideways (landscape) to indicate
            their power as used. The player then draws a new shot card from the same deck as the
            previous one. Lucky must play the new card.
          </P>
          <P>While Lucky is oriented in landscape you cannot use this power.</P>
          <P>When resetting your hand, return Lucky to the portrait orientation.</P>
        </SideBySide>
        <SideBySide right={golfer6b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            As per a-side plus when using Lucky’s ability, the player discards one of their other
            clubs. If the player has no other clubs, they may not use Lucky’s power.
          </P>
          <P>You may only perform Lucky’s power once per hole.</P>
        </SideBySide>
        <H3>Slick Rikesh</H3>
        <SideBySide right={golfer4a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            After all players have revealed their club cards, Slick Rikesh may use another player’s
            chosen club. Slick Rikesh’s club initiative is the same as the original owner’s club and
            plays immediately afterwards. Slick Rikesh may only use his power once per hole.
          </P>
          <P>
            Rotate Slick Rikesh sideways (landscape) to indicate their power as used. Reset to
            portrait position after each hole.
          </P>
        </SideBySide>
        <SideBySide right={golfer4b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            The player with Slick Rikesh only picks one card during the draft and may pick that club
            from any round of the draft.
          </P>
          <P>
            During play, Slick Rikesh will play their club as usual (to determine initiative) and
            may then select any discarded club of another player to use. If there are no discarded
            clubs, Slick Rikesh must play their club.
          </P>
        </SideBySide>
        <H3>Slugger</H3>
        <SideBySide right={golfer5a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            Slugger must carry the driver. Remove this card from the deck before the Club Draft and
            give it to the player with Slugger. Slugger will select four clubs during the draft,
            skipping the last turn.
          </P>
          <P>During play, you never discard the driver. Return it to your hand.</P>
        </SideBySide>
        <SideBySide right={golfer5b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            As per the a-side plus Slugger must use a Wood or Driver on all tee boxes. Yes,
            including par-3s.
          </P>
        </SideBySide>
        <H3>SWIFT</H3>
        <SideBySide right={golfer7a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            During the Determine Play Order step, subtract one from the initiative of the club
            played by Swift. If Swift’s initiative ties with another player, Swift goes first.
          </P>
        </SideBySide>
        <SideBySide right={golfer7b} size={StandardPortraitCardSize}>
          <H4>B-Side</H4>
          <P>
            During the Determine Play Order step, subtract one from the initiative of the club
            played by Swift. If Swift’s initiative ties with another player, Swift goes after that
            player.
          </P>
        </SideBySide>
        <H3>The Shark</H3>
        <SideBySide right={golfer1a} size={StandardPortraitCardSize}>
          <H4>A-Side</H4>
          <P>
            Water hazards are of no concern to The Shark, including those on fairways. Treat them as
            per the underlying tile.
          </P>
        </SideBySide>
        <H4>B-Side</H4>
        <SideBySide right={golfer1b} size={StandardPortraitCardSize}>
          <P>As per a-side plus The Shark may not carry any wedges.</P>
          <P>Wedges include Pitching Wedge, Sand Wedge and the Lob Wedge.</P>
          <P>
            During the Club Draft Phase, a player who has The Shark must pass if the only available
            clubs are wedges. The Shark can pick from the remaining cards after the draft.
          </P>
        </SideBySide>
      </Section>
      <Section id="game-modes" title="MULTIPLE WAYS TO PLAY">
        <P>
          18 Holes, like golf, has a variety of different game modes. The primary method described
          in this book keeps all players competing for each hole. Golf is a tough game where it can
          be hard to catch up if you fall behind. If you like that kind of game or want a more
          realistic golf experience, then you may be interested in Golf Racing, Stroke Play or
          Stableford.
        </P>
        <P>
          If your group prefers games that keep everyone competing for each point, then try Match
          Play and Skins.
        </P>
        <P>
          Some variations stray a bit further from the fairway. These are for people who want a less
          traditional golf experience. The Chaos Golf variations and Kelly Golf fit this
          description.
        </P>
        <P>If you want a solo experience, then try Solo Chaos Golf, Stableford or Stroke Play.</P>
        <P>
          If you want a two-player game, then try Chaos Teams: our dedicated two-player experience.
          Or, one of Match Play, Skins, Stroke Play, Stableford or Golf Racing.
        </P>
        <P>
          Each game mode comes with a section that describes the game mode, how many players it
          supports and when you might be interested in this game. To the right we have the section
          for Match Play, the rule set described up to this point.
        </P>
        <P>Index of Variations:</P>
        <ul className="list-disc list-inside">
          <li className={link}>
            <Link to={ModesChaosGolf}>Chaos Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosTeams}>Chaos Teams</Link>
          </li>
          <li className={link}>
            <Link to={ModesGolfRacing}>Golf Racing</Link>
          </li>
          <li className={link}>
            <Link to={ModesKellyGolf}>Kelly Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesMatchPlay}>Match Play</Link>
          </li>
          <li className={link}>
            <Link to={ModesSkins}>Skins</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
          </li>
          <li className={link}>
            <Link to={ModesStableford}>Stableford</Link>
          </li>
          <li className={link}>
            <Link to={ModesStrokePlay}>Stroke Play</Link>
          </li>
        </ul>
      </Section>
    </div>
  );
};
