import React, { useContext, useState } from "react";

const Context = React.createContext({
  units: "cm",
  language: "en",
  showCourse: true,
  toggleUnits: () => {},
  toggleView: () => {},
  setEnglish: () => {},
  setFrench: () => {},
  setItalian: () => {},
  setGerman: () => {},
  setSpanish: () => {},
});

export const withUserConfiguration = (Component) => (props) => {
  const { units, language, toggleUnits, showCourse, toggleView } = useContext(
    Context
  );

  return (
    <Component
      {...props}
      units={units}
      language={language}
      toggleUnits={toggleUnits}
      showCourse={showCourse}
      toggleView={toggleView}
    />
  );
};

export const withLanguage = (Component) => (props) => {
  const {
    language,
    setFrench,
    setEnglish,
    setItalian,
    setGerman,
    setSpanish,
  } = useContext(Context);

  return (
    <Component
      {...props}
      setFrench={setFrench}
      setEnglish={setEnglish}
      setItalian={setItalian}
      setGerman={setGerman}
      setSpanish={setSpanish}
      language={language}
    />
  );
};

const UserConfigurationProvider = ({ children }) => {
  const [units, setUnits] = useState("cm");
  const [showCourse, setShowCourse] = useState(false);
  const [language, setLanguage] = useState("en");

  function toggleUnits() {
    setUnits((current) => (current === "cm" ? "in" : "cm"));
  }

  function toggleView() {
    setShowCourse((current) => !current);
  }

  const setEnglish = () => {
    setLanguage("en");
  };

  const setFrench = () => {
    setLanguage("fr");
  };

  const setItalian = () => {
    setLanguage("it");
  };

  const setGerman = () => {
    setLanguage("de");
  };

  const setSpanish = () => {
    setLanguage("es");
  };

  return (
    <Context.Provider
      value={{
        units,
        language,
        toggleUnits,
        setEnglish,
        setFrench,
        setItalian,
        setGerman,
        setSpanish,
        showCourse,
        toggleView,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default UserConfigurationProvider;
