import React from "react";
import { Button } from "../common/Button";
import logo from "../images/logo_18_holes.png";
import circleLogo from "../images/icon.png";
import { Measurement } from "../user/Measurement";
import { CourseViewOrGuide } from "../user/CourseViewOrGuide";
import { AccountButton } from "../user/AccountButton";
import { BrowseCourses, CaddyHome, FAQ, Modes, MyCourses, Rules } from "./locations";
import { Link } from "react-router-dom";

export const SiteNavigation = () => {
  return (
    <nav className="flex flex-0 items-center justify-between flex-row flex-wrap bg-brand-green p-4 print:hidden">
      <Link className="flex items-center flex-shrink-0 text-white mr-2 md:mr-6" to="/">
        <img className="h-16 w-auto image-contain pb-3 hidden md:block" src={logo} alt="18 Holes game logo" />
        <img className="h-8 w-auto image-contain md:hidden" src={circleLogo} alt="18 Holes game logo" />
      </Link>
      <div className="flex w-20 md:w-auto text-sm flex-grow ">
        <Link
          to={BrowseCourses}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-center hidden md:block"
          style={{ marginRight: "0.5em" }}
        >
          Find a Course
        </Link>
        <Link
          to={BrowseCourses}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs text-center md:hidden"
          style={{ marginRight: "0.5em" }}
        >
          Courses
        </Link>
        <Link
          to={MyCourses}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-center hidden md:block"
          style={{ marginRight: "0.5em" }}
        >
          Course Designer
        </Link>
        <Link
          to={MyCourses}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs text-center md:hidden"
          style={{ marginRight: "0.5em" }}
        >
          Designer
        </Link>
        <Link
          to={Rules}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs md:text-sm text-center"
          style={{ marginRight: "0.5em" }}
        >
          Rules
        </Link>
        <Link
          to={FAQ}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs md:text-sm text-center"
          style={{ marginRight: "0.5em" }}
        >
          FAQ
        </Link>
        <Link
          to={Modes}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs md:text-sm text-center"
          style={{ marginRight: "0.5em" }}
        >
          Modes
        </Link>
        <a
          href={CaddyHome}
          className="block inline-block mt-0 text-teal-200 hover:text-white md:mr-4 text-xs md:text-sm text-center"
          style={{ marginRight: "0.5em" }}
          target="_blank"
        >
          Caddy
        </a>
      </div>

      <div className="pr-1">
        <AccountButton />
      </div>

      <div className="pr-2">
        <CourseViewOrGuide />
      </div>

      <div className="pr-1">
        <Measurement />
      </div>

      <div className="pl-1 md:pl-2">
        <Button href="https://shop.seabrook-studios.com">Shop</Button>
      </div>
    </nav>
  );
};
