import React from "react";

export const Private = () => {
  return (
    <p className="text-sm text-black flex items-center p-2 bg-white bg-opacity-75 rounded-b">
      <svg
        className="fill-current text-black w-3 h-3 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
      </svg>
      Unpublished
    </p>
  );
};
