import React, { useState, useEffect, createContext, useContext } from "react";
import numeral from "numeral";
import meta from "../meta.json";
import { coastlinesTiles, coastlinesExclusiveTiles } from "../coastlinesTiles";

export const initialFilterState = {
  showGreens: false,
  showFairways: false,
  showTeeboxes: false,
  showExpansion: false,
  showExclusive: false,
  showUnused: false,
};

const TotalTiles = 95;

const hasMetaOnly = ({ a, b }) => meta[a] && meta[b];

const Context = createContext({
  tiles: [],
  onSelectTile: () => {},
  selectedTile: null,
  selectedFace: null,
  onFilterChange: () => {},
});

export const withOnFilterChange = (Component) => (props) => {
  const { onFilterChange } = useContext(Context);

  return <Component {...props} onFilterChange={onFilterChange} />;
};

export const withPaletteTiles = (Component) => (props) => {
  const { tiles, onSelectTile, selectedTile, selectedFace } = useContext(Context);

  return (
    <Component
      {...props}
      tiles={tiles}
      onSelectTile={onSelectTile}
      selectedTile={selectedTile}
      selectedFace={selectedFace}
    />
  );
};

const setForTileId = (id) => {
  if (id < 79) {
    return "base";
  }
  if (id < 93) {
    return "coastlines";
  }

  return "coastlines-exclusive";
};

const initialTileData = Array(TotalTiles)
  .fill(0)
  .map((_, i) => i + 1)
  .map((n) => ({
    tile: n,
    a: `t${numeral(n).format("00")}a`,
    b: `t${numeral(n).format("00")}b`,
    set: setForTileId(n),
  }))
  .filter(hasMetaOnly);

const PaletteBase = ({ onBrushSelect, used, children }) => {
  const [filters, setFilters] = useState(initialFilterState);
  const [selectedTile, setSelectedTile] = useState("");
  const [selectedFace, setSelectedFace] = useState("");
  const [tiles, setTiles] = useState(initialTileData);

  const filterGreens = ({ a, b }) => {
    if (!filters.showGreens) {
      return true;
    }

    return filters.showGreens && (meta[a].hasGreen || meta[b].hasGreen);
  };
  const filterFairways = ({ a, b }) => {
    if (!filters.showFairways) {
      return true;
    }

    return filters.showFairways && (meta[a].isFairway || meta[b].isFairway);
  };

  const filterByTeeboxes = ({ a, b }) => {
    if (!filters.showTeeboxes) {
      return true;
    }

    return filters.showTeeboxes && (meta[a].isTeeBox || meta[b].isTeeBox);
  };

  const filterByExpansion = ({ a, b }) => {
    if (!filters.showExpansion) {
      return true;
    }

    return filters.showExpansion && (coastlinesTiles.includes(a) || coastlinesTiles.includes(b));
  };

  const filterByExclusive = ({ a, b }) => {
    if (!filters.showExclusive) {
      return true;
    }

    return (
      filters.showExclusive &&
      (coastlinesExclusiveTiles.includes(a) || coastlinesExclusiveTiles.includes(b))
    );
  };

  const filterByUsed = ({ a, b }) => {
    if (!filters.showUnused) {
      return true;
    }

    return filters.showUnused && !(used.includes(a) || used.includes(b));
  };

  useEffect(() => {
    const newTiles = initialTileData
      .map(addUnavailableStatus)
      .filter(filterGreens)
      .filter(filterFairways)
      .filter(filterByTeeboxes)
      .filter(filterByExpansion)
      .filter(filterByExclusive)
      .filter(filterByUsed);

    setTiles(newTiles);
  }, [used, filters]);

  const select = (tile, side) => {
    return () => {
      setSelectedTile(tile);
      selectFace(side, () => onBrushSelect(side));
    };
  };

  const selectFace = (face, next) => {
    setSelectedFace(face);
    next();
  };

  const addUnavailableStatus = ({ a, b, tile, set }) => ({
    tile,
    a,
    b,
    unavailable: used.includes(a) || used.includes(b),
    set,
  });

  function onFilterChange(newFilters) {
    setFilters(newFilters);
  }

  return (
    <Context.Provider
      value={{
        tiles,
        onSelectTile: select,
        selectedTile,
        selectedFace,
        onFilterChange,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const PaletteControl = PaletteBase;

export default PaletteControl;
