import React from "react";
import { withFilter } from "./PublicCoursesProvider";

const FilterBase = ({ name, id, filters, toggleFilter }) => {
  const selected = filters[id];

  function clickHandler() {
    toggleFilter(id);
  }

  const background = selected ? "bg-brand-green" : "";
  const textColour = selected ? "text-white" : "text-black";

  return (
    <div className="w-1/5 md:w-1/6 flex p-1">
      <div
        onClick={clickHandler}
        className={`cursor-pointer w-full flex border rounded border-brand-green hover:text-teal-500 hover:bg-opacity-50 justify-center items-center ${background}`}
        style={{ height: 75 }}
      >
        <p className={`text-sm text-center text-brand-green p-1 ${textColour}`}>{name}</p>
      </div>
    </div>
  );
};

const Filter = withFilter(FilterBase);

export const Filters = () => {
  return (
    <div className="flex flex-row w-full flex-wrap justify-start flex-wrap bg-blue">
      {/* <Filter name="My Courses" /> */}
      {/* <Filter name="My Favourites" /> */}
      <Filter name="Short Courses" id="short" />
      <Filter name="18 Holes" id="full" />
      <Filter name="18 Cards Variant" id="18-cards" />
      {/* <Filter name="Chaos &amp; Kelly Golf" id="chaos" />
      <Filter name="Solo Ready" id="solo" />
      <Filter name="Easy Courses" id="easy" />
      <Filter name="Medium Courses" id="medium" />
      <Filter name="Hard Courses" id="hard" /> */}
      <Filter name="Coastlines" id="coastlines" />
      <Filter name="Coastlines Exclusive" id="coastlines-exclusive" />
    </div>
  );
};
