import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const Slot = ({ children, position, onMoveTo }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "HOLE",
    drop: ({ hole }) => {
      onMoveTo(hole.holeNumber, position);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
      {isOver && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: "yellow",
          }}
        />
      )}
    </div>
  );
};

const Hole = ({ hole }) => {
  const [{ opacity }, dragRef] = useDrag({
    item: { type: "HOLE", hole },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  const tileCountText = `${hole.tileCount} Tile${
    hole.tileCount === 1 ? "" : "s"
  }`;

  return (
    <div
      className="flex flex-row w-full justify-center items-center"
      ref={dragRef}
      style={{ opacity }}
    >
      <div className="flex flex-0 p-2 justify-center items-center">
        <div
          className="w-8 h-8 rounded flex justify-center items-center"
          style={{ backgroundColor: hole.colour }}
        >
          <p className="text-center">{hole.holeNumber}</p>
        </div>
      </div>
      <div className="flex flex-row w-full p-1">
        <div className="flex flex-row w-full border rounded">
          <div className="flex flex-1 justify-between items-center flex-row pl-4">
            <p>{tileCountText}</p>
            <div className="flex flex-1 justify-end items-center flex-row">
              <p className="pr-2">PAR</p>
              <div className="flex flex-col justify-center items-center p-2">
                {hole.par.map((p) => (
                  <p className="text-center">{p}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HoleSequencing = ({ holeStartTiles, onMoveTo, height }) => {
  return (
    <div
      className="flex w-full flex-1 flex-col justify-start items-start bg-white rounded-b whitespace-no-wrap overflow-y-scroll overflow-x-hidden"
      style={{ height }}
    >
      <DndProvider backend={HTML5Backend}>
        {holeStartTiles.map((hole, i) => (
          <Slot key={hole.id} position={i + 1} onMoveTo={onMoveTo}>
            <Hole hole={hole} />
          </Slot>
        ))}
      </DndProvider>
    </div>
  );
};

export default HoleSequencing;
