export const BrowseCourses = "/courses";
export const MyCourses = "/u/courses";
export const Account = "/account";
export const Welcome = "/welcome";
export const Privacy = "/privacy";
export const Credits = "/credits";
export const IsNewUser = "/new-user";
export const More = "/more";
export const FAQ = "/faq";
export const Rules = "/rules";
export const RuleBook = "/rulebook";
export const Modes = "/modes";
export const ModesChaosGolf = "/modes/chaos-golf";
export const ModesChaosGolf2v2 = "/modes/chaos-golf-2v2";
export const ModesChaosTeams = "/modes/chaos-teams";
export const ModesGolfRacing = "/modes/golf-racing";
export const ModesKellyGolf = "/modes/kelly-golf";
export const ModesSkins = "/modes/skins";
export const ModesChaosGolfSolo = "/modes/chaos-golf-solo";
export const ModesStableford = "/modes/stableford";
export const ModesStrokePlay = "/modes/stroke-play";
export const ModesCroquetGolf = "/modes/croquet-golf";
export const ModesMatchPlay = "/modes/match-play";
export const Modes18Cards = "/modes/18-cards";
export const PrintAndPlay = "/pnp";
export const CaddyHome = "https://screentop.gg/@seabrookstudios/18-holes";
export const CaddyJoinGame = "/caddy/join";
export const PublicRules = "https://drive.google.com/drive/u/1/folders/1aOLbeKWtyW5pn8C0-Q66NzlvGJfEYGqI";
export const CaddyGame = (gameId) => `/caddy/game/${gameId}`;
export const Designer = (courseId) => `/u/course/${courseId}`;
export const Published = (id) => `/p/${id}`;
export const PublishedDesigner = (username) => `/p/${username}`;
