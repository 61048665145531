import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ReactDOM from "react-dom";
import "./index.css";
import "./tailwind.output.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Auth0Provider } from "./auth/react-auth0-wrapper";

Bugsnag.start({
  collectUserIp: false,
  generateAnonymousId: false,
  apiKey: "cdf9c8ba93e9bb7235386cdedf75e512",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider>
      <App />
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

registerServiceWorker();
