import React from "react";
import { link, P } from "../../brand/typography";
import { ModesMatchPlay } from "../../navigation/locations";
import { Link } from "react-router-dom";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FromMatchPlayFrench = () => (
  <>
    <P>
      Utilisez les règles du{" "}
      <Link className={link} to={ModesMatchPlay}>
        Match Play
      </Link>
      , sauf en cas de conflit avec les règles ci-dessous.
    </P>
  </>
);

const FromMatchPlayEnglish = () => (
  <>
    <P>
      Use the rules for&nbsp;
      <Link className={link} to={ModesMatchPlay}>
        Match Play
      </Link>
      &nbsp;except when in conflict with the rules below.
    </P>
  </>
);

const FromMatchPlayItalian = () => (
  <>
    <P>
      Usa le regole per&nbsp;
      <Link className={link} to={ModesMatchPlay}>
        Match Play
      </Link>
      &nbsp;eccetto quando vanno in conflitto con le regole sotto.
    </P>
  </>
);

const FromMatchPlaySpanish = () => (
  <>
    <P>
      Utiliza las reglas del&nbsp;
      <Link className={link} to={ModesMatchPlay}>
        Match Play
      </Link>
      , excepto cuando entren en conflicto con las reglas que se indican a
      continuación.
    </P>
  </>
);

const FromMatchPlayGerman = () => (
  <>
    <P>
      Verwende die Regeln für das&nbsp;
      <Link className={link} to={ModesMatchPlay}>
        Match Play
      </Link>
      , es sei denn, sie stehen im Widerspruch zu den nachstehenden Regeln.
    </P>
  </>
);

const FromMatchPlayBase = ({ language }) => (
  <>
    {language === "fr" && <FromMatchPlayFrench />}
    {language === "en" && <FromMatchPlayEnglish />}
    {language === "it" && <FromMatchPlayItalian />}
    {language === "es" && <FromMatchPlaySpanish />}
    {language === "de" && <FromMatchPlayGerman />}
  </>
);

export const FromMatchPlay = withLanguage(FromMatchPlayBase);
