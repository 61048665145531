import React, { useState, useEffect, createContext, useContext } from "react";
import numeral from "numeral";
import meta from "../adjustment-meta.json";

const TotalTiles = 21;

const hasMetaOnly = ({ a, b }) => meta[a] && meta[b];

const Context = createContext({
  tiles: [],
  onSelectTile: () => {},
  selectedTile: null,
  selectedFace: null,
});

export const withAdjustmentPaletteTiles = (Component) => (props) => {
  const { tiles, onSelectTile, selectedTile, selectedFace } = useContext(
    Context
  );

  return (
    <Component
      {...props}
      tiles={tiles}
      onSelectTile={onSelectTile}
      selectedTile={selectedTile}
      selectedFace={selectedFace}
    />
  );
};

const initialTileData = Array(TotalTiles)
  .fill(0)
  .map((_, i) => i + 1)
  .map((n) => ({
    tile: n,
    a: `a${numeral(n).format("00")}a`,
    b: `a${numeral(n).format("00")}b`,
  }))
  .filter(hasMetaOnly);

const AdjustementHexPaletteControlBase = ({
  onBrushSelect,
  used,
  children,
}) => {
  const [selectedTile, setSelectedTile] = useState("");
  const [selectedFace, setSelectedFace] = useState("");
  const [tiles, setTiles] = useState(initialTileData);

  useEffect(() => {
    const newTiles = initialTileData.map(addUnavailableStatus);

    setTiles(newTiles);
  }, [used]);

  const select = (tile, side) => {
    return () => {
      setSelectedTile(tile);
      selectFace(side, () => onBrushSelect(side));
    };
  };

  const selectFace = (face, next) => {
    setSelectedFace(face);
    next();
  };

  const addUnavailableStatus = ({ a, b, tile }) => ({
    tile,
    a,
    b,
    unavailable: used.includes(a) || used.includes(b),
  });

  return (
    <Context.Provider
      value={{
        tiles,
        onSelectTile: select,
        selectedTile,
        selectedFace,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const AdjustmentHexPaletteControl = AdjustementHexPaletteControlBase;

export default AdjustmentHexPaletteControl;
