import React from "react";
import { link, P } from "../../brand/typography";
import { Modes } from "../../navigation/locations";
import { Link } from "react-router-dom";
import { BackIcon } from "./BackIcon";
import { withLanguage } from "../../user/UserConfigurationProvider";

const BackToModesLinkBase = ({ language }) => (
  <Link
    to={Modes}
    className={`flex flex-row justify-start items-center ${link}`}
  >
    <span className="pr-1">
      <BackIcon />
    </span>
    {language === "en" && <P>MULTIPLE WAYS TO PLAY</P>}
    {language === "fr" && <P>DIFFÉRENTES FAÇONS DE JOUER</P>}
    {language === "it" && <P>DIVERSI MODI PER GIOCARE</P>}
    {language === "es" && <P>MÚLTIPLES FORMAS DE JUGAR</P>}
    {language === "de" && <P>MEHRERE SPIELMÖGLICHKEITEN</P>}
  </Link>
);
export const BackToModesLink = withLanguage(BackToModesLinkBase);
