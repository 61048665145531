import React from "react";

import eraser from "./Palette/eraser.png";
import grid from "./Palette/grid.png";
import move from "./Palette/move.png";
import parIcon from "../images/par.png";
import holeIcon from "../images/holeIcon.png";
import greenIcon from "../images/green.png";
import deleteIcon from "../images/delete.png";
import teebox from "../images/teebox.png";
import difficultyIcon from "../images/difficulty.png";
import green from "../images/green.png";
import tilesIcon from "../images/tiles.png";
import Filters from "./Palette/Filters/Filters";
import { BrandGreen } from "../colours";

const IconButton = ({ onClick, enabled, alt, icon }) => {
  return (
    <button
      className="p-1 rounded cursor-pointer mr-1 hover:opacity-50"
      style={{ backgroundColor: enabled ? "orange" : BrandGreen }}
      onClick={onClick}
    >
      <img className="w-8 h-8" src={icon} alt={alt} />
    </button>
  );
};

const TextButton = ({ onClick, enabled, label }) => {
  return (
    <button
      className="p-1 rounded cursor-pointer mr-1 hover:opacity-50 flex flex-col justify-center items-center"
      style={{ backgroundColor: enabled ? "orange" : BrandGreen }}
      onClick={onClick}
    >
      <p className="h-8 text-center" style={{ lineHeight: "2rem" }}>
        {label}
      </p>
    </button>
  );
};

const CourseInfoBase = ({
  courseName,
  showGrid,
  showPar,
  erasing,
  teeboxes,
  greens,
  difficulty,
  tiles,
  par,
  onNameChange,
  onToggleGrid,
  onTogglePar,
  onToggleEraser,
  onToggleMove,
  onDeleteCourse,
  showPositions,
  showIds,
  showHoleNumbers,
  showGreenNumbers,
  onTogglePositions,
  onToggleIds,
  onToggleHoleNumbers,
  onToggleGreenNumbers,
  noOverlays,
  onHideOverlays,
}) => {
  const areYouSure = (fn) => {
    return () => {
      if (window.confirm("This will delete the course. This cannot be undone")) {
        fn();
      }
    };
  };

  return (
    <>
      <div className="flex-row flex flex-0 w-full justify-between items-start">
        <div className="flex flex-row justify-center items-center flex-1 mb-2">
          <input
            className="text-sm sm:text-base relative w-full border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none py-2 pr-2 pl-4"
            value={courseName}
            onChange={onNameChange}
          />
        </div>
        <div className="flex flex-row justify-end items-center flex-1 ml-1 mr-1">
          <p className="uppercase text-md lg:text-xl text-right text-white mr-2">Overlays</p>
          {false && (
            <IconButton enabled={showPar} onClick={onTogglePar} alt="show par" icon={parIcon} />
          )}
          <TextButton enabled={noOverlays} onClick={onHideOverlays} label="NONE" />
          <TextButton enabled={showPar} onClick={onTogglePar} label="PAR" />
          {false && <TextButton enabled={showPositions} onClick={onTogglePositions} label="POS" />}
          <TextButton enabled={showIds} onClick={onToggleIds} label="25A" />
          {false && (
            <IconButton
              enabled={showHoleNumbers}
              onClick={onToggleHoleNumbers}
              alt="show hole numbers"
              icon={holeIcon}
            />
          )}
          <TextButton enabled={showHoleNumbers} onClick={onToggleHoleNumbers} label="HOLES" />
          <TextButton enabled={showGreenNumbers} onClick={onToggleGreenNumbers} label="GREENS" />
          {false && (
            <IconButton
              enabled={showGreenNumbers}
              onClick={onToggleGreenNumbers}
              icon={greenIcon}
              alt="show green numbers"
            />
          )}
          <IconButton
            enabled={showGrid}
            onClick={onToggleGrid}
            alt="toggle show grid icon"
            icon={grid}
          />
          <button
            className="p-1 rounded cursor-pointer mr-1 hover:opacity-50"
            onClick={onToggleEraser}
            style={{ backgroundColor: erasing ? "orange" : BrandGreen }}
          >
            <img className="w-8 h-8" src={eraser} alt="enable erase mode" />
          </button>
          <button
            className="p-1 rounded cursor-pointer mr-1 hover:opacity-50"
            onClick={onToggleMove}
            style={{ backgroundColor: !erasing ? "orange" : BrandGreen }}
          >
            <img className="w-8 h-8" src={move} alt="enable move mode" />
          </button>
          <button
            className="p-1 rounded cursor-pointer mr-1 hover:opacity-50"
            onClick={areYouSure(onDeleteCourse)}
          >
            <img className="w-8 h-8" src={deleteIcon} alt="delete course" />
          </button>
        </div>
      </div>
      <div className="flex-row flex flex-0 w-full justify-between items-start">
        <div className="flex-row flex flex-1 w-full justify-start items-start h-12">
          <div className="p-1 flex flex-row justify-center items-center mr-2">
            <p className="text-3xl text-black text-center">Par</p>
            <p className="text-3xl text-white text-center ml-2">{par}</p>
          </div>
          <div className="p-1 flex flex-row justify-center items-center mr-2">
            <img src={teebox} className="w-8 h-8" alt="teebox tiles icon" />
            <p className="text-3xl text-white text-center ml-1">{teeboxes}</p>
          </div>
          <div className="p-1 flex flex-row justify-center items-center mr-2">
            <img src={green} className="w-8 h-8" alt="greem tiles icon" />
            <p className="text-3xl text-white text-center ml-1">{greens}</p>
          </div>
          <div className="p-1 flex flex-row justify-center items-center mr-2">
            <img src={difficultyIcon} className="w-8 h-8" alt="difficulty icon" />
            <p className="text-3xl text-white text-center ml-1">{difficulty}</p>
          </div>
          <div className="p-1 flex flex-row justify-center items-center mr-2">
            <img src={tilesIcon} className="w-8 h-8" alt="tile count icon" />
            <p className="text-3xl text-white text-center ml-1">{tiles}</p>
          </div>
        </div>
        <div className="flex-row flex flex-1 justify-end items-end h-12">
          <Filters />
        </div>
      </div>
    </>
  );
};

const CourseInfo = CourseInfoBase;

export default CourseInfo;
