import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";
import { BrowseCourses } from "../navigation/locations";
import { ButtonAlt } from "../common/Button";
import boxShot from "../images/box-shot.png";
import boxBack from "../images/box-bottom-only.png";
import shot2 from "../images/18-holes-shot-2.jpg";
import shot3 from "../images/18-holes-shot-3.jpg";
import shot5 from "../images/18-holes-shot-5.jpg";
import shot6 from "../images/18-holes-shot-6.jpg";
import shot7 from "../images/18-holes-shot-7.jpg";
import teebox from "../images/teebox.png";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-wrap flex-row justify-evenly items-center">
          <img
            className="flex flex-0"
            src={boxShot}
            alt="18 Holes box"
            style={{ width: "50%" }}
          />
          <div className="flex flex-1 flex-col justify-center items-center text-center">
            <h1 className="my-4 text-3xl font-bold leading-tight">
              <em>Hit off-course on purpose.</em>
            </h1>
            <p className="leading-normal text-2xl mb-8">
              18 Holes, a golf-themed game for 1 to 5 players.
            </p>
            <div className="pt-4 pb-4">
              <ButtonAlt size="2xl" href="https://shop.seabrook-studios.com">
                BUY NOW
              </ButtonAlt>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-evenly items-center p-4">
          <p className="text-xl">1 - 5 players</p>
          <p className="text-xl">ages 14+</p>
          <p className="text-xl">30 - 45 min</p>
        </div>
        <div className="flex justify-center items-center p-2">
          <div className="w-full border-b">&nbsp;</div>
          <img
            className="h-4 w-4 relative"
            src={teebox}
            alt=""
            style={{ bottom: -5 }}
          />
          <div className="w-full border-b">&nbsp;</div>
        </div>
        <div></div>
        <div className="flex flex-row">
          <div className="flex flex-col justify-start items-start p-8 w-3/5">
            <p className="p-4">
              A beautiful drive down the fairway is a satisfying feeling. And
              yet, beyond the trees, you spy an alternative. A short-cut to the
              green. Do you take the chance? Hit off-course on purpose in this
              golf-themed board game for 1 to 5 players. With some clever
              thinking, you'll be able to turn bad luck into the perfect shot.
            </p>
            <p className="p-4">
              In 18 Holes, you'll design and build the course, you'll draft for
              clubs and you'll compete to win each hole. Every hole is an
              opportunity to outplay your opponents and reach the green first.
              Play solo or with two to five players either around the table or
              remote using our companion app.
            </p>
            <p className="p-4">
              Every game of 18 Holes plays on a new course. Be random, design it
              together or go online to the{" "}
              <Link className="text-brand-green" to={BrowseCourses}>
                18 Holes Clubhouse&nbsp;
              </Link>
              and play a course created by another player. With 49 different
              double-sided tiles, course-adjustment tiles and eight different
              game modes (including solo and two player). 18 Holes lets you
              tailor the playing experience to suit your crowd.
            </p>
            <div className="p-4">
              <h3 className="font-bold text-2xl">Contents of Box</h3>
              <ul>
                <li>• 49 large double-sided course tiles</li>
                <li>• 21 double-sided hazard adjustment tiles</li>
                <li>• 5 golfer meeples</li>
                <li>• 8 unique golfer personas</li>
                <li>• 34 club cards</li>
                <li>• 74 shot cards</li>
                <li>• 24 solo chaos golf cards</li>
                <li>• 18 1-point tokens</li>
                <li>• 18 2-point tokens</li>
                <li>• 18 5-point tokens</li>
                <li>• 20 hole-in-one-tokens</li>
                <li>• Solo turn marker</li>
                <li>• 28 page rule book</li>
                <li>• 16 page course booklet</li>
                <li>• 18 3d flags</li>
                <li>• 14 3d trees</li>
                <li>• 1 box!</li>
                <li>
                  <em className="text-sm pt-2">Golf ball not included.</em>
                </li>
              </ul>
            </div>
            <p className="p-4">
              18 Holes includes a total of 140 cards (63x88mm). The box is
              295x295x5mm and weighs 1.8 kg.
            </p>
            <p className="p-4">
              Release Details: available now. Available from select US retailers
              from January 29, 2021. Australian retailers from Feb 8, 2021 and
              Worldwide from Febuary 26, 2021.
            </p>
            <p className="p-4">
              Product Details: The MSRP is $80 and the SKU is SBS1801.
            </p>
          </div>
          <div className="w-2/5 flex flex-col">
            <img
              className="pt-2"
              src={shot2}
              alt="Perspective shot of 3 golfer meeples looking down the fairway towards the first green"
            />
            <img
              className="pt-2"
              src={shot3}
              alt="An 18 Holes course setup with decks and player cards arranged for play"
            />
            <img
              className="pt-2"
              src={shot5}
              alt="A aerial view of an 18 Holes course"
            />
            <img
              className="pt-2"
              src={shot7}
              alt="Each of the 8 golfer cards splayed out"
            />
            <img
              className="pt-2"
              src={shot6}
              alt="18 holes box with golf ball for scale"
            />
            <img
              className="pt-2"
              src={boxBack}
              alt="Back of the 18 Holes box"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
