import React from "react";
import rulerCm from "../images/ruler-cm.png";
import rulerIn from "../images/ruler-in.png";
import { withUserConfiguration } from "./UserConfigurationProvider";

const MeasurementBase = ({ units, toggleUnits }) => {
  return (
    <div
      className="flex flex-row justify-center items-center p-1 md:p-2 cursor-pointer"
      onClick={toggleUnits}
    >
      <img
        src={units === "cm" ? rulerCm : rulerIn}
        className="pr-1 w-auto h-8"
        alt="ruler icon"
      />
      <p className="text-white text-sm w-4 text-center">{units}</p>
    </div>
  );
};
export const Measurement = withUserConfiguration(MeasurementBase);
