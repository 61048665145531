import React from "react";
import { withLanguage } from "../user/UserConfigurationProvider";
import { link } from "../brand/typography";

const SetLanguageBase = ({
  setEnglish,
  setFrench,
  setItalian,
  setSpanish,
  setGerman,
}) => {
  function setToEnglish(event) {
    setEnglish();
    event.preventDefault();
  }

  function setToFrench(event) {
    setFrench();
    event.preventDefault();
  }

  function setToItalian(event) {
    setItalian();
    event.preventDefault();
  }

  function setToSpanish(event) {
    setSpanish();
    event.preventDefault();
  }

  function setToGerman(event) {
    setGerman();
    event.preventDefault();
  }

  return (
    <div className="flex flex-row justify-evenly items-center p-4 print:hidden">
      <p className={link} onClick={setToGerman}>
        Deutsch
      </p>
      <p className={link} onClick={setToEnglish}>
        English
      </p>
      <p className={link} onClick={setToSpanish}>
        Español
      </p>
      <p className={link} onClick={setToFrench}>
        Français
      </p>
      <p className={link} onClick={setToItalian}>
        Italiano
      </p>
    </div>
  );
};
export const SetLanguage = withLanguage(SetLanguageBase);
