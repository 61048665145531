import React from "react";
import { H2, P } from "../brand/typography";
import { ModeLinks } from "./ModeLinks";

export const EnglishModes = () => {
  return (
    <>
      <H2>MULTIPLE WAYS TO PLAY</H2>
      <P>
        18 Holes, like golf, has a variety of different game modes. The primary
        method described in this book keeps all players competing for each hole.
        Golf is a tough game where it can be hard to catch up if you fall
        behind. If you like that kind of game or want a more realistic golf
        experience, then you may be interested in Golf Racing, Stroke Play or
        Stableford.
      </P>
      <P>
        If your group prefers games that keep everyone competing for each point,
        then try Match Play and Skins.
      </P>
      <P>
        Some variations stray a bit further from the fairway. These are for
        people who want a less traditional golf experience. The Chaos Golf
        variations and Kelly Golf fit this description.
      </P>
      <P>
        If you want a solo experience, then try Solo Chaos Golf, Stableford or
        Stroke Play.
      </P>
      <P>
        If you want a two-player game, then try Chaos Teams: our dedicated
        two-player experience. Or, one of Match Play, Skins, Stroke Play,
        Stableford or Golf Racing.
      </P>
      <P>
        Each game mode comes with a section that describes the game mode, how
        many players it supports and when you might be interested in this game.
        To the right we have the section for Match Play, the rule set described
        up to this point.
      </P>
      <P>Index of Variations:</P>
      <ModeLinks />
    </>
  );
};
