import React from "react";
import meta from "../meta.json";
import { withPaletteTiles } from "./Palette";
import Tile from "./Tile/Tile";

const noop = () => null;

const PaletteDisplayBase = ({
  tiles,
  height,
  onSelectTile,
  selectedTile,
  selectedFace,
  used,
  highlight,
}) => {
  return (
    <div
      className="flex flex-1 flex-col justify-start items-start bg-white rounded-b whitespace-no-wrap overflow-y-scroll overflow-x-hidden"
      style={{ height }}
    >
      {tiles.map(({ tile, a, b, unavailable, set }) => {
        const clickHandlerSideA = unavailable ? noop : onSelectTile(tile, a);
        const clickHandlerSideB = unavailable ? noop : onSelectTile(tile, b);

        return (
          <Tile
            key={tile}
            unavailable={unavailable}
            set={set}
            a={a}
            b={b}
            tile={tile}
            displayId={meta[a].displayId}
            isSelected={selectedTile === tile}
            isMouseOverOnCourse={highlight === a || highlight === b}
            clickHandlerSideA={clickHandlerSideA}
            clickHandlerSideB={clickHandlerSideB}
            showSideAUnavailable={selectedFace === b || used.includes(b)}
            showSideBUnavailable={selectedFace === a || used.includes(a)}
          />
        );
      })}
    </div>
  );
};

const PaletteDisplay = withPaletteTiles(PaletteDisplayBase);

export default PaletteDisplay;
