import axios from "axios";
import { BrowseCourses } from "../navigation/locations";

/**
 * @returns {Promise<SearchMetadata[]>}
 */
export function getPublicCourses() {
  return axios
    .get("https://public.18holesgame.com/courses")
    .then((response) => response.data);
}

export function getCourse(id, history) {
  return axios
    .get(`https://public.18holesgame.com/courses/${id}`)
    .then((response) => response.data)
    .catch(() => {
      history.push(BrowseCourses);
    });
}

/**
 *
 * @param {CourseId} id
 * @returns {Promise<string|null>}
 */
export function getPrintUrl(id) {
  return axios
    .get(`https://public.18holesgame.com/courses/${id}/print`)
    .then((response) => response.data)
    .then(({ url }) => url);
}
