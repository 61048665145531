import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, link, P, ul, ol } from "../../brand/typography";
import {
  FastTravelRulesEnglish,
  FastTravelRulesFrench,
  FastTravelRulesGerman,
  FastTravelRulesItalian,
  FastTravelRulesSpanish,
} from "./FastTravelRules";
import { FromChaosGolf } from "./FromChaosGolf";
import { BackToModesLink } from "./BackToModesLink";
import { withLanguage } from "../../user/UserConfigurationProvider";

const FrenchModeKellyGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Kelly Golf</H1>
      <P>
        Chaque joueur a un green défini qu'il essaie d'atteindre. Les joueurs
        marquent des points pour chaque green ciblé atteint.
      </P>

      <H3>Nombre de joueurs</H3>
      <P>2 à 5 joueurs</P>

      <H3>JOUEZ CE MODE QUAND....</H3>
      <P>
        Vous aimez le Chaos Golf et les quêtes, et vous voulez ajouter un peu
        plus d'incertitude dans le jeu.
      </P>

      <H3>COMMENT GAGNER</H3>
      <ul className={ul}>
        <li>3 points pour chaque green secret revendiqué. </li>
        <li>2 points pour tout green secret d'un autre joueur revendiqué. </li>
        <li>1 point pour tout autre green. </li>

        <P>Le score le plus élevé gagne.</P>
      </ul>

      <H3>Variations des règles</H3>
      <FromChaosGolf />

      <H4>Configuration</H4>
      <ol className={ol}>
        <li>Placez un drapeau sur chaque green.</li>
        <li>
          Placez les petits jetons de green à 1 point sur la table, face vers le
          haut, et mélangez-les.{" "}
        </li>
        <li>Faites une pile de jetons à 2 points près du plateau.</li>
        <li>
          Chaque joueur tire deux jetons à 1 point, il en garde un et rend
          l'autre. Les joueurs gardent leur cible secrète.
        </li>
      </ol>

      <H4>Comment jouer</H4>
      <P>
        Chaque joueur peut frapper dans n'importe quelle direction et essayer de
        revendiquer n'importe quel trou. 
      </P>
      <P>
        Lorsqu'il ne reste qu'un seul jeton dans le sac, le joueur le tire au
        sort le pioche.
      </P>

      <H4>Marquer un green prédéfini</H4>
      <P>
        Lorsqu'un joueur atteint le green prédéfini par son jeton, il révèle son
        jeton de green face vers le haut, retire le drapeau du plateau et le
        remet dans la boîte. Il prend ensuite un jeton de 2 points et le rajoute
        à son score. Il ajoute ensuite à son score le jeton de 1 point qu’il
        utilisait comme cible secrète.
      </P>
      <P>
        Piochez deux jetons de remplacement à 1 point, gardez l'un comme
        prochaine cible et rendez l'autre.
      </P>

      <H4>Marquer le green prédéfini d'une autre personne</H4>
      <P>
        Si quelqu'un réclame votre green prédéfini, remettez-lui votre jeton de
        1 point. Piochez deux jetons de remplacement à 1 point, gardez l'un
        d'eux et rendez l'autre. 
      </P>
      <P>
        Si vous revendiquez le green prédéfini d'un autre joueur, vous marquez
        son jeton de 1 point et le drapeau du green.
      </P>

      <H4>Marquer tout autre green</H4>
      <P>Retirez le drapeau du green et ajoutez-le à votre score.</P>

      <H4>Score final</H4>
      <P>
        Le jeu se termine immédiatement après qu'un joueur ait remporté le
        dernier green. Additionnez la valeur des jetons que vous avez marqués.
        Les drapeaux valent 1 point. Le score le plus élevé l'emporte.
      </P>

      <H3>PIÈCES NÉCESSAIRES POUR MARQUER DES POINTS</H3>
      <ul className={ul}>
        <li>Drapeaux</li>
        <li>Jetons à 2 points</li>
        <li>Jetons à 1 point</li>
      </ul>

      <FastTravelRulesFrench mode="Kelly Golf" />
    </>
  );
};

const ItalianModeKellyGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Kelly Golf</H1>
      <P>
        Ogni giocatore ha un green obiettivo che deve tentare di raggiungere. I
        giocatori segnano punti per ogni green obiettivo raggiunto.
      </P>

      <H3>GIOCATORI</H3>
      <P>2 - 5</P>

      <H3>GIOCA QUESTA MODALITÀ QUANDO...</H3>
      <P>
        Ti piace il chaos golf e le fetch quest, e vuoi aggiungere un po’ più di
        incertezza al gioco.
      </P>

      <H3>COME SI VINCE</H3>
      <ul className={ul}>
        <li>3 punti per ciascun green segreto acquisito. </li>
        <li>2 punti per acquisire il green segreto di un altro giocatore. </li>
        <li>1 punto per ogni altro green.</li>

        <P>Vince il giocatore che avrà totalizzato il punteggio più alto.</P>
      </ul>

      <H3>VARIAZIONI ALLE REGOLE</H3>
      <FromChaosGolf />

      <H4>PREPARAZIONE</H4>
      <ol className={ol}>
        <li>Metti una bandierina su ciascun green.</li>
        <li>
          Metti i contrassegni green piccoli da un punto con il lato del punto
          in alto sul tavolo, mescola.
        </li>
        <li>
          Metti i contrassegni da 2 punti in una pila vicino al tabellone.
        </li>
        <li>
          Ogni giocatore pesca due segnalini da un punto conservandone uno e
          restituendo l’altro. I giocatori mantengono segreto l’obiettivo.
        </li>
      </ol>

      <H4>Svolgimento del Gioco</H4>
      <P>
        Ogni giocatore può colpire in qualsiasi direzione e cercare di
        conquistare qualsiasi buca.{" "}
      </P>
      <P>
        Quando rimane solo un segnalino nella busta, il giocatore pesca quel
        segnalino.
      </P>

      <H4>METTERE A SEGNO UN GREEN OBIETTIVO</H4>
      <P>
        Quando il giocatore raggiunge il proprio green obiettivo, rivela il
        segnalino green obiettivo a faccia in su e toglie la bandierina dal
        tabellone, riponendola nella scatola. Prendi un segnalino da 2 punti e
        lo metti con il tuo punteggio. Aggiungi il segnalino da un punto che
        usavi come obiettivo segreto al tuo punteggio.
      </P>
      <P>
        Pesca altri due segnalini sostitutivi da un punto, ne tieni uno come
        prossimo obiettivo e restituisci l’altro.
      </P>

      <H4>METTERE A SEGNO IL GREEN OBIETTIVO DI UN ALTRO</H4>
      <P>
        Se qualcuno acquisisce il tuo obiettivo, passagli il tuo segnalino da un
        punto. Pesca due segnalini sostitutivi da un punto, uno lo tieni e uno
        lo restituisci.
      </P>
      <P>
        Se acquisisci il green obiettivo di un altro giocatore, tu prendi il suo
        segnalino da un punto e la bandierina del green.
      </P>

      <H4>METTERE A SEGNO QUALSIASI ALTRO GREEN</H4>
      <P>Rimuovi la bandierina green e aggiungila al tuo punteggio.</P>

      <H4>PUNTEGGIO FINALE</H4>
      <P>
        Il gioco termina immediatamente dopo che un giocatore avrà conquistato
        l’ultimo green. Somma il valore dei token accumulati. Le bandierine
        valgono un punto. Vince chi avrà totalizzato il punteggio più alto
        vince.
      </P>

      <H3>COSA SERVE PER IL PUNTEGGIO</H3>
      <ul className={ul}>
        <li>Bandierine</li>
        <li>Segnalini da 2 punti</li>
        <li>Segnalini da 1 punto</li>
      </ul>

      <FastTravelRulesItalian mode="Kelly Golf" />
    </>
  );
};

const EnglishModeKellyGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Kelly Golf</H1>
      <P>
        Each player has a target green that they are trying to reach. Players
        score points for each target green reached.
      </P>

      <H3>PLAYS</H3>
      <P>2 - 5 players</P>

      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You like chaos golf and fetch quests, and you want to add a little bit
        more uncertainty into the game.
      </P>

      <H3>HOW TO WIN</H3>
      <ul className={ul}>
        <li>3 points for each secret green claimed.</li>
        <li>2 points for claiming the secret green of another player.</li>
        <li>1 point for any other green.</li>

        <P>The highest score wins.</P>
      </ul>

      <H3>Rule Variations</H3>
      <FromChaosGolf />

      <H4>Setup</H4>
      <ol className={ol}>
        <li>Place a flag on each green.</li>
        <li>
          Place the 1-point small green markers point-side up on the table,
          shuffle.
        </li>
        <li>Place the 2-point tokens in a pile near the board.</li>
        <li>
          Each player draws two 1-point tokens keeping one and returning the
          other. Players keep their target secret.
        </li>
      </ol>

      <H4>Game Play</H4>
      <P>Each player may hit in any direction and try to claim any hole.</P>
      <P>
        When there is only one token left in the bag, the player draws that one
        token.
      </P>

      <H4>Scoring a target green</H4>
      <P>
        When a player reaches their target green, they reveal their target green
        token face up and take the flag off the board and put it back into the
        box. Take a 2 point token and put it with your score. Add the 1-point
        token you were using as a secret target to your score.
      </P>
      <P>
        Draw two replacement 1-point tokens, keep one as your next target and
        return the other.
      </P>

      <H4>Scoring another’s target green</H4>
      <P>
        If someone claims your target, hand them your 1-point token. Draw two
        replacement 1-point tokens, keep one and return the other.
      </P>
      <P>
        If you claim another player’s target green you score their 1-point token
        and the flag from the green.
      </P>

      <H4>Scoring any other green</H4>
      <P>Remove the flag from the green and add it to your score.</P>

      <H4>Final Scoring</H4>
      <P>
        The game ends immediately after a player scores the last green. Sum the
        value of your scored tokens. The flags are worth 1 point. Highest score
        wins.
      </P>

      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>Flags</li>
        <li>2-point tokens</li>
        <li>1-point tokens</li>
      </ul>

      <FastTravelRulesEnglish mode="Kelly Golf" />
    </>
  );
};

const GermanModeKellyGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Kelly Golf</H1>
      <P>
        Jeder Spieler hat ein Ziel-Green, das er zu erreichen versucht. Für
        jedes erreichte Ziel-Green erhalten die Spieler Punkte.
      </P>

      <H3>SPIELER</H3>
      <P>2 - 5 Spieler</P>

      <H3>SPIELE DIESEN MODUS WENN ...</H3>
      <P>
        … du Chaos-Golf und Geheimaufträge magst und du dem Spiel ein wenig mehr
        Ungewissheit hinzufügen möchtest.
      </P>

      <H3>WIE GEWINNT MAN</H3>
      <ul className={ul}>
        <li>3 Punkte für jedes beanspruchte geheime Green.</li>
        <li>
          2 Punkte für die Beanspruchung des geheimen Greens eines anderen
          Spielers.{" "}
        </li>
        <li>1 Punkt für jedes andere Green. .</li>

        <P>Die höchste Punktzahl gewinnt.</P>
      </ul>

      <H3>Regelvariationen</H3>
      <FromChaosGolf />

      <H4>Aufbau</H4>
      <ol className={ol}>
        <li>Platziere auf jedem Green eine Fahne.</li>
        <li>
          Lege die kleinen grünen 1-Punkt-Marker mit der Punkt-Seite nach oben
          auf den Tisch und mische sie.
        </li>
        <li>
          Lege die 2-Punkte-Token in einen Stapel in der Nähe des Spielbretts.
        </li>
        <li>
          Jeder Spieler zieht zwei 1-Punkt-Token, behält eines und gibt das
          andere zurück. Die Spieler halten ihr Ziel geheim.
        </li>
      </ol>

      <H4>Gameplay</H4>
      <P>
        Jeder Spieler darf in jede Richtung schlagen und versuchen, jedes Loch
        zu beanspruchen.{" "}
      </P>
      <P>
        Wenn sich nur noch ein Token im Beutel befindet, zieht der Spieler, der
        dran ist, diesen einen Token.
      </P>

      <H4>Ein Ziel-Green punkten</H4>
      <P>
        Wenn du dein Ziel-Green erreichst, deckst du deinen Ziel-Green-Token
        auf, nimmst die Fahne vom Brett und legst sie zurück in die Box. Nimm
        dann einen 2-Punkte-Token und lege ihn zu deinen Punkten. Füge den
        1-Punkt-Token, den du als geheimes Ziel verwendet hast, zu deinem
        Punktestand hinzu.
      </P>
      <P>
        Ziehe zwei neue 1-Punkt-Tokens, behalte einen als dein nächstes Ziel und
        gib den anderen wieder zurück.
      </P>

      <H4>Ziel-Green eines anderen punkten</H4>
      <P>
        Wenn jemand dein Ziel beansprucht, gib ihm deinen 1-Punkt-Token. Ziehe
        zwei neue 1-Punkt-Token, behalte einen und gib den anderen zurück.
      </P>
      <P>
        Wenn du das Ziel-Green eines anderen Spielers beanspruchst, erzielst du
        dessen 1-Punkt-Token und die Fahne vom Green.
      </P>

      <H4>Jegliche andere Green punkten</H4>
      <P>Entferne die Fahne vom Green und füge sie deinem Punktestand hinzu.</P>

      <H4>Endpunktestand</H4>
      <P>
        Das Spiel endet sofort, nachdem ein Spieler das letzte Green gepunktet
        hat. Summiere den Wert deiner gepunkteten Token; die Fahnen sind 1 Punkt
        wert. Die höchste Punktzahl gewinnt.
      </P>

      <H3>FÜR DAS PUNKTEN BENÖTIGTE TEILE</H3>
      <ul className={ul}>
        <li>Fahnen</li>
        <li>2-Punkt-Token</li>
        <li>1-Punkt-Token</li>
      </ul>

      <FastTravelRulesGerman mode="Kelly Golf" />
    </>
  );
};

const SpanishModeKellyGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Kelly Golf</H1>
      <P>
        Cada jugador tiene un green objetivo que trata de alcanzar. Los
        jugadores anotan puntos por cada green objetivo alcanzado.
      </P>

      <H3>JUEGOS</H3>
      <P>2 - 5 jugadores</P>

      <H3>JUEGA ESTE MODO CUANDO...</H3>
      <P>
        Te gusta el Chaos Golf y las misiones de búsqueda, y quieres añadir un
        poco más de incertidumbre al juego.
      </P>

      <H3>CÓMO GANAR</H3>
      <ul className={ul}>
        <li>3 puntos por cada green secreto reclamado. </li>
        <li>2 puntos por reclamar el green secreto de otro jugador. </li>
        <li>1 punto por cualquier otro green. </li>

        <P>La puntuación más alta gana.</P>
      </ul>

      <H3>Variaciones de las reglas</H3>
      <FromChaosGolf />

      <H4>Configuración</H4>
      <ol className={ol}>
        <li>Coloca una bandera en cada green.</li>
        <li>
          Coloca los pequeños marcadores verdes de un punto en la mesa, y
          baraja.
        </li>
        <li>Coloca las fichas de 2 puntos en un montón cerca del tablero.</li>
        <li>
          Cada jugador saca dos fichas de 1 punto, se queda una y devuelve la
          otra. Los jugadores mantienen su objetivo en secreto.
        </li>
      </ol>

      <H4>Juego</H4>
      <P>
        Cada jugador puede golpear en cualquier dirección e intentar reclamar
        cualquier hoyo.{" "}
      </P>
      <P>Cuando solo queda una ficha en la bolsa, el jugador saca esa ficha.</P>

      <H4>Marcando un green objetivo</H4>
      <P>
        Cuando un jugador llega a su green objetivo, pone su ficha del green
        objetivo boca arriba, quita la bandera del tablero y la vuelve a poner
        en la caja. Coge una ficha de 2 puntos y ponla con tu puntuación. Añade
        a tu puntuación la ficha de 1 punto que estabas usando como objetivo
        secreto.
      </P>
      <P>
        Saca dos fichas de reemplazo de 1 punto, mantén una como tu próximo
        objetivo y devuelve la otra.
      </P>

      <H4>Anotar el green objetivo de otro</H4>
      <P>
        Si alguien reclama tu objetivo, entrégale tu ficha de 1 punto. Saca dos
        fichas de reemplazo de 1 punto, guarda una y devuelve la otra.
      </P>
      <P>
        Si reclamas el green objetivo de otro jugador, anotarás su ficha de 1
        punto y la bandera del green.
      </P>

      <H4>Anotando cualquier otro green</H4>
      <P>Quita la bandera del green y añádela a tu puntuación.</P>

      <H4>Puntuación final</H4>
      <P>
        El juego termina inmediatamente después de que un jugador anota el
        último green. Suma el valor de tus fichas anotadas. Las banderas valen
        un punto. La puntuación más alta gana.
      </P>

      <H3>PARTES NECESARIAS PARA ANOTAR</H3>
      <ul className={ul}>
        <li>Banderas</li>
        <li>Fichas de 2 puntos</li>
        <li>Fichas de 1 punto</li>
      </ul>

      <FastTravelRulesSpanish mode="Kelly Golf" />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeKellyGolf />}
          {language === "fr" && <FrenchModeKellyGolf />}
          {language === "it" && <ItalianModeKellyGolf />}
          {language === "es" && <SpanishModeKellyGolf />}
          {language === "de" && <GermanModeKellyGolf />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
