import React from "react";
import { Link } from "react-router-dom";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, P, ul, link, H6 } from "../../brand/typography";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";
import { FromChaosGolf } from "./FromChaosGolf";
import { ModesChaosGolf, ModesCroquetGolf } from "../../navigation/locations";
import { NoTranslationAvailable } from "./NoTranslationAvailable";
import { Example } from "../RuleBookParts";
import deck3Left from "../images/deck-3-left.png";
import chaosGolf2v2Example1 from "../images/2v2-chaos-golf-step1.png";
import chaosGolf2v2Example2 from "../images/2v2-chaos-golf-step2.png";
import chaosGolf2v2Example3 from "../images/2v2-chaos-golf-step3.png";

const EnglishModeChaosGolf2v2 = () => {
  return (
    <>
      <BackToModesLink />
      <H1>2v2 CHAOS GOLF</H1>
      <P>
        Are you ready for the perfect golfing double-date? Two teams of two vie
        for most points in this chaos golf variant where when one player on a
        team takes a turn, both players do.
      </P>
      <H3>Plays</H3>
      <P>4 players</P>
      <H3>PLAY THIS MODE WHEN...</H3>
      <P>
        You're on a double date or you want to try your hand at teams based
        chaos golf.
      </P>
      <H3>HOW TO WIN</H3>
      <P>The team with the most points wins.</P>
      <H3>Rule Variations</H3>
      <FromChaosGolf />
      <P>
        Use the rules from&nbsp;
        <Link className={link} to={ModesCroquetGolf}>
          Croquet Golf
        </Link>
        &nbsp;except when in conflict with the rules below or with&nbsp;
        <Link className={link} to={ModesChaosGolf}>
          Chaos Golf
        </Link>
        &nbsp;.
      </P>
      <H4>Setup</H4>
      <P>Setup remains as per for Chaos Golf with the following changes:</P>
      <P>
        Organise the players into two even teams and then complete the draft.
        Players may not discuss club selection during the draft.
      </P>
      <H4>Game Play</H4>
      <P>
        During the select &amp; reveal clubs step, players may not discuss club
        selection. This includes discussion of drawing from particular shot
        decks. Players may discuss intent, such as aiming for a particular
        green.
      </P>
      <P>
        Once play order has been determined play continues like Chaos Golf with
        one significant change. When one player on a team takes a turn,&nbsp;
        <strong>the other player must take the same action</strong>. This means
        for most rounds, each player will move twice. Once for their own turn
        and once for the turn of their partner.
      </P>
      <P>
        Players are not required to move in the same direction as their team
        mate.
      </P>
      <P>The current player moves first. Their team mate moves second.</P>
      <P>
        If the current player moves one hex, their team mate moves one hex
        (unless in a bunker).
      </P>
      <P>
        If the current player plays a club card that results in a shot card
        being selected, their team mate will play the same shot card. Rough
        penalties apply.
      </P>
      <P>
        If the current player plays a club card that moves a set distance e.g.
        Wood, their team mate moves the same distance. Rough penalties apply.
      </P>
      <P>
        If the current player decides to reset their hand. Their team mate does{" "}
        <strong>not</strong> get to reset their hand.
      </P>
      <H6>Chipping</H6>
      <P>
        If the current player is playing a chip card, both players need to
        nominate the direction of play before the card is drawn from the chip
        deck. The lob wedge negates this rule for both players.
      </P>
      <H6>Rough Penalties</H6>
      <P>
        Rough penalties still apply and each player is considered independent.
        If the current player players club that moves 4 hexes and their teammate
        is in the rough. They would move 4 hexes and their teammate would move
        3.
      </P>
      <H6>Water Hazards</H6>
      <P>
        If the team mate is currently missing a turn due to a water hazard. They
        stand their golfer up instead of taking the same action.
      </P>
      <H6>Bunker Exits</H6>
      <P>
        If one current player is attempting to exit the bunker and their team
        mate is also in a bunker, the bunker outcome is used for both players.
        If the current player is attempting to exit the bunker and their team
        mate is not in a bunker, the use the shot card drawn rather than the
        bunker outcome –regardless of the outcome. If the current player is not
        in a bunker and their team mate is, the team mate may exit the bunker
        according to the bunker outcome icon on any shot card played by the
        current player. If not shot card is played i.e. move one, their team
        mate does not move.
      </P>
      <H6>BIG HITS</H6>
      <P>
        As a big hit results in a shot card being drawn, the team mate will use
        the same shot card for their shot. You no longer hand the&nbsp;
        <strong>Big Hit</strong> option cards to person on your left, you have
        to hand them to nearest person on the other team.
      </P>
      <H6>Fast Travel</H6>
      <P>
        Fast travel rules still apply but each player's travel is considered
        independently and therefore team mates will not get extra movement.
      </P>
      <Example>
        <P>
          Clare (white/blue) and Zoey (red/orange) are on the same team. It's
          Clare's turn and she plays a club that results in shot card shown.
        </P>
        <div style={{ maxHeight: 350, overflow: "hidden" }}>
          <img src={chaosGolf2v2Example1} style={{ maxHeight: 350 }} />
          <img
            src={deck3Left}
            style={{
              maxHeight: 150,
              height: 150,
              position: "relative",
              top: -350,
              left: 250,
            }}
          />
        </div>
        <P>
          Clare is in the rough. This is to her advantage and she moves into the
          same hex as Zoey shunting her onto the fairway (Croquet Golf rules).
        </P>
        <img src={chaosGolf2v2Example2} style={{ maxHeight: 350 }} />
        <P>
          Zoey immediately takes a turn using Clare's shot card. Thanks to
          Clare's move she is now able to land on the green.
        </P>
        <img src={chaosGolf2v2Example3} style={{ maxHeight: 350 }} />
      </Example>
      <H4>Scoring</H4>
      <P>As per Chaos Golf.</P>
      <H3>PARTS NEEDED FOR SCORING</H3>
      <ul className={ul}>
        <li>5-point tokens</li>
        <li>2-point tokens</li>
      </ul>
    </>
  );
};

const FrenchModeChaosGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeChaosGolf2v2 />
    </>
  );
};

const SpanishModeChaosGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeChaosGolf2v2 />
    </>
  );
};

const GermanModeChaosGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeChaosGolf2v2 />
    </>
  );
};

const ItalianModeChaosGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeChaosGolf2v2 />
    </>
  );
};

const ModeChaosGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeChaosGolf2v2 />}
          {language === "fr" && <FrenchModeChaosGolf />}
          {language === "it" && <ItalianModeChaosGolf />}
          {language === "es" && <SpanishModeChaosGolf />}
          {language === "de" && <GermanModeChaosGolf />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeChaosGolf);
