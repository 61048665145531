import React from "react";
import { Link } from "react-router-dom";
import { withLoggedInStatus } from "../auth/react-auth0-wrapper";
import { Account, Welcome } from "../navigation/locations";

/**
 *
 * @param {object} props
 * @param {boolean} props.isLoggedIn
 */
const AccountButtonBase = ({ isLoggedIn }) => {
  return (
    <Link
      className="flex flex-row justify-center items-center text-teal-200 hover:text-white text-xs md:text-sm w-14 text-center"
      to={isLoggedIn ? Account : Welcome}
    >
      {isLoggedIn ? "Account" : "Sign in"}
    </Link>
  );
};

export const AccountButton = withLoggedInStatus(AccountButtonBase);
