import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CourseDesigner from "./Designer/CourseDesigner";
import CourseHome from "./Course/CourseHome";
import ShowLogin from "./auth/ShowLogin";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import PublicCoursesProvider from "./Browse/PublicCoursesProvider";
import UserConfigurationProvider from "./user/UserConfigurationProvider";
import UserAccountProvider from "./user/UserAccountProvider";
import MyCourses from "./Designer/MyCourses";
import PrivateCoursesProvider from "./Designer/PrivateCoursesProvider";
import Account from "./user/Account";
import IsNewUser from "./user/NewUser";

import BrowseCourses from "./Browse/BrowseCourses";
import UserCourses from "./Browse/UserCourses";

import LandingPage from "./brand/LandingPage";
import Privacy from "./brand/Privacy";
import Credits from "./brand/Credits";
import More from "./brand/More";
import PrintAndPlay from "./brand/PrintAndPlay";

import RuleBook from "./rulebook/RuleBook";
import Modes from "./rulebook/Modes";
import ModesChaosGolf from "./rulebook/modes/ChaosGolf";
import ModesChaosGolf2v2 from "./rulebook/modes/ChaosGolf2v2";
import ModesChaosTeams from "./rulebook/modes/ChaosTeams";
import ModesGolfRacing from "./rulebook/modes/GolfRacing";
import ModesKellyGolf from "./rulebook/modes/KellyGolf";
import ModesSkins from "./rulebook/modes/Skins";
import ModesChaosGolfSolo from "./rulebook/modes/ChaosGolfSolo";
import ModesStableford from "./rulebook/modes/Stableford";
import ModesStrokePlay from "./rulebook/modes/StrokePlay";
import ModesMatchPlay from "./rulebook/modes/MatchPlay";
import Modes18Cards from "./rulebook/modes/Modes18Cards";

import * as Locations from "./navigation/locations";
import CroquetGolf from "./rulebook/modes/CroquetGolf";

const HardCodedMappings = [
  {
    from: "/course/1",
    to: "https://18holesgame.com/p/seabrook-studios/altona-meadows-vqN5Yjvm",
  },
  {
    from: "/course/2",
    to: "https://18holesgame.com/p/seabrook-studios/the-glen-Ds4RqaFv",
  },
  {
    from: "/course/3",
    to: "https://18holesgame.com/p/seabrook-studios/chaos-18-vGUpMMOg",
  },
  {
    from: "/course/4",
    to: "https://18holesgame.com/p/seabrook-studios/donut-sands-opd8lYAt",
  },
  {
    from: "/course/5",
    to: "https://18holesgame.com/p/seabrook-studios/triangle-tees-BmIjb45u",
  },
  {
    from: "/course/6",
    to: "https://18holesgame.com/p/seabrook-studios/seabrook-black-9-Z5jQJY8f",
  },
  {
    from: "/course/7",
    to: "https://18holesgame.com/p/seabrook-studios/royal-yea-th7hXrtY",
  },
  {
    from: "/course/8",
    to: "https://18holesgame.com/p/seabrook-studios/cheshunt-tCq7LL7e",
  },
  {
    from: "/course/9",
    to: "https://18holesgame.com/p/seabrook-studios/chaos-greens-l3GzDAYu",
  },
  {
    from: "/course/10",
    to: "https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5",
  },
  {
    from: "/course/11",
    to: "https://18holesgame.com/p/racooke24/cooke-national-golf-course-iY8ODghU",
  },
  {
    from: "/course/12",
    to: "https://18holesgame.com/p/slowlitespeed/archs-legendary-run-4H614QmC",
  },
  {
    from: "/course/13",
    to: "https://18holesgame.com/p/quantumpotato/angels-ocean-nzOEN06P",
  },
  {
    from: "/course/14",
    to: "https://18holesgame.com/p/valentin/pebble-fields-8ZD542GO",
  },
  {
    from: "/course/15",
    to: "https://18holesgame.com/p/ash/shaganappi-point-FgpkooR7",
  },
  {
    from: "/course/16",
    to: "https://18holesgame.com/p/seabrook-studios/feline-fields-O2mTCOTd",
  },
  {
    from: "/course/17",
    to: "https://18holesgame.com/p/abznak/bramblethorn-acres-dEEaOeUi",
  },
  {
    from: "/course/18",
    to: "https://18holesgame.com/p/seabrook-studios/sandy-9-VjZir7Jj",
  },
  {
    from: "/course/19",
    to: "https://18holesgame.com/p/seabrook-studios/spiral-fields-hC00yi4h",
  },
  {
    from: "/course/20",
    to: "https://18holesgame.com/p/seabrook-studios/chaos-pines-3MUlbmQb",
  },
  {
    from: "/lets-play",
    to: "https://18holesgame.com/more",
  },
];

const SiteMap = () => {
  return (
    <Router>
      <UserConfigurationProvider>
        <PublicCoursesProvider>
          <UserAccountProvider>
            <PrivateCoursesProvider>
              <ToastContainer />
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path={Locations.Privacy} component={Privacy} />
                <Route exact path={Locations.Credits} component={Credits} />
                <Route exact path={Locations.FAQ} component={More} />
                <Route exact path={Locations.More} component={More} />
                <Route exact path={Locations.Rules} component={RuleBook} />
                <Route exact path={Locations.RuleBook} component={RuleBook} />
                <Route exact path={Locations.Modes} component={Modes} />
                <Route exact path={Locations.ModesChaosGolf} component={ModesChaosGolf} />
                <Route exact path={Locations.ModesChaosGolf2v2} component={ModesChaosGolf2v2} />
                <Route exact path={Locations.ModesChaosTeams} component={ModesChaosTeams} />
                <Route exact path={Locations.ModesGolfRacing} component={ModesGolfRacing} />
                <Route exact path={Locations.ModesKellyGolf} component={ModesKellyGolf} />
                <Route exact path={Locations.ModesSkins} component={ModesSkins} />
                <Route exact path={Locations.ModesChaosGolfSolo} component={ModesChaosGolfSolo} />
                <Route exact path={Locations.ModesStableford} component={ModesStableford} />
                <Route exact path={Locations.ModesStrokePlay} component={ModesStrokePlay} />
                <Route exact path={Locations.ModesMatchPlay} component={ModesMatchPlay} />
                <Route exact path={Locations.ModesCroquetGolf} component={CroquetGolf} />
                <Route exact path={Locations.Modes18Cards} component={Modes18Cards} />
                <Route exact path={Locations.BrowseCourses} component={BrowseCourses} />
                <Route exact path={Locations.PrintAndPlay} component={PrintAndPlay} />
                <Route path={Locations.Welcome} exact component={ShowLogin} />
                {HardCodedMappings.map((mapping) => {
                  return (
                    <Route
                      key={mapping.from}
                      exact
                      path={mapping.from}
                      render={() => {
                        window.location.href = mapping.to;
                      }}
                    />
                  );
                })}
                <Route exact path="/p/:username/:title" component={CourseHome} />
                <Route exact path="/p/:username" component={UserCourses} />

                <Route exact path={Locations.IsNewUser} component={IsNewUser} />
                <Route exact path={Locations.Account} component={Account} />

                <Route exact path={Locations.MyCourses} component={MyCourses} />
                <Route exact path="/u/course/:courseId" component={CourseDesigner} />

                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </PrivateCoursesProvider>
          </UserAccountProvider>
        </PublicCoursesProvider>
      </UserConfigurationProvider>
    </Router>
  );
};

const App = () => {
  return (
    <div>
      <SiteMap />
    </div>
  );
};

export default App;
