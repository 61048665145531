import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ensureLoggedIn, withLogout } from "../auth/react-auth0-wrapper";
import { ButtonButtonAlt } from "../common/Button";
import { BrowseCourses } from "../navigation/locations";
import { SiteNavigation } from "../navigation/SiteNavigation";
import { withAccountInfo } from "./UserAccountProvider";

const AccountBase = ({
  username,
  designerName,
  canChangeUsername,
  saveProfile,
  logout,
}) => {
  let history = useHistory();
  const [newUsername, setUsername] = useState(username || "");
  const [newDesignerName, setDesignerName] = useState(designerName || "");
  const [hasChanges, setHasChanges] = useState(false);
  const [message, setUsernameMessage] = useState("");

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const onDesignerNameChange = (event) => {
    setDesignerName(event.target.value);
  };

  async function onClickHandler() {
    const message = await saveProfile(newUsername, newDesignerName);
    setUsernameMessage(message);

    if (!message) {
      setHasChanges(false);
    }
  }

  async function onLogoutHandler() {
    logout();
    history.push(BrowseCourses);
  }

  useEffect(() => {
    setDesignerName(designerName || "");
  }, [designerName]);

  useEffect(() => {
    setUsername(username || "");
  }, [username]);

  useEffect(() => {
    setHasChanges(
      (newUsername !== username || newDesignerName !== designerName) &&
        newUsername.length > 0 &&
        newDesignerName.length > 0
    );
  }, [newUsername, newDesignerName]);

  return (
    <>
      <SiteNavigation />
      <div className="max-w-4xl flex items-center h-auto flex-wrap mx-auto my-0 mt-8">
        <div className="w-full rounded-l-lg shadow-2xl bg-white opacity-75 mx-0">
          <div className="p-12 text-left">
            <h1 className="text-3xl pb-1">Your designer profile</h1>
            <input
              className="text-2xl font-bold p-2 border rounded w-full"
              placeholder="Enter your name"
              value={newDesignerName}
              onChange={onDesignerNameChange}
              maxLength={70}
            />
            <p className="text-sm pt-1">
              Your name will be publically available as the designer on any
              courses you publish.
            </p>
            <input
              className="text-1xl font-bold p-2 mt-4 border rounded w-full"
              placeholder="Enter your username"
              value={newUsername}
              onChange={onUsernameChange}
              maxLength={35}
              disabled={!canChangeUsername}
            />
            {canChangeUsername ? (
              <p className="text-sm pt-1">
                Your username is used to create sharable links for your
                courses.&nbsp;
                <em>
                  After you have published a course you cannot change your
                  username.
                </em>
              </p>
            ) : (
              <p className="text-sm pt-1">
                You cannot change your username as you have published a course.
              </p>
            )}
            <p className="text-sm pt-8 h-8 text-red-500">{message}</p>
            <div className="pt-12 pb-8 flex-row flex flex-1 justify-between">
              <ButtonButtonAlt
                onClick={onClickHandler}
                enabled={hasChanges}
                busyText="Saving"
              >
                Save Profile
              </ButtonButtonAlt>
              <ButtonButtonAlt enabled onClick={onLogoutHandler}>
                Logout
              </ButtonButtonAlt>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Account = ensureLoggedIn(withLogout(withAccountInfo(AccountBase)));

export default Account;
