import React from "react";
import { Link } from "react-router-dom";
import courseTile from "./images/course-tile.png";
import adjustmentTile from "./images/adjustment-tiles.png";
import clubCard from "./images/it/sample-club.png";
import clubCardWithPlayerMarker from "./images/it/sample-club-with-player-count.png";
import soloCard from "./images/sample-solo.png";
import ruleBook from "./images/rule-book-cover.png";
import courseBook from "./images/course-book-cover.png";
import golferCard from "./images/it/sample-golfer.png";
import shotCard from "./images/sample-shot.png";
import token1 from "./images/1-point.png";
import token2 from "./images/2-points.png";
import token5 from "./images/5-points.png";
import meeple from "./images/meeple.png";
import flags from "./images/flag.png";
import trees from "./images/tree.png";
import holeInOne from "./images/final-hole-in-one-token.png";
import soloMarker from "./images/final-solo-marker.png";
import courseTileWithHighlight from "./images/course-tile-with-highlight.png";
import teeToGreen from "./images/from-tee-to-green.png";
import greenHex from "./images/green-hex.png";
import bunkerHex from "./images/bunker-hex.png";
import treeHex from "./images/tree-hex.png";
import teeboxHex from "./images/teebox-hex.png";
import clubhouse from "./images/clubhouse.png";
import hybrid8 from "./images/it/8-hybrid.png";
import iron9 from "./images/it/9-iron.png";
import iron6 from "./images/it/6-iron.png";
import iron4 from "./images/it/4-iron.png";
import iron3 from "./images/it/3-iron.png";
import wood5 from "./images/it/5-wood.png";
import lobWedge from "./images/it/lw.png";
import driver from "./images/it/driver.png";
import deckC from "./images/deck-c.png";
import deckCStraight from "./images/deck-c-straight.png";
import deckCRight from "./images/deck-c-right.png";
import deck3Left from "./images/deck-3-left.png";
import deck3Super from "./images/it/deck-3-super.png";
import deck4Left from "./images/deck-4-left.png";
import deck4Straight from "./images/deck-4-straight.png";
import deck5right from "./images/deck-5-right.png";
import wildCard from "./images/deck-4-wild.png";
import shuffle from "./images/it/shuffle.png";
import superHit from "./images/it/super-hit.png";
import shortHit from "./images/it/short-hit.png";
import longestDriveCard from "./images/it/longest-drive.png";
import roughHex from "./images/rough-hex.png";
import clareExample from "./images/clare-example.png";
import bunkerOutcomes from "./images/bunker-outcomes.png";
import exitBunkerExample from "./images/exit-bunker.png";
import exitBunkerShotCard from "./images/exit-bunker-shot-card.png";
import roughExample1 from "./images/rough-example-1.png";
import roughExample2 from "./images/rough-example-2.png";
import chippingExample from "./images/chipping-example.png";
import golfer1a from "./images/it/golfer-1-a.png";
import golfer1b from "./images/it/golfer-1-b.png";
import golfer2a from "./images/it/golfer-2-a.png";
import golfer2b from "./images/it/golfer-2-b.png";
import golfer3a from "./images/it/golfer-3-a.png";
import golfer3b from "./images/it/golfer-3-b.png";
import golfer4a from "./images/it/golfer-4-a.png";
import golfer4b from "./images/it/golfer-4-b.png";
import golfer5a from "./images/it/golfer-5-a.png";
import golfer5b from "./images/it/golfer-5-b.png";
import golfer6a from "./images/it/golfer-6-a.png";
import golfer6b from "./images/it/golfer-6-b.png";
import golfer7a from "./images/it/golfer-7-a.png";
import golfer7b from "./images/it/golfer-7-b.png";
import golfer8a from "./images/it/golfer-8-a.png";
import golfer8b from "./images/it/golfer-8-b.png";
import {
  ModesChaosGolf,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  PublicRules,
} from "../navigation/locations";
import {
  Section,
  BoxPart,
  SideBySide,
  SideBySideSection,
  FirstGame,
  GreenPanel,
  Example,
  AlwaysSideBySide,
  HybridCardsItalian,
  StandardPortraitCardSize,
  StandardLandscapeCardSize,
  SmallPortraitCardSize,
  SmallHex,
  CourseTileSize,
  WaterHazards,
} from "./RuleBookParts";
import { A, H1, H2, note, P, H3, H4, H5, link, ul } from "../brand/typography";

const lang = "it";

export const RuleBookItalian = () => {
  return (
    <div className="flex flex-col justify-start items-start p-8 md:w-5/5 print:block">
      <H1>Regolamento</H1>
      <div className="self-center print:hidden">
        <A href={PublicRules} target="_blank">
          Download as PDF
        </A>
      </div>
      <div className="p-4">
        <H2>INDICE</H2>
        <div className="flex flex-col">
          <A href="#box-contents">CONTENUTI DELLA CONFEZIONE</A>
          <A href="#establishing-shot">STABILIRE IL TIRO DAL TEE</A>
          <A href="#overview">PANORAMICA</A>
          <A href="#from-tee-box-to-green">DAL TEE BOX AL GREEN</A>
          <A href="#setup">PREPARAZIONE</A>
          <A href="#playing-a-round">GIOCARE UN ROUND</A>
          <A href="#rough">GIOCARE DAL ROUGH</A>
          <A href="#chipping">CHIPPING</A>
          <A href="#super-short-wild">SUPER, SHORT, SHUFFLE E WILD CARD</A>
          <A href="#hazards">OSTACOLI E ALTRE TESSERE</A>
          <A href="#golfers">ABILITÀ DEI GOLFISTI</A>
          <A href="#game-modes">DIVERSI MODI PER GIOCARE</A>
        </div>
      </div>
      <Section id="box-contents" title="CONTENUTI DELLA CONFEZIONE">
        <div className="flex flex-col md:flex-row justify-between items-center md:flex-wrap print:w-full print:flex-row print:flex-wrap">
          <BoxPart src={courseTile} text="49 tessere percorso" size={200} />
          <BoxPart src={adjustmentTile} text="21 tessere aggiustamento" size={66} />
          <BoxPart src={meeple} size={150} text="5 golfisti" />
          <BoxPart src={golferCard} size={150} text="8 carte golfista" />
          <BoxPart src={clubCard} size={200} text="34 carte mazza" />
          <BoxPart src={shotCard} size={150} text="74 carte tiro" />
          <BoxPart src={soloCard} size={200} text="24 carte solo chaos golf" />
          <BoxPart src={token1} size={66} text="18 segnalini da 1 punto" />
          <BoxPart src={token2} size={66} text="18 segnalini da 2 punti" />
          <BoxPart src={token5} size={100} text="18 segnalini da 5 punti" />
          <BoxPart src={holeInOne} size={66} text="18 segnalini Hole-in-One" />
          <BoxPart src={soloMarker} size={66} text="1 contrassegno turno solitario" />
          <BoxPart src={flags} size={66} text="18 bandierine e supporti" />
          <BoxPart src={trees} size={66} text="14 alberi" />
          <BoxPart src={ruleBook} size={66} text="Questo manuale" />
          <BoxPart src={courseBook} size={66} text="Libro Percorso" />
        </div>
        <em className={note}>
          Per segnare il punteggio, alcune modalità di gioco richiedono carta e penna (non incluse).
        </em>
      </Section>
      <Section id="establishing-shot" title="STABILIRE IL TIRO DAL TEE">
        <P>
          <em>
            Nella principale sede aziendale del gigante dello sport internazionale Golf Corp, è in
            corso una riunione del consiglio di amministrazione. Tale incontro determinerà il futuro
            del golf.
          </em>
        </P>
        <P>
          Stimati membri del consiglio, la situazione è disperata. Le partecipazioni sono in calo, i
          progressi fatti nella tecnologia delle mazze hanno reso il nostro gioco troppo semplice.
          Con la concorrenza in continuo aumento che cerca di attirare l’attenzione della gente, le
          persone non possono più permettersi di passare 4 ore a giocare a golf. La bassa
          partecipazione mette pressione sui nostri campi per sbarcare il lunario. Frotte di
          imprenditori edilizi hanno iniziato a dare la caccia ai proprietari per convertire i
          nostri preziosi fairway in proprietà immobiliari senz’anima.
        </P>
        <P>
          Non tutto è perduto. Ho messo insieme un team formato dalle nostre menti migliori per
          escogitare una nuova edizione del nostro grande gioco. Il team ha pensato a un paio di
          modifiche alle regole in grado di tirarci fuori dalla sabbia e farci filare dritti verso
          la buca.
        </P>
        <P>
          I proprietari hanno bisogno di più persone sui loro campi da golf, e non siamo riusciti a
          migliorare il numero di ore buone durante la giornata. Dovremmo cambiare il nostro
          approccio e introdurre il gioco simultaneo. Più persone che giocano a golf
          contemporaneamente significa maggiori incassi. La nostra tecnologia brevettata HOLOBALL™
          permetterà a tutti i membri di un gruppo di iniziare in sicurezza una partita a golf allo
          stesso momento.
        </P>
        <P>
          Si tratta di una tecnologia fantastica. Credevo che l’elemento vincente sarebbe stato una
          flotta di droni che tracciavano e fermavano le palline da golf. Ma questo… Questa cosa di
          rendere la stessa pallina da golf un drone è una mossa ancora più intelligente. Palline da
          golf che non si perdono mai. Palline da golf che possono fermarsi prima di colpire
          qualcuno e poi riprendere il volo. Palline da golf che tengono traccia dei punteggi.
        </P>
        <P>
          Ora, ammetto che c’è stato qualche imprevisto. Si potrebbe perfino dire che la tecnologia
          è ancora un po’ primitiva. Però, penso che possiamo iniziare a utilizzarla su alcuni dei
          nostri campi che faticano a rimanere sotto il par.
        </P>
        <P>
          In passato avevamo un ottimo rapporto con i produttori di mazze. Tuttavia, negli ultimi
          tempi, ogni nuovo progresso nella tecnologia golfistica indebolisce la sfida, che si
          tratti delle palline che ritornano da sole, dei super driver o dei più recenti wedge da
          sabbia magnetici.
        </P>
        <P>
          Per controbattere ai produttori di mazze che traggono profitto dalla scomparsa del nostro
          grande gioco, abbiamo introdotto alcune modifiche che non prenderanno direttamente di mira
          loro ma che ne ridurranno l’influenza.
        </P>
        <P>
          Il golf, da sempre, non ha a che fare solo con l’esecuzione ma anche con la scelta del
          tiro. Ridurremo il limite delle mazze da 14 a 5, obbligando i giocatori a pensare di più
          al tipo di mazza da scegliere e a come usarla. Giocare a golf significa pianificare e
          mettere in pratica un approccio strategico; per questo motivo non consentiremo ai
          giocatori di utilizzare la stessa mazza fin quando non avranno usato tutte le altre.
        </P>
        <P>Membri del consiglio. Benvenuti nella nuova era del golf!</P>
        <P>
          <em>
            Il discorso viene accolto da un lungo applauso nella sala del consiglio di
            amministrazione.
          </em>
        </P>
      </Section>
      <Section id="overview" title="Panoramica">
        <P>
          Sono passati 10 anni da quella riunione del consiglio. Il golf non è mai stato popolare
          come adesso. Assieme all’invenzione della tecnologia HOLOBALL™, sono stati creati nuovi
          modi per giocare, e lo sport prospera. Tu sei un golfista in quest’epoca d’oro del golf.
          Scegli le tue mazze e completa il percorso selezionando attentamente i tiri e
          districandoti con intelligenza fra gli ostacoli.
        </P>
        <P>
          Il gioco si svolge sulle 18 buche di un percorso da golf – ogni buca richiede al giocatore
          di utilizzare una o più mazze. Dopo averne scelta una, i giocatori non potranno
          utilizzarla di nuovo prima di aver giocato con tutte le altre mazze. I golfisti guadagnano
          punti raggiungendo per primi i green. Vince chi mette a segno il maggior numero di punti.
        </P>
        <P>
          Questo libro contiene le regole per tante modalità diverse, inclusa quella in solitario, a
          due giocatori, golf racing e chaos golf. Ogni modalità di gioco permette di personalizzare
          l’esperienza secondo i propri gruppi di gioco. La prima parte di questo libro copre il set
          di regole standard chiamato Match Play.
        </P>
        <SideBySide title="TESSERE PERCORSO" right={courseTileWithHighlight} size={200}>
          <P>
            Usa queste tessere per costruire il percorso sul quale giocherai. Ci sono 49 tessere a
            doppia faccia che puoi usare per creare un nuovo percorso ogni volta che giochi.
          </P>
          <P>
            Le tessere hanno un livello di difficoltà fra i tre possibili indicati dalle stelle
            presenti nell’angolo di ogni tessera. Ciascuna tessera ha un numero e una freccia che
            punta nella direzione principale.
          </P>
          <P>
            Consulta il libretto accompagnatorio per i design dei percorsi e le istruzioni su come
            costruirli.
          </P>
        </SideBySide>
        <SideBySide title="TESSERE DI AGGIUSTAMENTO" right={adjustmentTile} size={132} height="132">
          <P>
            Questi mini esagoni possono essere posizionati sopra le tessere percorso per modificarne
            la difficoltà. Per un gioco più rilassato, copri gli ostacoli mettendo le tessere con il
            lato rough rivolto verso l’alto. Per una sfida più impegnativa, invece, usa il lato
            ostacoli per modificare quelli esistenti o aggiungerne di nuovi.
          </P>
        </SideBySide>
        <SideBySide title="CARTE GOLFISTA" right={golferCard} size={200}>
          <P>
            I giocatori selezionano le carte golfista che forniscono un’abilità speciale. Le abilità
            sul lato A riducono il livello della sfida, mentre quelle sul lato B contengono alcune
            restrizioni. Puoi selezionare sia il lato A che il lato B.
          </P>
        </SideBySide>
        <SideBySide title="CARTE MAZZA" right={clubCard} size={250}>
          <P>
            Ciascuna mazza possiede un’abilità unica oppure ti dice da quale mazzo tiro pescare.
            Puoi portare con te solo cinque mazze sul campo. Una volta utilizzata una mazza, non
            potrai più usarla fin quando non avrai utilizzato tutte le altre mazze.
          </P>
        </SideBySide>
        <SideBySide title="CARTE TIRO" right={shotCard} size={200}>
          <P>
            Queste carte sono organizzate in cinque mazzi e rappresentano le diverse distanze e
            direzioni per i tuoi tiri. Ciascun mazzo contiene carte che colpiscono la palla a
            sinistra, a destra e, in alcuni casi, in una direzione a tua scelta.
          </P>
          <P>
            Una mappa del volo su ciascuna carta ti aiuta a tracciare il tragitto della tua pallina.
            L’esagono arancione segna il punto di atterraggio della pallina. Quando la colpisci dal
            rough, questa viaggia per un esagono in meno. Vedi&nbsp;
            <A href="#rough">GIOCARE DAL ROUGH</A>.
          </P>
          <P>
            Le carte tiro possono essere usate anche per uscire dai bunker. L’icona con il risultato
            del bunker si trova nell’angolo in basso a destra. Vedi&nbsp;
            <A href="#4d">USCIRE DA BUNKER, GREEN E VINCERE</A>.
          </P>
        </SideBySide>
        <SideBySide
          title="SEGNALINI GREEN / SEGNALINI PUNTI"
          right={courseTileWithHighlight}
          size={200}
        >
          <P>
            I segnalini rotondi hanno un doppio scopo. La parte frontale ha i green numerati da 1 a
            18. Dato che le tessere percorso non hanno numeri sui green, posizionare i segnalini con
            il numero rivolto verso l’alto aiuta a tenere traccia dell’ordine delle buche.
          </P>
          <P>
            In molte delle modalità di gioco di 18 Holes, per segnare i punti si usano i segnalini
            con il lato punti rivolto verso l’alto. Questi lati mostrano valori 5, 2 e 1. Vedi&nbsp;
            <A href="#game-modes">METODI DIVERSI PER GIOCARE</A>.
          </P>
          <P>
            Ci sono segnalini di ricambio da 1 pt, 2 pt e 5 pt. Questi non hanno numeri green.
            Conservali casomai dovessi averne bisogno.
          </P>
        </SideBySide>
        <SideBySide title="SEGNALINO Hole-in-One" right={holeInOne} size={200}>
          <P>
            Si raccolgono quando metti a segno un Hole-in-One. Nell’eventualità di un pareggio, a
            vincere sarà il giocatore con il maggior numero di segnalini Hole-in-One.
          </P>
        </SideBySide>
        <SideBySide title="Carte Chaos Golf in solitario" right={soloCard} size={250}>
          <P>
            Usa questo mazzo di carte per organizzare il flusso di gioco del chaos golf in solitario
            e per regolarne la difficoltà. Queste carte hanno due simboli diversi: i cerchi a un
            colore, che rappresentano i turni nei quali solo il giocatore ottiene punti, e i cerchi
            bicolore, che rappresentano i turni in cui sia tu che i tuoi avversari realizzate dei
            punti. Vedi&nbsp;
            <A href={ModesChaosGolfSolo}>CHAOS GOLF IN SOLITARIO</A>.
          </P>
        </SideBySide>
        <SideBySide title="SEGNALINO TURNO PER GIOCO IN SOLITARIO" right={soloMarker} size={200}>
          <P>Si usa per tenere traccia del turno durante una partita di Chaos golf in solitario.</P>
        </SideBySide>
        <SideBySide title="BANDIERINE" right={flags} size={200}>
          <P>
            Queste bandierine si posizionano sui green per indicare dove stai mirando. Ce n’è una
            per ciascuna buca, numerata da 1 a 18, e 3 di riserva (senza numero). Metti da parte
            queste ultime nel caso dovessero servire in futuro.
          </P>
        </SideBySide>
        <SideBySide title="ALBERI" right={trees} size={200}>
          <P>
            Si tratta di decorazioni da mettere lungo il percorso. Posizionali sugli ostacoli
            alberati per renderli più visibili. Poiché ci sono solo 14 alberi, mentre giochi dovrai
            spostarli lungo il percorso.
          </P>
        </SideBySide>
        <SideBySide title="GOLFISTI" right={meeple} size={200}>
          <P>
            Ogni giocatore è rappresentato da una miniatura del golfista. Non c’è alcuna differenza
            fra loro, quindi scegli il colore che più ti piace.
          </P>
        </SideBySide>
      </Section>
      <SideBySideSection
        id="from-tee-box-to-green"
        title="Dal Tee Box al Green"
        right={teeToGreen}
        size="450"
        height="1024"
      >
        <P>
          Una partita a 18 Holes si gioca su un percorso che consiste in una o più buche. La maggior
          parte dei percorsi presenta 18 buche anche se è comune avere dei percorsi più brevi e
          rapidi da 9 buche. Metti a segno un punto per ogni buca vinta. Si vince la buca
          raggiungendo il green prima degli avversari. Nell’eventualità di un pareggio, ad
          accumulare punti saranno più giocatori.
        </P>
        <P>
          Il green è l’esagono piccolo con la bandierina nera. A destra abbiamo una singola buca
          composta da tre caselle percorso. La casella superiore contiene il green. Il tee box è
          sulla casella inferiore. Dal tee box al green corre il fairway.
        </P>
        <P>
          Quando giochi una buca, inizi con il tuo golfista sul tee box e l’obiettivo è arrivare al
          green. Giochi con le mazze che fanno spostare il tuo golfista. Non sei obbligato a seguire
          il fairway fino al green. Può però essere una buona idea perché i tuoi tiri andranno più
          lontani quando giocati dal fairway.
        </P>
        <P>
          Ci sono solo un paio di caselle su cui non è bene atterrare: gli ostacoli alberati e la
          sede del club. L’ostacolo alberato avrà uno steccato bianco attorno ad esso mentre{" "}
          <A href="#hazards">la sede del club si può vedere a pagina 15</A>. Puoi atterrare sui
          fairway e sui green delle altre buche e puoi atterrare sulle caselle che contengono altri
          golfisti. Tutto questo grazie alla HOLOBALL™.
        </P>
        <P>
          Benché tu possa atterrare sulla maggior parte delle caselle del gioco, non tutte sono
          raccomandate. Alcune contengono dell’acqua che ti farà perdere un turno e altre contengono
          dei bunker dai quali dovrai uscire prima di poter continuare. Il verde scuro che si trova
          su ogni altra casella è il rough e andrà a ridurre la distanza dei tuoi tiri. Vedi&nbsp;
          <A href="#hazards">OSTACOLI E ALTRE CASELLE</A>.
        </P>
        <P>
          Ci sono molti modi per personalizzare la tua esperienza con 18 Holes. Modalità di gioco
          diverse ti forniscono diverse esperienze e sfide. Puoi regolare i percorsi per renderli
          più semplici o più difficili. Gli otto golfisti compresi nel gioco ti permettono di
          rendere il gioco più semplice o più difficile a seconda del giocatore. Provando le varie
          opzioni, siamo sicuri che troverai un’esperienza di gioco con la quale ti divertirai più
          che con le altre e che continuerai a scegliere in seguito.
        </P>
      </SideBySideSection>
      <Section id="setup" title="PREPARAZIONE">
        <H3>COSTRUIRE IL PERCORSO</H3>
        <P>
          Puoi preparare il percorso utilizzando uno dei design del libretto percorsi oppure
          progettarne uno tutto tuo. L’ultima pagina del libretto percorsi include le istruzioni su
          come creare i percorsi.
        </P>
        <SideBySide right={greenHex} size={132}>
          <P>
            Una volta costruito il tuo percorso, posiziona una bandierina su ciascuna buca. Metti la
            prima bandierina sul green (destra) per la prima buca e continua fino al 18º green. Se
            giochi con gli alberi, mettili sui primi 14 alberi del percorso. Gli ostacoli alberati
            sono gli esagoni con gli steccati bianchi intorno.
          </P>
        </SideBySide>
        <P>È il momento che tutti quanti scelgano un golfista e selezionino le mazze.</P>
        <FirstGame lang={lang}>
          <A
            href="https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5"
            target="_blank"
          >
            Prova lo Starting Course
          </A>
        </FirstGame>
        <H3>SELEZIONA IL GOLFISTA</H3>
        <P>
          Ogni giocatore seleziona una carta golfista da giocare. Puoi permettere ai giocatori di
          selezionare la carta e il lato che preferiscono oppure distribuirne una a caso.
        </P>
        <P>
          Ogni carta golfista presenta un lato A e un lato B. Il golfista lato A tende a rendere il
          gioco più semplice mentre il golfista lato B tende a renderlo più difficile.
        </P>
        <P>
          La selezione del golfista precede la scelta delle mazze, ciò perché alcuni golfisti
          influenzano la fase di selezione delle mazze.
        </P>
        <FirstGame lang={lang}>
          Salta le carte golfista fino a quando non sarai pronto ad aumentare la sfida.
        </FirstGame>
        <H3>SELEZIONE DELLE MAZZE</H3>
        <P>I giocatori selezionano le mazze fino ad averne 5 a testa.</P>
        <SideBySide right={clubCardWithPlayerMarker} size={250}>
          <P>
            Mescola le carte mazza da golf. Se giochi da solo o con due giocatori, rimuovi le mazze
            che mostrano 3+ o 5+ nell’angolo in basso a destra. In una partita con 3 o 4 giocatori,
            rimuovi le carte 5+.
          </P>
        </SideBySide>
        <P>
          Distribuisci lo stesso numero di carte a ogni giocatore. Le carte rimanenti non verranno
          utilizzate e andranno rimesse nella scatola senza essere fatte vedere.
        </P>
        <P>
          Ogni giocatore seleziona una mazza fra le carte ricevute e la mette davanti a sé, coperta.
          Poi passa le carte rimanenti al giocatore alla propria sinistra sinistra. Di nuovo,
          ciascun giocatore seleziona una carta dalle quattro rimanenti e la mette davanti a sé
          coperta, poi passa il resto delle carte a sinistra. Si continua così finché tutti i
          giocatori non avranno cinque mazze. Questa è la loro mano. Ciascun giocatore avrà bisogno
          anche di spazio per la pila degli scarti davanti a sé dove mettere le mazze usate.
        </P>
        <P>
          Se un giocatore ha The Shark, Iron Jane o Slugger si veda&nbsp;
          <A href="#golfers">ABILITÀ GOLFISTA</A> per le regole che cambiano la selezione.
        </P>
        <P>
          <em>
            La selezione è la tua prima possibilità di ottenere un vantaggio. Le caratteristiche del
            percorso determinano l’efficacia di alcune mazze. Dai un’occhiata al percorso per vedere
            quali mazze pensi saranno più utili.
          </em>
        </P>
        <FirstGame lang={lang}>
          Sulla faccia di alcune carte mazza da golf si trova una A,B,C,D o E. Anziché scegliere,
          distribuisci le A al giocatore 1, le B al giocatore 2, e così via.
        </FirstGame>
        <H3>INIZIA A GIOCARE</H3>
        <P>
          Ora sei pronto per iniziare a giocare. Posiziona tutti i golfisti sul tee box della prima
          buca. Tutti i giocatori devono avere le mazze in mano, nascoste alla vista degli altri
          giocatori.
        </P>
        <P>
          Prendi i 5 mazzi tiro, questi avranno scritto C, 2, 3, 4 o 5 sul retro. Mescola e
          posizionali a portata di mano di tutti i giocatori.
        </P>
      </Section>
      <Section id="playing-a-round" title="Giocare un Round">
        <GreenPanel>
          <H3>Giocare un Round</H3>
          <P>OGNI ROUND DI 18 HOLES È SUDDIVISO NELLE SEGUENTI FASI:</P>
          <H4 href="#selecting-clubs">SELEZIONE DELLE MAZZE</H4>
          <ul className="pl-4">
            <li>
              <A href="#1">
                1.
                <span className="pl-3">Ripristinare le mani vuote</span>
              </A>
            </li>
            <li>
              <A href="#2">
                2.
                <span className="pl-3">Selezionare e rivelare le mazze</span>
              </A>
            </li>
            <li>
              <A href="#3">
                3.<span className="pl-3">Determinare l’ordine di gioco</span>
              </A>
            </li>
          </ul>
          <H4 href="#on-your-turn">NEL TUO TURNO</H4>
          <ul className="pl-4">
            <li>
              <A href="#4">
                4.<span className="pl-3">Completa un’azione</span>
              </A>
            </li>
            <ul className="pl-4">
              <li>
                <A href="#4a">
                  A.<span className="pl-3">Muovi di un esagono</span>
                </A>
              </li>
              <li>
                <A href="#4b">
                  B.<span className="pl-3">Gioca la carta mazza</span>
                </A>
              </li>
              <li>
                <A href="#4c">
                  C.<span className="pl-3">Prova un grande colpo</span>
                </A>
              </li>
              <li>
                <A href="#4d">
                  D.<span className="pl-3">Esci dal bunker</span>
                </A>
              </li>
              <li>
                <A href="#4e">
                  E.<span className="pl-3">Azzera la tua mano</span>
                </A>
              </li>
            </ul>
            <li>
              <A href="#5">
                5.<span className="pl-3">Scarta la mazza.</span>
              </A>
            </li>
          </ul>
          <H4 href="#reaching-the-green">RAGGIUNGERE IL GREEN</H4>
          <ul className="pl-4">
            <li>
              <A href="#6">
                6.<span className="pl-3">Prendere bandierine</span>
              </A>
            </li>
            <li>
              <A href="#7">
                7.<span className="pl-3">Prendere segnalini Hole-in-One</span>
              </A>
            </li>
            <li>
              <A href="#8">
                8.
                <span className="pl-3">Spostare tutti i giocatori alla buca successiva</span>
              </A>
            </li>
            <li>
              <A href="#9">
                9.
                <span className="pl-3">Rimuovere ogni effetto degli ostacoli</span>
              </A>
            </li>
          </ul>
        </GreenPanel>
        <H3 id="selecting-clubs" href="#selecting-clubs">
          SELEZIONE DELLE MAZZE
        </H3>
        <H4 id="1" href="#1">
          1. RIPRISTINARE LE MANI VUOTE
        </H4>
        <P>Se non hai mazze in mano, riprendi in mano tutte le mazze scartate.</P>
        <P>
          Se hai una mazza in mano che non puoi usare, puoi anche in questo caso azzerare la tua
          mano, ad esempio se hai il driver e non sei sul tee box.
        </P>
        <P>
          Se giochi con il lato A di Dorian, puoi azzerare le tue mazze prima di partire da una
          buca.
        </P>
        <H4 id="2" href="#2">
          2. SSELEZIONARE LE MAZZE
        </H4>
        <P>
          Ciascun giocatore seleziona una mazza dalla propria mano e la posiziona a faccia in giù
          davanti a sé.
        </P>
        <P>
          Una volta che tutti i giocatori lo avranno fatto, tutte le scelte verranno rivelate
          contemporaneamente. Dopodiché, i giocatori non potranno più cambiare idea.
        </P>
        <P>
          Il giocatore con Bunjil deve usare il suo potere prima di posizionare una mazza a faccia
          in giù. Se Bunjil si trova sul lato B ed espone una carta tiro, i giocatori che hanno
          posizionato una mazza a faccia in giù potranno cambiare idea.
        </P>
        <P>
          Il giocatore con Slick Rikesh sul suo lato A può usare il suo potere dopo aver scoperto le
          carte e nominare la mazza di un altro giocatore da usare.
        </P>
        <H4 id="3" href="#3">
          3. DETERMINARE L’ORDINE DI GIOCO
        </H4>
        <P>
          Usa l’ordine di iniziativa di ciascuna mazza per determinare l’ordine di gioco. La mazza
          con l’iniziativa più bassa inizierà a giocare per prima. Puoi trovare l’iniziativa
          nell’angolo in alto a sinistra della carta mazza.
        </P>
        <P>
          Se Slick Rikesh sta usando la mazza di un altro giocatore, Slick Rikesh gioca
          immediatamente dopo l’altro giocatore.
        </P>
        <P>
          Se Swift è sul lato A, vince qualsiasi pareggio sull’iniziativa. Se Swift è sul lato B,
          perde qualsiasi pareggio sull’iniziativa.
        </P>
        <P>
          Se Swift pareggia con Slick Rikesh che ha preso in prestito una mazza, vince sul lato A e
          perde sul lato B.
        </P>
        <P>
          <em>
            Guardando le mazze sotto, la Ibrida 8 possiede un’iniziativa di 5, e quel giocatore
            gioca il proprio turno per primo. Il giocatore successivo è la persona con il Ferro 6
            (iniziativa 12). L’ultimo a giocare è chi ha giocato il Legno 5 (iniziativa 20).
          </em>
        </P>
        <div className="flex flex-col md:flex-row print:flex-row justify-evenly items-center pt-4">
          <div>
            <img src={hybrid8} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron6} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={wood5} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H3 id="on-your-turn" href="#on-your-turn" style={{ breakBefore: "page" }}>
          NEL TUO TURNO
        </H3>
        <P>Si gioca seguendo quest’ordine.</P>
        <H4 id="4" href="#4">
          4. COMPLETA UN’AZIONE
        </H4>
        <P>
          Quando è il tuo turno, puoi eseguire <strong>una</strong> delle azioni seguenti:
        </P>
        <ul className={ul}>
          <li>A. Move one hex</li>
          <li>B. Play club card</li>
          <li>C. Attempt a big hit</li>
          <li>D. Exit bunker</li>
          <li>E. Reset your hand</li>
        </ul>
        <P>Dopo aver completato il turno, scarterai la mazza.</P>
        <H4 id="4a" href="#4a">
          4.a) SPOSTATI DI UN ESAGONO
        </H4>
        <P>
          Se non ti trovi in un bunker, hai l’opzione di spostarti di un esagono. Posiziona il tuo
          golfista su uno degli esagoni adiacenti. Non puoi spostarti su un esagono che ha un
          ostacolo alberato o fuori dal tabellone. Vedi&nbsp;
          <A href="#hazards">OSTACOLI E ALTRE TESSERE</A>.
        </P>
        <H4 id="4b" href="#4b">
          4.b) GIOCA LA CARTA MAZZA
        </H4>
        <P>A seconda della mazza, potrai fare una delle seguenti cose:</P>
        <ul className={ul}>
          <li>Pesca una carta dal mazzo tiro indicato dalla carta</li>
          <li>Pesca una carta da uno dei due mazzi tiro indicati</li>
          <li>Pesca due carte dal mazzo tiro indicato dalla carta </li>
          <li>Sposta il tuo golfista del numero di esagoni in linea retta</li>
        </ul>
        <P>
          Su ciascuna mazza troverai la tua opzione. Giocare una carta mazza coinvolge tre fasi:
          pescare, decidere, muovere.
        </P>
        <P>
          Per la maggior parte delle mazze, peschi le tue carte tiro, decidi in quale direzione
          colpire e infine sposti il tuo golfista, in quest’ordine. Quando peschi dal mazzo chip,
          prima di pescare una carta devi decidere la direzione. Vedi{" "}
          <A href="#chipping">CHIPPING</A>. Quando giochi un legno, non peschi alcuna carta poiché
          la tua mazza muove sempre di una distanza fissa in linea retta.
        </P>
        <H5>PESCARE UNA CARTA TIRO SINGOLA</H5>
        <P>
          La maggior parte delle mazze ti permette di pescare una carta da un mazzo tiro singolo.
          Alcune mazze offrono diversi mazzi fra cui scegliere, ma puoi comunque pescare solo una
          carta.
        </P>
        <P>
          Seleziona il mazzo e pesca la prima carta. Se la carta pescata ha degli effetti speciali
          (super, short o shuffle), segui le istruzioni scritte sulla carta tiro. Posiziona ogni
          carta tiro aggiuntiva che peschi in cima a quella precedente. Una volta finito di pescare,
          usa la carta tiro per capire dove spostare il tuo golfista.
        </P>
        <P>
          Per maggiori informazioni sulle carte speciali, vedi
          <A href="#super-short-wild">SUPER, SHORT, SHUFFLE E WILD CARD</A>.
        </P>
        <P>
          Se il mazzo è vuoto quando devi pescare, mescola la pila degli scarti e girala per formare
          un nuovo mazzo. Non mescolare nel nuovo mazzo le carte che hai pescato in questo turno.
        </P>
        <P>
          Se giochi con Lucky puoi utilizzare la tua abilità di pescare una nuova carta a questo
          punto.
        </P>
        <H5>Pescare Carte Tiro Multiple</H5>
        <P>Quando peschi due carte tiro, puoi scegliere quale delle due usare.</P>
        <P>
          Posiziona le carte tiro fianco a fianco. Se una delle carte tiro ti chiede di pescare
          altre carte (super, short, shuffle), puoi scegliere di seguire le istruzioni e posizionare
          le nuove carte su quella che richiede di pescare. Quando avrai finito di pescare, dovrai
          scegliere una fra le carte visibili. Usa la carta selezionata per muovere il tuo golfista.
        </P>
        <Example>
          <P>
            Nell’esempio sotto, Gustav finisce per pescare 3 carte tiro. Deve scegliere una delle
            due carte visibili in cima.
          </P>
          <div className="flex flex-row justify-evenly items-start w-full">
            <div style={{ paddingTop: 50 }}>
              <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
            </div>
            <div
              style={{
                maxHeight: 275,
                paddingTop: 50,
                overflow: "hidden",
                width: "60%",
              }}
            >
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <img src={deck3Super} style={{ maxHeight: 200, height: 200 }} />
              </div>
              <div style={{ position: "relative", top: -235, left: 35 }}>
                <img src={deck4Straight} style={{ maxHeight: 200, height: 200 }} />
              </div>
            </div>
          </div>
        </Example>
        <H5>SPOSTA IL TUO GOLFISTA</H5>
        <P>
          Il piano di volo mostrato sulla tua carta tiro indica il viaggio della pallina. L’icona
          del golfista sulla carta tiro rappresenta dove la posizione del golfista all’inizio del
          tiro. Sposta il tuo golfista lungo gli esagoni numerati fino a raggiungere l’esagono
          conclusivo arancione. Non devi colpirlo verso il green o lungo il fairway.
        </P>
        <P>
          Se il tuo piano di volo viaggia attraverso un esagono con degli alberi circondati da
          steccato, allora devi fermarti prima di entrare in qualsiasi esagono con gli alberi. La
          tua pallina ha colpito gli alberi e si è fermata.
        </P>
        <P>
          Se atterri su un ostacolo (bunker, lago), segui le regole della pagina&nbsp;
          <A href="#hazards">OSTACOLI E ALTRE CASELLE</A>.
        </P>
        <AlwaysSideBySide right={roughHex} size={132}>
          <P>
            Se hai iniziato il tuo turno nell’esagono del rough (destra), vedi:&nbsp;
            <A href="#rough">GIOCARE DAL ROUGH</A>.
          </P>
        </AlwaysSideBySide>
        <H5>Cambiare idea</H5>
        <P>
          Se non hai tolto il dito dal golfista, puoi cambiare la direzione in cui colpisci la
          pallina.
        </P>
        <Example>
          <P>
            Clare si trova in una posizione in cui non può raggiungere il green con un colpo. Gioca
            un Ferro 2 e pesca una singola carta dal mazzo tiro 4.
          </P>

          <P>
            La carta tiro pescata piega verso sinistra. La sua opzione migliore è colpire fuori dal
            percorso e usare la forma del tiro che le possa permettere di atterrare oltre l’ostacolo
            d’acqua adiacente al green, in una posizione eccellente per il suo prossimo turno.
          </P>
          <div style={{ maxHeight: 350, overflow: "hidden" }}>
            <img src={clareExample} style={{ maxHeight: 350 }} />
            <img
              src={shotCard}
              style={{
                maxHeight: 150,
                height: 150,
                position: "relative",
                top: -350,
                left: 250,
              }}
            />
          </div>
        </Example>
        <H4 id="4c" href="#4c" style={{ breakBefore: "page" }}>
          4.c) PROVARE Un Grande Colpo
        </H4>
        <P>
          A volte ti serve più distanza. Puoi colpire con la tua mazza più lontano rispetto alle
          opzioni visualizzate sulla carta mazza. Per farlo, nel tuo turno, annuncia al gruppo che
          stai per eseguire un Grande Colpo. Ora puoi pescare una carta in più del solito, e tutte
          le carte devono provenire dal mazzo di un livello superiore rispetto a quello dal numero
          più alto mostrato sulla tua carta mazza.
        </P>
        <P>
          Dopo aver estratto le carte, passale al giocatore alla tua sinistra. Questi scarterà tutte
          le carte tiro. Delle carte che rimangono, tu ne sceglierai una da giocare.
        </P>
        <P>
          Qualsiasi carta super, short, o reshuffle viene giocata come al solito. Una super
          significa che il tuo Grande Colpo è ancora più grosso del previsto e una short è molto
          probabile che ti deluderà proprio quando ti serve di più.
        </P>
        <P>
          Non tutte le mazze ti permettono di tentare un Grande Colpo; è così, ad esempio, per le
          mazze che appartengono al mazzo 5. Nell’angolo in alto di ogni mazza si trova un simbolo
          che indica se puoi usarla per un Grande Colpo. Nell’esempio sotto, il driver ha un’icona
          No Big Hit mentre il Ferro 9 mostra che puoi fare un Grande Colpo.
        </P>
        <div className="flex flex-r0ow justify-evenly items-center">
          <div>
            <img src={driver} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron9} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>

        <GreenPanel>
          <H3>Grande Colpo</H3>
          <SideBySide right={iron4} size={200}>
            <P>
              Se la tua mazza permette di pescare dai mazzi tiro 3 e 4, allora puoi pescare 2 carte
              dal mazzo tiro 5. Non puoi pescare due carte dal mazzo tiro 4.
            </P>
          </SideBySide>
          <SideBySide right={iron3} size={200}>
            <P>
              Se la tua carta mazza ti permette di pescare 2 carte, allora devi pescare 3 carte. In
              questo caso l’altro giocatore ne scarterà 1 e tu sceglierai fra le 2 rimaste.
            </P>
          </SideBySide>
        </GreenPanel>
        <H4 id="4d" href="#4d" style={{ breakBefore: "page" }}>
          4.D) USCIRE DAI BUNKER
        </H4>
        <P>
          Quando atterri in un bunker, devi uscirne prima di poter continuare a giocare. Prima di
          pescare una carta tiro, devi decidere la direzione in cui speri di muoverti. Questa regola
          è la stessa del chipping (vedi <A href="#chipping">« CHIPPING »</A>). L’esagono su cui hai
          scelto di muoverti è il tuo esagono obiettivo.
        </P>
        <P>
          Pesca una singola carta tiro da uno dei mazzi mostrati sulla carta. Invece di guardare il
          percorso di volo della carta tiro, guarda l’icona di risultato bunker nell’angolo in basso
          a destra
        </P>
        <P>Ci sono quattro risultati, mostrati sotto. Da sinistra a destra abbiamo:</P>
        <ul className="list-disc list-inside">
          <li>Rimani nel bunker</li>
          <li>Spostati a sinistra del tuo esagono obiettivo</li>
          <li>Spostati sul tuo esagono obiettivo</li>
          <li>Spostati a destra del tuo esagono obiettivo</li>
        </ul>
        <div className="p-4 flex justify-center items-center">
          <img src={bunkerOutcomes} width={300} />
        </div>
        <P>
          Se esci dal bunker, sposta il tuo golfista nell’esagono determinato dall’icona bunker.
        </P>
        <P>
          Se la mazza che hai scelto di giocare è il sand wedge, considera il bunker come rough. Non
          devi eseguire l’azione di uscita dal bunker. Se il sand wedge si trova nella tua mano o
          nella pila degli scarti, non ti protegge dai bunker.
        </P>
        <Example>
          <P>Sebastian si trova in un bunker (A). Dichiara di colpire verso il green (freccia).</P>
          <P>
            Pesca una carta tiro, il risultato bunker rivelato mostra che la pallina va a destra.
          </P>
          <P>Segue l’icona bunker e si sposta a destra (B) della direzione da lui dichiarata.</P>
          <div style={{ height: 325, overflow: "hidden" }} className="p-4">
            <img
              src={exitBunkerShotCard}
              width={150}
              style={{ position: "relative", top: 100, left: 0 }}
            />
            <img
              src={exitBunkerExample}
              width={300}
              style={{ position: "relative", top: -225, left: 0 }}
            />
            <div
              style={{
                position: "relative",
                top: -249,
                left: 98,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </Example>
        <H4 id="4e" href="#4e" style={{ breakBefore: "page" }}>
          4.e) AZZERA LA TUA MANO
        </H4>
        <P>
          Puoi scegliere di non colpire la pallina e azzerare la tua mano. Riporta tutte le mazze
          scartate nella tua mano. Se giochi con il lato A di Dorian, a questo punto non puoi
          azzerare la tua mano.
        </P>
        <H4 id="5" href="#5">
          5. SCARTA LA MAZZA
        </H4>
        <P>
          Il tuo turno termina qui. Metti la mazza utilizzata nella pila degli scarti. Se giochi una
          carta che non scade mai, riprendila in mano, ad esempio il driver di Slugger o la mazza
          del lato A di Dorian. Se giochi con il lato B di Slick Rikesh o il lato B di Dorian, salta
          questa fase.
        </P>
        <H3 id="reaching-the-green" href="#reaching-the-green">
          RAGGIUNGERE IL GREEN
        </H3>
        <H4 id="6" href="#6">
          6. PRENDERE LE BANDIERINE
        </H4>
        <P>
          Quando un giocatore raggiunge il green, prende la bandierina. Se diversi giocatori
          raggiungono il green nello stesso round, allora un giocatore prende la bandierina e gli
          altri un segnalino da 1 punto.
        </P>
        <H4 id="7" href="#7">
          7. PRENDERE I SEGNALINI Hole-in-One
        </H4>
        <P>
          Se hai messo a segno un Hole-in-One, allora prendi un segnalino Hole-in-One. Metti a segno
          un Hole-in-One nel tuo primo turno per la buca quando colpisci dal tee box e atterri sul
          green.
        </P>
        <H4 id="8" href="#8">
          8. SPOSTATI ALLA BUCA SEGUENTE
        </H4>
        <P>
          Dopo che i giocatori avranno ricevuto i segnalini green, sposta tutti i giocatori al tee
          box della buca successiva.
        </P>
        <H4 id="9" href="#9">
          9. RIMUOVI OGNI EFFETTO DEI PERICOLI
        </H4>
        <P>
          Ogni giocatore che stava per perdere un turno a causa di un ostacolo d’acqua, vede venire
          rimosso questo stato.
        </P>
        <H3>Vincere la Partita</H3>
        <P>
          Alla fine del percorso, ogni giocatore conta il numero di segnalini bandierina e segnalini
          da 1 punto in suo possesso. Ogni bandierina e segnalinino vale 1 punto. A vincere sarà il
          giocatore con più punti.
        </P>
        <P>
          In caso di pareggio, conta i segnalini Hole-in-One. Il giocatore con il maggior numero di
          segnalini Hole-in-One vince il tie-break. Se i giocatori saranno ancora alla pari,
          condivideranno la vittoria.
        </P>
      </Section>
      <Section id="rough" title="GIOCARE DAL ROUGH">
        <P>
          Quando inizi il turno su una casella rough, il tuo tiro viaggia di una distanza ridotta.
        </P>
        <SideBySide right={wood5} size={250}>
          <P>
            Per le mazze che si spostano di una distanza fissa (legni), questo è rappresentato da un
            secondo esagono sulla tua mazza con il simbolo rough. Sotto: il legno 5 si sposta di 2
            esagoni quando giocato dal rough e di 3 quando giocato da un fairway.
          </P>
        </SideBySide>
        <SideBySide right={deck5right} size={200}>
          <P>
            Per le mazze che pescano dai mazzi tiro, c’è un simbolo rough sulla tua carta tiro (vedi
            a destra). Usa questo al posto dell’esagono arancio quando determini la destinazione
            finale del tuo golfista.
          </P>
        </SideBySide>
        <P>
          Qualunque casella priva di un fairway viene considerata rough. Un ostacolo d’acqua è
          ritenuto essere rough (dopo che hai saltato un turno). Un ostacolo d’acqua su un fairway
          (&nbsp;
          <A href="#hazards">OSTACOLI E ALTRE CASELLE</A>) è considerato fairway. La maggior parte
          di uno percorso golfistico è rough. Un consiglio generale è di starci alla larga.
        </P>
        <SideBySide RightComponent={HybridCardsItalian} size={200}>
          <P>
            <em>
              Tutte le mazze ibride (Ibrida 3, Ibrida 5 e Ibrida 8) non subiscono penalità quando si
              colpisce dal rough.
            </em>
          </P>
        </SideBySide>
        <SideBySide right={deckCStraight} size={200}>
          <P>
            <em>
              Tutte le carte tiro pescate dal mazzo chip non subiscono penalità quando si colpisce
              dal rough. Non ci sono simboli rough su queste carte (viaggiano sempre di 2 esagoni).
            </em>
          </P>
        </SideBySide>
        <Example>
          <P>Mackenzie (dischetto bianco) si trova nel rough.</P>
          <img src={roughExample1} width={300} />
          <P>Gioca un Ferro 2, e questo le permette di pescare dal mazzo tiro 4.</P>
          <div className="flex justify-center items-center w-full">
            <img src={deck4Left} width={StandardPortraitCardSize} />
          </div>
          <P>
            La carta tiro curva verso sinistra. Quando traccia il suo movimento, usa il simbolo
            rough sulla carta tiro per delinearlo.
          </P>
          <P>
            A causa della distanza ridotta, si sposta di due esagoni in avanti e poi di uno a
            sinistra per atterrare dove mostra il contrassegno rough sulla carta tiro. In questo
            esempio, è atterrata nuovamente sul fairway– una buona posizione per il tiro successivo.
          </P>
          <img src={roughExample2} width={300} />
        </Example>
      </Section>
      <Section id="chipping" title="Chipping">
        <SideBySide right={deckC} size={StandardPortraitCardSize}>
          <P>
            Il mazzo chip è diverso dagli altri mazzi tiro. Queste carte tiro danno la possibilità
            di passare sopra gli alberi, non subiscono la penalità rough e hanno una limitazione.
            Prima di pescare qualsiasi carta, dovrai dichiarare la direzione in cui vuoi fare chip.
          </P>
        </SideBySide>
        <H4>DICHIARARE UNA DIREZIONE</H4>
        <P>
          Questa regola comporta la scelta di una qualsiasi delle sei direzioni che lasciano
          l’esagono e la sua comunicazione agli altri giocatori. Puoi descriverla dicendo qualcosa
          del tipo “Colpisco verso l’acqua” oppure indicare con il dito. Non puoi cambiarle la
          direzione dopo aver pescato una carta tiro.
        </P>
        <H4>PESCARE CARTE TIRO</H4>
        <P>Una volta dichiarata la direzione, pescherai le tue carte come al solito.</P>
        <H4>SPOSTARE IL GOLFISTA</H4>
        <P>
          La differenza principale con la fase di spostamento del tuo golfista è che in questo caso
          avrai dichiarato in quale direzione colpisci la pallina. Sposta il tuo golfista come al
          solito con un’eccezione.
        </P>
        <P>
          Se l’esagono adiacente al tuo golfista è un albero, ci passi sopra con il chip e atterri
          sull’esagono di destinazione arancione. Non puoi comunque atterrare sugli alberi, se il
          tuo esagono obiettivo è un ostacolo alberato: atterrerai sull’esagono su cui saresti
          finito con il chip. Se anche questo è un ostacolo alberato, il tuo golfista non si muove.
        </P>
        <SideBySide right={lobWedge} size={StandardLandscapeCardSize}>
          <P>
            Il Lob Wedge è una mazza unica nel senso che non devi dichiarare la direzione della
            pallina prima di colpirla.
          </P>
        </SideBySide>
        <Example>
          <P>
            Brock si trova in una posizione strana con gli altri che bloccano il progresso verso il
            fairway. Gioca il suo fidato ferro 9 e punta verso la casella in cui intende fare chip
            (freccia). È una giocata sicura poiché la maggior parte delle carte del mazzo chip lo
            farà arrivare sul fairway.
          </P>
          <P>
            Poi estrae una seconda carta tiro dal mazzo chip per vedere che cosa ha in serbo per lui
            il fato. La cosa lo ripaga, e ottiene una carta tiro che vira a destra riportandolo sul
            fairway e in posizione eccellente per il prossimo tiro.
          </P>
          <div style={{ height: 350, overflow: "hidden" }}>
            <img src={chippingExample} width={350} />
            <img
              src={deckCRight}
              width={SmallPortraitCardSize}
              style={{ position: "relative", left: 0, top: -150 }}
            />
          </div>
        </Example>
      </Section>
      <Section id="super-short-wild" title="SUPER, SHORT, SHUFFLE E WILD CARD">
        <H3>SUPER E SHORT</H3>
        <P>
          Le carte super e short vanno messe sul tavolo per poi pescare una carta short sostitutiva
          e porla in cima alla carta super o short. Pesca una carta sostitutiva dal mazzo indicato
          sulla carta super o short.
        </P>
        <P>
          Queste carte formano una catena, quindi è possibile ritornare alla fine al mazzo da cui si
          è partiti oppure partire dal mazzo tiro 2 per arrivare al mazzo tiro 5.
        </P>
        <P>
          Un super hit dal mazzo chip che dà come risultato un’estrazione dal mazzo tiro diverso dal
          mazzo chip perde l’abilità di oltrepassare gli alberi.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={superHit} width={StandardPortraitCardSize} />
          <img src={shortHit} width={StandardPortraitCardSize} />
        </div>
        <H3>Shuffle</H3>
        <P>
          Quando si estrae una carta shuffle, tutti gli scarti attuali da questo mazzo tiro andranno
          messi nuovamente nel mazzo e si mescola. Posiziona questa carta sul tavolo e pesca una
          carta sostitutiva. Mettila in cima. Pesca sempre la carta sostitutiva dallo stesso mazzo.
        </P>
        <P>
          Le carte che hai pescato finora in questo turno, ad esempio le carte super o altre carte
          dalle mazze che ti permettono di pescare 2 carte, non vengono rimesse nel mazzo.
        </P>
        <P>
          Se peschi una carta shuffle e non c’è una pila di scarti, non devi mescolare il mazzo.
          Devi comunque pescare una carta sostitutiva.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={shuffle} width={StandardPortraitCardSize} />
        </div>
        <H3 style={{ breakBefore: "page" }}>Wild Card</H3>
        <P>
          Quando peschi una carta con risultati multipli su di essa, puoi scegliere quello che vuoi
          usare. Se una delle opzioni è il fuori percorso, allora devi scegliere l’unica opzione che
          rimane in gioco.
        </P>
        <P>
          Vedi <A href="#hazards">HAZARDS AND OTHER TILES</A> per maggiori informazioni sul fuori
          percorso.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={wildCard} width={StandardPortraitCardSize} />
        </div>
        <H3>CARTA LONGEST DRIVE</H3>
        <P>
          Quando peschi e giochi questa carta tiro, conserva la carta longest drive. Questa carta
          funge da promemoria per ricordare che hai colpito il drive più lungo della partita, e se
          non vinci oggi, almeno tornerai a casa con qualcosa. Non la carta, capisci bene, che
          rimarrà nella scatola. Avrai la soddisfazione di aver colpito il drive più lungo.
        </P>
        <P>
          Questa carta tiro viaggia per 6 esagoni in linea retta. Si ferma sugli alberi come fanno
          le altre carte tiro.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={longestDriveCard} width={StandardPortraitCardSize} />
        </div>
      </Section>
      <Section id="hazards" title="OSTACOLI E ALTRE CASELLE">
        <SideBySide title="Bunker" right={bunkerHex} size={SmallHex}>
          <P>
            L’ostacolo bunker si applica a tutti i turni in cui inizi in tale esagono. Quando sei
            nel bunker, devi uscirne. Vedi&nbsp;
            <A href="#4d">“4.D) USCIRE DAL BUNKER</A>.
          </P>
          <P>Puoi usare il Sand Wedge per trattare i bunker come rough.</P>
        </SideBySide>
        <SideBySide title="Green" right={greenHex} size={SmallHex}>
          <P>
            La tua destinazione. A seconda della modalità di gioco, i giocatori che raggiungono il
            green per primi vincono il round. Quando giochi dai green di un’altra buca, considera la
            casella fairway.
          </P>
        </SideBySide>
        <SideBySide title="Rough" right={roughHex} size={SmallHex}>
          <P>
            I tiri che partono da questo esagono viaggiano per un esagono in meno. Dopo aver
            tracciato il tuo tiro, guarda l’icona rough sulla carta tiro. Questo simbolo rappresenta
            in cui atterra la tua pallina. Vedi <A href="#rough">GIOCARE DAL ROUGH</A>.
          </P>
        </SideBySide>
        <div style={{ breakBefore: "page" }}>
          <SideBySide title="FUORI CAMPO &amp; SEDE DEL CLUB" right={clubhouse} size={SmallHex}>
            <P>
              Quando la tua pallina lascia il campo, è fuori campo. La pallina è fuori campo anche
              quando va fuori campo per parte del suo volo e ritorna poi su una casella. Quando sei
              fuori campo, il tuo golfista rimane nel punto in cui ha fatto partire il tiro.
              Riprende la mazza in mano; il suo turno è finito.
            </P>
            <P>
              Atterrare sulla sede del club è considerato un fuori campo a meno che, naturalmente,
              tu non stia giocando sulla 19ª.
            </P>
            <P>
              <em>
                Nota: i pezzi angolari a diamante delle caselle percorso sono parte del tracciato,
                ci puoi atterrare sopra.
              </em>
            </P>
          </SideBySide>
        </div>
        <div
          className="flex p-8 justify-center items-center"
          style={{ height: 300, overflow: "hidden" }}
        >
          <div className="pt-16">
            <img src={courseTile} width={CourseTileSize} />
            <div
              style={{
                position: "relative",
                top: -240,
                left: 35,
                borderRadius: 75,
                width: 75,
                height: 75,
                flex: 0,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H5>VARIAZIONE</H5>
        <P>
          Abbiamo ideato la regola del fuori campo perché la maggior parte dei giocatori non
          riusciva a navigare nello spazio fuori campo senza una griglia. Ma alcuni giocatori
          possono. Se tutti i giocatori concordano nell’uso di questa variante, allora puoi
          consentire ai giocatori di colpire con i fuori campo. Atterrare nei fuori campo è sempre
          un fuori campo.
        </P>
        <SideBySide title="Tee box" right={teeboxHex} size={SmallHex}>
          <P>
            Il tee box è il punto in cui inizia la buca. Queste caselle indicano il par e quante
            caselle percorso grandi aggiungere prima del green. Vedi l’ultima pagina del libretto
            percorsi per maggiori informazioni su come costruire percorsi.
          </P>
        </SideBySide>
        <SideBySide title="ALBERI" right={treeHex} size={SmallHex}>
          <P>
            Gli alberi interrompono il volo della tua pallina. Sposta la tua pallina di un esagono
            all’indietro lungo il percorso di volo. Se l’esagono successivo è un ostacolo, segui le
            regole per tale ostacolo.
          </P>
          <P>
            <em>
              Nota: solo gli alberi con uno steccato attorno interrompono il volo della pallina.
            </em>
          </P>
        </SideBySide>
        <SideBySide
          title="ACQUA (E FAIRWAY CON LAGHETTI)"
          RightComponent={WaterHazards}
          size={SmallHex}
        >
          <P>
            Gli ostacoli d’acqua si applicano quando la pallina termina il suo volo su tale esagono.
            Salti il turno seguente. Fai sdraiare il tuo golfista. Al prossimo turno, rimetti in
            piedi il golfista e salta il round. Il turno successivo, puoi giocare.
          </P>
          <P>
            Giochi il tuo prossimo tiro dalla casella con l’acqua. Gioca sulla casella con l’acqua
            come se fosse rough a meno che non si tratti di un fairway con un lago. In tal caso, è
            considerato fairway.
          </P>
          <P>The Shark ignora questo ostacolo e gioca la casella sottostante.</P>
        </SideBySide>
      </Section>
      <Section id="golfers" title="Abilità dei Golfisti">
        <P>
          Ci sono otto golfisti diversi in 18 Holes, e ognuno di loro porta un’esperienza
          differente. Ciascun golfista ha un lato A e un lato B. Il lato A rende il gioco più
          semplice, mentre il lato B rende il gioco più difficile. C’è una A o una B nell’angolo in
          basso a destra.
        </P>
        <H3>BUNJIL</H3>
        <SideBySide right={golfer2a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Immediatamente prima che Bunjil scelga la mazza da giocare, può guardare la prima carta
            di un mazzo una volta. Gira Bunjil di lato (paesaggio) per indicare che il suo potere è
            stato usato. Il giocatore non deve mostrare la carta agli altri giocatori. Rimetti la
            carta a faccia in giù in cima al mazzo tiro.
          </P>
          <P>Una volta terminata la buca, rimetti Bunjil con un orientamento verticale.</P>
          <P>
            Bunjil può guardare solo le carte nei mazzi tiro da cui potrebbe legittimamente pescare.
          </P>
        </SideBySide>
        <SideBySide right={golfer2b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Come per il lato A inoltre Bunjil deve decidere se tenere la carta tiro e usarla sul
            prossimo tiro oppure rimettere la carta in cima al mazzo tiro, a faccia in su in modo da
            farla vedere agli altri giocatori.
          </P>
          <P>
            Se Bunjil tiene la carta, allora il giocatore deve giocare una mazza che possa accedere
            a quel mazzo tiro.
          </P>
        </SideBySide>
        <H3>Dorian</H3>
        <SideBySide right={golfer8a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Dopo la selezione delle carte, prima della prima buca, il giocatore con Dorian comunica
            al resto del gruppo quale delle sue mazze non scadrà mai. Ogni volta che si usa questa
            mazza, rimettila in mano invece di scartarla.
          </P>
          <P>
            Dorian non può azzerare le mazze durante una buca ma può azzerare le mazze solo alla
            fine di una buca se la sua mano contiene solo la mazza che non scade mai (e vengono
            soddisfatte le regole per azzerare la mano).
          </P>
        </SideBySide>
        <SideBySide right={golfer8b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Dorian non scarta mai le mazze dopo l’uso. Dorian può prendere solo tre mazze durante la
            selezione.
          </P>
        </SideBySide>
        <H3>Iron Jane</H3>
        <SideBySide right={golfer3a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Prima della selezione mazze, il giocatore che ha Iron Jane seleziona le mazze fra quelle
            disponibili. Mescola le carte rimanenti e le distribuisce agli altri giocatori.
          </P>
        </SideBySide>
        <SideBySide right={golfer3b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Come per il lato A inoltre Iron Jane può trasportare solo quattro mazze. Iron Jane non
            può avere due mazze identiche.
          </P>
        </SideBySide>
        <H3>Lucky</H3>
        <SideBySide right={golfer6a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Durante il gioco, una volta per mano, Lucky può pescare nuovamente una carta tiro. Per
            farlo, il giocatore con Lucky lo comunica al gruppo di gioco e poi gira di lato Lucky
            (orizzontale) a indicare che ha usato il suo potere. Il giocatore poi pesca una nuova
            carta tiro dallo stesso mazzo di prima. Lucky deve giocare la nuova carta.
          </P>
          <P>Mentre Lucky è orientata in modalità orizzontale non puoi usare questo potere.</P>
          <P>Quando la mano viene azzerata, bisogna riportare Lucky sull’orientamento verticale.</P>
        </SideBySide>
        <SideBySide right={golfer6b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Come per il lato A inoltre quando usa l’abilità di Lucky, il giocatore scarta una delle
            altre mazze. Se il giocatore non ha altre mazze, non può usare il potere di Lucky.
          </P>
          <P>Puoi eseguire il potere di Lucky solo una volta per buca.</P>
        </SideBySide>
        <H3>Slick Rikesh</H3>
        <SideBySide right={golfer4a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Dopo che tutti i giocatori avranno rivelato le proprie carte mazza, Slick Rikesh può
            usare la mazza scelta da un altro giocatore. L’iniziativa della mazza di Slick Rikesh è
            la stessa della mazza del possessore originale e gioca immediatamente dopo. Slick Rikesh
            può usare il suo potere solo una volta per buca.
          </P>
          <P>
            Gira Slick Rikesh lateralmente (orizzontale) per indicare che il potere è stato usato.
            Riporta in modalità verticale dopo ogni buca.
          </P>
        </SideBySide>
        <SideBySide right={golfer4b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Il giocatore con Slick Rikesh sceglie solo una carta durante la selezione e può prendere
            quella mazza da qualsiasi round della selezione.
          </P>
          <P>
            Durante il gioco, Slick Rikesh giocherà quella mazza come al solito (per determinare
            l’iniziativa) e può quindi selezionare qualsiasi mazza scartata di un altro giocatore da
            usare. Se non ci sono mazze scartate, Slick Rikesh deve usare quella mazza.
          </P>
        </SideBySide>
        <H3>Slugger</H3>
        <SideBySide right={golfer5a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Slugger deve portare con sé il driver. Rimuovi questa carta dal mazzo prima della
            selezione mazze e dalla al giocatore che ha Slugger. Slugger sceglierà quattro mazze
            durante la selezione, saltando l’ultimo turno.
          </P>
          <P>Durante il gioco, non scarti mai il driver. Rimettilo in mano.</P>
        </SideBySide>
        <SideBySide right={golfer5b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Come per il lato A inoltre Slugger deve usare un Legno o il Driver su tutti i tee box.
            Sì, inclusi i par 3.
          </P>
        </SideBySide>
        <H3>SWIFT</H3>
        <SideBySide right={golfer7a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Durante il passaggio per determinare l’ordine di gioco, sottrai uno dall’iniziativa
            della mazza giocata da Swift. Se l’iniziativa di Swift pareggia con un altro giocatore,
            Swift va per primo.
          </P>
        </SideBySide>
        <SideBySide right={golfer7b} size={StandardPortraitCardSize}>
          <H4>Lato B</H4>
          <P>
            Durante il passaggio per determinare l’ordine di gioco, sottrai uno dall’iniziativa
            della mazza giocata da Swift. Se l’iniziativa di Swift pareggia con un altro giocatore,
            Swift va dopo quel giocatore.
          </P>
        </SideBySide>
        <H3>The Shark</H3>
        <SideBySide right={golfer1a} size={StandardPortraitCardSize}>
          <H4>Lato A</H4>
          <P>
            Gli ostacoli d’acqua non sono un problema per The Shark, inclusi quelli sui fairway.
            Trattali come la casella sottostante.
          </P>
        </SideBySide>
        <H4>Lato B</H4>
        <SideBySide right={golfer1b} size={StandardPortraitCardSize}>
          <P>Come per il lato A inoltre The Shark non può trasportare wedge.</P>
          <P>I Wedge includono Pitching Wedge, Sand Wedge e Lob Wedge.</P>
          <P>
            Durante la fase di selezione mazze, il giocatore che ha The Shark deve passare se le
            uniche mazze disponibili sono Wedge. The Shark può scegliere fra le mazze rimaste dopo
            la selezione.
          </P>
        </SideBySide>
      </Section>
      <Section id="game-modes" title="Diversi Modi Per Giocare">
        <P>
          18 Holes, like golf, has a variety of different game modes. The primary method described
          in this book keeps all players competing for each hole. Golf is a tough game where it can
          be hard to catch up if you fall behind. If you like that kind of game or want a more
          realistic golf experience, then you may be interested in Golf Racing, Stroke Play or
          Stableford.
        </P>
        <P>
          If your group prefers games that keep everyone competing for each point, then try Match
          Play and Skins.
        </P>
        <P>
          Some variations stray a bit further from the fairway. These are for people who want a less
          traditional golf experience. The Chaos Golf variations and Kelly Golf fit this
          description.
        </P>
        <P>If you want a solo experience, then try Solo Chaos Golf, Stableford or Stroke Play.</P>
        <P>
          If you want a two-player game, then try Chaos Teams: our dedicated two-player experience.
          Or, one of Match Play, Skins, Stroke Play, Stableford or Golf Racing.
        </P>
        <P>
          Each game mode comes with a section that describes the game mode, how many players it
          supports and when you might be interested in this game. To the right we have the section
          for Match Play, the rule set described up to this point.
        </P>
        <P>Index of Variations:</P>
        <ul className="list-disc list-inside">
          <li className={link}>
            <Link to={ModesChaosGolf}>Chaos Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosTeams}>Chaos Teams</Link>
          </li>
          <li className={link}>
            <Link to={ModesGolfRacing}>Golf Racing</Link>
          </li>
          <li className={link}>
            <Link to={ModesKellyGolf}>Kelly Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesMatchPlay}>Match Play</Link>
          </li>
          <li className={link}>
            <Link to={ModesSkins}>Skins</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
          </li>
          <li className={link}>
            <Link to={ModesStableford}>Stableford</Link>
          </li>
          <li className={link}>
            <Link to={ModesStrokePlay}>Stroke Play</Link>
          </li>
        </ul>
      </Section>
    </div>
  );
};
