import React from "react";
import roughIcon from "./images/rough-icon.png";
import waterHex from "./images/water-hex.png";
import fairwayLakeHex from "./images/fairway-lake-hex.png";
import hybrid3fr from "./images/fr/3-hybrid.png";
import hybrid5fr from "./images/fr/5-hybrid.png";
import hybrid8fr from "./images/fr/8-hybrid.png";
import hybrid3en from "./images/en/3-hybrid.png";
import hybrid5en from "./images/en/5-hybrid.png";
import hybrid8en from "./images/en/8-hybrid.png";
import hybrid3it from "./images/it/3-hybrid.png";
import hybrid5it from "./images/it/5-hybrid.png";
import hybrid8it from "./images/it/8-hybrid.png";
import hybrid3es from "./images/es/3-hybrid.png";
import hybrid5es from "./images/es/5-hybrid.png";
import hybrid8es from "./images/es/8-hybrid.png";
import hybrid3de from "./images/de/3-hybrid.png";
import hybrid5de from "./images/de/5-hybrid.png";
import hybrid8de from "./images/de/8-hybrid.png";
import { P, H2, H3 } from "../brand/typography";

const FirstGamePanelColor = "#b7e292";

export const StandardLandscapeCardSize = 200;
export const StandardPortraitCardSize = 150;
export const SmallPortraitCardSize = 100;

export const SmallHex = 132;

export const CourseTileSize = 250;

const YourFirstGame = {
  en: "YOUR FIRST GAME",
  fr: "VOTRE PREMIER JEU",
  it: "LA TUA PRIMA PARTITA",
  es: "TU PRIMER JUEGO",
  de: "DEIN ERSTES SPIEL",
};

export const FirstGame = ({ lang = "fr", children }) => (
  <div
    className="rounded p-4 w-full flex flex-row w-full md:w-4/5 items-center shadow-md mt-4 mb-8"
    style={{ backgroundColor: FirstGamePanelColor, breakInside: "avoid" }}
  >
    <div className="flex flex-col justify-center items-center pr-4">
      <p className="text-center font-bold">{YourFirstGame[lang]}</p>
      <img src={roughIcon} width={50} style={{ breakInside: "avoid" }} />
    </div>
    <div className="justify-center items-center w-4/5">
      <P>{children}</P>
    </div>
  </div>
);

export const GreenPanel = ({ children }) => (
  <div
    className="bg-green-500 rounded p-4 w-full shadow-md text-white print:text-gray-500"
    style={{
      boxDecorationBreak: "clone",
    }}
  >
    {children}
  </div>
);
export const Example = ({ children }) => (
  <div
    className="bg-purple-500 rounded p-4 shadow-md text-white print:text-gray-500"
    style={{
      breakInside: "avoid",
      boxDecorationBreak: "clone",
    }}
  >
    {children}
  </div>
);

export const BoxPart = ({ src, text, size }) => (
  <div className="flex flex-col p-4 items-center justify-center print:w-1/4">
    <img src={src} width={size} height={size}></img>
    <p className="text-center">{text}</p>
  </div>
);

export const Section = ({ id, children, title }) => (
  <div
    id={id}
    className="bg-gradient-to-b from-blue-100 to-white rounded p-4"
    style={{
      breakBefore: "page",
      breakInside: "avoid",
      breakAfter: "always",
    }}
  >
    <H2>
      <a href={`#${id}`}>{title}</a>
    </H2>
    {children}
  </div>
);

export const SideBySideSection = ({
  id,
  children,
  title,
  right,
  size,
  height = "auto",
}) => (
  <div
    id={id}
    className="bg-gradient-to-b from-blue-100 to-white rounded p-4"
    style={{ breakInside: "avoid" }}
  >
    <H2>
      <a href={`#${id}`}>{title}</a>
    </H2>
    <div className="flex flex-col-reverse md:flex-row print:flex-row">
      <div className="flex flex-col w-full md:w-3/5 print:w-3/5">
        {children}
      </div>
      <div className="justify-center items-center md:justify-end md:items-start print:justify-end print:items-start flex md:w-2/5 print:w-2/5">
        <img
          src={right}
          className="p-4"
          width={size}
          height={height}
          style={{ width: size, height: height }}
        />
      </div>
    </div>
  </div>
);

export const SideBySide = ({
  children,
  title = null,
  size,
  height = "auto",
  left = null,
  right = null,
  RightComponent = null,
  rightComponentAddStyles = "",
}) => (
  <div className="flex flex-col pt-2" style={{ breakInside: "avoid" }}>
    {title && <H3>{title}</H3>}
    <div className="flex flex-col-reverse md:flex-row print:flex-row">
      {left && (
        <div className="md:justify-end md:items-start print:justify-end print:items-start justify-center items-center flex w-full flex-0">
          <img
            src={left}
            className="p-4"
            style={{ width: size, height: height }}
          />
        </div>
      )}
      <div className="flex flex-col flex-2">{children}</div>
      {right && (
        <div className="md:justify-end md:items-start print:justify-end print:items-start justify-center items-center flex w-full flex-0">
          <img
            src={right}
            className="p-4"
            style={{ width: size, height: height }}
          />
        </div>
      )}
      {RightComponent && (
        <div className="md:justify-end md:items-start justify-center items-center flex w-full flex-0">
          <RightComponent className={rightComponentAddStyles} />
        </div>
      )}
    </div>
  </div>
);

export const AlwaysSideBySide = ({
  children,
  title = null,
  size,
  height = "auto",
  left = null,
  right = null,
}) => (
  <div className="flex flex-col pt-2" style={{ breakInside: "avoid" }}>
    {title && <H3>{title}</H3>}
    <div className="flex flex-row">
      {left && (
        <div className="justify-end items-start flex w-full flex-0">
          <img
            src={left}
            className="p-4"
            style={{ width: size, height: height }}
          />
        </div>
      )}
      <div className="flex flex-col flex-2">{children}</div>
      {right && (
        <div className="justify-end items-start flex w-full flex-0">
          <img
            src={right}
            className="p-4"
            style={{ width: size, height: height }}
          />
        </div>
      )}
    </div>
  </div>
);

export const HybridCardsFrench = () => (
  <div className="flex flex-col md:flex-row justify-evenly items-center">
    <img
      src={hybrid3fr}
      width={StandardLandscapeCardSize}
      alt="Hybrid 3 club card"
    />
    <img
      src={hybrid5fr}
      width={StandardLandscapeCardSize}
      alt="Hybrid 5 club card"
    />
    <img
      src={hybrid8fr}
      width={StandardLandscapeCardSize}
      alt="Hybrid 8 club card"
    />
  </div>
);

export const HybridCardsEnglish = () => (
  <div className="flex flex-col md:flex-row justify-evenly items-center">
    <img
      src={hybrid3en}
      width={StandardLandscapeCardSize}
      alt="Hybrid 3 club card"
    />
    <img
      src={hybrid5en}
      width={StandardLandscapeCardSize}
      alt="Hybrid 5 club card"
    />
    <img
      src={hybrid8en}
      width={StandardLandscapeCardSize}
      alt="Hybrid 8 club card"
    />
  </div>
);

export const HybridCardsItalian = ({ className }) => (
  <div
    className={`flex flex-col md:flex-row ${className} justify-evenly items-center`}
  >
    <img
      src={hybrid3it}
      width={StandardLandscapeCardSize}
      alt="Hybrid 3 club card"
    />
    <img
      src={hybrid5it}
      width={StandardLandscapeCardSize}
      alt="Hybrid 5 club card"
    />
    <img
      src={hybrid8it}
      width={StandardLandscapeCardSize}
      alt="Hybrid 8 club card"
    />
  </div>
);

export const HybridCardsSpanish = () => (
  <div className="flex flex-col md:flex-row justify-evenly items-center">
    <img
      src={hybrid3es}
      width={StandardLandscapeCardSize}
      alt="Hybrid 3 club card"
    />
    <img
      src={hybrid5es}
      width={StandardLandscapeCardSize}
      alt="Hybrid 5 club card"
    />
    <img
      src={hybrid8es}
      width={StandardLandscapeCardSize}
      alt="Hybrid 8 club card"
    />
  </div>
);

export const HybridCardsGerman = () => (
  <div className="flex flex-col md:flex-row justify-evenly items-center">
    <img
      src={hybrid3de}
      width={StandardLandscapeCardSize}
      alt="Hybrid 3 club card"
    />
    <img
      src={hybrid5de}
      width={StandardLandscapeCardSize}
      alt="Hybrid 5 club card"
    />
    <img
      src={hybrid8de}
      width={StandardLandscapeCardSize}
      alt="Hybrid 8 club card"
    />
  </div>
);

export const WaterHazards = () => (
  <div className="flex flex-row justify-evenly items-center">
    <img src={waterHex} width={SmallHex} />
    <img src={fairwayLakeHex} width={SmallHex} />
  </div>
);
