import React from "react";
import token5 from "../images/5-points.png";
import smallGreen from "../images/small-green-1.png";

export const ChaosGolfIcon = () => (
  <div style={{ width: 100, height: 100 }}>
    <img src={token5} width={100} />
    <img
      src={smallGreen}
      width={75}
      style={{ position: "relative", top: -86, left: 13 }}
    />
  </div>
);
