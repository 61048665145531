import numeral from "numeral";

export const coastlinesTiles = Array(14)
  .fill(0)
  .map((_, i) => i + 79)
  .map((id) => [`t${numeral(id).format("00")}a`, `t${numeral(id).format("00")}b`])
  .reduce((all, faces) => all.concat(faces), []);
export const coastlinesExclusiveTiles = Array(3)
  .fill(0)
  .map((_, i) => i + 93)
  .map((id) => [`t${numeral(id).format("00")}a`, `t${numeral(id).format("00")}b`])
  .reduce((all, faces) => all.concat(faces), []);
