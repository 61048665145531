import React from "react";
import { SiteNavigation } from "../../navigation/SiteNavigation";
import Footer from "../../navigation/Footer";
import { H1, H3, H4, P } from "../../brand/typography";
import { withLanguage } from "../../user/UserConfigurationProvider";
import { BackToModesLink } from "./BackToModesLink";
import { Example } from "../RuleBookParts";
import croquetGolf1 from "../images/croquet-golf-step-1.png";
import croquetGolf2 from "../images/croquet-golf-step-2.png";
import deck3Left from "../images/deck-3-left.png";
import { NoTranslationAvailable } from "./NoTranslationAvailable";

const EnglishModeCroquetGolf = () => {
  return (
    <>
      <BackToModesLink />
      <H1>Croquet Golf</H1>
      <P>
        This mode can applied to most other rule sets but is the more fun in
        modes like Chaos Golf where everyone isn't competing for the same hole.
      </P>
      <H3>Rule Variations</H3>
      Use the rules for other of the other game modes except when in conflict
      with the rules below.
      <H4>Game Play</H4>
      <P>
        In Croquet Golf, if you land in the same hex as another golfer (or
        golfers) you <em>must</em> move the other golfers one hex. The direction
        you move the other golfer is a continuation of the direction you entered
        the hex from.
      </P>
      <P>
        If the moved golfer ends up in a hex with other golfers no additional
        golfers are moved i.e. it does not chain.
      </P>
      <P>
        If the moved golfer lands on the green then follows the normal green
        scoring rules for that mode e.g. Match Play, the player is considered to
        have scored the green this round. In Chaos Golf modes, they claim the
        points immediately.
      </P>
      <P>You cannot knock someone off the green.</P>
      <P>If the moved golfer would be pushed into a tree, they do not move.</P>
      <P>
        In variations with Fast Travel: if the moved golfer was on a tee box and
        gets pushed off the tee box onto a green (by the current golfer landing
        on the tee box). Then the green is scored before any additional turns
        are played. And, if through this rule, you move a golfer onto a tee box,
        then that player may take an extra turn at the end of the round as
        though they had ended their turn on the tee box. Accordingly, if any
        golfer is knocked off a tee box they no longer qualify for an extra
        turn.
      </P>
      <Example>
        <P>
          In the example below our player Zoey (white) has pulled a card that
          goes 3 and to the left and would land in the same hex as Toby (red).
          Under Croquet Golf rules if she chose to make this move, she would
          push any other players out of the hex she lands in. In this case Toby
          will be moved one hex closer to the green.
        </P>
        <div className="flex flex-row flex-wrap items-center">
          <img src={croquetGolf1} style={{ maxHeight: 300, height: 300 }} />
          <div>
            <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
          </div>
        </div>
        <P>
          In the second course image we can see where the players have ended up.
        </P>
        <div className="flex flex-row flex-wrap items-center">
          <img src={croquetGolf2} style={{ maxHeight: 300, height: 300 }} />
        </div>
      </Example>
    </>
  );
};

const FrenchModeCroquetGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeCroquetGolf />
    </>
  );
};
const SpanishModeCroquetGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeCroquetGolf />
    </>
  );
};

const GermanModeCroquetGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeCroquetGolf />
    </>
  );
};

const ItalianModeCroquetGolf = () => {
  return (
    <>
      <NoTranslationAvailable />
      <EnglishModeCroquetGolf />
    </>
  );
};

const ModeCroquetGolf = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-12 md:w-5/5">
          {language === "en" && <EnglishModeCroquetGolf />}
          {language === "fr" && <FrenchModeCroquetGolf />}
          {language === "it" && <ItalianModeCroquetGolf />}
          {language === "es" && <SpanishModeCroquetGolf />}
          {language === "de" && <GermanModeCroquetGolf />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModeCroquetGolf);
