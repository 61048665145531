import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";

const Heading = ({ children }) => (
  <h3 className="font-bold text-lg pt-8 w-full text-center">{children}</h3>
);

const LineItem = ({ children }) => (
  <p className="flex-1 text-right pl-2 pr-2">{children}</p>
);
const Credit = ({ children }) => (
  <p className="flex-1 text-left font-bold pl-2 pr-2">{children}</p>
);
const GameCreditsItems = [
  ["Game Design", "Ryan Boucher"],
  ["Artwork", "Josh Sacco"],
  ["Graphic Design", "Josh Sacco"],
  ["Additional Art & Graphic Design", "Ryan Boucher"],
  ["Writing", "Ryan Boucher"],
  ["Editing", "Ryan Boucher, Spencer Williams"],
  ["Models", "Aaron Clarke, Jess Kirwan, Josh Sacco and Pam Rucinque."],
  ["Voice Over", "Stefanie Kechayas"],
];
const CourseDesigners = [
  ["", "Louis Coduti"],
  ["", "quantumpotato"],
  ["", "André Valentin"],
  ["", "Ash Rollke"],
  ["", "Tim Smith"],
  ["", "The Cooke Family: Ashley, Ryan, Brezlun, and Teegan Cooke."],
];

const FrenchTranslationItems = [
  ["Translation", "Sébastien Bonastre"],
  ["Additional Translation", "Philippe Michel"],
];
const GermanTranslationItems = [
  ["Translation", "Robert Hajdu"],
  ["Additional Translation", "Marcel Stoecker"],
  ["Proofreading", "Pat 1201 on Fiverr"],
  ["Additional Proofreading", "Marcel Stoecker"],
];
const ItalianTranslationItems = [
  ["Translation", "Andrea Santucci"],
  ["Additional Translation", "Stefano Vecchi"],
  ["Proofreading", "Matteo Donati"],
];
const SpanishTranslationItems = [
  ["Translation", "Rocío Martínez Soria"],
  ["Proofreading", "julianabm92 on Fiverr"],
];

const Playtesters = [
  "Alister",
  "Andrew Hamilton",
  "Andy Savidge",
  "Andy Shaw",
  "Ben Finney",
  "Brandon Franceschini",
  "Carol Kirwan",
  "Chris Harris",
  "Dan Sansom-Gower",
  "Doug Kirwan",
  "Genevieve Rix",
  "James Versace",
  "Jaris Rener",
  "Jason Wright",
  "Jess Kirwan",
  "Jonathan Fisher",
  "Laura",
  "Liauw Fendy",
  "Light",
  "Morgan Sheridan",
  "Nik Pantis",
  "Ollie Versteegen",
  "Paul Grogan",
  "Paul",
  "Sarah Cooke",
  "Sarah Evans-Malone",
  "Sarah Pavlich",
  "Stella Jahja",
  "Steven",
  "Tim Smith",
  "Trent",
  "and those that left feedback and not their names",
];

const AdditionalThanks = [
  "David Maygar",
  "Khosit Ddy",
  "Paul Grogan",
  "Spencer Williams",
  "Andy Savidge",
  "William Habush",
  "Pam Malone",
  "Daniel Chamberlain",
  "Matthew Seeds",
  "Barry Miller",
];

const SpecialThanks = [
  "Jess Kirwan",
  "Toby Kirwan-Boucher",
  "James Versace",
  "Jonathan Fisher",
  "Andy Shaw",
  "Tim Smith",
];

const GamelandThanks = [
  ["U.S. Director of Sales", "Dave Snyder"],
  ["Product Sales Management", "Vicky Wong"],
];

const FunagainThanks = [
  ["General Manager", "Rick Scovill"],
  ["Freight Manager", "Katie Hooper"],
  ["GM Fulfilment", "Verla LeBaron"],
  ["Customer Service", "Karen Wolterman"],
];

const HitPointThanks = [["Director of Sales", "James Takenaka"]];
const SkylineThanks = [["Accountant", "Adam Grigsby"]];

const WebsiteCredits = [
  ["Website", "Ryan Boucher"],
  ["Course Designer", "Ryan Boucher"],
  ["Caddy", "Ryan Boucher"],
];

const CreditBlock = ({ title, items }) => (
  <>
    <Heading>{title}</Heading>
    <div className="flex flex-col w-full space-around items-center">
      {items.map(([role, name]) => (
        <div className="flex flex-row flex-1 w-full">
          <LineItem>{role || "\u00A0"}</LineItem>
          <Credit>{name || "\u00A0"}</Credit>
        </div>
      ))}
    </div>
  </>
);

const CreditBlockProminantList = ({ title, items }) => (
  <>
    <Heading>{title}</Heading>
    <div className="flex flex-col w-full space-around items-center">
      {items.map((name) => (
        <p className="flex-1 text-center pl-2 pr-2">{name}</p>
      ))}
    </div>
  </>
);

const CreditBlockList = ({ title, items }) => (
  <>
    <Heading>{title}</Heading>
    <div className="flex flex-col w-full space-around items-center">
      <p className="flex-1 text-center pl-2 pr-2">{items.join(", ")}</p>
    </div>
  </>
);

const Credits = () => {
  return (
    <div className="flex flex-col justify-bettern">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-8 md:w-4/5">
          <h1 className="font-bold text-2xl pt-16 w-full text-center">
            Credits
          </h1>
          <CreditBlock title="Game Credits" items={GameCreditsItems} />
          <CreditBlockProminantList
            title="Course Designers"
            items={CourseDesigners}
          />
          <CreditBlockList title="Playtesters" items={Playtesters} />
          <CreditBlock
            title="French Translation"
            items={FrenchTranslationItems}
          />
          <CreditBlock
            title="German Translation"
            items={GermanTranslationItems}
          />
          <CreditBlock
            title="Italian Translation"
            items={ItalianTranslationItems}
          />
          <CreditBlock
            title="Spanish Translation"
            items={SpanishTranslationItems}
          />
          <CreditBlock title="Online" items={WebsiteCredits} />
          <CreditBlockProminantList
            title="Special Thanks"
            items={SpecialThanks}
          />
          <CreditBlockProminantList
            title="Additional Special Thanks"
            items={AdditionalThanks}
          />
          <h1 className="font-bold text-2xl pt-16 w-full text-center">
            Seabrook Studios Thanks
          </h1>
          <CreditBlock title="Gameland" items={GamelandThanks} />
          <CreditBlock title="Funagain Games" items={FunagainThanks} />
          <CreditBlock title="Hit Point Sales" items={HitPointThanks} />
          <CreditBlock title="Skyline Accountants" items={SkylineThanks} />
          {/* <CreditBlock title="GameMarketing Genie" items={HitPointThanks} />
          <CreditBlock title="GameMarketing Genie" items={HitPointThanks} /> */}
          <h1 className="font-bold text-2xl pt-16 w-full text-center">
            Attributions
          </h1>
          <ul className="w-full">
            <li className="text-center">
              eraser by shashank singh from the Noun Project
            </li>
            <li className="text-center">
              grid by Guilherme Simoes from the Noun Project
            </li>
            <li className="text-center">
              Move by Gregor Cresnar from the Noun Project
            </li>
            <li className="text-center">
              Ruler by My Toley from the Noun Project
            </li>
            <li className="text-center">
              cloud success by Melvin Salas from the Noun Project
            </li>
            <li className="text-center">
              Delete by Alice Design from the Noun Project
            </li>
            <li className="text-center">
              stack by Brad Avison from the Noun Project
            </li>
            <li className="text-center">
              Diamond by Tara from the Noun Project
            </li>
            <li className="text-center">
              trees by Vicons Design from the Noun Project
            </li>
            <li className="text-center">
              sand by Adrien Coquet from the Noun Project
            </li>
            <li className="text-center">
              Water by Marli du Plessis from the Noun Project
            </li>
            <li className="text-center">
              path by Adrien Coquet from the Noun Project
            </li>
            <li className="text-center">
              Golf by Vectors Market from the Noun Project
            </li>
            <li className="text-center">
              Flag by Adrien Coquet from the Noun Project
            </li>
            <li className="text-center">
              Download by Cuputo from the Noun Project
            </li>
            <li className="text-center">
              back by Adrien Coquet from the Noun Project
            </li>
            <li className="text-center">
              Crown by Pedro from the Noun Project
            </li>
          </ul>
          <em className="pt-8 text-center">
            Seabrook Studios acknowledges the Traditional Owners of the land on
            which we work. We pay our respects to their Elders, past and
            present, and the Aboriginal Elders of other communities visiting
            these lands. Sovereignty has never been ceded. It always was and
            always will be, Aboriginal land.
          </em>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Credits;
