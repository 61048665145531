import * as all from "./tiles.js";
import tileImageData from "./tiles.json";

const rough = all.rough;

const BrushShapes = {
  rough,
  ...tileImageData,
  hazards: all.adjustmentTileImageData,
};

/**
 * @param {SupportedTile} brush
 */
export const getRotatedBrush = (brush) => {
  if (brush === null) {
    return null;
  }
  if (brush === "rough") {
    return BrushShapes.rough;
  }

  return BrushShapes[brush];
};

export default BrushShapes;
