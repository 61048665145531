import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";

const faq = [
  {
    q: "Is the game translated?",
    a: "Yes. Into French, German, Italian and Spanish. From the rules page you can select which language you want to view the rules in.",
    link: "https://18holesgame.com/rules",
  },
  {
    q: "Do I need to play a club every turn?",
    a: "Yes. Regardless of the action you decide to take, you need to play a club. The club you pick will be used to determine the play order.",
    link: "https://18holesgame.com/rules#2",
  },
  {
    q: "During the draft, how many cards does each player get?",
    a: "Each player gets an even number of cards and the remainder are discarded. For 2 players this 9 cards each. 3 players is 8 cards. 4 and 5 player games receive 6 cards each.",
    link: "https://18holesgame.com/rules#setup",
  },
  {
    q: "What happens when I am in a bunker and none of my clubs allow me to exit the bunker? e.g. I only have woods or a driver?",
    a: "As you have no valid clubs to play with, you are able to reset your hand at the start of the round. You then need to pick a club that can be used to exit the bunker.",
    link: "https://18holesgame.com/rules#1",
  },
  {
    q: "What if my hand contains only woods and drivers and I land in a bunker. How do I get out?",
    a: "Don't choose not to land in the bunker. You can hit the ball in any direction and all of the other five directions is unlikely to also contain bunkers. If you've designed such a contrived situation or, you've not realised until your are in the bunker, you can use the out-of-bounds rule to return to where you hit the ball for your next turn. I strongly recommend taking at least one club that can get you out of a bunker when on the course.",
    link: "https://18holesgame.com/rules#4d",
  },
  {
    q: "Are the bunker outcomes the same across the decks?",
    a: "No they all have different probabilities with the chip deck having the best chance. The 2-deck is the least likely.",
  },
  {
    q: "Do I use a club when I take the Move One Hex option?",
    a: "Yes. Step 2 has every player select and reveal a club. The club determines initiative order. On your turn you choose an action (Move one hex, play club card, attempt a big hit, exit bunker or reset your hand). After your action you club is discarded.",
    link: "https://18holesgame.com/rules#playing-a-round",
  },
  {
    q: "If I'm playing Slugger can I use the driver to move one hex?",
    a: "Yes, but only from the teebox as the driver is only allowed to be used on the teebox.",
    link: "https://18holesgame.com/rules#golfers",
  },
  {
    q: "Do we get all of our clubs back on each new tee?",
    a: "No. Clubs are only returned when you have an empty hand or a hand that contains no valid cards.",
  },
  {
    q: "Is there a penalty for hitting over hazards?",
    a: "Aside from trees, no. The water, bunker and rough hazards only apply when you land in them. Trees are an exception as regular shots are not allowed to go over trees. Your ball stops the hex before the tree. When chipping some cards go over trees.",
    link: "https://18holesgame.com/rules#hazards",
  },
  {
    q: "Does hitting in any direction with a wood mean in a straight line or can I zig-zag?",
    a: "Woods can only hit in a straight line.",
    link: "https://18holesgame.com/rules#4b",
  },
];

const More = () => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white justify-center">
        <div className="flex flex-col justify-start align-center items-start p-8 md:w-4/5">
          <h2 className="font-bold text-xl pt-4">Frequently Asked Questions</h2>
          {faq.map(({ q, a, link }) => (
            <>
              <h3 className="font-bold text-lg pt-8">{q}</h3>
              <p>
                {a}&nbsp;
                {link && (
                  <a className="text-brand-green hover:text-brand-yellow" href={link}>
                    See more.
                  </a>
                )}
              </p>
            </>
          ))}
          <p className="pt-16">
            Have a question of your own? You can ask a question on the{" "}
            <a
              className="text-brand-green hover:text-brand-yellow"
              href="https://www.boardgamegeek.com/boardgame/274234/18-holes/forums/0"
            >
              BoardGameGeek forum.
            </a>{" "}
            or you can email us:{" "}
            <a className="text-brand-green hover:text-brand-yellow" href="mailto:hello@seabrook-studios.com">
              hello [at] seabrook-studios.com
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default More;
