import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";
import { RuleBookFrench } from "./RuleBookFrench";
import { RuleBookEnglish } from "./RuleBookEnglish";
import { RuleBookItalian } from "./RuleBookItalian";
import { RuleBookSpanish } from "./RuleBookSpanish";
import { RuleBookGerman } from "./RuleBookGerman";
import { withLanguage } from "../user/UserConfigurationProvider";
import { SetLanguage } from "./SetLanguage";

const RuleBookBase = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <SetLanguage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        {language === "en" && <RuleBookEnglish />}
        {language === "fr" && <RuleBookFrench />}
        {language === "it" && <RuleBookItalian />}
        {language === "es" && <RuleBookSpanish />}
        {language === "de" && <RuleBookGerman />}
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(RuleBookBase);
