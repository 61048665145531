import React from "react";
import { SiteNavigation } from "../navigation/SiteNavigation";
import Footer from "../navigation/Footer";
import { FrenchModes } from "./FrenchModes";
import { EnglishModes } from "./EnglishModes";
import { ItalianModes } from "./ItalianModes";
import { SpanishModes } from "./SpanishModes";
import { GermanModes } from "./GermanModes";
import { withLanguage } from "../user/UserConfigurationProvider";
import { SetLanguage } from "./SetLanguage";

const ModesBase = ({ language }) => {
  return (
    <div className="flex flex-col justify-between">
      <SiteNavigation homePage />
      <SetLanguage />
      <div className="h-full w-full lg:w-3/5 mx-auto bg-white">
        <div className="flex flex-col justify-start items-start p-8 md:w-5/5">
          {language === "en" && <EnglishModes />}
          {language === "fr" && <FrenchModes />}
          {language === "it" && <ItalianModes />}
          {language === "es" && <SpanishModes />}
          {language === "de" && <GermanModes />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withLanguage(ModesBase);
