import React from "react";
import styled from "styled-components";
import tileImageData from "../../tiles.json";
import { adjustmentTileImageData } from "../../tiles.js";
import meta from "../../meta.json";
import adjustmentMeta from "../../adjustment-meta.json";

const formatGreens = (greens) => {
  if (!greens) {
    return null;
  }

  return greens === 1 ? `1 green` : `${greens} greens`;
};

const formatExtraTiles = ({ tiles, isSingleTileHex, greens }) => {
  if (isSingleTileHex) {
    return "";
  }
  if (greens > 0) {
    return "";
  }

  if (tiles === 0) {
    return "(no tiles)";
  }
  if (tiles === 1) {
    return "(+1 tile)";
  }

  return `(+${tiles} tiles)`;
};

const parInfo = (tileId) => {
  if (meta[tileId].par.length === 0) {
    return formatGreens(meta[tileId].greens) || "-";
  }

  return `Par ${meta[tileId].par} ${formatExtraTiles(meta[tileId])}`;
};

const TileFace = styled.img`
  height: 105px;
  width: 120px;
  ${({ showAsUnavailable }) => showAsUnavailable && `filter: grayscale(100%);`}
`;

const AdjustmentTileFace = styled.img`
  height: 120px;
  width: 120px;
  padding: 8px;
  ${({ showAsUnavailable }) => showAsUnavailable && `filter: grayscale(100%);`}
`;

const TileHeader = ({ tile, unavailable, isAdjustment, set }) => {
  const courseTileText = `Tile ${tile}`;
  const adjustmentTileText = `Adjustment Tile`;

  return (
    <span className="font-bold w-full text-center">
      {isAdjustment ? adjustmentTileText : courseTileText}
      {unavailable ? " - PLACED" : ""}
    </span>
  );
};

const Set = ({ tile, isAdjustment, set }) => {
  if (isAdjustment) {
    return null;
  }
  return (
    <span className="font-bold w-full text-center">
      {set === "base" ? "Base Game" : ""}
      {set === "coastlines" ? "Coastlines" : ""}
      {set === "coastlines-exclusive" ? "Coastlines Exclusive" : ""}
    </span>
  );
};

const TileSideHeader = ({ side, tileId, isAdjustment }) => {
  const adjustmentTileTextASide = "Hazard Side";
  const adjustmentTileTextBSide = "Rough Side";
  const adjustmentTileText = side === "A" ? adjustmentTileTextASide : adjustmentTileTextBSide;
  const courseTileText = `${side} Side`;

  const text = isAdjustment ? adjustmentTileText : courseTileText;

  return (
    <>
      <span>{text}</span>
      {!isAdjustment && <span>{parInfo(tileId)}</span>}
    </>
  );
};

const colourTile = (selected, unavailable) => {
  if (unavailable) {
    return "grey";
  }

  return selected ? "yellow" : "white";
};

const Tile = ({
  isSelected,
  displayId,
  unavailable,
  a,
  b,
  showSideAUnavailable,
  clickHandlerSideA,
  showSideBUnavailable,
  clickHandlerSideB,
  isMouseOverOnCourse,
  set,
  isAdjustment = false,
}) => {
  return (
    <div
      className={`flex flex-col justify-center items-center p-2 flex-1 w-full border box-border`}
      style={{
        backgroundColor: colourTile(isSelected, unavailable),
        borderColor: isMouseOverOnCourse ? "yellow" : colourTile(isSelected, unavailable),
      }}
    >
      <TileHeader tile={displayId} unavailable={unavailable} isAdjustment={isAdjustment} />
      <Set tile={displayId} set={set} />
      <div className="flex flex-row justify-center items-center flex-1 w-full">
        <div className="flex flex-col justify-center items-center flex-1 w-full">
          <TileSideHeader side="A" tileId={a} isAdjustment={isAdjustment} />
          {isAdjustment ? (
            <AdjustmentTileFace
              showAsUnavailable={showSideAUnavailable}
              src={adjustmentTileImageData[adjustmentMeta[a]]}
              onClick={clickHandlerSideA}
            />
          ) : (
            <TileFace
              showAsUnavailable={showSideAUnavailable}
              src={tileImageData[a]}
              onClick={clickHandlerSideA}
            />
          )}
        </div>
        <div className="flex flex-col justify-center items-center flex-1">
          <TileSideHeader side="B" tileId={b} isAdjustment={isAdjustment} />
          {isAdjustment ? (
            <AdjustmentTileFace
              showAsUnavailable={showSideBUnavailable}
              src={adjustmentTileImageData[adjustmentMeta[b]]}
              onClick={clickHandlerSideB}
            />
          ) : (
            <TileFace
              showAsUnavailable={showSideBUnavailable}
              src={tileImageData[b]}
              onClick={clickHandlerSideB}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Tile;
