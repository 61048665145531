import React, { useContext, useEffect, useState } from "react";
import { getPrivateCourses } from "../services/private";
import { useAuth0 } from "../auth/react-auth0-wrapper";

const Context = React.createContext({
  courses: [],
  coursesLoaded: false,
  requestFinished: true,
});

export const withMyCourses = (Component) => (props) => {
  const { courses, coursesLoaded } = useContext(Context);

  return (
    <Component {...props} courses={courses} coursesLoaded={coursesLoaded} />
  );
};

const PrivateCoursesProvider = ({ children }) => {
  const { token, onTokenExpired } = useAuth0();
  const [courses, setCourses] = useState([]);
  const [coursesLoaded, setCoursesLoaded] = useState(false);
  const [requestFinished, setRequestFinished] = useState(true);

  useEffect(() => {
    if (!token) {
      return;
    }

    setRequestFinished(false);

    getPrivateCourses(token, onTokenExpired).then((courses) => {
      setCourses(courses);
      setCoursesLoaded(true);
      setRequestFinished(true);
    });
  }, [token]);

  return (
    <Context.Provider value={{ courses, coursesLoaded, requestFinished }}>
      {children}
    </Context.Provider>
  );
};

export default PrivateCoursesProvider;
