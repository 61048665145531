import React from "react";
import { Link } from "react-router-dom";
import courseTile from "./images/course-tile.png";
import adjustmentTile from "./images/adjustment-tiles.png";
import clubCard from "./images/de/sample-club.png";
import clubCardWithPlayerMarker from "./images/de/sample-club-with-player-count.png";
import soloCard from "./images/sample-solo.png";
import ruleBook from "./images/rule-book-cover.png";
import courseBook from "./images/course-book-cover.png";
import golferCard from "./images/de/sample-golfer.png";
import shotCard from "./images/sample-shot.png";
import token1 from "./images/1-point.png";
import token2 from "./images/2-points.png";
import token5 from "./images/5-points.png";
import meeple from "./images/meeple.png";
import flags from "./images/flag.png";
import trees from "./images/tree.png";
import holeInOne from "./images/final-hole-in-one-token.png";
import soloMarker from "./images/final-solo-marker.png";
import courseTileWithHighlight from "./images/course-tile-with-highlight.png";
import teeToGreen from "./images/from-tee-to-green.png";
import greenHex from "./images/green-hex.png";
import bunkerHex from "./images/bunker-hex.png";
import treeHex from "./images/tree-hex.png";
import teeboxHex from "./images/teebox-hex.png";
import clubhouse from "./images/clubhouse.png";
import hybrid8 from "./images/de/8-hybrid.png";
import iron9 from "./images/de/9-iron.png";
import iron6 from "./images/de/6-iron.png";
import iron4 from "./images/de/4-iron.png";
import iron3 from "./images/de/3-iron.png";
import wood5 from "./images/de/5-wood.png";
import lobWedge from "./images/de/lw.png";
import driver from "./images/de/driver.png";
import deckC from "./images/deck-c.png";
import deckCStraight from "./images/deck-c-straight.png";
import deckCRight from "./images/deck-c-right.png";
import deck3Left from "./images/deck-3-left.png";
import deck3Super from "./images/de/deck-3-super.png";
import deck4Left from "./images/deck-4-left.png";
import deck4Straight from "./images/deck-4-straight.png";
import deck5right from "./images/deck-5-right.png";
import wildCard from "./images/deck-4-wild.png";
import shuffle from "./images/de/shuffle.png";
import superHit from "./images/de/super-hit.png";
import shortHit from "./images/de/short-hit.png";
import longestDriveCard from "./images/de/longest-drive.png";
import roughHex from "./images/rough-hex.png";
import clareExample from "./images/clare-example.png";
import bunkerOutcomes from "./images/bunker-outcomes.png";
import exitBunkerExample from "./images/exit-bunker.png";
import exitBunkerShotCard from "./images/exit-bunker-shot-card.png";
import roughExample1 from "./images/rough-example-1.png";
import roughExample2 from "./images/rough-example-2.png";
import chippingExample from "./images/chipping-example.png";
import golfer1a from "./images/de/golfer-1-a.png";
import golfer1b from "./images/de/golfer-1-b.png";
import golfer2a from "./images/de/golfer-2-a.png";
import golfer2b from "./images/de/golfer-2-b.png";
import golfer3a from "./images/de/golfer-3-a.png";
import golfer3b from "./images/de/golfer-3-b.png";
import golfer4a from "./images/de/golfer-4-a.png";
import golfer4b from "./images/de/golfer-4-b.png";
import golfer5a from "./images/de/golfer-5-a.png";
import golfer5b from "./images/de/golfer-5-b.png";
import golfer6a from "./images/de/golfer-6-a.png";
import golfer6b from "./images/de/golfer-6-b.png";
import golfer7a from "./images/de/golfer-7-a.png";
import golfer7b from "./images/de/golfer-7-b.png";
import golfer8a from "./images/de/golfer-8-a.png";
import golfer8b from "./images/de/golfer-8-b.png";
import {
  ModesChaosGolf,
  ModesChaosGolfSolo,
  ModesChaosTeams,
  ModesGolfRacing,
  ModesKellyGolf,
  ModesMatchPlay,
  ModesSkins,
  ModesStableford,
  ModesStrokePlay,
  PublicRules,
} from "../navigation/locations";
import {
  Section,
  BoxPart,
  SideBySide,
  SideBySideSection,
  FirstGame,
  GreenPanel,
  Example,
  AlwaysSideBySide,
  HybridCardsEnglish,
  StandardPortraitCardSize,
  StandardLandscapeCardSize,
  SmallPortraitCardSize,
  SmallHex,
  CourseTileSize,
  WaterHazards,
} from "./RuleBookParts";
import { A, H1, H2, note, P, H3, H4, H5, link, ul } from "../brand/typography";

const language = "de";
export const RuleBookGerman = () => {
  return (
    <div className="flex flex-col justify-start items-start p-8 md:w-5/5 print:block">
      <H1>Regelbuch</H1>
      <div className="self-center print:hidden">
        <A href={PublicRules} target="_blank">
          Download as PDF
        </A>
      </div>
      <div className="p-4">
        <H2>INHALTSVERZEICHNIS</H2>
        <div className="flex flex-col">
          <A href="#box-contents">IM LIEFERUMFANG DER BOX ENTHALTEN</A>
          <A href="#establishing-shot">ABSCHLAG EINRICHTEN</A>
          <A href="#overview">ÜBERSICHT</A>
          <A href="#from-tee-box-to-green">VON DEM ABSCHLAGPLATZ ZUM GREEN</A>
          <A href="#setup">SETUP</A>
          <A href="#playing-a-round">EINE RUNDE SPIELEN</A>
          <A href="#rough">SPIELEN AUS DEM ROUGH</A>
          <A href="#chipping">CHIPPING</A>
          <A href="#super-short-wild">SUPER, SHORT, SHUFFLE UND WILD-KARTEN</A>
          <A href="#hazards">HINDERNISSE &amp; ANDERE FELDER</A>
          <A href="#golfers">GOLFER-FÄHIGKEITEN</A>
          <A href="#game-modes">MEHRERE SPIELMÖGLICHKEITEN</A>
        </div>
      </div>
      <Section id="box-contents" title="IM LIEFERUMFANG DER BOX ENTHALTEN:">
        <div className="flex flex-col md:flex-row justify-between items-center md:flex-wrap print:w-full print:flex-row print:flex-wrap">
          <BoxPart src={courseTile} text="49 Golfplatz-Plättchen" size={200} />
          <BoxPart src={adjustmentTile} text="21 Anpassungsplättchen" size={66} />
          <BoxPart src={meeple} size={150} text="5 Golfer" />
          <BoxPart src={golferCard} size={150} text="8 Golfer-Karten" />
          <BoxPart src={clubCard} size={200} text="34 Golfschläger-Karten" />
          <BoxPart src={shotCard} size={150} text="74 Schlag-Karten" />
          <BoxPart src={soloCard} size={200} text="24 Solo Chaos-Golf Karten" />
          <BoxPart src={token1} size={66} text="18 1-Punkt-Token" />
          <BoxPart src={token2} size={66} text="18 2-Punkt-Token" />
          <BoxPart src={token5} size={100} text="18 5-Punkt-Token" />
          <BoxPart src={holeInOne} size={66} text="18 Hole-in-One-Token" />
          <BoxPart src={soloMarker} size={66} text="1 Solo-Runden-Marker" />
          <BoxPart src={flags} size={66} text="18 Fahnen &amp; Stände" />
          <BoxPart src={trees} size={66} text="14 Bäume" />
          <BoxPart src={ruleBook} size={66} text="Dieses Handbuch" />
          <BoxPart src={courseBook} size={66} text="Golfkursbroschüre" />
        </div>
        <em className={note}>
          Einige Spielmodi erfordern Stift und Papier für die Punktevergabe (nicht im Lieferumfang
          enthalten).
        </em>
      </Section>
      <Section id="establishing-shot" title="ABSCHLAG EINRICHTEN">
        <P>
          <em>
            Es findet eine Vorstandssitzung statt, mitten im Firmensitz des internationalen
            Sportgiganten Golf Corp. Dieses Treffen wird die Zukunft des Golfsports bestimmen.
          </em>
        </P>
        <P>
          Sehr geehrte Vorstandsmitglieder, die Lage scheint schlimm zu sein. Die Besucherzahlen
          gehen zurück, die Fortschritte in der Schlägertechnologie haben unser Spiel zu einfach
          gemacht. Durch den zunehmenden Wettbewerb um ihre Aufmerksamkeit sind die Menschen nicht
          mehr in der Lage, 4 Stunden Golf zu spielen. Niedrige Besucherzahlen üben Druck auf unsere
          Golfplätze aus, sich über Wasser zu halten. Scharen von Immobilienentwicklern haben
          begonnen, die Eigentümer zu jagen, damit sie unsere kostbaren Fairways in seelenlose
          Anwesen verwandeln können.
        </P>
        <P>
          Doch es ist noch nicht alles verloren. Ich habe ein Team unserer besten Köpfe die nächste
          Iteration unseres großartigen Spiels planen lassen. Die Mannschaft hat sich ein paar
          Regeländerungen einfallen lassen, die uns aus dem Sandbunker herausholen und auf das Loch
          zurollen lassen werden.
        </P>
        <P>
          Die Golfplatz-Besitzer brauchen mehr Leute auf ihren Plätzen, und wir haben es versäumt,
          die Anzahl der guten Stunden am Tag zu erhöhen. Wir werden unseren Ansatz ändern und das
          Simultanspiel einführen. Mehr Leute, die gleichzeitig Golf spielen, bedeuten mehr
          Greenfees. Unsere patentierte HOLOBALL™ Technologie wird es für alle in einer Gruppe
          sicher machen, zur gleichen Zeit abzuschlagen.
        </P>
        <P>
          Es ist eine fantastische Technologie. Ich dachte, die Flottille von Drohnen, die Golfbälle
          verfolgen und stoppen, würde den Sieg herbeirufen. Aber dies ... den Golfball selbst zur
          Drohne zu machen, ist clever. Golfbälle, die nie verloren gehen. Golfbälle, die anhalten
          können, bevor sie jemanden treffen und ihren Flug danach wieder aufnehmen können.
          Golfbälle, die deinen Punktestand verfolgen.
        </P>
        <P>
          Nun, ich gebe zu, dass es einige Verletzungen gegeben hat. Man könnte sagen, dass die
          Technik noch etwas unausgereift ist. Aber ich denke, wir können damit beginnen, sie auf
          einige der Kurse aunzuwenden, die Schwierigkeiten haben, über dem Nennwert zu bleiben.
        </P>
        <P>
          Früher hatten wir eine gute Beziehung zu den Golfschläger-Herstellern. Doch in letzter
          Zeit untergräbt jeder neue Fortschritt in der Golftechnologie die Herausforderung, ob es
          sich nun um Selbstlenk-Bälle, Super-Drivers oder die kürzlich erschienenen magnetischen
          Sand Wedges handelt.
        </P>
        <P>
          Um den Golfschläger-Herstellern, die vom Untergang unseres großartigen Spiels profitieren,
          entgegenzuwirken, haben wir einige Änderungen vorgenommen, die nicht direkt auf sie
          abzielen, sondern schlicht ihren Einfluss verringern werden.
        </P>
        <P>
          Beim Golf ging es schon immer nicht nur um die Ausführung, sondern auch um die
          Schlag-Auswahl. Wir werden das Schlägerlimit von 14 auf 5 reduzieren, was die Spieler
          zwingt, mehr darüber nachzudenken, welche Schläger sie nehmen und wann sie sie benutzen.
          Beim Golf geht es auch um die Planung und Ausarbeitung Ihres Ansatzes, und deshalb werden
          wir den Spielern erst dann erlauben, denselben Schläger zu benutzen, wenn sie alle ihre
          anderen Schläger benutzt haben.
        </P>
        <P>Liebe Vorstandsmitglieder, Willkommen im nächsten Zeitalter des Golfsports!</P>
        <P>
          <em>Der Sitzungssaal bricht in Applaus aus.</em>
        </P>
      </Section>
      <Section id="overview" title="Übersicht">
        <P>
          Seit dieser Vorstandssitzung sind zehn Jahre vergangen. Golf war noch nie so populär wie
          heute. Zusammen mit der Erfindung der HOLOBALL™-Technologie wurden neue Möglichkeiten des
          Golfspiels geschaffen, und der Sport floriert. Du bist ein Golfer in dieser goldenen Ära
          des Golfsports. Wähle deine Schläger und miss dich auf dem Platz mit einer sorgfältigen
          Schlagauswahl und intelligenter Hindernis-Navigation.
        </P>
        <P>
          Das Spiel wird über die 18 Löcher eines Golfplatzes gespielt – jedes Loch erfordert den
          Einsatz eines oder mehrerer Schläger. Nachdem ein Schläger gespielt wurde, können die
          Spieler diesen Schläger erst wieder verwenden, wenn sie alle anderen Schläger gespielt
          haben. Golfer erzielen Punkte, indem sie zunächst die Greens erreichen. Sieger ist der
          Golfer mit den meisten Punkten.
        </P>
        <P>
          Dieses Buch enthält Regeln für viele verschiedene Modi, darunter Solospieler,
          Zwei-Spieler, Golfrennen und Chaos-Golf. Für jeden Spielmodus kannst du das Erlebnis auf
          deine Spielgruppen zuschneiden. Der erste Teil dieses Buches behandelt den
          Standardregelsatz namens Match-Spiel.
        </P>
        <SideBySide title="Golfplatz-Plättchen" right={courseTileWithHighlight} size={200}>
          <P>
            Verwende diese Plättchen, um den Platz zu bauen, auf dem du spielst. Es gibt 49
            doppelseitige Plättchen, mit denen du jedes Mal, wenn du spielst, einen neuen Platz
            erstellen kannst.
          </P>
          <P>
            Die Plättchen haben einen von drei Schwierigkeitsgraden, die durch Sterne in der Ecke
            jeder Fläche angezeigt werden. Jede Plättchen hat eine Nummer und einen Pfeil, der in
            die Hauptrichtung zeigt.
          </P>
          <P>
            In der Begleitbroschüre findest du Golfplatz-Designs und Anleitungen zum Aufbau von
            Golfplätzen.
          </P>
        </SideBySide>
        <SideBySide title="Anpassungs-Plättchen" right={adjustmentTile} size={132} height="132">
          <P>
            Diese Mini-Hexfelder können auf die Golfplatz-Plättchen gelegt werden, um den
            Schwierigkeitsgrad des Platzes zu verändern. Für ein entspannteres Spiel decke die
            Hindernisse mit der Rough-Seite ab. Wenn du eine Herausforderung suchst, kannst du die
            Hindernis-Seite verwenden, um bestehende Hindernisse anzupassen oder neue hinzuzufügen.
          </P>
        </SideBySide>
        <SideBySide title="Golfer-Karten" right={golferCard} size={200}>
          <P>
            Die Spieler wählen Golfer-Karten aus, die eine einzigartige Spezialfähigkeit bieten. Die
            Fähigkeiten auf der A-Seite reduzieren die Herausforderung, während die B-Seite oft
            einige Einschränkungen enthält. Du wählst beim Spielen entweder die A-Seite oder die
            B-Seite.
          </P>
        </SideBySide>
        <SideBySide title="Golfschläger-Karten" right={clubCard} size={250}>
          <P>
            Jeder Schläger hat eine einzigartige Fähigkeit oder sagt dir, von welchem Schlag-Stapel
            du ziehen sollst. Du kannst nur fünf Schläger auf den Platz mitnehmen. Einmal verwendet,
            kannst du sie erst dann wieder verwenden, wenn du alle deine anderen Schläger verwendet
            hast.
          </P>
        </SideBySide>
        <SideBySide title="Schlag-Karten" right={shotCard} size={200}>
          <P>
            Diese Karten sind in fünf Stapel organisiert und stellen die verschiedenen Entfernungen
            und Richtungen für deine Schläge dar. Jedes Stapel enthält Karten, die den Ball links,
            rechts und in einigen Fällen auch in die von dir gewählte Richtung treffen.
          </P>
          <P>
            Auf jeder Karte befindet sich eine Flugkarte, die dir hilft, die Flugbahn deines Balles
            zu planen. Das orangefarbene Feld markiert, wo dein Ball landet. Wenn der Ball vom Rough
            aus geschlagen wird, reist er ein Feld weniger. Siehe&nbsp;
            <A href="#rough">SPIELEN AUS DEM ROUGH</A>.
          </P>
          <P>
            Du verwendest auch Schlag-Karten, um aus Bunkern herauszukommen. Das
            Bunkerergebnis-Symbol befindet sich in der unteren rechten Ecke. Siehe&nbsp;
            <A href="#4d">BUNKER VERLASSEN, GREENS UND GEWINNEN</A>.
          </P>
        </SideBySide>
        <SideBySide title="Green Marker / Punkte-Token " right={courseTileWithHighlight} size={200}>
          <P>
            Die kreisförmigen Tokens erfüllen einen doppelten Zweck. Auf der Vorderseite sind die
            Greens von 1 bis 18 nummeriert. Da die Spielfelder des Golfplatzes auf den Greens nicht
            nummeriert sind, hilft es, die Reihenfolge der Löcher zu verfolgen, wenn man die Token
            mit der Nummerierung nach oben legt.
          </P>
          <P>
            In vielen der Spielmodi in 18 Holes werden die Token für die Punktevergabe mit der
            Punkte-Seite nach oben verwendet. Diese Seite zeigen Punkte-Werte von 5, 2 und 1.
            Siehe&nbsp;
            <A href="#game-modes">MEHRERE SPIELMÖGLICHKEITEN</A>.
          </P>
          <P>
            Es gibt Ersatz-Tokens mit 1 Pkt., 2 Pkt. und 5 Pkt. Diese haben keine Green-Nummern.
            Bitte bewahre sie an einem sicheren Ort auf, falls du sie benötigst.
          </P>
        </SideBySide>
        <SideBySide title="Hole-in-One-Marker" right={holeInOne} size={200}>
          <P>
            Diese werden gesammelt, wenn du ein Hole-in-One erzielst. Im Falle eines Tie-Breaks ist
            der Spieler mit den meisten Hole-in-One-Markern der Gewinner.
          </P>
        </SideBySide>
        <SideBySide title="Solo Chaos-Golf Karten" right={soloCard} size={250}>
          <P>
            Verwende diesen Stapel, um den Ablauf des Solo-Chaos-Golfs zu organisieren und den
            Schwierigkeitsgrad anzupassen. Diese Karten haben zwei verschiedene Symbole. Einfarbige
            Kreise, die Runden darstellen, bei denen nur der Spieler punktet und zweifarbige Kreise,
            bei denen sowohl du als auch der automatisierte Gegner punkten. Dies sind Runden, in
            denen deine Gegner punkten können. Siehe&nbsp;
            <A href={ModesChaosGolfSolo}>SOLO-CHAOS-GOLF</A>.
          </P>
        </SideBySide>
        <SideBySide title="SOLO-RUNDEN-MARKER" right={soloMarker} size={200}>
          <P>
            Wird verwendet, um während einer Partie Solo Chaos-Golf zu verfolgen, in welcher Runde
            du bist.
          </P>
        </SideBySide>
        <SideBySide title="Fahnen" right={flags} size={200}>
          <P>
            Diese Fahnen werden auf den Greens platziert, um anzuzeigen, wohin du zielst. Für jedes
            Loch gibt es eine von 1 bis 18 nummerierte Fahne und drei Ersatzfahnen (unnummeriert).
            Lege die zusätzlichen Fahnen zur sicheren Aufbewahrung beiseite.
          </P>
        </SideBySide>
        <SideBySide title="BÄUME" right={trees} size={200}>
          <P>
            Diese Bäume sind Dekorationen, die du auf deinem Golflatz aufstellen kannst. Platziere
            sie über Baumhindernissen, um sie deutlicher hervortreten zu lassen. Es gibt nur 14
            Bäume, du musst sie also während des Spiels auf dem Platz verschieben.
          </P>
        </SideBySide>
        <SideBySide title="GOLFER" right={meeple} size={200}>
          <P>
            Jeder Spieler hat einen Golfspieler, der ihn auf dem Golfplatz vertritt. Es gibt keinen
            Unterschied zwischen ihnen, also wähle die Farbe, die dir am besten gefällt.
          </P>
        </SideBySide>
      </Section>
      <SideBySideSection
        id="from-tee-box-to-green"
        title="Von dem Abschlagplatz zum Green"
        right={teeToGreen}
        size="450"
        height="1024"
      >
        <P>
          Ein Spiel von 18 Holes wird auf einem Platz gespielt, der aus einem oder mehreren Löchern
          besteht. Die meisten Golfplätze haben 18 Löcher, obwohl es üblich ist, kürzere und
          schnellere 9-Loch-Plätze zu haben. Für jedes Loch, das du gewinnst, erhältst du einen
          Punkt. Du gewinnst ein Loch, wenn du das Green vor deinen Gegnern erreichst. Im Falle
          eines Unentschiedens erzielen mehrere Spieler Punkte.
        </P>
        <P>
          Das Green ist das kleine Feld mit einer kleinen schwarzen Fahne darauf. Auf der rechten
          Seite haben wir ein einzelnes Loch, das aus drei Spielfeldern besteht. Die oberste
          Plättchen enthält das Green. Auf der unteren Plättchen befindet sich der Abschlagplatz.
          Vom Abschlagplatz bis zum Green verläuft das Fairway.
        </P>
        <P>
          Ein Loch lässt dich mit deinem Golfer am Abschlagplatz beginnen, und die Aufgabe besteht
          darin, zum Green zu gelangen. Du spielst Schläger, die deinen Golfer bewegen werden. Du
          musst nicht dem Fairway zum Green folgen. Es kann jedoch eine gute Strategie sein, weil
          deine Schläge weiter gehen, wenn sie vom Fairway aus gespielt werden.
        </P>
        <P>
          Es gibt nur ein paar Felder, auf denen du nicht landen kannst. Dies sind die
          Baumhindernisse und das Klubhaus. Das Baumhindernis wird von einem weißen Zaun umgeben
          sein. <A href="#hazards">Das Klubhaus ist auf Seite 15 zu sehen</A>. Du kannst auf den
          Fairways und Greens anderer Löcher landen und du kannst auf Feldern landen, auf denen sich
          andere Golfer befinden. All dies dank HOLOBALL™.
        </P>
        <P>
          Du kannst zwar auf den meisten Feldern landen, aber nicht alle sind empfehlenswert. Einige
          enthalten Wasser, durch das du einen Zug verpassen kannst und andere enthalten Bunker, aus
          denen du aussteigen musst, bevor du weiterspielen kannst. Das dunkle Grün, das sich auf
          jedem anderen Feld befindet, ist das Rough und verringert die Entfernung deiner Schläge.
          Siehe&nbsp;
          <A href="#hazards">HINDERNISSE &amp; ANDERE FELDER</A>.
        </P>
        <P>
          Es gibt viele Möglichkeiten, deine 18-Holes-Erfahrung individuell anzupassen. Verschiedene
          Spielmodi bieten dir unterschiedliche Erfahrungen und Herausforderungen. Du kannst Kurse
          anpassen, um sie einfacher und schwieriger zu gestalten. Die acht Golfer, die ins Spiel
          kommen, ermöglichen es dir, das Spiel je nach Spieler einfacher oder schwieriger zu
          gestalten. Wenn du die verschiedenen Optionen ausprobierst, sind wir zuversichtlich, dass
          du ein Spielerlebnis finden wirst, das dir Spaß macht und zu dem du wieder zurückkehren
          wollen wirst.
        </P>
      </SideBySideSection>
      <Section id="setup" title="Setup">
        <H3>DEN GOLFPLATZ AUFBAUEN</H3>
        <P>
          Du kannst den Golfplatz mit einem der Entwürfe aus der Golfplatzbroschüre einrichten oder
          einen eigenen Entwurf gestalten. Auf der Rückseite der Golfplatzbroschüre findest du eine
          Anleitung zum Erstellen von Golfplätzen.
        </P>
        <SideBySide right={greenHex} size={132}>
          <P>
            Wenn du deinen Golfplatz erstellt hast, platzierst du auf jedem Loch eine Fahne.
            Platziere die 1. Fahne auf dem Green (rechts) für das erste Loch und fahre so bis zum
            18. Green fort. Wenn du mit Bäumen spielst, platziere diese auf den ersten 14
            Baumfeldern. Die Baumhindernisse sind die Felder mit kleinen weißen Zäunen um sie herum.
          </P>
        </SideBySide>
        <P>Es ist an der Zeit, dass jeder einen Golfer und Schläger auswählt.</P>
        <FirstGame lang={language}>
          <A
            href="https://18holesgame.com/p/seabrook-studios/starter-course-KtgRTBj5"
            target="_blank"
          >
            Versuche den Starting Course.
          </A>
        </FirstGame>
        <H3>GOLFER WÄHLEN</H3>
        <P>
          Jeder Spieler wählt eine Golferkarte zum Spielen aus. Du kannst die Spieler die gewünschte
          Karte und Seite auswählen lassen oder eine Karte nach dem Zufallsprinzip austeilen.
        </P>
        <P>
          Jede Golferkarte hat eine A-Seite und eine B-Seite. Die Golfer mit der A-Seite erleichtern
          das Spiel, während die Golfer mit der B-Seite das Spiel eher erschweren.
        </P>
        <P>
          Die Auswahl der Golfer erfolgt vor der Schläger-Auswahl, da einige Golfer die
          Schläger-Auswahlphase beeinflussen.
        </P>
        <FirstGame lang={language}>
          Überspringe die Golferkarten, bis du bereit bist, die Herausforderung anzunehmen.
        </FirstGame>
        <H3 style={{ breakBefore: "page" }}>GOLFSCHLÄGER AUSWÄHLEN</H3>
        <P>Die Spieler werden so lange Schläger auswählen, bis sie jeweils 5 haben. </P>
        <SideBySide right={clubCardWithPlayerMarker} size={250}>
          <P>
            Mische die Schlägerkarten. Wenn du ein Spiel mit einem oder zwei Spielern spielst,
            entferne die Schläger, die in der unteren rechten Ecke 3+ oder 5+ anzeigen. Bei einem
            Spiel mit 3 oder 4 Spielern entferne die Karten mit 5+.
          </P>
        </SideBySide>
        <P>
          Gib jedem Spieler die gleiche Anzahl von Karten. Verbleibende Karten werden nicht
          verwendet und ungesehen in die Box zurückgelegt.
        </P>
        <P>
          Jeder Spieler wählt aus den ausgeteilten Karten einen Schläger aus und legt ihn verdeckt
          vor sich ab. Die restlichen Karten gibt er an die Person zu seiner Linken weiter. Wiederum
          wählt jeder Spieler eine Karte aus den verbleibenden vier Karten aus und legt sie verdeckt
          vor sich ab und gibt den Rest nach links weiter. Es geht so weiter, bis alle Spieler fünf
          Schläger haben. Dies ist dein Blatt. Jeder Spieler benötigt vor sich auch Platz für einen
          Ablagestapel für verwendete Schläger.
        </P>
        <P>
          Wenn ein Spieler "The Shark", "Iron Jane" oder "Slugger" hat, gehe zu&nbsp;
          <A href="#golfers">GOLFERFÄHIGKEITEN</A>&nbsp;für Regeln, die den Draft ändern.
        </P>
        <P>
          <em>
            Die Schlägerauswahl ist deine erste Chance, dir einen Vorteil zu verschaffen. Die
            Golfplatz-Merkmale bestimmen die Effektivität einiger Schläger. Schaue dir den Platz an,
            um zu sehen, welche Schläger deiner Meinung nach nützlich sein werden.
          </em>
        </P>
        <FirstGame lang={language}>
          Auf der Vorderseite einiger Golfschläger-Karten befindet sich ein A,B,C,D oder E. Anstatt
          einer Auswahl gib die A's an Spieler 1, die B's an Spieler 2 usw
        </FirstGame>
        <H3>SPIEL BEGINNEN</H3>
        <P>
          Du bist nun bereit, mit dem Spiel zu beginnen. Setze alle Golfer auf das Abschlagfeld des
          ersten Lochs. Alle Spieler sollten ihre Schläger in der Hand vor anderen Spielern
          versteckt halten.
        </P>
        <P>
          Nimm die 5 Schlag-Stapel, die C, 2, 3, 4 oder 5 auf der Rückseite haben. Mische und stelle
          sie in Reichweite aller Spieler auf.
        </P>
      </Section>
      <Section id="playing-a-round" title="Eine Runde spielen">
        <GreenPanel>
          <H3>Eine Runde spielen</H3>
          <P>JEDE RUNDE VON 18 HOLES IST IN FOLGENDE SCHRITTE UNTERTEILT</P>
          <H4 href="#selecting-clubs">AUSWAHL DER SCHLÄGER</H4>
          <ul className="pl-4">
            <li>
              <A href="#1">
                1.
                <span className="pl-3">Leere Hände zurücksetzen</span>
              </A>
            </li>
            <li>
              <A href="#2">
                2.
                <span className="pl-3">Schläger auswählen &amp; aufdecken</span>
              </A>
            </li>
            <li>
              <A href="#3">
                3.<span className="pl-3">Spielreihenfolge festlegen</span>
              </A>
            </li>
          </ul>
          <H4 href="#on-your-turn">IN DEINER RUNDE</H4>
          <ul className="pl-4">
            <li>
              <A href="#4">
                4.<span className="pl-3">Vervollständige eine Aktion</span>
              </A>
            </li>
            <ul className="pl-4">
              <li>
                <A href="#4a">
                  A.<span className="pl-3">Bewege dich ein Feld</span>
                </A>
              </li>
              <li>
                <A href="#4b">
                  B.<span className="pl-3">Golfschläger-Karte spielen</span>
                </A>
              </li>
              <li>
                <A href="#4c">
                  C.<span className="pl-3">Versuch eines großen Schlags</span>
                </A>
              </li>
              <li>
                <A href="#4d">
                  D.<span className="pl-3">Verlasse den Bunker</span>
                </A>
              </li>
              <li>
                <A href="#4e">
                  E.<span className="pl-3">Setze deine Hand zurück</span>
                </A>
              </li>
            </ul>
            <li>
              <A href="#5">
                5.<span className="pl-3">Lege den Schläger ab.</span>
              </A>
            </li>
          </ul>
          <H4 href="#reaching-the-green">DAS GREEN ERREICHEN</H4>
          <ul className="pl-4">
            <li>
              <A href="#6">
                6.<span className="pl-3">Erziele Fahnen</span>
              </A>
            </li>
            <li>
              <A href="#7">
                7.<span className="pl-3">Erziele Hole-in-One Tokens</span>
              </A>
            </li>
            <li>
              <A href="#8">
                8.
                <span className="pl-3">Bringe alle Spieler zum nächsten Loch</span>
              </A>
            </li>
            <li>
              <A href="#9">
                9.
                <span className="pl-3">Beseitige alle gefährlichen Auswirkungen</span>
              </A>
            </li>
          </ul>
        </GreenPanel>
        <H3 id="selecting-clubs" href="#selecting-clubs">
          AUSWAHL DER SCHLÄGER
        </H3>
        <H4 id="1" href="#1">
          1. LEERE HÄNDE ZURÜCKSETZEN
        </H4>
        <P>
          Wenn du keine Schläger auf der Hand hast, ziehe alle deine abgelegten Schläger auf deine
          Hand.
        </P>
        <P>
          Wenn du einen Schläger auf der Hand hast, den du nicht benutzen kannst, kannst du deine
          Hand auch zurücksetzen. Z.B. hast du den Driver und stehst nicht auf einem Abschlagfeld.
        </P>
        <P>
          Wenn du mit Dorians A-Seite spielst, darfst du deine Schläger nur zurücksetzen, bevor du
          an einem Loch abschlägst.
        </P>
        <H4 id="2" href="#2">
          2. SCHLÄGER AUSWÄHLEN
        </H4>
        <P>
          Jeder Spieler wählt einen Schläger aus seiner Hand aus und legt ihn mit der Vorderseite
          nach unten vor sich ab.
        </P>
        <P>
          Wenn alle Spieler dies getan haben, deckt ihr gleichzeitig alle Schläger auf. Danach
          dürfen die Spieler ihre Auswahl nicht mehr ändern.
        </P>
        <P>
          Der Spieler mit Bunjil muss seine Kraft einsetzen, bevor er einen Schläger verdeckt
          platziert. Wenn Bunjil auf seiner B-Seite liegt und eine Schlag-Karte aufdeckt, können
          Spieler, die einen Schläger verdeckt platziert haben, ihre Auswahl ändern.
        </P>
        <P>
          Der Spieler mit Slick Rikesh auf seiner A-Seite kann nach der Enthüllung seine Kraft
          einsetzen und einen Schläger eines anderen Spielers nominieren.
        </P>
        <H4 id="3" href="#3">
          3. SPIELREIHENFOLGE FESTLEGEN
        </H4>
        <P>
          Verwende die Initiativreihenfolge der einzelnen Schläger, um die Spielreihenfolge
          festzulegen. Der Schläger mit der geringsten Initiative beginnt. Du findest die Initiative
          in der linken oberen Ecke der Golfschläger-Karte.
        </P>
        <P>
          Wenn Slick Rikesh den Schläger eines anderen Spielers benutzt, spielt Slick Rikesh
          unmittelbar nach dem anderen Spieler.
        </P>
        <P>
          Wenn Swift auf ihrer A-Seite ist, gewinnt sie jedes Initiativunentschieden. Wenn Swift auf
          ihrer B-Seite steht, verliert sie alle Initiativunentschieden.
        </P>
        <P>
          Wenn Swift mit Slick Rikesh gleichauf liegt, der sich einen Schläger ausleiht, gewinnt sie
          auf ihrer A-Seite und verliert auf ihrer B-Seite.
        </P>
        <P>
          <em>
            Betrachtet man die unten aufgeführten Schläger, so hat der 8 Hybrid eine Initiative von
            5, und dieser Spieler ist zuerst an der Reihe. Der nächste Spieler ist die Person mit
            dem Iron 6 (Initiative 12). Der letzte Spieler, der an der Reihe ist, ist die Person,
            die Wood 5 gespielt hat (Initiative 20).
          </em>
        </P>
        <div className="flex flex-col md:flex-row justify-evenly items-center pt-4">
          <div>
            <img src={hybrid8} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron6} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={wood5} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: -3,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H3 id="on-your-turn" href="#on-your-turn" style={{ breakBefore: "page" }}>
          WENN DU AN DER REIHE BIST
        </H3>
        <P>Nach dieser Spielreihenfolge ist jeder Spieler an der Reihe.</P>
        <H4 id="4" href="#4">
          4. FÜHRE EINE AKTION DURCH
        </H4>
        <P>
          Wenn du an der Reihe bist, führe <strong>eine</strong> der folgenden Aktionen durch:
        </P>
        <ul className={ul}>
          <li>A. Bewege dich um ein Feld weiter</li>
          <li>B. Spiele eine Golfschläger-Karte</li>
          <li>C. Versuche einen großen Schlag</li>
          <li>D. Verlasse den Bunker</li>
          <li>E. Setze deine Hand zurück</li>
        </ul>
        <P>Wenn du fertig mit deinem Zug bist, wirfst du deinen Schläger ab</P>
        <H4 id="4a" href="#4a">
          4.a) BEWEGE DICH UM EIN FELD WEITER
        </H4>
        <P>
          Solange du dich nicht in einem Bunker befindest, hast du die Möglichkeit, dich um ein Feld
          zu bewegen. Platziere deinen Golfer auf eines der angrenzenden Felder. Du kannst nicht auf
          ein Feld mit einem Baumhindernis oder vom Brett ziehen. Siehe&nbsp;
          <A href="#hazards">HINDERNISSE &amnp; ANDERE PLÄTTCHEN</A>.
        </P>
        <H4 id="4b" href="#4b">
          4.b) SPIELE EINE GOLFSCHLÄGER-KARTE
        </H4>
        <P>Je nach Club kannst du eine der folgenden Möglichkeiten nutzen:</P>
        <ul className={ul}>
          <li>Ziehe 1 Karte von dem auf der Karte angegebenen Schlag-Stapel</li>
          <li>Ziehe 1 Karte von einem der beiden gezeigten Schlag-Stapel</li>
          <li>Ziehe 2 Karten von dem auf der Karte angegebenen Schlag-Stapel</li>
          <li>Bewege deinen Golfer um die Anzahl der Felder in einer geraden Linie</li>
        </ul>
        <P>
          Die Option, die du hast, ist auf jedem Schläger vermerkt. Das Spielen einer
          Golfschläger-Karte umfasst drei Schritte: ziehen, entscheiden, bewegen.
        </P>
        <P>
          Bei den meisten Golfschlägern ziehst du deine Schlag-Karte, entscheidest, in welche
          Richtung du schlagen willst und bewegst deinen Golfer dann in dieser Reihenfolge. Wenn du
          vom Chip-Stapel ziehst, musst du die Richtung bestimmen, bevor du eine Karte ziehst.
          Siehe&nbsp;
          <A href="#chipping">CHIPPING</A>. Wenn du mit einem Wood spielst, ziehe keine Karten, da
          sich dein Schläger immer eine bestimmte Strecke in gerader Linie bewegt.
        </P>
        <H5>Ziehe eine einzelne Schlag-Karte</H5>
        <P>
          Die meisten Schläger lassen dich eine Karte aus einem einzigen Schlag-Stapel ziehen.
          Einige Schläger geben dir mehrere Stapel zur Auswahl, aber du kannst trotzdem nur eine
          Karte ziehen.
        </P>
        <P>
          Wähle den Stapel und ziehe die oberste Karte. Wenn die gezogene Karte Spezialeffekte hat
          (Super, Short oder Shuffle), dann folge den Anweisungen, die auf der Schlag-Karte stehen.
          Lege jede zusätzliche Schlag-Karte, die du ziehst, auf die vorhergehende Karte. Wenn du
          mit dem Ziehen fertig bist, benutze die Schlag-Karte, um herauszufinden, wohin du deinen
          Golfer bewegen sollst.
        </P>
        <P>
          Weitere Informationen zu Sonderkarten findest du unter&nbsp;
          <A href="#super-short-wild">SUPER-, SHORT-, SCHUFFLE- UND WILD-KARTEN</A>.
        </P>
        <P>
          Wenn das Stapel bei deinem Zug leer ist, mische den Ablagestapel und drehe ihn um, um ein
          neues Stapel zu bilden. Mische die Karten, die du in diesem Zug gezogen hast, nicht in den
          neuen Stapel.
        </P>
        <P>
          Wenn du Lucky spielst, kannst du an dieser Stelle dein Können zeigen und eine Karte neu
          ziehen.
        </P>
        <H5>Ziehe von mehreren Schlag-Karten</H5>
        <P>
          Wenn du 2 Schlag-Karten ziehst, kannst du dir aussuchen, welche der Schlag-Karten du
          verwenden möchtest.
        </P>
        <P>
          Lege alle Schlag-Karten nebeneinander. Wenn eine der Schlag-Karten dich auffordert,
          weitere Karten zu ziehen (Super, Short, Shuffle), kannst du die Anweisungen dieser Karten
          befolgen und die neuen Karten über die Karte legen, die das Ziehen erfordert. Wenn du mit
          dem Ziehen fertig bist, musst du eine von den obersten sichtbaren Karten auswählen. Mit
          der ausgewählten Karte bewegst du deinen Golfer.
        </P>
        <Example>
          <P>
            Im untenstehenden Beispiel hat Gustav letztendlich 3 Schlag-Karten gezogen. Er muss sich
            für eine der beiden obersten sichtbaren Karten entscheiden.
          </P>
          <div className="flex flex-row justify-evenly items-start w-full">
            <div style={{ paddingTop: 50 }}>
              <img src={deck3Left} style={{ maxHeight: 200, height: 200 }} />
            </div>
            <div
              style={{
                maxHeight: 275,
                paddingTop: 50,
                overflow: "hidden",
                width: "60%",
              }}
            >
              <div style={{ position: "relative", top: 0, left: 0 }}>
                <img src={deck3Super} style={{ maxHeight: 200, height: 200 }} />
              </div>
              <div style={{ position: "relative", top: -235, left: 35 }}>
                <img src={deck4Straight} style={{ maxHeight: 200, height: 200 }} />
              </div>
            </div>
          </div>
        </Example>
        <H5>Bewege deinen Golfer</H5>
        <P>
          Der Flugplan auf deiner Schlag-Karte zeigt die Flugbahn des Balles an. Das Golfersymbol
          auf der Schlag-Karte zeigt an, wo sich dein Golfer zu Beginn des Schlags befindet. Bewege
          deinen Golfer entlang der nummerierten Felder, bis du das orangefarbene Zielfeld
          erreichst. Du musst ihn nicht in Richtung des Greens oder entlang des Fairways schlagen.
        </P>
        <P>
          Wenn dein Flugplan ein Feld mit eingezäunten Bäumen durchquert, musst du anhalten, bevor
          ein Feld mit den Bäumen betreten wird. Dein Ball hat die Bäume getroffen und ist stehen
          geblieben.
        </P>
        <P>
          Wenn du in einem Hindernis (Bunker, See) gelandet bist, befolge die Regeln&nbsp;
          <A href="#hazards">HINDERNISSE & ANDERE FELDER</A>.
        </P>
        <AlwaysSideBySide right={roughHex} size={132}>
          <P>
            Wenn du deinen Zug auf einem RoughFeld (rechts) begonnen hast, siehe&nbsp;
            <A href="#rough">SPIELEN AUS DEM ROUGH</A>.
          </P>
        </AlwaysSideBySide>
        <H5>Ändern deiner Meinung</H5>
        <P>
          Solange du den Finger noch nicht vom Golfer genommen hast, kannst du die Richtung, in die
          du den Ball geschlagen hast, ändern.
        </P>
        <Example>
          <P>
            Clare befindet sich in einer Situation, in der sie nicht mit einem Schlag auf das Green
            kommen kann. Sie spielt eine Iron 2 und zieht eine einzelne Karte vom Schlag-Stapel #4.
          </P>

          <P>
            Die gezogene Schlag-Karte richtet sich nach links. Ihre beste Option ist es, abseits des
            Platzes zu schlagen und mit der Form des Schlages jenseits des Wasserhindernisses neben
            dem Green zu landen, in einer hervorragenden Position für den nächsten Zug
          </P>
          <div style={{ maxHeight: 350, overflow: "hidden" }}>
            <img src={clareExample} style={{ maxHeight: 350 }} />
            <img
              src={shotCard}
              style={{
                maxHeight: 150,
                height: 150,
                position: "relative",
                top: -350,
                left: 250,
              }}
            />
          </div>
        </Example>
        <H4 id="4c" href="#4c" style={{ breakBefore: "page" }}>
          4.c) VERSUCH EINES GROßEN SCHLAGES
        </H4>
        <P>
          Manchmal braucht man mehr Reichweite. Du kannst mit deinem Golfschläger weiter schlagen
          als die auf der Golfschläger-Karte angezeigten Optionen. Sage der Gruppe an, wenn du an
          der Reihe bist, dass du einen GROßEN SCHLAG ausführst. Du ziehst nun eine Karte mehr als
          gewöhnlich, und alle Karten müssen vom Stapel um eine eins höher sein als der höchste
          nummerierte Stapel, der auf deiner Golfschläger-Karte angezeigt wird.
        </P>
        <P>
          Nachdem du deine Karten gezogen hast, gib sie dem Spieler zu deiner Linken. Er wirft eine
          der Schlag-Karten ab. Von den verbleibenden Karten wähle eine zum Spielen.
        </P>
        <P>
          Alle Super-, Short- oder Reshuffle-Karten werden wie gewohnt gespielt. Ein Super bedeutet,
          dass dein großer Schlag noch größer ist als erwartet und ein Short dich wahrscheinlich in
          der Zeit deiner großen Not enttäuschen wird.
        </P>
        <P>
          Nicht jeder Golfschläger erlaubt es dir, einen großen Schlag zu landen, z. B.
          Golfschläger, die bereits vom Schlag-Stapel #5 ziehen. In der oberen Ecke jedes Schlägers
          befindet sich ein Symbol, das anzeigt, ob du ihn für einen großen Schlag verwenden kannst.
          Im Beispiel unten hat der Schläger ein Symbol für ‚Kein großer Schlag‘, während das Eisen
          9 anzeigt, dass du einen großen Schlag erzielen kannst.
        </P>
        <div className="flex flex-r0ow justify-evenly items-center">
          <div>
            <img src={driver} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
          <div>
            <img src={iron9} width={200} />
            <div
              style={{
                position: "relative",
                top: -151,
                left: 155,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>

        <GreenPanel>
          <H3>Große Schläge</H3>
          <SideBySide right={iron4} size={200}>
            <P>
              Wenn dein Golfschläger dir erlaubt, von dem Schlag-Stapel #3 und #4 zu ziehen, dann
              darfst du zwei Karten vom Schlag-Stapel #5 ziehen. Vom Schlag-Stapel Nr. 4 darfst du
              nicht zwei Karten ziehen.
            </P>
          </SideBySide>
          <SideBySide right={iron3} size={200}>
            <P>
              Wenn deine Golfschläger-Karte es dir erlaubt, 2 Karten zu ziehen, musst du 3 Karten
              ziehen. In diesem Fall wirft der andere Spieler 1 Karte ab und du ziehst aus den
              verbleibenden 2 Karten.
            </P>
          </SideBySide>
        </GreenPanel>
        <H4 id="4d" href="#4d" style={{ breakBefore: "page" }}>
          4.D) BUNKER VERLASSEN
        </H4>
        <P>
          Wenn du in einem Bunker landest, musst du da rauskommen, bevor du das Spiel fortsetzen
          kannst. Bevor du eine Schlag-Karte ziehst, musst du entscheiden, in welche Richtung du
          dich zu bewegen hoffst. Diese Regel ist die gleiche wie beim Chippen (siehe&nbsp;
          <A href="#chipping">« CHIPPING »</A>). Das Feld, in das du dich bewegen willst, ist dein
          Zielfeld.
        </P>
        <P>
          Ziehe eine Karte mit einem einzigen Schlag von einem der auf der Karte abgebildeten
          Stapel. Anstatt auf die Flugbahn der Schlag-Karte zu schauen, schaue auf das
          Bunker-Ergebnis-Symbol in der unteren rechten Ecke.
        </P>
        <P>
          Es gibt vier Ergebnisse, die im Folgenden dargestellt werden. Von links nach rechts sind
          dies:
        </P>
        <ul className="list-disc list-inside">
          <li>im Bunker bleiben</li>
          <li>sich links von deinem Zielfeld bewegen</li>
          <li>sich auf dein Zielfeld zubewegen</li>
          <li>sich rechts von deinem Zielfeld bewegen</li>
        </ul>
        <div className="p-4 flex justify-center items-center">
          <img src={bunkerOutcomes} width={300} />
        </div>
        <P>
          Wenn du den Bunker verlässt, bewegst du deinen Golfer auf das Feld, das durch das
          Bunkersymbol bestimmt wird.
        </P>
        <P>
          Wenn der Schläger, den du zum Spielen gewählt hast, der Sand Wedge ist, betrachte den
          Bunker als Rough. Du brauchst die Aktion ‚Bunker verlassen‘ nicht auszuführen. Wenn sich
          dein Sand Wedge auf deiner Hand oder auf deinem Ablagestapel befindet, schützt er dich
          nicht vor Bunkern.
        </P>
        <Example>
          <P>
            Sebastian befindet sich in einem Bunker (A). Er erklärt, dass er auf das Green schlägt
            (Pfeil).
          </P>
          <P>
            Er zieht eine Schlag-Karte, und das aufgedeckte Bunker-Ergebnis zeigt, dass der Ball
            nach rechts geht.
          </P>
          <P>
            Er folgt dem Bunkersymbol und bewegt sich nach rechts (B) in die von ihm erklärte
            Richtung.
          </P>
          <div style={{ height: 325, overflow: "hidden" }} className="p-4">
            <img
              src={exitBunkerShotCard}
              width={150}
              style={{ position: "relative", top: 100, left: 0 }}
            />
            <img
              src={exitBunkerExample}
              width={300}
              style={{ position: "relative", top: -225, left: 0 }}
            />
            <div
              style={{
                position: "relative",
                top: -249,
                left: 98,
                borderRadius: 50,
                width: 50,
                height: 50,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </Example>
        <H4 id="4e" href="#4e">
          4.e) DEINE HAND ZURÜCKSETZEN
        </H4>
        <P>
          Du kannst dich dafür entscheiden, den Ball nicht zu schlagen und deine Hand
          zurückzusetzen. Nimm alle weggeworfenen Schläger auf deine Hand zurück. Wenn du die
          A-Seite von Dorian spielst, darfst du deine Hand an diesem Punkt nicht zurücksetzen.
        </P>
        <H4 id="5" href="#5">
          5. Golfschläger ablegen
        </H4>
        <P>
          Hier endet dein Zug. Lege den Schläger, den du benutzt hast, auf deinen Ablagestapel. Wenn
          du eine Karte ausspielst, die nie abläuft, nimm sie auf deine Hand zurück, z.B. Slugger's
          Driver oder Dorian's A-Seite Golfschläger. Wenn du die B-Seite von Slick Rikesh oder die
          B-Seite von Dorian spielst, überspringst du diesen Schritt.
        </P>
        <H3 id="reaching-the-green" href="#reaching-the-green">
          Das Green erreichen
        </H3>
        <H4 id="6" href="#6">
          6. FAHNEN ERHALTEN
        </H4>
        <P>
          Wenn ein Spieler das Green erreicht, erhält er die Fahne. Wenn mehrere Spieler in
          derselben Runde das Green erreichen, nimmt ein Spieler die Fahne und die anderen einen
          1-Punkt-Stein.
        </P>
        <H4 id="7" href="#7">
          7. Hole-in-One-TOKEN ERHALTEN
        </H4>
        <P>
          Wenn du ein Hole-in-One erzielt hast, nimm ein Hole-in-One-Token. Ein Hole-in-One wird bei
          deinem ersten Spielzug für das Loch erzielt, wenn du vom Abschlagplatz aus schägst und auf
          dem Green landest.
        </P>
        <H4 id="8" href="#8">
          8. ZUM NÄCHSTEN LOCH GEHEN
        </H4>
        <P>
          Nachdem die Spieler ihre Green-Token erhalten haben, bewege alle Spieler zum Abschlagfeld
          des nächsten Lochs.
        </P>
        <H4 id="9" href="#9">
          9. ALLE HINDERNISAUSWIRKUNGEN BESEITIGEN
        </H4>
        <P>
          Alle Spieler, die einen Zug wegen eines Wasserhindernisses verpasst hätten, werden von
          diesem Status befreit.
        </P>
        <H3>DAS SPIEL GEWINNEN</H3>
        <P>
          Am Ende des Kurses zählt jeder Spieler die Anzahl der Fahnen und der 1-Punkt-Token, die er
          besitzt. Jede Fahne und jeder Token ist 1 Punkt wert. Der Spieler mit den meisten Punkten
          gewinnt.
        </P>
        <P>
          Bei Gleichstand zähle die Hole-in-One-Token. Der Spieler mit den meisten Hole-in-One-Token
          gewinnt das Unentschieden. Bei erneutem Gleichstand teilen sich diese Spieler den Sieg.
        </P>
      </Section>
      <Section id="rough" title="SPIELEN AUS DEM ROUGH">
        <P>
          Wenn du deinen Zug auf einem Rough-Feld beginnst, legt dein Schlag eine reduzierte Distanz
          zurück.
        </P>
        <SideBySide right={wood5} size={250}>
          <P>
            Bei Golfschlägern, die eine festgelegte Distanz (Wood) zurücklegen, wird dies durch ein
            zweites Feld auf deinem Golfschläger durch ein Rough-Symbol dargestellt. Unten: Das
            5-Wood geht 2 Felder, wenn es vom Rough aus gespielt wird, und 3 Felder von einem
            Fairway aus.
          </P>
        </SideBySide>
        <SideBySide right={deck5right} size={200}>
          <P>
            Für Golfschläger, die von Schlag-Stapel ziehen, befindet sich ein Rough-Symbol auf
            deiner Schlag-Karte (siehe rechts). Verwende dieses anstelle des orangefarbenen Feldes,
            wenn du das Endziel für deinen Golfer festlegst.
          </P>
        </SideBySide>
        <P>
          Als Rough gilt jedes Feld, auf dem kein Fairway vorhanden ist. Ein Wasserhindernis gilt
          als Rough (nachdem du eine Runde verpasst hast). Ein Wasserhindernis auf einem Fairway
          (&nbsp;
          <A href="#hazards">HINDERNISSE &amp; ANDERE FELDER</A>) gilt als Fairway. Der größte Teil
          eines Golfplatzes ist Rough. Der allgemeine Ratschlag lautet, sich davon fernzuhalten.
        </P>
        <SideBySide RightComponent={HybridCardsEnglish} size={200}>
          <P>
            <em>
              Alle Hybrid-Schläger (3 Hybrid-, 5 Hybrid- und 8 Hybrid-Schläger) werden beim Schlagen
              aus dem Rough nicht bestraft.
            </em>
          </P>
        </SideBySide>
        <SideBySide right={deckCStraight} size={200}>
          <P>
            <em>
              Alle aus dem Chipstapel gezogenen Schlag-Karten erhalten keine Strafen, wenn sie aus
              dem Rough geschlagen werden. Auf diesen Karten befinden sich keine Rough-Symbole (sie
              bewegen sich immer 2 Felder).
            </em>
          </P>
        </SideBySide>
        <Example>
          <P>Mackenzie (weißer Kreis) befindet sich im Rough.</P>
          <img src={roughExample1} width={300} />
          <P>Sie spielt ein 2 Iron und dieses lässt sie vom Schlag-Stapel #4 ziehen.</P>
          <div className="flex justify-center items-center w-full">
            <img src={deck4Left} width={StandardPortraitCardSize} />
          </div>
          <P>
            Die Schlag-Karte dreht sich nach links. Beim Berechnen ihrer Bewegung verwendet sie das
            grobe Symbol auf der Schlag-Karte, um es zu berechnen.
          </P>
          <P>
            Aufgrund der geringeren Entfernung bewegt sie zwei Felder geradeaus und dann eines nach
            links, um dort zu landen, was der Rough-Marker auf der Schlag-Karte zeigt. In diesem
            Beispiel ist sie wieder auf dem Fairway gelandet – eine gute Position für ihren nächsten
            Schlag.
          </P>
          <img src={roughExample2} width={300} />
        </Example>
      </Section>
      <Section id="chipping" title="Chipping">
        <SideBySide right={deckC} size={StandardPortraitCardSize}>
          <P>
            Das Chip-Stapel unterscheidet sich von den anderen Schlag-Stapel. Diese Schlag-Karten
            geben dir die Möglichkeit, über Bäume hinweg zu schlagen, erleiden keine Rough-Strafe,
            sind jedoch mit einer Einschränkung versehen. Bevor du Karten ziehst, musst du die
            Richtung angeben, in die der Chip geht.
          </P>
        </SideBySide>
        <H4>Eine Richtung deklarieren</H4>
        <P>
          Diese Regel bedeutet, dass man sich für eine der sechs Richtungen entscheidet, die das
          Feld verlassen und dies den anderen Spielern mitteilt. Du kannst es entweder so
          beschreiben: "In Richtung Wasser schlagen" oder auf das Wasser zeigen. Du darfst deine
          Richtung nicht mehr ändern, nachdem du eine Schlag-Karte gezogen hast.
        </P>
        <H4>Schlag-Karten ziehen</H4>
        <P>Sobald du deine Richtung erklärt hast, ziehe wie gewohnt deine Karten.</P>
        <H4>Bewege deinen Golfer</H4>
        <P>
          Die wichtigste Änderung in der Bewegungsphase deines Golfers ist, dass du bereits erklärt
          hast, in welche Richtung du den Ball schlägst. Bewege deinen Golfer wie gewohnt mit einer
          Ausnahme.
        </P>
        <P>
          Wenn das Feld neben deinem Golfer ein Baum ist, machst du einen Chip über ihn und landest
          auf dem orangen Zielfeld. Du kannst immer noch nicht auf Bäumen landen, wenn dein Zielfeld
          ein Baumhindernis ist, dann landest du auf dem Feld, über das du gechipt hättest. Wenn
          dies ebenfalls ein Baumhindernis ist, bewegt sich dein Golfspieler nicht.
        </P>
        <SideBySide right={lobWedge} size={StandardLandscapeCardSize}>
          <P>
            Der Lob Wedge ist insofern ein einzigartiger Schläger, als dass du die Richtung, in die
            du den Ball schlagen wirst, vor dem Spiel nicht angeben musst.
          </P>
        </SideBySide>
        <Example>
          <P>
            Brock befindet sich in einer unangenehmen Lage mit Bäumen, die den Fortschritt auf dem
            Fairway blockieren. Er spielt sein treues 9 Iron und zeigt auf das Feld, über das er
            chippen will (Pfeil). Es ist ein sicheres Spiel, da die meisten Karten aus dem
            Chipstapel ihn auf das Fairway ziehen werden.
          </P>
          <P>
            Dann zieht er eine Schlag-Karte aus dem Chipstapel, um zu sehen, was das Schicksal zu
            bieten hat. Es zahlt sich aus und er erhält eine Schlag-Karte, die nach rechts driftet
            und ihn zurück auf das Fairway und in eine ausgezeichnete Position für seinen nächsten
            Schlag bringt.
          </P>
          <div style={{ height: 350, overflow: "hidden" }}>
            <img src={chippingExample} width={350} />
            <img
              src={deckCRight}
              width={SmallPortraitCardSize}
              style={{ position: "relative", left: 0, top: -150 }}
            />
          </div>
        </Example>
      </Section>
      <Section id="super-short-wild" title="SUPER, SHORT, SHUFFLE UND WILD-KARTEN">
        <H3>Super und Short</H3>
        <P>
          Bei den Super- und Short-Karten legt man sie auf den Tisch und zieht dann eine
          Ersatz-Schlag-Karte und legt sie über die Super- oder Short-Karte. Ziehe eine
          Ersatzabschlag-Karte aus dem auf der Super- oder Short-Karte aufgeführten Stapel.
        </P>
        <P>
          Diese Karten bilden eine Kette, so dass es möglich ist, wieder in dem Stapel zu landen,
          mit dem du begonnen hast, oder von Schlag-Stapel #2 zu Schlag-Stapel #5 zu gehen.
        </P>
        <P>
          Ein Supertreffer aus dem Chip-Stapel, der zu einem Zug aus einem anderen als dem
          Chip-Stapel führt, verliert seine Fähigkeit, über Bäume zu fliegen.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={superHit} width={StandardPortraitCardSize} />
          <img src={shortHit} width={StandardPortraitCardSize} />
        </div>
        <H3>Shuffle</H3>
        <P>
          Wenn du eine Shuffle-Karte ziehst, lege alle aktuellen abgelegten Karten aus diesem
          Schlag-Stapel wieder hinein und mische sie. Lege diese Karte auf den Tisch, ziehe eine
          Ersatzkarte und lege sie darauf. Ziehe die Ersatzkarte immer vom gleichen Stapel.
        </P>
        <P>
          Karten, die du in diesem Zug bisher gezogen hast, z. B. Superkarten oder andere Karten von
          Golfschlägern, mit denen du 2 Karten ziehen kannst, werden nicht wieder in das Stapel
          zurückgelegt.
        </P>
        <P>
          Wenn du eine gemischte Karte ziehst und kein Ablagestapel vorhanden ist, brauchst du das
          Stapel nicht zu mischen. Du musst trotzdem eine Ersatzkarte ziehen.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={shuffle} width={StandardPortraitCardSize} />
        </div>
        <H3 style={{ breakBefore: "page" }}>Joker-Karten</H3>
        <P>
          Wenn du eine Karte mit mehreren Ergebnissen ziehst, kannst du wählen, welche du verwenden
          möchtest. Wenn eine der Optionen nicht mehr gültig ist, musst du die einzige Option
          nehmen, die im Spiel bleibt.
        </P>
        <P>
          Siehe <A href="#hazards">HINDERNISSE &amp; ANDERE PLÄTTCHEN</A> für weitere Informationen
          über Out-of-Bounds
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={wildCard} width={StandardPortraitCardSize} />
        </div>
        <H3>LÄNGSTER DRIVE-KARTE</H3>
        <P>
          Wenn du diese Schlagkarte ziehst und spielst, behältst du die Längster Drive-Karte. Diese
          Karte ist dein Andenken daran, dass du die Längster Drive-Karte im Spiel geschlagen hast,
          und wenn du heute nicht gewinnst, gehst du wenigstens mit etwas nach Hause. Nicht die
          Karte, die bleibt in der Box. Du erhältst das Gefühl der selbstgefälligen Genugtuung, den
          längsten Drive gemacht zu haben.
        </P>
        <P>
          Diese Schlag-Karte bewegt sich 6 Felder in einer geraden Linie. Sie hält an Bäumen an, wie
          andere Schlag-Karten auch.
        </P>
        <div className="flex flex-row justify-evenly items-center">
          <img src={longestDriveCard} width={StandardPortraitCardSize} />
        </div>
      </Section>
      <Section id="hazards" title="HINDERNISSE &amp; ANDERE FELDER">
        <SideBySide title="Bunker" right={bunkerHex} size={SmallHex}>
          <P>
            Das Bunkerhindernis gilt für alle Runden, in denen du in diesem Feld beginnst. Wenn du
            dich im Bunker befindest, musst du herauskommen. Siehe&nbsp;
            <A href="#4d">“4.D) BUNKER VERLASSEN</A>.
          </P>
          <P>Du kannst den Sand Wedge verwenden, um Bunker als Rough zu behandeln.</P>
        </SideBySide>
        <SideBySide title="Green" right={greenHex} size={SmallHex}>
          <P>
            Dein Ziel. Je nach Spielmodus gewinnen die Spieler, die das Green zuerst erreichen, die
            Runde. Wenn du vom Green eines anderen Lochs aus spielst, berücksichtige das Feld als
            Fairway.
          </P>
        </SideBySide>
        <SideBySide title="Rough" right={roughHex} size={SmallHex}>
          <P>
            Schläge aus diesem Feld gehen ein Feld weniger. Nachdem du deinen Schuss gezogen hast,
            suche nach dem Rough-Symbol auf der Schlag-Karte. Dieses Symbol zeigt an, wo dein Ball
            landet. Siehe&nbsp;<A href="#rough">SPIELEN AUS DEM ROUGH</A>.
          </P>
        </SideBySide>
        <div style={{ breakBefore: "page" }}>
          <SideBySide title="Out-of-Bounds &amp; CLUBHAUS" right={clubhouse} size={SmallHex}>
            <P>
              Immer wenn dein Ball den Platz verlässt, ist er im Aus. Ein Out-of-Bounds liegt auch
              dann vor, wenn der Ball einen Teil seines Fluges außerhalb des Spielfeldes fliegt und
              auf ein Feld zurückkehrt. Wenn du "out-of-bound" bist, bleibt dein Golfer dort, wo er
              den Schlag begonnen hat. Bringe deinen Schläger wieder auf deine Hand zurück; deine
              Runde ist beendet.
            </P>
            <P>
              Eine Landung auf dem Clubhaus gilt ebenfalls als Out-of-Bounds, es sei denn, du
              spielst auf das 19te Loch.
            </P>
            <P>
              <em>
                Hinweis: Die Diamant-Eckstücke der Golfplatz-Plättchen zählen als Felder im Spiel,
                du kannst auf ihnen landen.
              </em>
            </P>
          </SideBySide>
        </div>
        <div
          className="flex p-8 justify-center items-center"
          style={{ height: 300, overflow: "hidden" }}
        >
          <div className="pt-16">
            <img src={courseTile} width={CourseTileSize} />
            <div
              style={{
                position: "relative",
                top: -240,
                left: 35,
                borderRadius: 75,
                width: 75,
                height: 75,
                flex: 0,
                borderColor: "red",
                borderWidth: 4,
              }}
            />
          </div>
        </div>
        <H5>Variation</H5>
        <P>
          Wir haben die "Out-of-Bounds"-Regel entwickelt, weil die meisten Spieler sich ohne Raster
          nicht im Out-of-Bounds-Raum bewegen konnten. Aber einige Spieler können es. Wenn sich alle
          Spieler einverstanden erklären, diese Variante zu verwenden, kannst du den Spielern
          erlauben, durch die Out-Of-Bounds zu schlagen. In einem Out-of-Bounds zu landen ist immer
          ein Out-of-Bounds.
        </P>
        <SideBySide title="Abschlagfeld" right={teeboxHex} size={SmallHex}>
          <P>
            Das Abschlagfeld ist der Ort, an dem jedes Loch beginnt. Diese Felder zeigen das Par an
            und wie viele große Golfplatz-Plättchen vor dem Green hinzugefügt werden müssen. Auf der
            letzten Seite der Golfbplatzbroschüre findest du weitere Informationen zum Erstellen von
            Golfplätzen.
          </P>
        </SideBySide>
        <SideBySide title="Bäume" right={treeHex} size={SmallHex}>
          <P>
            Bäume stoppen den Flug deines Balls. Bewege deinen Ball ein Feld rückwärts entlang der
            Flugbahn. Wenn das nächste Feld ein Hindernis darstellt, befolge die Regeln für dieses
            Hindernis.
          </P>
          <P>
            <em>Hinweis: Nur Bäume mit einem Zaun um sie herum stoppen den Flug des Balles.</em>
          </P>
        </SideBySide>
        <SideBySide
          title="Wasser (UND Fairways mit Seen)"
          RightComponent={WaterHazards}
          size={SmallHex}
        >
          <P>
            Wasserhindernisse treten auf, wenn der Ball seinen Flug auf diesem Feld beendet. Du
            verpasst deinen nächsten Zug. Lege deinen Golfer hin. Bei deinem nächsten Zug stellst du
            deinen Golfer auf und überspringst die Runde. In der darauffolgenden Runde kannst du
            wieder spielen.
          </P>
          <P>
            Du spielst deinen nächsten Schlag von dem Wasserfeld aus. Spiele das Wasserfeld als
            Rough, es sei denn, es handelt sich um ein Fairway mit einem See. In diesem Fall gilt es
            als Fairway.
          </P>
          <P>Der Shark ignoriert diese Hindernis und betrachtet nur das darunterliegende Feld.</P>
        </SideBySide>
      </Section>
      <Section id="golfers" title="Golfer-Fähigkeiten">
        <P>
          Es gibt acht verschiedene Golfer in 18 Holes und jeder von ihnen bringt eine andere
          Erfahrung mit sich. Jeder Golfer hat eine A-Seite und eine B-Seite. Die A-Seite
          erleichtert das Spiel und die B-Seite macht das Spiel schwieriger. In der unteren rechten
          Ecke befindet sich ein A oder ein B.
        </P>
        <H3>BUNJIL</H3>
        <SideBySide right={golfer2a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Unmittelbar bevor Bunjil seinen Golfschläger zum Spielen auswählt, darf er einmal auf
            die oberste Karte eines Stapel schauen. Drehe Bunjil seitwärts (quer), um seine
            eingesetzte Kraft anzuzeigen. Der Spieler darf die Karte den anderen Spielern nicht
            zeigen. Lege die Karte mit der Vorderseite nach unten auf die Oberseite des
            Schlag-Stapel zurück.
          </P>
          <P>Wenn das Loch beendet ist, drehe Bunjil wieder in die Hochformat-Ausrichtung.</P>
          <P>
            Bunjil darf nur in Schlag-Stapel eine Karte anschauen, aus denen er rechtmäßig ziehen
            darf.
          </P>
        </SideBySide>
        <SideBySide right={golfer2b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Wie bei der A-Seite muss sich Bunjil entscheiden, entweder die Schlag-Karte zu behalten
            und sie beim nächsten Schlag zu verwenden oder die Karte oben auf das Schlag-Stapel
            zurückzulegen, dieses mal offen, so dass alle Spieler sie sehen können.
          </P>
          <P>
            Wenn Bunjil die Karte behält, muss der Spieler einen Golfschläger spielen, der Zugang zu
            diesem Schlag-Stapel hat.
          </P>
        </SideBySide>
        <H3>Dorian</H3>
        <SideBySide right={golfer8a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Nach der Karten-Auswahl, vor dem ersten Loch, sagt der Spieler des Dorian dem Rest der
            Gruppe, welcher seiner Schläger nie abläuft. Immer wenn er diesen Schläger verwendet,
            nimmt er ihn auf die Hand zurück, anstatt ihn wegzuwerfen.
          </P>
          <P>
            Dorian darf seine Schläger während eines Lochs nicht zurücksetzen, darf aber seine
            Schläger am Ende eines Lochs zurücksetzen, wenn sein Blatt nur seinen einen Schläger
            enthält, der nie abläuft (und den Regeln für ein Hand-Reset entspricht).
          </P>
        </SideBySide>
        <SideBySide right={golfer8b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Dorian wirft seine Schläger nach Gebrauch niemals ab. Dorian darf während der Auswahl
            aber nur drei Schläger mitnehmen.
          </P>
        </SideBySide>
        <H3>Iron Jane</H3>
        <SideBySide right={golfer3a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Vor der Golfschläger-Auswahl wählt der Spieler, der Iron Jane spielt, zuerst alle seine
            Golfschläger aus den verfügbaren Golfschlägern aus. Er mischt die verbleibenden Karten
            und teilt sie an die anderen Spieler aus.
          </P>
        </SideBySide>
        <SideBySide right={golfer3b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Wie auf der A-Seite, aber Iron Jane darf nur vier Schläger mitführen. Iron Jane darf
            nicht zwei gleiche Schläger mitnehmen.
          </P>
        </SideBySide>
        <H3>Lucky</H3>
        <SideBySide right={golfer6a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Während des Spiels kann Lucky einmal pro Blatt eine Schlag-Karte neu ziehen. Dazu teilt
            der Spieler des Lucky dies der Spielgruppe mit und dreht Lucky dann zur Seite (quer), um
            ihre eingesetzte Kraft anzuzeigen. Der Spieler zieht dann eine neue Schlag-Karte aus dem
            gleichen Stapel der weggelegten Karte. Lucky muss diese neue Karte spielen.
          </P>
          <P>Während Lucky quer orientiert ist, kannst du diese Kraft nicht nutzen.</P>
          <P>
            Wenn du deine Hand zurücksetzt, stellst du Lucky auf die Hochformat-Ausrichtung zurück.
          </P>
        </SideBySide>
        <SideBySide right={golfer6b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Wie auf der A-Seite, aber zusätzlich, wenn die Fähigkeit von Lucky genutzt wird, wirft
            der Spieler einen seiner anderen Schläger ab. Wenn der Spieler keine anderen Schläger
            hat, darf er die Fähigkeit von Lucky nicht einsetzen.
          </P>
          <P>Du darfst Luckys Kraft nur einmal pro Loch ausführen.</P>
        </SideBySide>
        <H3>Slick Rikesh</H3>
        <SideBySide right={golfer4a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Nachdem alle Spieler ihre Golfschläger-Karten aufgedeckt haben, darf Slick Rikesh den
            von einem anderen Spieler gewählten Schläger verwenden. Die Golfschläger-Initiative von
            Slick Rikesh ist die gleiche, wie die des Schlägers des ursprünglichen Besitzers und
            spielt unmittelbar danach. Slick Rikesh darf seine Kraft nur einmal pro Loch einsetzen.
          </P>
          <P>
            Drehe Slick Rikesh seitlich (Quer), um die verwendete Fähigkeit anzuzeigen. Nach jedem
            Loch dann wieder auf die Hochformat-Position zurücksetzen.
          </P>
        </SideBySide>
        <SideBySide right={golfer4b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Der Spieler, der Slick Rikesh spielt, zieht nur eine Karte während der Schlägerauswahl
            und darf diesen Schläger aus jeder Runde der Schlägerauswahl wählen.
          </P>
          <P>
            Während des Spiels spielt Slick Rikesh seinen Golfschläger wie gewohnt (um die
            Initiative zu bestimmen) und kann dann einen beliebigen abgeworfenen Golfschläger eines
            anderen Spielers zur Verwendung auswählen. Wenn es keine abgeworfenen Golfschläger gibt,
            muss Slick Rikesh seinen eigenen Golfschläger spielen.
          </P>
        </SideBySide>
        <H3>Slugger</H3>
        <SideBySide right={golfer5a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Slugger muss den Driver tragen. Entferne diese Karte vor der Golfschläger-Auswahl aus
            dem Stapel und gib sie dem Slugger-Spieler. Slugger wählt während der Schlägerauswahl
            vier Schläger aus und überspringt die letzte Runde.
          </P>
          <P>
            Während des Spiels legst du nie den Driver ab. Nimm ihn immer auf deine Hand zurück.
          </P>
        </SideBySide>
        <SideBySide right={golfer5b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Wie auf der A-Seite, aber Slugger muss auf allen Abschlagsfeldern ein Wood oder einen
            Driver verwenden. Ja, einschließlich Par 3s.
          </P>
        </SideBySide>
        <H3>SWIFT</H3>
        <SideBySide right={golfer7a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Ziehe während des Schritts "Spielreihenfolge festlegen" einen Punkt von der Initiative
            des von Swift gespielten Schlägers ab. Wenn Swifts Initiative mit einem anderen Spieler
            gleichauf liegt, ist Swift zuerst dran.
          </P>
        </SideBySide>
        <SideBySide right={golfer7b} size={StandardPortraitCardSize}>
          <H4>B-Seite</H4>
          <P>
            Ziehe während des Schritts "Spielreihenfolge festlegen" einen Punkt von der Initiative
            des von Swift gespielten Schlägers ab. Wenn Swifts Initiative mit einem anderen Spieler
            gleichauf liegt, ist der andere Spieler zuerst dran.
          </P>
        </SideBySide>
        <H3>The Shark</H3>
        <SideBySide right={golfer1a} size={StandardPortraitCardSize}>
          <H4>A-Seite</H4>
          <P>
            Wasserhindernisse sind für The Shark kein Thema, auch nicht auf Fairways. Behandle sie
            einfach wie das Feld unter dem Wasserhindernis.
          </P>
        </SideBySide>
        <H4>B-Seite</H4>
        <SideBySide right={golfer1b} size={StandardPortraitCardSize}>
          <P>Wie auf der A-Seite, aber The Shark darf keine Wedges mitführen.</P>
          <P>Zu den Wedges gehören Pitching Wedge, Sand Wedge und der Lob Wedge.</P>
          <P>
            Während der Schlägerauswahl-Phase muss der Spieler, der The Shark hat, passen, wenn die
            einzig verfügbaren Schläger Wedges sind. The Shark kann nach der Schläger-Auswahl aus
            den verbleibenden Karten auswählen.
          </P>
        </SideBySide>
      </Section>
      <Section id="game-modes" title="MEHRERE SPIELMÖGLICHKEITEN">
        <P>
          18 Holes, wie Golf, hat eine Vielzahl von verschiedenen Spielmodi. Bei der in diesem Buch
          beschriebenen primären Methode konkurrieren alle Spieler um jedes Loch. Golf ist ein
          hartes Spiel, bei dem es schwierig sein kann, aufzuholen, wenn man zurückfällt. Wenn dir
          diese Art von Spiel gefällt oder du ein realistischeres Golferlebnis wünscht, dann
          interessiert du dich vielleicht für Golf Rennen, Stroke Play oder Stableford.
        </P>
        <P>
          Wenn deine Gruppe Spiele bevorzugt, bei denen alle um jeden Punkt konkurrieren, dann
          versuche es mit Match Play und Skins.
        </P>
        <P>
          Einige Varianten entfernen sich etwas weiter vom Fairway. Diese sind für Leute, die ein
          weniger traditionelles Golferlebnis wünschen. Die Chaos Golf-Varianten und Kelly Golf
          passen auf diese Beschreibung.
        </P>
        <P>
          Wenn du eine Solo-Erfahrung haben möchtest, versuche Solo Chaos Golf, Stableford oder
          Stroke Play.
        </P>
        <P>
          Wenn du ein Spiel mit zwei Spielern willst, dann probiere Chaos Teams: unsere engagierte
          Erfahrung mit zwei Spielern. Oder auch Match Play, Skins, Stroke Play, Stableford oder
          Golf Racing.
        </P>
        <P>
          Jeder Spielmodus wird mit einem Abschnitt geliefert, der den Spielmodus beschreibt, wie
          viele Spieler er unterstützt und warum du dich für dieses Spiel interessieren könntest.
          Auf der rechten Seite haben wir den Abschnitt zum Match Play, dem bis hierher
          beschriebenen Regelsatz.
        </P>
        <P>Index der Variationen:</P>
        <ul className={ul}>
          <li className={link}>
            <Link to={ModesChaosGolf}>Chaos Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosTeams}>Chaos Teams</Link>
          </li>
          <li className={link}>
            <Link to={ModesChaosGolfSolo}>Chaos Golf Solo</Link>
          </li>
          <li className={link}>
            <Link to={ModesGolfRacing}>Golf Racing</Link>
          </li>
          <li className={link}>
            <Link to={ModesKellyGolf}>Kelly Golf</Link>
          </li>
          <li className={link}>
            <Link to={ModesMatchPlay}>Match Play</Link>
          </li>
          <li className={link}>
            <Link to={ModesSkins}>Skins</Link>
          </li>
          <li className={link}>
            <Link to={ModesStableford}>Stableford</Link>
          </li>
          <li className={link}>
            <Link to={ModesStrokePlay}>Stroke Play</Link>
          </li>
        </ul>
      </Section>
    </div>
  );
};
