import axios from "axios";

const options = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getUserAccount = (token, onTokenExpired) => {
  return axios
    .get("https://api.18holesgame.com/user", options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};

export const updateUserAccount = async (
  token,
  onTokenExpired,
  username,
  designerName
) => {
  const body = {
    username,
    designerName,
  };

  return axios
    .post("https://api.18holesgame.com/user", body, options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};

export const updateUserDisplayName = async (
  token,
  onTokenExpired,
  designerName
) => {
  const body = {
    designerName,
  };

  return axios
    .post("https://api.18holesgame.com/user", body, options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};

export const getUserPublicProfile = (userId, token, onTokenExpired) => {
  return axios
    .get(`https://api.18holesgame.com/public/${userId}/profile`, options(token))
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.info("Token has expired");
        onTokenExpired();
      }

      console.error(error);

      return Promise.reject();
    });
};
