import React, { useState, useEffect } from "react";
import fairway from "../../../images/fairway.png";
import teebox from "../../../images/teebox.png";
import green from "../../../images/green.png";
import { withOnFilterChange, initialFilterState } from "../Palette";
import { BrandGreen } from "../../../colours";

const Tooltip = ({ text }) => {
  return null;
  // return (
  //   <div className="absolute mx-2 opacity-0 hover:opacity-100">
  //     <div
  //       className="bg-black text-white text-xs rounded py-1 px-4 right-0 relative pointer-events-none"
  //       style={{ bottom: "100%", top: -45 }}
  //     >
  //       {text}
  //       <svg
  //         className="absolute text-black h-2 w-full left-0"
  //         style={{ top: "100%" }}
  //         x="0px"
  //         y="0px"
  //         viewBox="0 0 255 255"
  //         xmlSpace="preserve"
  //       >
  //         <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
  //       </svg>
  //     </div>
  //   </div>
  // );
};

const FiltersBase = ({ onFilterChange }) => {
  const [filters, setFilters] = useState(initialFilterState);

  const createToggleFilter = (filter) => () => {
    setFilters((current) => ({
      ...current,
      [filter]: !current[filter],
    }));
  };

  const showNone = () => {
    setFilters({
      showGreens: false,
      showFairways: false,
      showWater: false,
      showBunker: false,
      showTrees: false,
      showUnused: false,
      showTeeboxes: false,
      showExpansion: false,
      showExclusive: false,
    });
  };

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  return (
    <div className="flex flex-row flex-1 rounded justify-end items-center pb-1">
      <p className="uppercase text-md lg:text-xl text-right text-white mr-2">Filters</p>
      <div
        className="p-1 w-8 h-8 lg:w-12 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showTeeboxes")}
        style={{ backgroundColor: filters.showTeeboxes ? "orange" : BrandGreen }}
      >
        <Tooltip text="Filter teeboxes" />
        <img
          src={teebox}
          className="w-5 h-5 lg:w-8 lg:h-8"
          alt="toggle filter by tiles with par 5 teeboxes"
        />
      </div>
      <div
        className="p-1 w-8 h-8 lg:w-12 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showFairways")}
        style={{
          backgroundColor: filters.showFairways ? "orange" : BrandGreen,
        }}
      >
        <Tooltip text="Filter By Fairways" />
        <img
          src={fairway}
          className="w-5 h-5 lg:w-8 lg:h-8"
          alt="toggle filter by tiles with fairways"
        />
      </div>
      <div
        className="p-1 w-8 h-8 lg:w-12 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showGreens")}
        style={{ backgroundColor: filters.showGreens ? "orange" : BrandGreen }}
      >
        <Tooltip text="Filter By Greens" />
        <img
          src={green}
          className="w-5 h-5 lg:w-8 lg:h-8"
          alt="toggle filter by tiles with green"
        />
      </div>
      <div
        className="p-1 w-16 h-8 lg:w-16 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showExpansion")}
        style={{ backgroundColor: filters.showExpansion ? "orange" : BrandGreen }}
      >
        <Tooltip text="Filter By Expansion" />
        <span className="text-sm">Expansion</span>
      </div>
      <div
        className="p-1 w-16 h-8 lg:w-16 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showExclusive")}
        style={{ backgroundColor: filters.showExclusive ? "orange" : BrandGreen }}
      >
        <Tooltip text="Filter By Expansion" />
        <span className="text-sm">Exclusive</span>
      </div>
      <div
        className="p-1 w-8 h-8 lg:w-12 lg:h-12 rounded cursor-pointer mr-1 hover:opacity-50 select-none flex justify-center items-center"
        onClick={createToggleFilter("showUnused")}
        style={{ backgroundColor: filters.showUnused ? "orange" : BrandGreen }}
      >
        <Tooltip text="Filter By Used" />
        <span className="text-sm">Unused</span>
      </div>
      <div
        className="p-1 w-8 h-8 lg:w-12 lg:h-12 rounded border-white border flex-row text-center flex justify-center items-center cursor-pointer select-none hover:opacity-50"
        onClick={showNone}
      >
        <Tooltip text="Hide all tiles" />
        None
      </div>
    </div>
  );
};

const Filters = withOnFilterChange(FiltersBase);

export default Filters;
