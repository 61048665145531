import React, { Component, useEffect, useState } from "react";
import KeyHandler, { KEYPRESS, KEYDOWN } from "react-key-handler";
import { defineGrid, extendHex } from "honeycomb-grid";
import { toast } from "react-toastify";
import PaletteControl from "./Palette/Palette";
import BrushShapes, { getRotatedBrush } from "./brushes";
import meta from "./meta.json";
import adjustmentMeta from "./adjustment-meta.json";
import adjustmentMetaMapping from "./adjustment-meta-mapping.json";

import { SiteNavigation } from "../navigation/SiteNavigation";
import Course from "./Course";
import CourseInfo from "./CourseInfo";
import Toolbox from "./Toolbox";
import { ensureLoggedIn, withToken } from "../auth/react-auth0-wrapper";
import { deleteCourse, getPrivateCourse, updateCourse, publishCourse } from "../services/private";
import { useHistory } from "react-router-dom";
import { BrowseCourses, MyCourses } from "../navigation/locations";
import { rotate, Exits2Direction, Exits, getRotations } from "./PathForHoleFrame";
import { holeColours } from "../colours";
import AdjustmentHexPaletteControl from "./Palette/AdjustmentHexPaletteControl";
import { Button } from "../common/Button";
import { canPublishCourse } from "./can-publish-course";
import { coastlinesTiles, coastlinesExclusiveTiles } from "./coastlinesTiles";

const hexesWide = 13;
const hexesHigh = 11;

const getColourForhole = (used = []) => {
  return holeColours.filter((c) => !used.includes(c))[0] || "black";
};

const Hex = extendHex({ size: 50, orientation: "flat", offset: 0 });
const Grid = defineGrid(Hex);
const courseGrid = Grid.rectangle({
  width: hexesWide,
  height: hexesHigh,
  direction: 1,
});

const id = (q, r) => `${q}|${r}`;

const Offsets = {
  NW: { col: -1, row: 0 },
  N: { col: 0, row: -1 },
  NE: { col: +1, row: -1 },
  SE: { col: +1, row: 0 },
  S: { col: 0, row: +1 },
  SW: { col: -1, row: +1 },
};

const getNRowCol = (col, row, direction) => {
  return [col + Offsets[direction].col, row + Offsets[direction].row];
};

const getNeighbour = (positions, col, row, direction) => {
  const [nCol, nRow] = getNRowCol(col, row, direction);

  return positions.find((p) => p.ref.col === nCol && p.ref.row === nRow);
};

const resolveStatusForCourse = (greenConfiguration, used) => {
  if (greenConfiguration) {
    const oneClub = (greenConfiguration.oneClub && greenConfiguration.oneClub !== 0) || 4;
    const twoClub = (greenConfiguration.twoClub && greenConfiguration.twoClub !== 0) || 10;
    const threeClub = (greenConfiguration.threeClub && greenConfiguration.threeClub !== 0) || 4;

    if (oneClub >= 13) {
      return "coastlines-exclusive";
    }
    if (twoClub >= 13) {
      return "coastlines-exclusive";
    }
    if (threeClub >= 13) {
      return "coastlines-exclusive";
    }
  }

  const tilesUsed = used.filter(Boolean);
  const isCoastlines = tilesUsed.filter((tileId) => coastlinesTiles.includes(tileId)).length > 0;

  const hasExclusiveTile =
    tilesUsed.filter((tileId) => coastlinesExclusiveTiles.includes(tileId)).length > 0;

  if (hasExclusiveTile) {
    return "coastlines-exclusive";
  }
  if (isCoastlines) {
    return "coastlines";
  }

  return "base";
};

const loadFromRemote = (greenConfiguration) => {
  if (!greenConfiguration) {
    return {
      oneClub: 4,
      twoClub: 10,
      threeClub: 4,
    };
  }

  const oneClub = greenConfiguration.oneClub === 0 ? 0 : greenConfiguration.oneClub || 4;
  const twoClub = greenConfiguration.twoClub === 0 ? 0 : greenConfiguration.twoClub || 10;
  const threeClub = greenConfiguration.threeClub === 0 ? 0 : greenConfiguration.threeClub || 4;

  return {
    oneClub: oneClub,
    twoClub: twoClub,
    threeClub: threeClub,
  };
};

class CourseDesignerBase extends Component {
  constructor(props) {
    super(props);

    const findSaved = (id) => props.course.positions.find((p) => p.id === id);

    const startPosition = courseGrid.map((hex) => {
      const refId = id(hex.q, hex.r);
      const savedHex = findSaved(id(hex.q, hex.r));

      return {
        outline: hex.toPoint().add(hex.center()).add({ x: 10, y: 16 }),
        image: hex.toPoint(),
        ref: {
          row: hex.r,
          col: hex.q,
          q: hex.q,
          r: hex.r,
          id: refId,
        },
        rotation: savedHex ? savedHex.rotation : 0,
        brush: savedHex ? savedHex.tile : null,
        tile: savedHex ? getRotatedBrush(savedHex.tile) : null,
        hasError: false,
      };
    });

    /**
     * @type {object} adjustments
     */
    const adjustments = props.course.positions.reduce(
      (obj, pos) => ({
        ...obj,
        [pos.id]: pos.adjustments,
      }),
      {}
    );

    const usedAdjustments = determineUsedAdjustmentsFromSave(props.course.positions);

    const usedTiles = [...startPosition.map(({ brush }) => brush)];

    const greenConfiguration = loadFromRemote(props.course.greenConfiguration);

    this.state = {
      positions: startPosition,
      selected: {},
      brush: null,
      brushShape: null,
      showCursor: false,
      rotation: 0,
      x: 0,
      y: 0,
      height: window.document.body.clientHeight,
      saved: true,
      showGrid: true,
      showPar: true,
      showPositions: false,
      showIds: false,
      showHoleNumbers: false,
      showGreenNumbers: false,
      noOverlays: false,
      used: usedTiles,
      usedAdjustments,
      adjustments,

      par: 0,
      teeboxes: 0,
      greens: 0,
      difficulty: 0,
      tiles: 0,

      isMoving: false,

      courseName: props.course.displayName || "Unnamed Meadows",
      tileMouseIsOver: null,
      tilePlacementErrors: [],
      holeNumbers: props.course.holeNumbers || [],
      holeStartTiles: [],
      greenNumbers: [],
      holeNumberReferenceColours: [],

      canPublish: false,

      greenConfiguration: greenConfiguration,

      windConfiguration: {
        TL: (props.course.windConfiguration && props.course.windConfiguration.TL) || "3",
        TR: (props.course.windConfiguration && props.course.windConfiguration.TR) || "3",
        TM: (props.course.windConfiguration && props.course.windConfiguration.TM) || "3",
        BR: (props.course.windConfiguration && props.course.windConfiguration.BR) || "3",
        BM: (props.course.windConfiguration && props.course.windConfiguration.BM) || "3",
        BL: (props.course.windConfiguration && props.course.windConfiguration.BL) || "3",
        S: (props.course.windConfiguration && props.course.windConfiguration.S) || "1",
        C: (props.course.windConfiguration && props.course.windConfiguration.C) || "3",
      },

      status: resolveStatusForCourse(props.course.greenConfiguration, usedTiles),
    };

    this.id = setInterval(this.encode, 10000);
  }

  findNeighbours = (position) => {
    const tileRotation = getRotations(position.rotation);
    const exits = Exits[meta[position.brush].holeFrame];

    return exits
      .map((exit) => {
        const rotatedHoleFrame = rotate(exit, tileRotation);
        const requiredHoleFrameForNeighbour = rotate(rotatedHoleFrame, 3);

        const neighbourTile = getNeighbour(
          this.state.positions,
          position.ref.col,
          position.ref.row,
          Exits2Direction[rotatedHoleFrame]
        );

        if (!neighbourTile) {
          return null;
        }

        const tileMetaForNeighbour = meta[neighbourTile.brush];
        if (!tileMetaForNeighbour) {
          return null;
        }

        const nRotation = getRotations(neighbourTile.rotation);
        const exitsForNeighbour = Exits[tileMetaForNeighbour.holeFrame].map((exit) =>
          rotate(exit, nRotation)
        );

        if (!exitsForNeighbour.includes(requiredHoleFrameForNeighbour)) {
          return null;
        }

        return neighbourTile;
      })
      .filter(Boolean);
  };

  componentDidMount() {
    this.setState({ height: window.document.documentElement.offsetHeight });
    this.updateCourseInfo();
  }

  componentWillUnmount() {
    window.clearInterval(this.id);
  }

  validateBrushes = () => {};

  updateCourseInfo = () => {
    const validtiles = this.state.positions.filter(
      ({ brush }) => brush !== "rough" && brush !== null
    );

    const par = validtiles
      .map(({ brush }) => {
        return meta[brush].par.reduce((t, a) => t + a, 0);
      })
      .reduce((t, a) => t + a, 0);

    const teeboxes = validtiles
      .map(({ brush }) => meta[brush].par.length)
      .reduce((t, a) => t + a, 0);

    const greens = validtiles.map(({ brush }) => meta[brush].greens).reduce((t, a) => t + a, 0);

    const difficulty = validtiles
      .map(({ brush }) => meta[brush].difficulty)
      .reduce((t, a) => t + a, 0);

    const withErrors = [
      ...this.state.positions
        .map((position) => {
          if (!position.brush) {
            return null;
          }

          const tileMeta = meta[position.brush];
          if (!tileMeta) {
            return null;
          }

          if (tileMeta.holeFrame === "NONE") {
            return null;
          }

          const tileRotation = getRotations(position.rotation);
          const exits = Exits[tileMeta.holeFrame] || [];
          const blockedExits = exits.filter((exit) => {
            const rotatedHoleFrame = rotate(exit, tileRotation);
            const requiredHoleFrameForNeighbour = rotate(rotatedHoleFrame, 3);

            const neighbourTile = getNeighbour(
              this.state.positions,
              position.ref.col,
              position.ref.row,
              Exits2Direction[rotatedHoleFrame]
            );

            if (!neighbourTile) {
              return true;
            }

            const tileMetaForNeighbour = meta[neighbourTile.brush];
            if (!tileMetaForNeighbour) {
              return false;
            }

            const nRotation = getRotations(neighbourTile.rotation);
            const exitsForNeighbour = Exits[tileMetaForNeighbour.holeFrame].map((exit) =>
              rotate(exit, nRotation)
            );

            if (exitsForNeighbour.includes(requiredHoleFrameForNeighbour)) {
              return false;
            }

            return true;
          });

          return {
            outline: position.outline,
            ref: position.ref,
            hasError: blockedExits.length > 0,
          };
        })
        .filter(Boolean)
        .filter(({ hasError }) => hasError),
    ];

    const unsortedTeeboxTiles = this.state.positions
      .filter(({ brush }) => brush)
      .filter(({ brush }) => meta[brush])
      .filter(
        ({ brush }) =>
          (meta[brush].isTeeBox && !meta[brush].hasGreen) || meta[brush].isSingleTileHex
      )
      .map((position) => {
        const id = position.ref.id;
        const existingRecord = this.state.holeNumbers.find((holeNumber) => holeNumber.id === id);
        return {
          id,
          holeNumber: existingRecord ? existingRecord.holeNumber : null,
          position,
        };
      });

    unsortedTeeboxTiles.sort((a, b) => a.holeNumber - b.holeNumber);

    const teeboxTiles = [
      ...unsortedTeeboxTiles.map((record, i) => ({
        ...record,
        holeNumber: i + 1,
      })),
    ];

    const usedColours = [...this.state.holeNumberReferenceColours.map(({ colour }) => colour)];
    const holeStartTiles = teeboxTiles.map(({ id, holeNumber, position }) => {
      if (withErrors.find((tile) => tile.ref.id === id)) {
        return { id, holeNumber, otherTiles: [] };
      }
      if (meta[position.brush].isSingleTileHex) {
        return { id, holeNumber, otherTiles: [] };
      }

      const otherTiles = [];
      let exit = false;
      let startPoint = position;
      let visited = [id];
      while (!exit) {
        const neighbours = this.findNeighbours(startPoint);
        const notYetVisited = neighbours.filter((n) => !visited.includes(n.ref.id));
        if (notYetVisited.length !== 1) {
          exit = true;
        }
        if (notYetVisited.length === 1) {
          const neighbour = notYetVisited[0];

          visited.push(neighbour.ref.id);
          otherTiles.push(neighbour.ref.id);

          exit = meta[neighbour.brush].hasGreen;
          startPoint = neighbour;
        }
      }

      const previousUsedColour = this.state.holeNumberReferenceColours.find(
        (colourRef) => colourRef.id === id
      );
      const colour = previousUsedColour ? previousUsedColour.colour : getColourForhole(usedColours);

      usedColours.push(colour);

      return {
        id,
        holeNumber,
        otherTiles,
        colour,
      };
    });

    const holeNumbers = holeStartTiles.reduce((set, { id, holeNumber, otherTiles, colour }) => {
      const tilesWithHoleNumbers = otherTiles
        .map((id) => this.state.positions.find((position) => position.ref.id === id))
        .map(({ ref }) => ({ id: ref.id, holeNumber, colour }));

      return set.concat({ id, holeNumber, colour }).concat(...tilesWithHoleNumbers);
    }, []);

    const holeStartTilesWithMeta = holeStartTiles.map((hole) => {
      const tile = this.state.positions.find((position) => position.ref.id === hole.id);
      const metaForTile = meta[tile.brush];

      const parForOtherTiles = hole.otherTiles
        .map((otherTileId) => {
          const otherTile = this.state.positions.find(
            (position) => position.ref.id === otherTileId
          );
          const metaForOtherTile = meta[otherTile.brush];

          return metaForOtherTile.par;
        })
        .reduce((all, s) => all.concat(s), []);

      return {
        id: hole.id,
        holeNumber: hole.holeNumber,
        colour: hole.colour,
        tileCount: hole.otherTiles.length + 1,
        par: metaForTile.par.concat(parForOtherTiles),
      };
    });

    const holeNumberReferenceColours = holeStartTiles.map(({ id, colour }) => ({
      id,
      colour,
    }));

    const greenNumbers = holeNumbers
      .map(({ id, holeNumber }) => {
        const position = this.state.positions.find((position) => position.ref.id === id);

        return {
          id,
          holeNumber,
          hasGreen: meta[position.brush].hasGreen,
          greenCount: meta[position.brush].greens,
        };
      })
      .filter(({ hasGreen }) => hasGreen)
      .reduce((tiles, tile) => {
        const lastGreen = tiles.reduce((t, tile) => Math.max(...tile.greens), 0);

        return tiles.concat({
          id: tile.id,
          greens: Array(tile.greenCount)
            .fill(0)
            .map((_, i) => i + 1)
            .map((i) => lastGreen + i),
        });
      }, []);

    this.setState({
      par,
      teeboxes,
      greens,
      difficulty,
      tiles: validtiles.length,
      tilePlacementErrors: withErrors,
      holeNumbers,
      greenNumbers,
      holeStartTiles: holeStartTilesWithMeta,
      holeNumberReferenceColours,
      canPublish: canPublishCourse(withErrors, teeboxes, greens, holeNumbers, this.state.positions),
    });
  };

  paint = ({ offsetX, offsetY }) => {
    const selected = Grid.pointToHex(offsetX, offsetY);
    const hexId = id(selected.q, selected.r);

    const otherPositions = this.state.positions.filter(({ ref }) => ref.id !== hexId);
    const thisPosition = this.state.positions.find(({ ref }) => ref.id === hexId);

    if (!thisPosition) {
      return;
    }

    const brush = this.state.brush;

    if (thisPosition.brush === brush) {
      return;
    }

    const rotation = this.state.rotation;

    const oldRefId = thisPosition.ref.id;
    const oldBrush = thisPosition.brush;
    const oldRotation = thisPosition.rotation;

    const nextBrush = !this.state.erasing ? oldBrush : "rough";
    const nextRotation = !this.state.erasing ? oldRotation : 0;

    thisPosition.brush = brush;
    thisPosition.rotation = rotation;
    thisPosition.tile = getRotatedBrush(brush);

    const newPositions = otherPositions.concat(thisPosition);

    const adjustments = {
      ...this.state.adjustments,
      [oldRefId]: {},
      [thisPosition.ref.id]: {},
    };

    const newUsed = [...newPositions.map(({ brush }) => brush)];

    this.setState({
      positions: newPositions,
      used: newUsed,
      saved: false,
      brush: nextBrush,
      brushShape: getRotatedBrush(nextBrush),
      isMoving: nextBrush && nextBrush !== "rough",
      rotation: nextRotation,
      adjustments,
      usedAdjustments: determineUsedAdjustmentsFromAdjustments(adjustments),
      status: resolveStatusForCourse(this.state.greenConfiguration, newUsed),
    });

    this.validateBrushes();
    this.updateCourseInfo();
  };

  placeAdjustmentHex = (partClicked, refId) => {
    this.setState((current) => {
      return {
        adjustments: {
          ...current.adjustments,
          [refId]: {
            ...current.adjustments[refId],
            [partClicked]: adjustmentMeta[current.adjustmentBrush],
          },
        },
        usedAdjustments: current.usedAdjustments.concat(current.adjustmentBrush),
        adjustmentBrush: null,
        brushShape: null,
        saved: false,
      };
    });
  };

  trackMouse = ({ offsetX, offsetY }) => {
    const selected = Grid.pointToHex(offsetX, offsetY);

    var x = 50 * ((3 / 2) * selected.q) + selected.center().x;
    var y =
      50 * ((Math.sqrt(3) / 2) * selected.q + Math.sqrt(3) * selected.r) + selected.center().y;

    const distance = Math.sqrt(Math.pow(offsetX - x, 2) + Math.pow(offsetY - y, 2));

    if (distance > 30 && this.state.adjustmentBrush) {
      this.setState({
        x: offsetX,
        y: offsetY,
      });

      return;
    }

    const highlight = id(selected.q, selected.r);
    const tileMouseIsOver = this.state.positions.find(({ ref }) => ref.id === highlight);

    this.setState({
      x: offsetX,
      y: offsetY,
      selected,
      highlight,
      tileMouseIsOver: tileMouseIsOver && tileMouseIsOver.brush,
    });
  };

  onBrushSelect = (brush) => {
    this.setState({
      brush,
      brushShape: BrushShapes[brush],
      rotation: 0,
    });
  };

  onAdjustmentBrushSelect = (brush) => {
    this.setState(() => ({
      adjustmentBrush: brush,
      brushShape: BrushShapes.hazards[adjustmentMeta[brush]],
      rotation: 0,
    }));
  };

  hideCursor = () => {
    this.setState({ showCursor: false });
  };

  showCursor = () => {
    this.setState({ showCursor: true });
  };

  rotate = () => {
    const newRotation = this.state.rotation + 60;
    const wrappedRotation = newRotation >= 360 ? 0 : newRotation;

    this.setState({
      rotation: wrappedRotation,
      brushShape: getRotatedBrush(this.state.brush),
    });
  };

  encode = () => {
    if (this.state.saved) {
      return;
    }

    this.props.save(
      this.state.positions,
      this.state.courseName,
      this.state.holeNumbers,
      this.state.greenNumbers,
      this.state.adjustments,
      this.state.greenConfiguration,
      this.state.windConfiguration
    );
    this.setState({ saved: true });
  };

  publish = () => {
    this.props.publish();
  };

  toggleGrid = () => {
    this.setState({ showGrid: !this.state.showGrid });
  };

  togglePar = () => {
    this.setState({
      noOverlays: false,
      showPar: true,
      showPositions: false,
      showIds: false,
      showHoleNumbers: false,
      showGreenNumbers: false,
    });
  };

  togglePositions = () => {
    this.setState({
      noOverlays: false,
      showPar: false,
      showPositions: true,
      showIds: false,
      showHoleNumbers: false,
      showGreenNumbers: false,
    });
  };

  toggleIds = () => {
    this.setState({
      noOverlays: false,
      showPar: false,
      showPositions: false,
      showIds: true,
      showHoleNumbers: false,
      showGreenNumbers: false,
    });
  };

  toggleHoleNumbers = () => {
    this.setState({
      noOverlays: false,
      showPar: false,
      showPositions: false,
      showIds: false,
      showHoleNumbers: true,
      showGreenNumbers: false,
    });
  };

  toggleGreenNumbers = () => {
    this.setState({
      noOverlays: false,
      showPar: false,
      showPositions: false,
      showIds: false,
      showHoleNumbers: false,
      showGreenNumbers: true,
    });
  };

  onHideOverlays = () => {
    this.setState({
      noOverlays: true,
      showPar: false,
      showPositions: false,
      showIds: false,
      showHoleNumbers: false,
      showGreenNumbers: false,
    });
  };

  updateName = (e) => {
    this.setState({ courseName: e.target.value, saved: false });
  };

  toggleEraser = () => {
    this.setState({
      brush: "rough",
      brushShape: BrushShapes.rough,
      erasing: true,
    });
  };

  toggleMove = () => {
    this.setState({
      brush: null,
      brushShape: null,
      erasing: false,
    });
  };

  cancelEraser = () => {
    if (this.state.isMoving) {
      return;
    }

    this.setState({
      brush: null,
      brushShape: null,
      adjustmentBrush: null,
      erasing: false,
    });
  };

  deleteCourse = async () => {
    await this.props.deleteThisCourse();
  };

  swapHoles = (holeNumbers, fromHoleNumber, newHoleNumber) => {
    return holeNumbers
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === fromHoleNumber ? "XXX" : hole.holeNumber,
      }))
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === newHoleNumber ? fromHoleNumber : hole.holeNumber,
      }))
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === "XXX" ? newHoleNumber : hole.holeNumber,
      }));
  };

  swapHoleStartTiles = (holeStartTiles, fromHoleNumber, newHoleNumber) => {
    return holeStartTiles
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === fromHoleNumber ? "XXX" : hole.holeNumber,
      }))
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === newHoleNumber ? fromHoleNumber : hole.holeNumber,
      }))
      .map((hole) => ({
        ...hole,
        holeNumber: hole.holeNumber === "XXX" ? newHoleNumber : hole.holeNumber,
      }));
  };

  moveHole = (oldholeNumber, newHoleNumber) => {
    this.setState((currentState) => {
      const range = Math.abs(oldholeNumber - newHoleNumber);

      const newHoleNumbers = Array(range)
        .fill(0)
        .map((_, i) => (oldholeNumber > newHoleNumber ? newHoleNumber + i : newHoleNumber - i))
        .reduce((holeNumbers, toHoleNumber) => {
          return this.swapHoles(holeNumbers, oldholeNumber, toHoleNumber);
        }, currentState.holeNumbers);

      const newHoleStartTiles = Array(range)
        .fill(0)
        .map((_, i) => (oldholeNumber > newHoleNumber ? newHoleNumber + i : newHoleNumber - i))
        .reduce((holeStartTiles, toHoleNumber) => {
          return this.swapHoles(holeStartTiles, oldholeNumber, toHoleNumber);
        }, currentState.holeStartTiles);

      newHoleStartTiles.sort((a, b) => a.holeNumber - b.holeNumber);

      return {
        holeNumbers: newHoleNumbers,
        holeStartTiles: newHoleStartTiles,
        saved: false,
      };
    });
  };

  onGreenCardSetHandler = (putters, count) => {
    this.setState((current) => {
      const newGreenConfiguration = {
        oneClub: putters === 1 ? count : current.greenConfiguration.oneClub,
        twoClub: putters === 2 ? count : current.greenConfiguration.twoClub,
        threeClub: putters === 3 ? count : current.greenConfiguration.threeClub,
      };

      return {
        greenConfiguration: newGreenConfiguration,
        saved: false,
        status: resolveStatusForCourse(newGreenConfiguration, current.used),
      };
    });
  };

  onWindConfigurationChange = (direction, count) => {
    this.setState((current) => {
      const newWindConfiguration = {
        TL: direction === "TL" ? `${count}` : current.windConfiguration.TL,
        TR: direction === "TR" ? `${count}` : current.windConfiguration.TR,
        TM: direction === "TM" ? `${count}` : current.windConfiguration.TM,
        BR: direction === "BR" ? `${count}` : current.windConfiguration.BR,
        BM: direction === "BM" ? `${count}` : current.windConfiguration.BM,
        BL: direction === "BL" ? `${count}` : current.windConfiguration.BL,
        S: direction === "S" ? `${count}` : current.windConfiguration.S,
        C: direction === "C" ? `${count}` : current.windConfiguration.C,
      };

      return {
        windConfiguration: newWindConfiguration,
        saved: false,
      };
    });
  };

  render() {
    return (
      <PaletteControl onBrushSelect={this.onBrushSelect} used={this.state.used}>
        <AdjustmentHexPaletteControl
          onBrushSelect={this.onAdjustmentBrushSelect}
          used={this.state.usedAdjustments}
        >
          <SiteNavigation />
          <div className="flex-col flex-no-grow flex m-0 w-full bg-brand-green p-2">
            <KeyHandler keyEventName={KEYPRESS} keyValue="r" onKeyHandle={this.rotate} />
            <KeyHandler keyEventName={KEYDOWN} keyValue="Escape" onKeyHandle={this.cancelEraser} />
            <div className="mx-auto" style={{ width: "1280px" }}>
              <CourseInfo
                courseName={this.state.courseName}
                showGrid={this.state.showGrid}
                showPar={this.state.showPar}
                erasing={this.state.erasing}
                teeboxes={this.state.teeboxes}
                greens={this.state.greens}
                difficulty={this.state.difficulty}
                tiles={this.state.tiles}
                par={this.state.par}
                onNameChange={this.updateName}
                onToggleGrid={this.toggleGrid}
                onTogglePar={this.togglePar}
                onToggleEraser={this.toggleEraser}
                onToggleMove={this.toggleMove}
                onDeleteCourse={this.deleteCourse}
                showPositions={this.state.showPositions}
                showIds={this.state.showIds}
                showHoleNumbers={this.state.showHoleNumbers}
                showGreenNumbers={this.state.showGreenNumbers}
                onTogglePositions={this.togglePositions}
                onToggleIds={this.toggleIds}
                onToggleHoleNumbers={this.toggleHoleNumbers}
                onToggleGreenNumbers={this.toggleGreenNumbers}
                noOverlays={this.state.noOverlays}
                onHideOverlays={this.onHideOverlays}
              />
              <div
                className="flex-1 flex flex-row justify-end items-start"
                style={{ height: "1000px" }}
              >
                <Course
                  onClick={({ nativeEvent }) => this.paint(nativeEvent)}
                  onMouseMove={({ nativeEvent }) => this.trackMouse(nativeEvent)}
                  onMouseLeave={this.hideCursor}
                  onMouseEnter={this.showCursor}
                  positions={this.state.positions}
                  highlight={this.state.highlight}
                  showPar={this.state.showPar}
                  showGrid={this.state.showGrid}
                  showCursor={this.state.showCursor}
                  showPositions={this.state.showPositions}
                  showHoleNumbers={this.state.showHoleNumbers}
                  showGreenNumbers={this.state.showGreenNumbers}
                  showIds={this.state.showIds}
                  brush={this.state.brush}
                  adjustmentBrush={this.state.adjustmentBrush}
                  rotation={this.state.rotation}
                  x={this.state.x}
                  y={this.state.y}
                  brushShape={this.state.brushShape}
                  tilePlacementErrors={this.state.tilePlacementErrors}
                  holeNumbers={this.state.holeNumbers}
                  greenNumbers={this.state.greenNumbers}
                  placeAdjustmentHex={this.placeAdjustmentHex}
                  adjustments={this.state.adjustments}
                  isErasing={this.state.erasing}
                />
                <div className="flex-1 flex-row justify-center items-center pl-2 w-full">
                  <Toolbox
                    used={this.state.used}
                    usedAdjustments={this.state.usedAdjustments}
                    tileMouseIsOver={this.state.tileMouseIsOver}
                    holeStartTiles={this.state.holeStartTiles}
                    onMoveHole={this.moveHole}
                    greenConfiguration={this.state.greenConfiguration}
                    onGreenCardSetHandler={this.onGreenCardSetHandler}
                    status={this.state.status}
                    windConfiguration={this.state.windConfiguration}
                    onWindConfigurationChange={this.onWindConfigurationChange}
                    withErrors={this.state.tilePlacementErrors}
                    teeboxes={this.state.teeboxes}
                    greens={this.state.greens}
                    holeNumbers={this.state.holeNumbers}
                    positions={this.state.positions}
                    publish={this.publish}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdjustmentHexPaletteControl>
      </PaletteControl>
    );
  }
}

const Thing = ({ token, courseId, onTokenExpired }) => {
  let history = useHistory();
  const [course, setCourse] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getPrivateCourse(token, courseId, onTokenExpired, history).then((courseData) => {
      setCourse(courseData);
      setLoaded(true);
    });
  }, [courseId]);

  const publish = async () => {
    await publishCourse(token, courseId, onTokenExpired, history);
  };

  const save = async (
    appPositions,
    displayName,
    holeNumbers,
    greenNumbers,
    adjustments,
    greenConfiguration,
    windConfiguration
  ) => {
    const unsortedPositions = appPositions
      .filter(({ brush }) => brush !== "rough")
      .filter(({ brush }) => !!brush)
      .map(({ ref, brush, rotation }) => {
        const holeNumber = holeNumbers.find(({ id }) => id === ref.id);
        const greenNumber = greenNumbers.find(({ id }) => id === ref.id);

        const isTeeBoxOnly =
          meta[brush].isTeeBox &&
          !meta[brush].hasGreen &&
          holeNumber &&
          holeNumber.holeNumber === 1;
        const isSingleTileHex =
          meta[brush].isSingleTileHex && holeNumber && holeNumber.holeNumber === 1;

        return {
          id: ref.id,
          tile: brush,
          rotation,
          isStartTile: isTeeBoxOnly || isSingleTileHex,
          greens: greenNumber ? greenNumber.greens : [],
          holeNumber: holeNumber && holeNumber.holeNumber,
        };
      });

    unsortedPositions.sort((a, b) => a.holeNumber - b.holeNumber);

    const remapAdjustments = (adjustments, rotation) => {
      return Object.keys(adjustments)
        .map((hex) => {
          return [hex, hex];
        })
        .reduce(
          (obj, [orig, remapped]) => ({
            ...obj,
            [remapped]: adjustments[orig],
          }),
          {}
        );
    };

    const positions = unsortedPositions.map((position) => {
      return {
        id: position.id,
        tile: position.tile,
        rotation: position.rotation,
        isStartTile: position.isStartTile,
        greens: position.greens,
        adjustments: remapAdjustments(adjustments[position.id], position.rotation),
      };
    });

    const holeNumberToSend = holeNumbers.map((holeNumber) => ({
      id: holeNumber.id,
      holeNumber: holeNumber.holeNumber,
    }));

    await updateCourse(
      token,
      onTokenExpired,
      courseId,
      positions,
      holeNumberToSend,
      greenConfiguration,
      windConfiguration,
      displayName
    );

    toast.info("Saved", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const deleteThisCourse = async () => {
    await deleteCourse(token, onTokenExpired, courseId);

    history.push(MyCourses);
  };

  return loaded ? (
    <>
      <div className="w-full h-full fixed block top-0 left-0 bg-brand-green flex flex-col justify-center items-center xl:hidden">
        <p className="text-white text-4xl text-center" style={{ width: "80%" }}>
          Screen is too small for Course Designer
        </p>
        <p className="text-white text-2xl text-center text-teal-200 pt-4" style={{ width: "80%" }}>
          The 18 Holes Course Designer currently supports screens that are 1280px in width or
          larger.
        </p>
        <div className="pt-8">
          <Button href={BrowseCourses}>Back to Browse Courses</Button>
        </div>
      </div>
      <div className="hidden xl:block">
        <CourseDesignerBase
          course={course}
          save={save}
          publish={publish}
          deleteThisCourse={deleteThisCourse}
        />
      </div>
    </>
  ) : (
    <div className="w-full h-full fixed block top-0 left-0 bg-brand-green flex justify-center items-center">
      <svg
        className="animate-spin h-5 w-5 mr-3 text-black"
        width="100"
        height="100"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

const RouteHandler = ({
  match: {
    params: { courseId },
  },
  ...props
}) => <Thing {...props} courseId={courseId} />;

const CourseDesigner = ensureLoggedIn(withToken(RouteHandler));

export default CourseDesigner;

/**
 * @param {CoursePosition[]} positions
 * @returns {AdjustmentTileIds[]}
 */
function determineUsedAdjustmentsFromSave(positions = []) {
  return positions
    .map(({ adjustments = {} }) => {
      const places = Object.keys(adjustments);

      return places.map((place) => adjustments[place]).filter(Boolean);
    })
    .reduce((all, set) => all.concat(set), [])
    .reduce((used, type) => {
      const tileOptions = adjustmentMetaMapping[type].filter((tile) => !used.includes(tile));
      if (tileOptions.length === 0) {
        console.error("Too many adjustment tiles of type");
        return used;
      }

      return used.concat(tileOptions[0]);
    }, [])
    .filter(Boolean);
}

/**
 * @param {object} adjustments
 * @returns {AdjustmentTileIds[]}
 */
function determineUsedAdjustmentsFromAdjustments(adjustments = {}) {
  return Object.keys(adjustments)
    .map((ref) => adjustments[ref])
    .map((adjustments) => {
      const places = Object.keys(adjustments);

      return places.map((place) => adjustments[place]).filter(Boolean);
    })
    .reduce((all, set) => all.concat(set), [])
    .reduce((used, type) => {
      const tileOptions = adjustmentMetaMapping[type].filter((tile) => !used.includes(tile));
      if (tileOptions.length === 0) {
        console.error("Too many adjustment tiles of type");
        return used;
      }

      return used.concat(tileOptions[0]);
    }, [])
    .filter(Boolean);
}
